import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { 
  DocumentPlusIcon, 
  DocumentTextIcon, 
  DocumentDuplicateIcon, 
  ChartBarIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import axiosInstance from '../utils/axiosInstance';
import InternalQuoteBuilder from '../components/InternalQuoteBuilder';
import QuoteModal from '../components/QuoteModal';
import Modal from '../components/Modal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const QuotesHub = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [quotes, setQuotes] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    action: null
  });

  // Add new state for notifications
  const [notification, setNotification] = useState({
    show: false,
    message: '',
    type: '' // 'success' or 'error'
  });

  // Add to state variables at the top
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState('one-time');
  const [selectedQuoteId, setSelectedQuoteId] = useState(null);
  const [selectedQuotes, setSelectedQuotes] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalQuotes: 0,
    hasNextPage: false,
    hasPrevPage: false
  });

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState([]);

  // Add new state for selected templates
  const [selectedTemplates, setSelectedTemplates] = useState(new Set());

  const tabs = [
    { name: 'Create Quote', icon: DocumentPlusIcon, param: 'create' },
    { name: 'Manage Quotes', icon: DocumentTextIcon, param: 'manage' },
    { name: 'Templates', icon: DocumentDuplicateIcon, param: 'templates' },
    { name: 'Analytics', icon: ChartBarIcon, param: 'analytics' }
  ];

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const index = tabs.findIndex(t => t.param === tab);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
    fetchQuotes();
    fetchTemplates();
    fetchCustomers();
  }, [searchParams]);

  const fetchQuotes = async (page = currentPage) => {
    try {
      const response = await axiosInstance.get(`/quotes?page=${page}`);
      setQuotes(response.data.quotes);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching quotes:', error);
      setError('Failed to fetch quotes. Please try again.');
    }
  };

  const fetchTemplates = async () => {
    try {
      const response = await axiosInstance.get('/quotes/templates');
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching templates:', error);
      setError('Failed to fetch templates. Please try again.');
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      showNotification('Failed to fetch customers', 'error');
    }
  };

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    navigate(`/quotes?tab=${tabs[index].param}`, { replace: true });
  };

  // Helper to show notifications
  const showNotification = (message, type = 'success') => {
    setNotification({ show: true, message, type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: '' });
    }, 3000);
  };

  // Helper to show confirmation modal
  const showConfirmation = (title, message, action) => {
    setConfirmationModal({
      isOpen: true,
      title,
      message,
      action
    });
  };

  const handleStatusChange = async (quote, newStatus) => {
    showConfirmation(
      'Update Quote Status',
      `Are you sure you want to change the status to ${newStatus}?`,
      async () => {
        try {
          await axiosInstance.put(`/quotes/${quote._id}`, {
            status: newStatus
          });
          
          // Update local state
          setQuotes(quotes.map(q => 
            q._id === quote._id ? { ...q, status: newStatus } : q
          ));
          
          showNotification('Quote status updated successfully');
        } catch (error) {
          console.error('Error updating quote status:', error);
          showNotification('Failed to update quote status', 'error');
        }
        setConfirmationModal({ isOpen: false, title: '', message: '', action: null });
      }
    );
  };

  const handleDeleteQuote = async (quoteId) => {
    showConfirmation(
      'Delete Quote',
      'Are you sure you want to delete this quote? This action cannot be undone.',
      async () => {
        try {
          await axiosInstance.delete(`/quotes/${quoteId}`);
          setQuotes(quotes.filter(q => q._id !== quoteId));
          showNotification('Quote deleted successfully');
        } catch (error) {
          console.error('Error deleting quote:', error);
          
          // Handle 404 errors specifically - the quote might have been deleted already
          if (error.response && error.response.status === 404) {
            // Still update the UI to remove the quote
            setQuotes(quotes.filter(q => q._id !== quoteId));
            showNotification('Quote has been deleted', 'success');
          } else {
            showNotification('Failed to delete quote', 'error');
          }
        }
        setConfirmationModal({ isOpen: false, title: '', message: '', action: null });
      }
    );
  };

  const handleConvertToJob = async () => {
    try {
      // Instead of immediately converting, mark the quote as ready for conversion
      const response = await axiosInstance.post(`/quotes/${selectedQuoteId}/mark-for-conversion`, {
        frequency: selectedFrequency
      });
      
      // Update quotes list with the new status
      setQuotes(quotes.map(q => 
        q._id === selectedQuoteId ? { ...q, status: 'pending_conversion' } : q
      ));
      
      setShowFrequencyModal(false);
      setSelectedQuoteId(null);
      showNotification('Quote marked for conversion. You can now edit job details before finalizing.', 'success');
    } catch (error) {
      console.error('Error marking quote for conversion:', error);
      showNotification(
        error.response?.data?.details || error.response?.data?.message || 'Failed to mark quote for conversion',
        'error'
      );
    }
  };

  const handleConvertClick = (quoteId) => {
    setSelectedQuoteId(quoteId);
    setShowFrequencyModal(true);
  };

  const handleBulkDelete = async () => {
    if (selectedQuotes.size === 0) return;

    showConfirmation(
      'Delete Selected Quotes',
      `Are you sure you want to delete ${selectedQuotes.size} selected quotes? This action cannot be undone.`,
      async () => {
        try {
          const selectedQuoteIds = Array.from(selectedQuotes);
          const response = await axiosInstance.delete('/quotes/bulk', {
            data: { quoteIds: selectedQuoteIds }
          });
          
          // Clear selection and refresh the current page
          setSelectedQuotes(new Set());
          await fetchQuotes(currentPage);
          
          showNotification(`Successfully deleted ${response.data.deletedCount} quotes`);
        } catch (error) {
          console.error('Error bulk deleting quotes:', error);
          const errorMessage = error.response?.data?.message || 'Failed to delete quotes';
          showNotification(errorMessage, 'error');
        } finally {
          setConfirmationModal({ isOpen: false, title: '', message: '', action: null });
        }
      }
    );
  };

  // Add a select all function
  const handleSelectAll = (checked) => {
    if (checked) {
      const newSelected = new Set(quotes.map(q => q._id));
      setSelectedQuotes(newSelected);
    } else {
      setSelectedQuotes(new Set());
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchQuotes(newPage);
  };

  const renderCreateQuote = () => (
    <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-white">Create New Quote</h2>
        <p className="mt-2 text-gray-400">Create professional quotes for your customers</p>
      </div>
      <InternalQuoteBuilder />
    </div>
  );

  const renderManageQuotes = () => (
    <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-white">Manage Quotes</h2>
          <p className="mt-2 text-gray-400">View and manage all your quotes</p>
        </div>
        <div className="flex items-center gap-4">
          {quotes.length > 0 && (
            <label className="flex items-center gap-2 text-gray-300">
              <input
                type="checkbox"
                checked={selectedQuotes.size === quotes.length}
                onChange={(e) => handleSelectAll(e.target.checked)}
                className="w-5 h-5 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
              />
              Select All
            </label>
          )}
          {selectedQuotes.size > 0 && (
            <button
              onClick={handleBulkDelete}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
            >
              Delete Selected ({selectedQuotes.size})
            </button>
          )}
        </div>
      </div>
      <div className="space-y-4">
        {quotes.map((quote) => (
          <div
            key={quote._id}
            className={`bg-gray-700/50 rounded-lg p-4 flex justify-between items-center border ${
              selectedQuotes.has(quote._id) ? 'border-blue-500' : 'border-gray-600'
            }`}
          >
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                checked={selectedQuotes.has(quote._id)}
                onChange={(e) => {
                  const newSelected = new Set(selectedQuotes);
                  if (e.target.checked) {
                    newSelected.add(quote._id);
                  } else {
                    newSelected.delete(quote._id);
                  }
                  setSelectedQuotes(newSelected);
                }}
                className="w-5 h-5 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
              />
              <div>
                <div className="flex items-center gap-3 mb-1">
                  <p className="text-lg font-bold text-white">Quote #{quote.quoteNumber}</p>
                  <span className={`px-2 py-1 rounded text-xs ${
                    quote.status === 'draft' ? 'bg-gray-600 text-gray-200' :
                    quote.status === 'sent' ? 'bg-blue-900/50 text-blue-200' :
                    quote.status === 'approved' ? 'bg-green-900/50 text-green-200' :
                    quote.status === 'declined' ? 'bg-red-900/50 text-red-200' :
                    quote.status === 'expired' ? 'bg-yellow-900/50 text-yellow-200' :
                    quote.status === 'converted' ? 'bg-purple-900/50 text-purple-200' :
                    quote.status === 'pending_conversion' ? 'bg-orange-900/50 text-orange-200' :
                    'bg-gray-900/50 text-gray-200'
                  }`}>
                    {quote.status === 'pending_conversion' ? 'PENDING CONVERSION' : quote.status?.toUpperCase()}
                  </span>
                </div>
                <p className="text-gray-300">{quote.customerId?.name || 'No Customer'}</p>
                <div className="flex items-center gap-4 mt-1">
                  <p className="text-blue-400">
                    <strong>Total:</strong> ${quote.totalAmount.toFixed(2)}
                  </p>
                  {quote.createdAt && (
                    <p className="text-sm text-gray-400">
                      Created: {new Date(quote.createdAt).toLocaleDateString()}
                    </p>
                  )}
                  {quote.emailSentDate && (
                    <p className="text-sm text-gray-400">
                      Sent: {new Date(quote.emailSentDate).toLocaleDateString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <select
                value={quote.status}
                onChange={(e) => handleStatusChange(quote, e.target.value)}
                className="px-3 py-2 bg-gray-800 text-white rounded border border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-500/50"
              >
                <option value="draft">Draft</option>
                <option value="sent">Sent</option>
                <option value="approved">Approved</option>
                <option value="declined">Declined</option>
                <option value="expired">Expired</option>
                <option value="converted">Converted</option>
                <option value="pending_conversion">Pending Conversion</option>
              </select>
              <button
                onClick={() => setSelectedQuote(quote)}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                View
              </button>
              {quote.status !== 'converted' && quote.status !== 'pending_conversion' && (
                <button
                  onClick={() => handleConvertClick(quote._id)}
                  className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                >
                  Convert to Job
                </button>
              )}
              {quote.status === 'pending_conversion' && (
                <button
                  onClick={() => navigate(`/jobs/create?from_quote=${quote._id}`)}
                  className="px-4 py-2 bg-orange-600 text-white rounded-md hover:bg-orange-700 transition-colors"
                >
                  Edit Job Details
                </button>
              )}
            </div>
          </div>
        ))}
        {quotes.length === 0 && (
          <p className="text-gray-400 text-center py-4">No quotes found.</p>
        )}
      </div>
      
      {/* Pagination */}
      {pagination.totalPages > 1 && (
        <div className="mt-6 flex justify-center gap-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={!pagination.hasPrevPage}
            className={`px-4 py-2 rounded-md ${
              pagination.hasPrevPage
                ? 'bg-blue-600 hover:bg-blue-700 text-white'
                : 'bg-gray-700 text-gray-400 cursor-not-allowed'
            }`}
          >
            Previous
          </button>
          <span className="px-4 py-2 text-white">
            Page {pagination.currentPage} of {pagination.totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={!pagination.hasNextPage}
            className={`px-4 py-2 rounded-md ${
              pagination.hasNextPage
                ? 'bg-blue-600 hover:bg-blue-700 text-white'
                : 'bg-gray-700 text-gray-400 cursor-not-allowed'
            }`}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );

  const handleUseTemplate = (template) => {
    setSelectedTemplate(template);
    setShowTemplateModal(true);
  };

  const handleDeleteTemplate = (templateId) => {
    showConfirmation(
      'Delete Template',
      'Are you sure you want to delete this template? This action cannot be undone.',
      async () => {
        try {
          await axiosInstance.delete(`/quotes/templates/${templateId}`);
          setTemplates(templates.filter(t => t._id !== templateId));
          showNotification('Template deleted successfully');
        } catch (error) {
          console.error('Error deleting template:', error);
          const errorMessage = error.response?.data?.details || error.response?.data?.message || 'Failed to delete template';
          showNotification(errorMessage, 'error');
        }
        setConfirmationModal({ isOpen: false, title: '', message: '', action: null });
      }
    );
  };

  const handleCreateQuoteFromTemplate = async () => {
    if (!selectedTemplate || !selectedCustomer) {
      showNotification('Please select a customer', 'error');
      return;
    }

    try {
      const response = await axiosInstance.post(`/quotes/templates/${selectedTemplate._id}/use`, {
        customerId: selectedCustomer
      });

      showNotification('Quote created successfully from template');
      setShowTemplateModal(false);
      setSelectedTemplate(null);
      setSelectedCustomer(null);

      // If we're on the manage quotes tab, refresh the quotes
      if (selectedIndex === 1) {
        fetchQuotes(1);
      }

      // Navigate to manage quotes tab
      handleTabChange(1);
    } catch (error) {
      console.error('Error creating quote from template:', error);
      const errorMessage = error.response?.data?.details || error.response?.data?.message || 'Failed to create quote from template';
      showNotification(errorMessage, 'error');
    }
  };

  // Update handleSelectAllTemplates function
  const handleSelectAllTemplates = (checked) => {
    if (checked) {
      // Select all templates, regardless of usage count
      const allTemplates = templates.map(t => t._id);
      setSelectedTemplates(new Set(allTemplates));
    } else {
      setSelectedTemplates(new Set());
    }
  };

  // Update handleBulkDeleteTemplates function
  const handleBulkDeleteTemplates = async () => {
    if (selectedTemplates.size === 0) return;

    showConfirmation(
      'Delete Selected Templates',
      `Are you sure you want to delete ${selectedTemplates.size} selected templates? This action cannot be undone.`,
      async () => {
        try {
          const selectedTemplateIds = Array.from(selectedTemplates);
          const response = await axiosInstance.delete('/quotes/templates/bulk', {
            data: { templateIds: selectedTemplateIds }
          });
          
          // Clear selection and refresh templates
          setSelectedTemplates(new Set());
          await fetchTemplates();
          
          showNotification(`Successfully deleted ${response.data.deletedCount} templates`);
        } catch (error) {
          console.error('Error bulk deleting templates:', error);
          const errorMessage = error.response?.data?.details || error.response?.data?.message || 'Failed to delete templates';
          showNotification(errorMessage, 'error');
        } finally {
          setConfirmationModal({ isOpen: false, title: '', message: '', action: null });
        }
      }
    );
  };

  const renderTemplates = () => (
    <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h2 className="text-2xl font-bold text-white">Quote Templates</h2>
          <p className="mt-2 text-gray-400">Manage your quote templates</p>
        </div>
        <div className="flex items-center gap-4">
          {templates.length > 0 && (
            <label className="flex items-center gap-2 text-gray-300">
              <input
                type="checkbox"
                checked={selectedTemplates.size === templates.length}
                onChange={(e) => handleSelectAllTemplates(e.target.checked)}
                className="w-5 h-5 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
              />
              Select All
            </label>
          )}
          {selectedTemplates.size > 0 && (
            <button
              onClick={handleBulkDeleteTemplates}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
            >
              Delete Selected ({selectedTemplates.size})
            </button>
          )}
        </div>
      </div>
      <div className="space-y-4">
        {templates.map((template) => {
          const isUsed = template.usageCount > 0;
          return (
            <div
              key={template._id}
              className={`bg-gray-700/50 rounded-lg p-4 flex justify-between items-center border ${
                selectedTemplates.has(template._id) ? 'border-blue-500' : 'border-gray-600'
              }`}
            >
              <div className="flex items-center gap-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedTemplates.has(template._id)}
                    onChange={(e) => {
                      const newSelected = new Set(selectedTemplates);
                      if (e.target.checked) {
                        newSelected.add(template._id);
                      } else {
                        newSelected.delete(template._id);
                      }
                      setSelectedTemplates(newSelected);
                    }}
                    className="w-5 h-5 rounded border-gray-600 text-blue-600 focus:ring-blue-500 cursor-pointer"
                  />
                </div>
                <div>
                  <p className="text-lg font-bold text-white">{template.name}</p>
                  <p className="text-gray-300">{template.description}</p>
                  <div className="mt-1 text-sm text-gray-400">
                    <span>Usage Count: {template.usageCount || 0}</span>
                    {template.lastUsed && (
                      <span className="ml-4">
                        Last Used: {new Date(template.lastUsed).toLocaleDateString()}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => handleUseTemplate(template)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                >
                  Use Template
                </button>
                <button
                  onClick={() => handleDeleteTemplate(template._id)}
                  className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors"
                >
                  Delete
                </button>
              </div>
            </div>
          );
        })}
        {templates.length === 0 && (
          <p className="text-gray-400 text-center py-4">No templates found.</p>
        )}
      </div>
    </div>
  );

  const renderAnalytics = () => (
    <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-white">Quote Analytics</h2>
        <p className="mt-2 text-gray-400">Track your quote performance</p>
      </div>
      {/* Analytics components will go here */}
      <p className="text-gray-400">Analytics Coming Soon</p>
    </div>
  );

  // Add the frequency modal component
  const FrequencyModal = () => (
    <Modal
      isOpen={showFrequencyModal}
      onClose={() => setShowFrequencyModal(false)}
      title="Select Job Frequency"
    >
      <div className="p-6">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Job Frequency
          </label>
          <select
            value={selectedFrequency}
            onChange={(e) => setSelectedFrequency(e.target.value)}
            className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="one-time">One Time</option>
            <option value="weekly">Weekly</option>
            <option value="biweekly">Bi-Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowFrequencyModal(false)}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleConvertToJob}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
          >
            Convert to Job
          </button>
        </div>
      </div>
    </Modal>
  );

  // Add UseTemplateModal component
  const UseTemplateModal = () => (
    <Modal
      isOpen={showTemplateModal}
      onClose={() => {
        setShowTemplateModal(false);
        setSelectedTemplate(null);
        setSelectedCustomer(null);
      }}
      title="Create Quote from Template"
    >
      <div className="p-6">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Select Customer
          </label>
          <select
            value={selectedCustomer || ''}
            onChange={(e) => setSelectedCustomer(e.target.value)}
            className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select a customer</option>
            {customers.map((customer) => (
              <option key={customer._id} value={customer._id}>
                {customer.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => {
              setShowTemplateModal(false);
              setSelectedTemplate(null);
              setSelectedCustomer(null);
            }}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleCreateQuoteFromTemplate}
            disabled={!selectedCustomer}
            className={`px-4 py-2 rounded ${
              selectedCustomer
                ? 'bg-blue-600 hover:bg-blue-700 text-white'
                : 'bg-gray-600 text-gray-400 cursor-not-allowed'
            }`}
          >
            Create Quote
          </button>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      {/* Notification Toast */}
      {notification.show && (
        <div className={`fixed top-4 right-4 z-50 px-4 py-2 rounded-md shadow-lg ${
          notification.type === 'error' ? 'bg-red-600 text-white' : 'bg-green-600 text-white'
        }`}>
          {notification.message}
        </div>
      )}

      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-white">Quotes Hub</h1>
          <p className="mt-2 text-gray-400">Create and manage your quotes in one place</p>
        </div>

        <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
          <Tab.List className="flex space-x-2 rounded-xl bg-gray-800 p-1">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-blue-600 text-white shadow'
                        : 'text-gray-400 hover:bg-gray-700 hover:text-white'
                    )
                  }
                >
                  <div className="flex items-center justify-center space-x-2">
                    <Icon className="h-5 w-5" />
                    <span>{tab.name}</span>
                  </div>
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels className="mt-4">
            {tabs.map((tab, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  'rounded-xl bg-gray-800 p-3',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}
              >
                {tab.param === 'create' && renderCreateQuote()}
                {tab.param === 'manage' && renderManageQuotes()}
                {tab.param === 'templates' && renderTemplates()}
                {tab.param === 'analytics' && renderAnalytics()}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>

        {/* Confirmation Modal */}
        <Modal
          isOpen={confirmationModal.isOpen}
          onClose={() => setConfirmationModal({ isOpen: false, title: '', message: '', action: null })}
          title={confirmationModal.title}
        >
          <div className="p-6">
            <div className="flex items-center mb-4">
              <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500 mr-2" />
              <p className="text-white">{confirmationModal.message}</p>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setConfirmationModal({ isOpen: false, title: '', message: '', action: null })}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={() => confirmationModal.action()}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>

        {/* Quote Modal */}
        <QuoteModal
          isOpen={!!selectedQuote}
          onClose={() => setSelectedQuote(null)}
          quote={selectedQuote}
          onConvertToJob={handleConvertToJob}
          onDelete={handleDeleteQuote}
        />

        <FrequencyModal />
        <UseTemplateModal />
      </main>
    </div>
  );
};

export default QuotesHub; 