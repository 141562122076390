import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const LoadingScreen = () => (
  <div className="min-h-screen flex items-center justify-center bg-gray-900">
    <div className="text-center text-white">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto mb-4"></div>
      <h2 className="text-xl font-semibold mb-2">Loading...</h2>
      <p>Please wait while we verify your session...</p>
    </div>
  </div>
);

const ProtectedRoute = ({ children, requireSubscription = false }) => {
  const { user, loading, verifyToken } = useAuth();

  useEffect(() => {
    // If we're not loading and don't have a user, try to verify the token
    if (!loading && !user) {
      verifyToken().catch(() => {
        // Error handling is done in verifyToken
        console.log('[Auth Debug] ProtectedRoute: Token verification failed');
      });
    }
  }, [loading, user, verifyToken]);

  // Show loading state while checking authentication
  if (loading) {
    return <LoadingScreen />;
  }

  // Not logged in, redirect to login page
  if (!user) {
    console.log('[Auth Debug] ProtectedRoute: No user found, redirecting to signin');
    return <Navigate to="/signin" replace />;
  }

  // Check subscription requirement
  if (requireSubscription && (!user.subscriptionActive || !user.subscriptionTier)) {
    console.log('[Auth Debug] ProtectedRoute: No active subscription, redirecting to pricing');
    return <Navigate to="/pricing" replace state={{ 
      message: 'Please select a subscription plan to access this feature'
    }} />;
  }

  console.log('[Auth Debug] ProtectedRoute: Access granted');
  return children;
};

export default ProtectedRoute;
