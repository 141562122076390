import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Jobs from './Jobs';
import ManageJobs from './ManageJobs';
import Invoices from './Invoices';
import { BriefcaseIcon, ClipboardIcon, DocumentTextIcon, WrenchIcon, InformationCircleIcon, ExclamationCircleIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import axiosInstance from '../utils/axiosInstance';

const InputField = ({ 
  label, 
  name, 
  value, 
  onChange, 
  type = "text", 
  placeholder, 
  required = false,
  helpText = null 
}) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-300 mb-2" htmlFor={name}>
      {label}
    </label>
    <input
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
    />
    {helpText && (
      <p className="mt-1 text-xs text-gray-400">{helpText}</p>
    )}
  </div>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const JobsHub = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [services, setServices] = useState([]);
  const [currentServicesPage, setCurrentServicesPage] = useState(1);
  const [servicesPageSize, setServicesPageSize] = useState(10);
  const [serviceFormData, setServiceFormData] = useState({
    name: '',
    description: '',
    defaultPrice: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const tabs = [
    { name: 'Create Job', icon: BriefcaseIcon, component: Jobs, param: 'create' },
    { name: 'Manage Jobs', icon: ClipboardIcon, component: ManageJobs, param: 'manage' },
    { name: 'Invoices', icon: DocumentTextIcon, component: Invoices, param: 'invoices' },
    { name: 'Services', icon: WrenchIcon, param: 'services' }
  ];

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const index = tabs.findIndex(t => t.param === tab);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
    
    // Check if we need to fetch services or can use cached data
    const cachedServices = getServicesFromCache();
    if (cachedServices) {
      console.log('JobsHub: Using cached services data');
      setServices(cachedServices);
    } else {
      console.log('JobsHub: No cached services data, fetching fresh data');
      fetchServices();
    }
  }, [searchParams]);

  // Function to get cached services data
  const getServicesFromCache = () => {
    try {
      const cachedData = localStorage.getItem('servicesData');
      if (!cachedData) return null;
      
      const { data, timestamp } = JSON.parse(cachedData);
      const cacheAge = Date.now() - timestamp;
      
      // Cache expires after 30 minutes (1800000 ms)
      if (cacheAge < 1800000) {
        return data;
      } else {
        console.log('JobsHub: Services cache expired');
        return null;
      }
    } catch (error) {
      console.error('JobsHub: Error reading services from cache:', error);
      return null;
    }
  };

  // Function to cache services data
  const cacheServicesData = (data) => {
    try {
      const cacheData = {
        data,
        timestamp: Date.now()
      };
      localStorage.setItem('servicesData', JSON.stringify(cacheData));
    } catch (error) {
      console.error('JobsHub: Error caching services data:', error);
    }
  };

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    navigate(`/jobs?tab=${tabs[index].param}`, { replace: true });
    
    // If switching to Services tab, refresh the services data
    if (tabs[index].param === 'services') {
      // Force refresh services data when switching to Services tab
      fetchServices(true);
    }
  };

  // Function to force refresh services data
  const fetchServices = async (forceRefresh = false) => {
    try {
      // If not forcing refresh, check cache first
      if (!forceRefresh) {
        const cachedServices = getServicesFromCache();
        if (cachedServices) {
          console.log('JobsHub: Using cached services data');
          setServices(cachedServices);
          return;
        }
      }
      
      console.log('JobsHub: Fetching services data from API');
      const response = await axiosInstance.get('/services');
      setServices(response.data);
      
      // Cache the services data
      cacheServicesData(response.data);
    } catch (error) {
      console.error('JobsHub: Error fetching services:', error);
      setError('Failed to fetch services. Please try again.');
    }
  };

  const handleServiceFormChange = (e) => {
    setServiceFormData({ ...serviceFormData, [e.target.name]: e.target.value });
  };

  const handleAddService = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const response = await axiosInstance.post('/services', serviceFormData);
      const updatedServices = [...services, response.data];
      setServices(updatedServices);
      setServiceFormData({ name: '', description: '', defaultPrice: '' });
      setSuccess('Service added successfully!');
      
      // Update the cache with the new services data
      cacheServicesData(updatedServices);
    } catch (error) {
      console.error('JobsHub: Error adding service:', error);
      setError('Failed to add service. Please try again.');
    }
  };

  const handleDeleteService = async (serviceId) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        await axiosInstance.delete(`/services/${serviceId}`);
        const updatedServices = services.filter((service) => service._id !== serviceId);
        setServices(updatedServices);
        setSuccess('Service deleted successfully!');
        
        // Update the cache with the updated services data
        cacheServicesData(updatedServices);
      } catch (error) {
        console.error('JobsHub: Error deleting service:', error);
        setError('Failed to delete service. Please try again.');
      }
    }
  };

  // Add pagination logic for services
  const indexOfLastService = currentServicesPage * servicesPageSize;
  const indexOfFirstService = indexOfLastService - servicesPageSize;
  const currentServices = services.slice(indexOfFirstService, indexOfLastService);
  const totalServicesPages = Math.ceil(services.length / servicesPageSize);

  // Function to change services page
  const paginateServices = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalServicesPages) {
      setCurrentServicesPage(pageNumber);
    }
  };

  // Function to handle services page size change
  const handleServicesPageSizeChange = (e) => {
    setServicesPageSize(Number(e.target.value));
    setCurrentServicesPage(1); // Reset to first page when changing page size
  };

  const renderServices = () => (
    <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-white">Manage Services</h2>
        <p className="mt-2 text-gray-400">Create and manage the services you offer to your customers</p>
      </div>

      {/* Services Info Box */}
      <div className="mb-6 bg-blue-500/10 border border-blue-500/50 rounded-lg p-4 text-blue-300">
        <div className="flex items-start">
          <InformationCircleIcon className="h-6 w-6 mr-3 flex-shrink-0" />
          <div>
            <h3 className="font-semibold text-lg mb-1">Why Services Matter</h3>
            <p className="mb-2">Services define the types of work you offer to your customers. You need to create services before you can create jobs.</p>
            <ul className="list-disc list-inside space-y-1 text-sm">
              <li>Services appear in dropdown menus when creating jobs</li>
              <li>Each service can have a default price that will be pre-filled when creating jobs</li>
              <li>Services help you organize and categorize your work</li>
            </ul>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-900/50 border border-red-500 text-red-200 p-4 rounded-md mb-6">{error}</div>
      )}
      {success && (
        <div className="bg-green-900/50 border border-green-500 text-green-200 p-4 rounded-md mb-6">{success}</div>
      )}

      {/* Service Creation Form */}
      <div className="bg-gray-700/30 rounded-lg p-5 border border-gray-600 mb-8">
        <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
          <WrenchIcon className="h-5 w-5 mr-2 text-blue-400" />
          Add New Service
        </h3>
        
        <form onSubmit={handleAddService} className="space-y-4">
          <div className="grid md:grid-cols-2 gap-4">
            <InputField
              label="Service Name"
              name="name"
              value={serviceFormData.name}
              onChange={handleServiceFormChange}
              placeholder="e.g., Lawn Mowing, Hedge Trimming"
              required
              helpText="Enter a clear, specific name for this service"
            />
            <InputField
              label="Default Price ($)"
              name="defaultPrice"
              type="number"
              value={serviceFormData.defaultPrice}
              onChange={handleServiceFormChange}
              placeholder="e.g., 50.00"
              min="0"
              step="0.01"
              helpText="This price will be suggested when creating jobs with this service"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Description
            </label>
            <textarea
              name="description"
              value={serviceFormData.description}
              onChange={handleServiceFormChange}
              rows="3"
              className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Describe what this service includes, any limitations, or special requirements"
            />
            <p className="mt-1 text-xs text-gray-400">
              A detailed description helps your team understand what's included in this service
            </p>
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors flex items-center"
          >
            <span className="mr-2">+</span> Add Service
          </button>
        </form>
      </div>

      {/* Services List */}
      <div>
        <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
          <ClipboardIcon className="h-5 w-5 mr-2 text-blue-400" />
          Your Services
          <span className="ml-2 bg-blue-600 text-xs px-2 py-1 rounded-full text-white">
            {services.length}
          </span>
        </h3>
        
        {services.length === 0 ? (
          <div className="bg-gray-700/30 rounded-lg p-6 text-center border border-gray-600">
            <ExclamationCircleIcon className="h-10 w-10 mx-auto mb-3 text-gray-400" />
            <p className="text-gray-300 mb-2">You haven't created any services yet</p>
            <p className="text-gray-400 text-sm mb-4">Services are required before you can create jobs</p>
          </div>
        ) : (
          <div>
            <div className="space-y-4 mb-4">
              {currentServices.map((service) => (
                <div
                  key={service._id}
                  className="bg-gray-700/50 rounded-lg p-4 flex justify-between items-center border border-gray-600 hover:border-blue-500 transition-colors"
                >
                  <div>
                    <p className="text-lg font-bold text-white">{service.name}</p>
                    <p className="text-gray-300 text-sm">{service.description || 'No description provided'}</p>
                    <p className="text-blue-400 mt-1">
                      <strong>Default Price:</strong> ${parseFloat(service.defaultPrice).toFixed(2)}
                    </p>
                  </div>
                  <button
                    onClick={() => handleDeleteService(service._id)}
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
            
            {/* Pagination Controls */}
            {services.length > servicesPageSize && (
              <div className="bg-gray-900 px-4 py-3 flex items-center justify-between border-t border-gray-700 sm:px-6 rounded-b-lg">
                <div className="flex-1 flex justify-between sm:hidden">
                  <button
                    onClick={() => paginateServices(currentServicesPage - 1)}
                    disabled={currentServicesPage === 1}
                    className={`relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium rounded-md ${
                      currentServicesPage === 1 ? 'text-gray-500 bg-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-gray-700'
                    }`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => paginateServices(currentServicesPage + 1)}
                    disabled={currentServicesPage === totalServicesPages}
                    className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium rounded-md ${
                      currentServicesPage === totalServicesPages ? 'text-gray-500 bg-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-gray-700'
                    }`}
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-400">
                      Showing <span className="font-medium">{indexOfFirstService + 1}</span> to{' '}
                      <span className="font-medium">
                        {Math.min(indexOfLastService, services.length)}
                      </span>{' '}
                      of <span className="font-medium">{services.length}</span> services
                    </p>
                  </div>
                  <div className="flex items-center space-x-4">
                    <select
                      value={servicesPageSize}
                      onChange={handleServicesPageSizeChange}
                      className="bg-gray-700 border border-gray-600 text-white rounded-md px-2 py-1 text-sm"
                    >
                      <option value={5}>5 per page</option>
                      <option value={10}>10 per page</option>
                      <option value={25}>25 per page</option>
                      <option value={50}>50 per page</option>
                    </select>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                      <button
                        onClick={() => paginateServices(currentServicesPage - 1)}
                        disabled={currentServicesPage === 1}
                        className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-700 bg-gray-800 text-sm font-medium ${
                          currentServicesPage === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 hover:bg-gray-700'
                        }`}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      {Array.from({ length: totalServicesPages }, (_, i) => i + 1).map((number) => (
                        <button
                          key={number}
                          onClick={() => paginateServices(number)}
                          className={`relative inline-flex items-center px-4 py-2 border ${
                            currentServicesPage === number
                              ? 'bg-blue-600 text-white border-blue-500'
                              : 'bg-gray-800 text-gray-400 border-gray-700 hover:bg-gray-700'
                          } text-sm font-medium`}
                        >
                          {number}
                        </button>
                      ))}
                      <button
                        onClick={() => paginateServices(currentServicesPage + 1)}
                        disabled={currentServicesPage === totalServicesPages}
                        className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-700 bg-gray-800 text-sm font-medium ${
                          currentServicesPage === totalServicesPages ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 hover:bg-gray-700'
                        }`}
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-white">Jobs & Invoicing Hub</h1>
          <p className="mt-2 text-gray-400">Manage all your jobs and invoices in one place</p>
        </div>

        {services.length === 0 && (
          <div className="mb-6 bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-4">
            <div className="flex items-start">
              <ExclamationCircleIcon className="h-6 w-6 mr-3 text-yellow-500 flex-shrink-0" />
              <div>
                <h3 className="font-semibold text-lg text-yellow-500 mb-1">Action Required: Create Services First</h3>
                <p className="text-yellow-300 mb-2">
                  You need to create services before you can create jobs. Services define the types of work you offer to your customers.
                </p>
                <button
                  onClick={() => handleTabChange(3)} // Index 3 is the Services tab
                  className="px-4 py-2 bg-yellow-600 text-white rounded-lg hover:bg-yellow-700 transition-colors inline-flex items-center"
                >
                  <WrenchIcon className="h-5 w-5 mr-2" />
                  Go to Services Tab
                </button>
              </div>
            </div>
          </div>
        )}

        <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
          <Tab.List className="flex space-x-2 rounded-xl bg-gray-800 p-1">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-blue-600 text-white shadow'
                        : 'text-gray-400 hover:bg-gray-700 hover:text-white'
                    )
                  }
                >
                  <div className="flex items-center justify-center space-x-2">
                    <Icon className="h-5 w-5" />
                    <span>{tab.name}</span>
                    {tab.param === 'services' && services.length === 0 && (
                      <span className="flex h-2 w-2 relative">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                      </span>
                    )}
                  </div>
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels className="mt-4">
            {tabs.map((tab, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  'rounded-xl bg-gray-800 p-3',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}
              >
                {tab.component ? <tab.component /> : tab.param === 'services' ? renderServices() : null}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </main>
    </div>
  );
};

export default JobsHub; 