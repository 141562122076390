/* global google */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { ExclamationTriangleIcon, BugAntIcon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useGoogleMaps } from '../contexts/GoogleMapsContext';

// Add MarkerClusterer import
import { MarkerClusterer } from '@react-google-maps/api';

// Define marker URLs as constants using HTTPS
const MARKER_URLS = {
  ACTIVE: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
  INACTIVE: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
  DISCONTINUED: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
  DEFAULT: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
};

const libraries = ['places', 'geometry', 'marker'];

const ErrorMessage = ({ message, onRetry }) => (
  <div className="flex flex-col items-center justify-center p-8 bg-gray-800 rounded-lg shadow-lg">
    <ExclamationTriangleIcon className="h-12 w-12 text-yellow-500 mb-4" />
    <h3 className="text-xl font-semibold text-white mb-2">Map Loading Error</h3>
    <p className="text-gray-400 text-center mb-4">{message}</p>
    {onRetry && (
      <button
        onClick={onRetry}
        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
      >
        Try Again
      </button>
    )}
  </div>
);

const DebugPanel = ({ state, onClose }) => (
  <div className="fixed bottom-4 right-4 w-96 bg-gray-800/90 backdrop-blur rounded-lg shadow-lg p-4 text-white text-sm z-50 border border-gray-700">
    <div className="flex justify-between items-center mb-2">
      <h3 className="font-semibold flex items-center">
        <BugAntIcon className="h-5 w-5 mr-2 text-yellow-500" />
        Debug Info
      </h3>
      <button onClick={onClose} className="text-gray-400 hover:text-white">
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
    <div className="space-y-2 max-h-96 overflow-y-auto">
      {Object.entries(state).map(([key, value]) => (
        <div key={key} className="flex justify-between border-b border-gray-700 pb-1">
          <span className="text-gray-400">{key}:</span>
          <span className={`text-gray-200 ${value === false ? 'text-red-400' : value === true ? 'text-green-400' : ''}`}>
            {typeof value === 'boolean' 
              ? value.toString() 
              : value === null
                ? 'null'
                : typeof value === 'object' 
                  ? JSON.stringify(value) 
                  : value}
          </span>
        </div>
      ))}
    </div>
  </div>
);

const CustomerMap = () => {
  const { isLoaded, loadError, googleMapsReady } = useGoogleMaps();
  const [customers, setCustomers] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  const [mapZoom, setMapZoom] = useState(12);
  const [mapBounds, setMapBounds] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);
  const geocoderRef = useRef(null);
  const isMounted = useRef(true);
  const [showDebug, setShowDebug] = useState(false);
  const [debugState, setDebugState] = useState({
    scriptLoaded: false,
    mapLoaded: false,
    customersLoaded: false,
    markersCount: 0,
    visibleMarkersCount: 0,
    lastError: null,
    geocodingStatus: 'idle',
    mapCenter: null,
    selectedMarkerId: null,
    googleMapsStatus: 'initializing',
    lastEvent: null,
    customerCount: 0,
    customersWithCoords: 0,
    customersNeedingGeocode: 0
  });
  const [customerSearch, setCustomerSearch] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);

  // Update debug state when Google Maps status changes
  useEffect(() => {
    updateDebugState({
      scriptLoaded: isLoaded,
      googleMapsStatus: loadError ? 'error' : googleMapsReady ? 'ready' : 'loading',
      lastError: loadError?.message || null
    });
  }, [isLoaded, loadError, googleMapsReady]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateDebugState = useCallback((updates) => {
    console.log('Updating debug state:', updates);
    setDebugState(prev => ({
      ...prev,
      ...updates,
      lastEvent: `${new Date().toISOString()} - ${Object.keys(updates).join(', ')}`,
    }));
  }, []);

  // Add debug keyboard shortcut
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === 'd') {
        setShowDebug(prev => !prev);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Add this helper function for rate-limited geocoding
  const batchGeocodeAddresses = useCallback(async (customerList, batchSize = 5, delayMs = 1000) => {
    if (!geocoderRef.current || !isMounted.current) return [];
    
    const results = [];
    const batches = [];
    
    // Split customers into batches
    for (let i = 0; i < customerList.length; i += batchSize) {
      batches.push(customerList.slice(i, i + batchSize));
    }
    
    updateDebugState({
      geocodingStatus: `batched: ${batches.length} batches of ${batchSize}`,
    });
    
    // Process batches with delay between them
    for (let i = 0; i < batches.length; i++) {
      if (!isMounted.current) break;
      
      updateDebugState({
        geocodingStatus: `processing batch ${i+1}/${batches.length}`,
      });
      
      // Process each batch concurrently
      const batchPromises = batches[i].map(async (customer) => {
        const address = customer.address;
        const fullAddress = `${address.street}, ${address.city}, ${address.state} ${address.zipCode}`;
        
        try {
          const geocodeResults = await geocodeAddress(fullAddress, geocoderRef.current);
          if (geocodeResults && geocodeResults.length > 0) {
            const location = geocodeResults[0].geometry.location;
            console.log('Geocoding successful for customer:', customer.name);
            
            // Return the marker data and updated customer
            return {
              marker: {
                position: { lat: location.lat(), lng: location.lng() },
                customer,
              },
              updatedCustomer: {
                ...customer,
                address: {
                  ...customer.address,
                  coordinates: [location.lng(), location.lat()]
                }
              }
            };
          }
        } catch (err) {
          console.error('Geocoding error for address:', fullAddress, err);
          updateDebugState({
            lastError: `Geocoding failed for ${customer.name}: ${err.message}`,
          });
          return null;
        }
        return null;
      });
      
      // Wait for batch to complete
      const batchResults = await Promise.all(batchPromises);
      results.push(...batchResults.filter(r => r !== null));
      
      // Add delay between batches to avoid rate limits
      if (i < batches.length - 1) {
        await new Promise(resolve => setTimeout(resolve, delayMs));
      }
    }
    
    return results;
  }, [updateDebugState]);

  const geocodeCustomers = useCallback(async (customerList) => {
    if (!googleMapsReady || !isMounted.current) return;
    
    const markersData = [];
    const customersToGeocode = [];
    
    // Count customers with and without coordinates
    const customersWithCoords = customerList.filter(
      c => c.address && c.address.coordinates && c.address.coordinates.length === 2
    ).length;
    const customersNeedingGeocode = customerList.length - customersWithCoords;
    
    updateDebugState({
      geocodingStatus: 'sorting customers',
      customerCount: customerList.length,
      customersWithCoords,
      customersNeedingGeocode
    });
    
    console.log(`Total customers: ${customerList.length}`);
    console.log(`Customers with saved coordinates: ${customersWithCoords}`);
    console.log(`Customers needing geocoding: ${customersNeedingGeocode}`);

    try {
      // First, process all customers with existing coordinates
      for (const customer of customerList) {
        if (!isMounted.current) break;

        const address = customer.address;
        if (address && address.coordinates && address.coordinates.length === 2) {
          // Use existing coordinates from database
          console.log('Using stored coordinates for customer:', customer.name);
          markersData.push({
            position: { 
              lng: address.coordinates[0],
              lat: address.coordinates[1]
            },
            customer,
          });
        } else if (address) {
          // Queue for geocoding if needed
          customersToGeocode.push(customer);
        }
      }

      // Immediately update markers with what we have so far, so users see the map populated quickly
      if (markersData.length > 0) {
        setMarkers(markersData);
        updateDebugState({
          markersCount: markersData.length,
          customersToGeocode: customersToGeocode.length,
          initialMarkersLoaded: true
        });
        
        // Calculate initial map view based on available markers
        updateMapViewFromMarkers(markersData);
      }

      // Only initialize geocoder if we have customers that need geocoding
      if (customersToGeocode.length > 0) {
        console.log(`Need to geocode ${customersToGeocode.length} customers`);
        setLoading(true); // Show loading indicator while geocoding
        
        if (!geocoderRef.current) {
          geocoderRef.current = new window.google.maps.Geocoder();
        }

        // Use batch geocoding to process customers in smaller groups with delays
        console.log('Using batch geocoding for customers without coordinates');
        const batchResults = await batchGeocodeAddresses(customersToGeocode, 5, 1000);
        
        // Add markers from batch results
        const newMarkersData = [...markersData];
        
        for (const result of batchResults) {
          newMarkersData.push(result.marker);
          
          // Save the coordinates back to the database
          try {
            console.log("Sending coordinate update to server:", {
              customerId: result.updatedCustomer._id,
              coordinates: result.updatedCustomer.address.coordinates,
              customerName: result.updatedCustomer.name
            });
            
            const response = await axiosInstance.put(`/customers/${result.updatedCustomer._id}`, result.updatedCustomer);
            
            console.log('Coordinates saved to database response:', {
              customerId: result.updatedCustomer._id,
              customerName: result.updatedCustomer.name,
              status: response.status,
              success: response.status === 200,
              savedCoordinates: response.data?.address?.coordinates
            });
            
            // Verify the saved coordinates match what we sent
            if (response.data?.address?.coordinates) {
              const savedCoords = response.data.address.coordinates;
              if (savedCoords[0] !== result.updatedCustomer.address.coordinates[0] || 
                  savedCoords[1] !== result.updatedCustomer.address.coordinates[1]) {
                console.warn('Coordinate mismatch after save:', {
                  sent: result.updatedCustomer.address.coordinates,
                  received: savedCoords
                });
              }
            } else {
              console.warn('No coordinates in response data:', response.data);
            }
          } catch (saveError) {
            console.error('Failed to save coordinates to database:', {
              error: saveError.message,
              customer: result.updatedCustomer.name,
              status: saveError.response?.status,
              data: saveError.response?.data
            });
          }
        }
        
        if (isMounted.current) {
          setMarkers(newMarkersData);
          updateDebugState({
            markersCount: newMarkersData.length,
            geocodingComplete: true
          });
          
          // Update map view to include all markers
          updateMapViewFromMarkers(newMarkersData);
        }
      }
      
      // Update final status
      updateDebugState({
        geocodingStatus: 'complete',
        lastEvent: 'All markers processed'
      });
      
    } catch (error) {
      console.error('Error in processing customer locations:', error);
      updateDebugState({
        geocodingStatus: 'error',
        lastError: error.message,
      });
      if (isMounted.current) {
        setError('Failed to load customer locations. Please try again.');
      }
    } finally {
      setLoading(false); // Hide loading indicator
    }
  }, [googleMapsReady, updateDebugState, batchGeocodeAddresses]);

  // Helper function to calculate and set map view based on markers
  const updateMapViewFromMarkers = useCallback((markersData) => {
    if (!markersData || markersData.length === 0 || !isMounted.current) return;
    
    if (markersData.length === 1) {
      // For a single marker, center on it with closer zoom
      setMapCenter(markersData[0].position);
      setMapZoom(14);
      return;
    }
    
    // For multiple markers, calculate bounds and center
    if (window.google && window.google.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      markersData.forEach(marker => {
        bounds.extend(new window.google.maps.LatLng(
          marker.position.lat,
          marker.position.lng
        ));
      });
      
      const center = {
        lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
        lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2
      };
      setMapCenter(center);
      
      // Set appropriate zoom level based on bounds
      const mapRef = document.getElementById('customer-map');
      if (mapRef) {
        const mapWidth = mapRef.offsetWidth;
        const mapHeight = mapRef.offsetHeight;
        const GLOBE_WIDTH = 256; // a constant in Google's map projection
        const latFraction = (bounds.getNorthEast().lat() - bounds.getSouthWest().lat()) / 180;
        const lngFraction = (bounds.getNorthEast().lng() - bounds.getSouthWest().lng()) / 360;
        const latZoom = Math.floor(Math.log(mapHeight / GLOBE_WIDTH / latFraction) / Math.LN2);
        const lngZoom = Math.floor(Math.log(mapWidth / GLOBE_WIDTH / lngFraction) / Math.LN2);
        const zoom = Math.min(latZoom, lngZoom, 14); // Cap at zoom level 14
        setMapZoom(zoom);
      } else {
        setMapZoom(10);
      }
      
      // If we have a map reference, fit bounds directly
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, []);

  // Fetch customers when component mounts
  useEffect(() => {
    const fetchCustomers = async () => {
      if (!isMounted.current) return;
      
      setLoading(true);
      try {
        updateDebugState({
          lastEvent: 'Fetching customers'
        });
        
        const response = await axiosInstance.get('/customers');
        console.log('Fetched customers:', response.data);
        if (isMounted.current) {
          setCustomers(response.data);
          updateDebugState({
            customersLoaded: true,
            customerCount: response.data.length,
            lastEvent: 'Customers fetched'
          });
          if (googleMapsReady) {
            geocodeCustomers(response.data);
          }
        }
      } catch (err) {
        console.error('Error fetching customers:', err);
        if (isMounted.current) {
          setError('Failed to fetch customers. Please try again.');
          updateDebugState({
            lastError: err.message,
            lastEvent: 'Customer fetch error'
          });
          setLoading(false); // Ensure loading is turned off even on error
        }
      }
    };

    fetchCustomers();
  }, [geocodeCustomers, googleMapsReady, updateDebugState]);

  const geocodeAddress = (address, geocoder) => {
    return new Promise((resolve, reject) => {
      if (!isMounted.current) {
        reject('Component unmounted');
        return;
      }

      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(`Geocode was not successful: ${status}`);
        }
      });
    });
  };

  const getMarkerColor = (customer) => {
    switch (customer.status) {
      case 'Active':
        return MARKER_URLS.ACTIVE;
      case 'Inactive':
        return MARKER_URLS.INACTIVE;
      case 'Discontinued':
        return MARKER_URLS.DISCONTINUED;
      default:
        return MARKER_URLS.DEFAULT;
    }
  };

  const handleMapLoad = useCallback((map) => {
    console.log('Map load callback triggered');
    if (map) {
      console.log('Map loaded successfully');
      mapRef.current = map;
      
      // Initialize geocoder after confirmed map load
      if (!geocoderRef.current && window.google?.maps?.Geocoder) {
        geocoderRef.current = new window.google.maps.Geocoder();
        console.log('Geocoder initialized');
      }

      // Initialize bounds
      setMapBounds(map.getBounds() || null);

      updateDebugState({
        mapLoaded: true,
        googleMapsStatus: 'ready',
        lastEvent: 'Map loaded successfully'
      });

      // If we have customers but haven't geocoded them yet, do it now
      if (customers.length > 0 && markers.length === 0) {
        geocodeCustomers(customers);
      }
    }
  }, [customers, markers.length, geocodeCustomers, updateDebugState]);

  // Handle bounds changed event
  const handleBoundsChanged = useCallback(() => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
      updateDebugState({
        lastEvent: 'bounds_changed',
        viewportChangeTime: new Date().toISOString()
      });
    }
  }, [updateDebugState]);

  // Add debug info to marker click
  const handleMarkerClick = useCallback((marker) => {
    setSelectedMarker(marker);
    updateDebugState({
      selectedMarkerId: marker.customer._id,
      lastEvent: 'marker_clicked',
    });
  }, [updateDebugState]);

  // Add customer search/filter effect
  useEffect(() => {
    if (customerSearch) {
      const filtered = customers.filter(customer => 
        customer.name.toLowerCase().includes(customerSearch.toLowerCase()) ||
        customer.address?.street?.toLowerCase().includes(customerSearch.toLowerCase()) ||
        customer.address?.city?.toLowerCase().includes(customerSearch.toLowerCase())
      );
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers([]);
    }
  }, [customerSearch, customers]);

  const handleCustomerSelect = (customer) => {
    console.log('Customer selected from search:', customer);
    
    // Find the corresponding marker
    const marker = markers.find(m => m.customer._id === customer._id);
    console.log('Found marker:', marker ? 'yes' : 'no', marker?.position);
    
    if (marker) {
      console.log('Setting map center to:', marker.position);
      // Set map center and zoom
      setMapCenter(marker.position);
      setMapZoom(16); // Closer zoom for individual customer
      
      // Show the info window
      setSelectedMarker(marker);
      
      // Pan the map if we have a reference
      if (mapRef.current) {
        console.log('Panning map to location');
        mapRef.current.panTo(marker.position);
      }
      
      // Update debug state with detailed info
      updateDebugState({
        selectedMarkerId: customer._id,
        lastEvent: 'customer_selected_from_search',
        mapCenter: marker.position,
        searchDetails: {
          customerName: customer.name,
          markerFound: true,
          position: marker.position,
          zoom: 16
        }
      });
    } else {
      console.warn('No marker found for customer:', customer.name);
      updateDebugState({
        lastError: `No marker found for customer: ${customer.name}`,
        lastEvent: 'customer_search_failed',
        searchDetails: {
          customerName: customer.name,
          markerFound: false
        }
      });
    }
    // Clear search and close dropdown
    setCustomerSearch('');
    setShowCustomerDropdown(false);
  };

  // Add customer search component
  const renderCustomerSearch = () => (
    <div className="absolute left-1/2 transform -translate-x-1/2 top-4 z-10 w-96">
      <div className="relative customer-search">
        <input
          type="text"
          value={customerSearch}
          onChange={(e) => {
            setCustomerSearch(e.target.value);
            setShowCustomerDropdown(true);
            console.log('Search input changed:', e.target.value);
          }}
          onFocus={() => {
            setShowCustomerDropdown(true);
            console.log('Search input focused');
          }}
          placeholder="Search for a customer..."
          className="w-full px-4 py-2 pl-10 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
        
        {showCustomerDropdown && filteredCustomers.length > 0 && (
          <div className="absolute top-full mt-2 w-full bg-gray-800 border border-gray-700 rounded-lg shadow-xl max-h-96 overflow-y-auto">
            {filteredCustomers.map(customer => (
              <button
                key={customer._id}
                onClick={() => handleCustomerSelect(customer)}
                className="w-full px-4 py-2 text-left hover:bg-gray-700 text-white flex flex-col"
              >
                <span className="font-medium">{customer.name}</span>
                <span className="text-sm text-gray-400">
                  {customer.address?.street}, {customer.address?.city}, {customer.address?.state}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  // Add click outside handler to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.customer-search')) {
        setShowCustomerDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Update the marker visibility based on map bounds
  const updateVisibleMarkers = () => {
    // Check if we have both map bounds and markers to filter
    if (!mapBounds || !markers || markers.length === 0 || !googleMapsReady) {
      console.log("Cannot update visible markers:", { 
        hasBounds: !!mapBounds, 
        markersCount: markers ? markers.length : 0,
        googleMapsReady
      });
      setVisibleMarkers([]);
      updateDebugState({ visibleMarkersCount: 0, filteringApplied: false });
      return;
    }
    
    // Log the current bounds for debugging
    const ne = mapBounds.getNorthEast();
    const sw = mapBounds.getSouthWest();
    console.log("Current map bounds:", {
      ne: { lat: ne.lat(), lng: ne.lng() },
      sw: { lat: sw.lat(), lng: sw.lng() },
      totalMarkers: markers.length
    });

    // Filter markers that are visible in the current map bounds
    const visible = markers.filter(marker => {
      const position = { lat: marker.position.lat, lng: marker.position.lng };
      const isVisible = mapBounds.contains(position);
      
      if (isVisible) {
        console.log(`Marker visible: ${marker.customer.name} at [${marker.position.lng}, ${marker.position.lat}]`);
      }
      
      return isVisible;
    });
    
    console.log(`Filtered ${visible.length} visible markers out of ${markers.length} total`);
    
    // Update visible markers state
    setVisibleMarkers(visible);
    updateDebugState({ 
      visibleMarkersCount: visible.length, 
      filteringApplied: true,
      totalMarkers: markers.length
    });
  };

  // Call updateVisibleMarkers whenever the map bounds or markers change
  useEffect(() => {
    if (googleMapsReady) {
      updateVisibleMarkers();
    }
  }, [mapBounds, markers, googleMapsReady, updateVisibleMarkers]);

  if (loadError) {
    return (
      <div className="h-full flex items-center justify-center">
        <ErrorMessage message={loadError.message} />
      </div>
    );
  }

  return (
    <div className="h-full relative">
      {/* Debug Toggle Button */}
      <button
        onClick={() => setShowDebug(prev => !prev)}
        className="absolute top-4 right-4 z-50 bg-gray-800/90 backdrop-blur text-white px-3 py-1 rounded-lg flex items-center space-x-2 opacity-50 hover:opacity-100 transition-opacity"
      >
        <BugAntIcon className="h-5 w-5" />
        <span className="text-sm">Debug</span>
      </button>

      {/* Loading Indicator */}
      {loading && (
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center z-40">
          <div className="bg-gray-800 rounded-lg p-6 shadow-lg max-w-md text-center">
            <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto mb-4"></div>
            <h3 className="text-lg font-semibold text-white mb-2">
              {debugState.customersLoaded ? 'Geocoding Customer Addresses...' : 'Loading Customers...'}
            </h3>
            {debugState.customersLoaded && debugState.customersNeedingGeocode > 0 ? (
              <div className="text-gray-300 mb-2">
                <p>Found {debugState.customerCount} customers:</p>
                <p>• {debugState.customersWithCoords || 0} already have coordinates</p>
                <p>• {debugState.customersNeedingGeocode || 0} need geocoding</p>
              </div>
            ) : null}
            <p className="text-gray-400 text-sm">
              {debugState.geocodingStatus === 'sorting customers'
                ? 'Preparing customer data...'
                : debugState.geocodingStatus || 'Initializing...'}
            </p>
          </div>
        </div>
      )}

      {showDebug && (
        <DebugPanel
          state={debugState}
          onClose={() => setShowDebug(false)}
        />
      )}

      <div className="mb-4">
        <div className="flex space-x-4 mb-2">
          <div className="flex items-center">
            <img src={MARKER_URLS.ACTIVE} alt="Active" className="h-6 w-6 mr-2" />
            <span className="text-gray-200">Active</span>
          </div>
          <div className="flex items-center">
            <img src={MARKER_URLS.INACTIVE} alt="Inactive" className="h-6 w-6 mr-2" />
            <span className="text-gray-200">Inactive</span>
          </div>
          <div className="flex items-center">
            <img src={MARKER_URLS.DISCONTINUED} alt="Discontinued" className="h-6 w-6 mr-2" />
            <span className="text-gray-200">Discontinued</span>
          </div>
        </div>
      </div>

      <div className="relative h-[600px]" id="customer-map">
        {renderCustomerSearch()}
        
        {googleMapsReady ? (
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            center={mapCenter}
            zoom={mapZoom}
            onLoad={handleMapLoad}
            onBoundsChanged={handleBoundsChanged}
            onUnmount={() => {
              mapRef.current = null;
              geocoderRef.current = null;
            }}
            options={{
              styles: [
                { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                  featureType: "administrative.locality",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "poi",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#d59563" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "geometry",
                  stylers: [{ color: "#263c3f" }],
                },
                {
                  featureType: "poi.park",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#6b9a76" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry",
                  stylers: [{ color: "#38414e" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry.stroke",
                  stylers: [{ color: "#212a37" }],
                },
                {
                  featureType: "road",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#9ca5b3" }],
                },
                {
                  featureType: "water",
                  elementType: "geometry",
                  stylers: [{ color: "#17263c" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.fill",
                  stylers: [{ color: "#515c6d" }],
                },
                {
                  featureType: "water",
                  elementType: "labels.text.stroke",
                  stylers: [{ color: "#17263c" }],
                },
              ]
            }}
          >
            {/* Use MarkerClusterer to group markers */}
            <MarkerClusterer
              options={{
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                gridSize: 50,
                minimumClusterSize: 5,
                maxZoom: 15
              }}
            >
              {(clusterer) => 
                visibleMarkers.map((marker, idx) => (
                  <Marker
                    key={idx}
                    position={marker.position}
                    onClick={() => handleMarkerClick(marker)}
                    icon={getMarkerColor(marker.customer)}
                    clusterer={clusterer}
                  />
                ))
              }
            </MarkerClusterer>

            {selectedMarker && (
              <InfoWindow
                position={selectedMarker.position}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <div className="bg-white p-2 rounded shadow">
                  <h3 className="font-bold text-lg">{selectedMarker.customer.name}</h3>
                  <p className="text-sm">{selectedMarker.customer.email}</p>
                  <p className="text-sm">{selectedMarker.customer.phone}</p>
                  <p className="text-sm">
                    {selectedMarker.customer.address.street},<br />
                    {selectedMarker.customer.address.city}, {selectedMarker.customer.address.state} {selectedMarker.customer.address.zipCode}
                  </p>
                  {selectedMarker.customer.notes && (
                    <p className="text-sm mt-2 italic">{selectedMarker.customer.notes}</p>
                  )}
                  <p className="text-sm mt-2">
                    Status: <span className="font-semibold">{selectedMarker.customer.status}</span>
                  </p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        ) : (
          <div className="flex justify-center items-center h-full">
            <div className="text-center">
              <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500 mb-4"></div>
              <p className="text-gray-400">Waiting for Google Maps...</p>
              <p className="text-gray-500 text-sm mt-2">
                Status: {debugState.googleMapsStatus}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerMap; 