import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Employee from './Employee';
import ManageEmployees from './ManageEmployees';
import { UserPlusIcon, UserGroupIcon, UsersIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EmployeeHub = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = [
    { name: 'Create Employee', icon: UserPlusIcon, component: Employee, param: 'create' },
    { name: 'Manage Crews', icon: UserGroupIcon, component: ManageEmployees, param: 'crews' }
  ];

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const index = tabs.findIndex(t => t.param === tab);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
  }, [searchParams]);

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    navigate(`/employees?tab=${tabs[index].param}`, { replace: true });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
      <Header />
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 relative z-0">
        <div className="relative mb-8">
          <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12 pointer-events-none"></div>
          <h1 className="relative text-4xl font-extrabold sm:text-5xl mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Employee Management Hub
          </h1>
          <p className="relative mt-2 text-gray-400">Manage your employees and organize them into crews</p>
        </div>

        <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange} className="relative z-10">
          <Tab.List className="flex space-x-2 rounded-xl bg-gray-800 p-1">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow'
                        : 'text-gray-400 hover:bg-gray-700 hover:text-white'
                    )
                  }
                >
                  <div className="flex items-center justify-center space-x-2">
                    <Icon className="h-5 w-5" />
                    <span>{tab.name}</span>
                  </div>
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels className="mt-4">
            {tabs.map((tab, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  'rounded-xl bg-gray-800 p-3',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}
              >
                <tab.component hideHeader={true} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </main>
    </div>
  );
};

export default EmployeeHub; 