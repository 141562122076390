// src/components/JobPool.js
import React, { useState, useEffect } from 'react';
import { Input, Select, Badge, Tooltip } from 'antd';
import { SearchOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';

/**
 * Enhanced JobPool component that displays a list of unassigned jobs with filtering capabilities.
 * Each job is draggable for scheduling on the calendar.
 */
const JobPool = ({ jobs, onJobDragStart, onRefresh }) => {
  const [filteredJobs, setFilteredJobs] = useState(jobs);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    jobType: 'all', // 'all', 'one-time', 'recurring'
    sortBy: 'newest' // 'newest', 'oldest', 'price-high', 'price-low'
  });

  // Apply filters whenever jobs, searchTerm, or filters change
  useEffect(() => {
    let result = [...jobs];
    
    // Apply search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(job => 
        job.service?.toLowerCase().includes(term) || 
        job.customer?.name?.toLowerCase().includes(term)
      );
    }
    
    // Apply job type filter
    if (filters.jobType !== 'all') {
      const isRecurring = filters.jobType === 'recurring';
      result = result.filter(job => job.isRecurring === isRecurring);
    }
    
    // Apply sorting
    switch (filters.sortBy) {
      case 'oldest':
        result.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      case 'price-high':
        result.sort((a, b) => (b.price || 0) - (a.price || 0));
        break;
      case 'price-low':
        result.sort((a, b) => (a.price || 0) - (b.price || 0));
        break;
      case 'newest':
      default:
        result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
    }
    
    setFilteredJobs(result);
  }, [jobs, searchTerm, filters]);

  return (
    <div className="w-full bg-gray-800 p-4 border-r border-gray-700 flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-white">Job Pool</h3>
        <Tooltip title="Refresh Jobs">
          <button 
            onClick={onRefresh}
            className="p-2 rounded-full hover:bg-gray-700 text-gray-300 hover:text-white transition-colors"
          >
            <ReloadOutlined />
          </button>
        </Tooltip>
      </div>
      
      {/* Search and Filter Controls */}
      <div className="mb-4 space-y-2">
        <Input
          placeholder="Search jobs or customers..."
          prefix={<SearchOutlined className="text-gray-400" />}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          className="bg-gray-700 border-gray-600 text-white"
        />
        
        <div className="flex space-x-2">
          <Select
            className="w-1/2"
            value={filters.jobType}
            onChange={value => setFilters({...filters, jobType: value})}
            options={[
              { value: 'all', label: 'All Jobs' },
              { value: 'one-time', label: 'One-time' },
              { value: 'recurring', label: 'Recurring' }
            ]}
            dropdownStyle={{ backgroundColor: '#374151', color: 'white' }}
          />
          
          <Select
            className="w-1/2"
            value={filters.sortBy}
            onChange={value => setFilters({...filters, sortBy: value})}
            options={[
              { value: 'newest', label: 'Newest First' },
              { value: 'oldest', label: 'Oldest First' },
              { value: 'price-high', label: 'Price: High to Low' },
              { value: 'price-low', label: 'Price: Low to High' }
            ]}
            dropdownStyle={{ backgroundColor: '#374151', color: 'white' }}
          />
        </div>
      </div>
      
      {/* Job List */}
      <div className="overflow-y-auto flex-grow">
        {filteredJobs.length === 0 && (
          <div className="text-center py-8">
            <p className="text-gray-400 italic">No jobs match your filters</p>
          </div>
        )}
        
        {filteredJobs.map((job) => (
          <div
            key={job._id}
            draggable
            onDragStart={(e) => onJobDragStart(e, job)}
            className="mb-3 bg-gradient-to-br from-gray-700 to-gray-800 border border-gray-600 p-3 rounded-lg cursor-move hover:border-blue-500 hover:shadow-lg transition-all duration-200"
          >
            <div className="flex justify-between items-start">
              <strong className="text-white text-base">{job.service}</strong>
              {job.isRecurring ? (
                <Badge 
                  count={job.recurrencePattern || 'Recurring'} 
                  style={{ backgroundColor: '#10B981', color: 'white' }}
                />
              ) : (
                <Badge 
                  count="One-time" 
                  style={{ backgroundColor: '#6366F1', color: 'white' }}
                />
              )}
            </div>
            
            {job.customer && (
              <div className="text-sm text-gray-300 mt-1">
                {job.customer.name}
              </div>
            )}
            
            <div className="flex justify-between items-center mt-2">
              {job.price && (
                <div className="text-sm font-medium text-green-400">
                  ${parseFloat(job.price).toFixed(2)}
                </div>
              )}
              
              {job.estimatedDuration && (
                <div className="text-xs text-gray-400">
                  {Math.round(job.estimatedDuration / 60000)} min
                </div>
              )}
            </div>
            
            {job.location && job.location.address && (
              <div className="text-xs text-gray-400 mt-1 truncate">
                {job.location.address}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobPool;
