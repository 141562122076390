import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link, useLocation } from 'react-router-dom';
import { 
  PencilIcon, 
  TrashIcon,
  FunnelIcon,
  ArrowPathIcon,
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  XMarkIcon,
  CheckIcon,
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  PaperAirplaneIcon,
  DocumentTextIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';
import { PatternFormat } from 'react-number-format';
import ConfirmDialog from '../components/ConfirmDialog';
import Tooltip from '../components/Tooltip';
import CustomerModal from '../components/CustomerModal';

// Customer Action Buttons Component
const CustomerActions = ({ customer }) => {
  return (
    <div className="flex space-x-2 mt-3 ml-8" onClick={(e) => e.stopPropagation()}>
      <Link
        to={`/send-notifications?customers=${customer._id}`}
        className="px-2 py-1 bg-blue-600 text-white text-xs rounded hover:bg-blue-700 transition-colors flex items-center"
        title="Send email notification"
      >
        <EnvelopeIcon className="h-3 w-3 mr-1" />
        Email
      </Link>
      
      <Link
        to={`/jobs?customer=${customer._id}&action=createInvoice`}
        className="px-2 py-1 bg-green-600 text-white text-xs rounded hover:bg-green-700 transition-colors flex items-center"
        title="Create invoice"
      >
        <DocumentTextIcon className="h-3 w-3 mr-1" />
        Invoice
      </Link>
    </div>
  );
};

const ManageCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCustomerForModal, setSelectedCustomerForModal] = useState(null);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [smsFilter, setSmsFilter] = useState('all'); // 'all', 'enabled', 'disabled'
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage, setCustomersPerPage] = useState(7);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null
  });
  const [bulkEdit, setBulkEdit] = useState({
    status: '',
    landscapingPotential: false,
    smsConsent: ''
  });
  const [stripeConnected, setStripeConnected] = useState(false);
  const location = useLocation();

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    // Check for URL parameters indicating a return from another page
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    const status = searchParams.get('status');
    const openCustomerId = searchParams.get('openCustomer');
    const activeTab = searchParams.get('tab');
    
    if (action === 'notification' && status === 'success') {
      setSuccess('Notification sent successfully!');
    } else if (action === 'invoice' && status === 'success') {
      setSuccess('Invoice created successfully!');
    }
    
    // If openCustomer parameter is present, open the customer modal
    if (openCustomerId) {
      const customer = customers.find(c => c._id === openCustomerId);
      if (customer) {
        // Create a copy with initialTab if specified
        const customerWithTab = { 
          ...customer, 
          initialTab: activeTab === 'messages' ? 'messages' : 'details' 
        };
        setSelectedCustomerForModal(customerWithTab);
        setIsCustomerModalOpen(true);
      } else {
        // If customer not found in current list, fetch it
        const fetchCustomer = async () => {
          try {
            const response = await axiosInstance.get(`/customers/${openCustomerId}`);
            const customerWithTab = { 
              ...response.data, 
              initialTab: activeTab === 'messages' ? 'messages' : 'details' 
            };
            setSelectedCustomerForModal(customerWithTab);
            setIsCustomerModalOpen(true);
          } catch (error) {
            console.error('Error fetching customer:', error);
          }
        };
        
        fetchCustomer();
      }
    }
  }, [location, customers]);

  // Debug function to check table structure
  useEffect(() => {
    console.log('Table structure check:');
    console.log('Number of header columns:', document.querySelectorAll('th').length);
    console.log('Actions column exists:', !!document.querySelector('th:last-child'));
  }, [customers]);

  useEffect(() => {
    if (filter === '' && smsFilter === 'all') {
      setFilteredCustomers(customers);
    } else {
      let filtered = customers;
      
      // Apply text filter
      if (filter !== '') {
        filtered = filtered.filter(
          (customer) =>
            customer.name?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.email?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.phone?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.street?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.city?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.state?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.zipCode?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.notes?.toLowerCase().includes(filter.toLowerCase())
        );
      }
      
      // Apply SMS filter
      if (smsFilter === 'enabled') {
        filtered = filtered.filter(customer => customer.smsConsent && !customer.smsOptOut);
      } else if (smsFilter === 'disabled') {
        filtered = filtered.filter(customer => !customer.smsConsent || customer.smsOptOut);
      }
      
      setFilteredCustomers(filtered);
    }
  }, [filter, smsFilter, customers]);

  useEffect(() => {
    const checkStripeStatus = async () => {
      try {
        const response = await axiosInstance.get('/connect/status');
        setStripeConnected(response.data.isOnboarded);
      } catch (error) {
        console.error('Error checking Stripe status:', error);
        setStripeConnected(false);
      }
    };

    checkStripeStatus();
  }, []);

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/customers');
      setCustomers(response.data);
      setFilteredCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to fetch customers. Please try again.');
    }
    setLoading(false);
  };

  const handleDeleteCustomers = () => {
    if (selectedCustomers.length === 0) return;

    setConfirmDialog({
      isOpen: true,
      title: 'Delete Customers',
      message: `Are you sure you want to delete ${selectedCustomers.length} selected customer(s)?`,
      onConfirm: async () => {
        try {
          await Promise.all(
            selectedCustomers.map(customerId =>
              axiosInstance.delete(`/customers/${customerId}`)
            )
          );
          fetchCustomers();
          setSelectedCustomers([]);
        } catch (error) {
          console.error('Error deleting customers:', error);
          setError('Failed to delete customers. Please try again.');
        }
      }
    });
  };

  const handleEditCustomer = (customer) => {
    setSelectedCustomerForModal(customer);
    setIsCustomerModalOpen(true);
  };

  const handleCustomerRowClick = (e, customer) => {
    // If the click is on a checkbox or action button, don't open the modal
    if (e.target.closest('input[type="checkbox"]') || 
        e.target.closest('button') || 
        e.target.closest('a')) {
      return;
    }
    
    setSelectedCustomerForModal(customer);
    setIsCustomerModalOpen(true);
  };

  const handleUpdateCustomer = (updatedCustomer) => {
      setCustomers(
        customers.map((customer) =>
        customer._id === updatedCustomer._id ? updatedCustomer : customer
      )
    );
  };

  const handleDeleteCustomerFromModal = (customerId) => {
    setCustomers(customers.filter(customer => customer._id !== customerId));
  };

  const handleStatusChange = async (customerId, newStatus) => {
    try {
      await axiosInstance.put(
        `/customers/${customerId}/status`,
        { status: newStatus }
      );
      setCustomers(
        customers.map((customer) =>
          customer._id === customerId ? { ...customer, status: newStatus } : customer
        )
      );
    } catch (error) {
      console.error('Error updating customer status:', error);
      setError('Failed to update customer status. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setSelectedCustomerForModal(prev => ({ ...prev, [name]: checked }));
    } else if (name.includes('.')) {
      const [objectKey, nestedKey] = name.split('.');
      setSelectedCustomerForModal(prev => ({
        ...prev,
        [objectKey]: {
          ...prev[objectKey],
          [nestedKey]: value
        }
      }));
    } else {
      setSelectedCustomerForModal(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers(prev =>
      prev.includes(customerId)
        ? prev.filter(id => id !== customerId)
        : [...prev, customerId]
    );
  };

  const handleSelectAll = () => {
    const currentPageCustomers = getCurrentPageCustomers();
    const allSelected = currentPageCustomers.every(customer => 
      selectedCustomers.includes(customer._id)
    );
    
    if (allSelected) {
      setSelectedCustomers(prev => 
        prev.filter(id => !currentPageCustomers.find(c => c._id === id))
      );
    } else {
      setSelectedCustomers(prev => [
        ...prev,
        ...currentPageCustomers
          .filter(customer => !selectedCustomers.includes(customer._id))
          .map(customer => customer._id)
      ]);
    }
  };

  const getCurrentPageCustomers = () => {
    const indexOfLastCustomer = currentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
    return filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return 'bg-green-100 text-green-800';
      case 'Inactive':
        return 'bg-yellow-100 text-yellow-800';
      case 'Discontinued':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleBulkStatusChange = async (newStatus) => {
    if (!newStatus) return;
    
    try {
      // Get the current page's customers for tracking
      const currentCustomers = getCurrentPageCustomers();
      const currentCustomerIds = currentCustomers.map(c => c._id);
      
      await Promise.all(
        selectedCustomers.map(customerId =>
          axiosInstance.put(`/customers/${customerId}/status`, { status: newStatus })
        )
      );

      // Update customers state while preserving pagination
      const updatedCustomers = customers.map(customer => {
        if (selectedCustomers.includes(customer._id)) {
          return { ...customer, status: newStatus };
        }
        return customer;
      });
      
      setCustomers(updatedCustomers);
      setFilteredCustomers(updatedCustomers);
      
      // Find which page contains the first selected customer
      const firstSelectedIndex = updatedCustomers.findIndex(c => selectedCustomers.includes(c._id));
      if (firstSelectedIndex !== -1) {
        const targetPage = Math.floor(firstSelectedIndex / customersPerPage) + 1;
        setCurrentPage(targetPage);
      }

      setSuccess(`Successfully updated status for ${selectedCustomers.length} customers`);
      setBulkEdit(prev => ({ ...prev, status: '' }));
      setSelectedCustomers([]);
    } catch (error) {
      console.error('Error updating customer statuses:', error);
      setError('Failed to update customer statuses. Please try again.');
    }
  };

  const handleBulkSmsConsentChange = async (action) => {
    if (!action || selectedCustomers.length === 0) return;
    
    try {
      const updates = {};
      
      if (action === 'enable') {
        updates.smsConsent = true;
        updates.smsOptOut = false;
        updates.smsConsentDate = new Date();
      } else if (action === 'disable') {
        updates.smsConsent = false;
      } else if (action === 'optout') {
        updates.smsOptOut = true;
      }
      
      await Promise.all(
        selectedCustomers.map(customerId =>
          axiosInstance.put(`/customers/${customerId}`, updates)
        )
      );

      // Update customers state
      const updatedCustomers = customers.map(customer => {
        if (selectedCustomers.includes(customer._id)) {
          return { ...customer, ...updates };
        }
        return customer;
      });
      
      setCustomers(updatedCustomers);
      setFilteredCustomers(updatedCustomers);
      
      setSuccess(`Successfully updated SMS consent for ${selectedCustomers.length} customers`);
      setBulkEdit(prev => ({ ...prev, smsConsent: '' }));
      setSelectedCustomers([]);
    } catch (error) {
      console.error('Error updating SMS consent:', error);
      setError('Failed to update SMS consent. Please try again.');
    }
  };

  // Add page size options
  const pageSizeOptions = [5, 7, 10, 15, 25, 50];

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    setCustomersPerPage(newSize);
    // Adjust current page to keep as many current items visible as possible
    const firstItemIndex = (currentPage - 1) * customersPerPage;
    const newPage = Math.floor(firstItemIndex / newSize) + 1;
    setCurrentPage(newPage);
  };

  const handleRetrySync = async (customerId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/customers/${customerId}/retry-sync`);
      
      // Update the customer in the list with new sync status
      setCustomers(customers.map(c => 
        c._id === customerId ? response.data.customer : c
      ));
      
      setSuccess('Customer synced with Stripe successfully');
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to sync with Stripe');
    } finally {
      setLoading(false);
    }
  };

  const renderStripeSyncStatus = (customer) => {
    if (!stripeConnected) return null;

    const tooltipContent = customer.stripeSyncError 
      ? `Error: ${customer.stripeSyncError}`
      : customer.stripeSyncStatus === 'synced'
        ? 'Synced with Stripe'
        : customer.stripeSyncStatus === 'pending'
          ? 'Sync in progress'
          : 'Not synced with Stripe';

    return (
      <div className="flex items-center space-x-2">
        {customer.stripeSyncStatus === 'synced' && (
          <Tooltip content={tooltipContent}>
            <div className="text-green-500 flex items-center">
              <CheckCircleIcon className="h-5 w-5 mr-1" />
              <span>Synced</span>
            </div>
          </Tooltip>
        )}
        {customer.stripeSyncStatus === 'failed' && (
          <div className="flex items-center space-x-1">
            <Tooltip content={tooltipContent}>
              <div className="text-red-500 flex items-center">
                <XCircleIcon className="h-5 w-5 mr-1" />
                <span>Failed</span>
              </div>
            </Tooltip>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleRetrySync(customer._id);
              }}
              className="p-1 text-gray-400 hover:text-gray-300 transition-colors"
              disabled={loading}
            >
              <Tooltip content="Retry Stripe sync">
                <ArrowPathIcon className="h-4 w-4" />
              </Tooltip>
            </button>
          </div>
        )}
        {customer.stripeSyncStatus === 'pending' && (
          <Tooltip content={tooltipContent}>
            <div className="text-blue-500 flex items-center">
              <ArrowPathIcon className="h-5 w-5 mr-1 animate-spin" />
              <span>Pending</span>
            </div>
          </Tooltip>
        )}
        {(!customer.stripeSyncStatus || customer.stripeSyncStatus === null) && (
          <Tooltip content={tooltipContent}>
            <div className="text-gray-400 flex items-center">
              <ExclamationCircleIcon className="h-5 w-5 mr-1" />
              <span>Not Synced</span>
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleOpenSmsModal = (customer, e) => {
    e.stopPropagation();
    // Create a copy of the customer with the initialTab property
    const customerWithTab = { ...customer, initialTab: 'messages' };
    setSelectedCustomerForModal(customerWithTab);
    setIsCustomerModalOpen(true);
  };

  return (
    <div className="min-h-screen">
      <main className="py-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-white">Manage Customers</h1>
          <p className="mt-2 text-gray-400">View and manage your customer information</p>
          
          <div className="mt-4 bg-blue-900/30 border border-blue-500/50 rounded-lg p-3 flex items-start">
            <InformationCircleIcon className="h-5 w-5 text-blue-400 mr-2 flex-shrink-0 mt-0.5" />
            <p className="text-sm text-blue-300">
              <span className="font-semibold">Pro Tip:</span> Each customer has quick action buttons for sending SMS/email notifications and creating invoices directly from the customer list.
            </p>
          </div>
        </div>

        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-white">Manage Customers</h1>
            <p className="mt-1 text-gray-400">View and manage your customer database</p>
          </div>
          
          {/* Filter Controls */}
          <div className="flex items-center space-x-4">
            <div className="relative">
              <FunnelIcon className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Filter customers..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="pl-10 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            
            {/* SMS Filter Dropdown */}
            <select
              value={smsFilter}
              onChange={(e) => setSmsFilter(e.target.value)}
              className="bg-gray-700 border border-gray-600 text-white rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="all">All Customers</option>
              <option value="enabled">SMS Enabled</option>
              <option value="disabled">SMS Disabled</option>
            </select>
            
            <button
              onClick={fetchCustomers}
              className="p-2 text-gray-400 hover:text-white transition-colors"
            >
              <ArrowPathIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Alerts */}
        {error && (
          <div className="mb-6 bg-red-500/10 border border-red-500 text-red-500 px-4 py-3 rounded-lg flex items-center">
            <XMarkIcon className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}
        {success && (
          <div className="mb-6 bg-green-500/10 border border-green-500 text-green-500 px-4 py-3 rounded-lg flex items-center">
            <CheckIcon className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        {/* Selection Actions Bar */}
        {selectedCustomers.length > 0 && (
          <div className="mb-6 bg-gray-800 rounded-lg border border-gray-700 p-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <span className="text-gray-400">
                  {selectedCustomers.length} customer{selectedCustomers.length !== 1 ? 's' : ''} selected
                </span>
                <button
                  onClick={() => setSelectedCustomers([])}
                  className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
                >
                  Deselect All
                </button>
                {selectedCustomers.length === 1 && (
                  <button
                    onClick={() => handleEditCustomer(customers.find(c => c._id === selectedCustomers[0]))}
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center space-x-2"
                  >
                    <PencilIcon className="h-5 w-5" />
                    <span>Edit Customer</span>
                  </button>
                )}
                <div className="flex items-center space-x-2">
                  <select
                    value={bulkEdit.status}
                    onChange={(e) => handleBulkStatusChange(e.target.value)}
                    className="bg-gray-700 border border-gray-600 text-white rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="" disabled>Change Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Discontinued">Discontinued</option>
                  </select>
                </div>
                <div className="flex items-center space-x-2">
                  <select
                    value={bulkEdit.smsConsent}
                    onChange={(e) => handleBulkSmsConsentChange(e.target.value)}
                    className="bg-gray-700 border border-gray-600 text-white rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="" disabled>SMS Consent</option>
                    <option value="enable">Enable SMS</option>
                    <option value="disable">Disable SMS</option>
                    <option value="optout">Mark as Opted Out</option>
                  </select>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={handleDeleteCustomers}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center space-x-2"
                >
                  <TrashIcon className="h-5 w-5" />
                  <span>Delete Selected</span>
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Customers Table */}
        <div className="bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-900/50">
                <tr>
                  <th className="px-6 py-3 text-left" style={{ width: '40px' }}>
                    <input
                      type="checkbox"
                      checked={
                        getCurrentPageCustomers().length > 0 &&
                        getCurrentPageCustomers().every(customer =>
                          selectedCustomers.includes(customer._id)
                        )
                      }
                      onChange={handleSelectAll}
                      className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                    />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider" style={{ width: '25%' }}>
                    Customer
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider" style={{ width: '15%' }}>
                    Contact
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider" style={{ width: '20%' }}>
                    Address
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider" style={{ width: '10%' }}>
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider" style={{ width: '10%' }}>
                    Stripe Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider" style={{ width: '20%' }}>
                    Notes
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {getCurrentPageCustomers().map((customer) => (
                  <tr 
                    key={customer._id} 
                    className={`hover:bg-gray-700/50 transition-colors cursor-pointer ${
                      selectedCustomers.includes(customer._id) ? 'bg-gray-700/75' : ''
                    }`}
                    onClick={(e) => handleCustomerRowClick(e, customer)}
                  >
                    <td className="px-6 py-4" onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={selectedCustomers.includes(customer._id)}
                        onChange={() => handleSelectCustomer(customer._id)}
                        className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <UserIcon className="h-5 w-5 text-gray-400 mr-3" />
                          <div>
                            <div className="font-medium text-white">{customer.name}</div>
                            <div className="text-sm text-gray-400">ID: #{customer._id.slice(-6)}</div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="space-y-1">
                        <div className="flex items-center text-sm text-gray-300">
                          <EnvelopeIcon className="h-4 w-4 mr-2" />
                          {customer.email}
                        </div>
                        <div className="flex items-center text-sm text-gray-300">
                          <PhoneIcon className="h-4 w-4 mr-2" />
                          {customer.phone}
                          {customer.phone && (
                            <span className="ml-2">
                              {customer.smsConsent && !customer.smsOptOut ? (
                                <button
                                  onClick={(e) => handleOpenSmsModal(customer, e)}
                                  className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-900 text-green-200 hover:bg-green-800 transition-colors"
                                  title="Open SMS messages"
                                >
                                  <CheckCircleIcon className="h-3 w-3 mr-1" />
                                  SMS
                                </button>
                              ) : (
                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-700 text-gray-300">
                                  <XCircleIcon className="h-3 w-3 mr-1" />
                                  SMS
                                </span>
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-start space-x-2">
                        <MapPinIcon className="h-5 w-5 text-gray-400 mt-0.5" />
                        <div className="text-sm text-gray-300">
                          <div>{customer.address?.street}</div>
                          <div>{customer.address?.city}, {customer.address?.state} {customer.address?.zipCode}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4" onClick={(e) => e.stopPropagation()}>
                      <select
                        value={customer.status}
                        onChange={(e) => handleStatusChange(customer._id, e.target.value)}
                        className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(customer.status)}`}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Discontinued">Discontinued</option>
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      {renderStripeSyncStatus(customer)}
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-300 max-w-xs truncate">
                        {customer.notes || 'No notes'}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="px-6 py-4 bg-gray-900/50 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-400">Show</span>
              <select
                value={customersPerPage}
                onChange={handlePageSizeChange}
                className="bg-gray-700 border border-gray-600 text-white rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                {pageSizeOptions.map(size => (
                  <option key={size} value={size}>{size}</option>
                ))}
              </select>
              <span className="text-sm text-gray-400">customers per page</span>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
              <div>
                <p className="text-sm text-gray-400">
                  Showing{' '}
                  <span className="font-medium">{((currentPage - 1) * customersPerPage) + 1}</span>
                  {' '}-{' '}
                  <span className="font-medium">
                    {Math.min(currentPage * customersPerPage, filteredCustomers.length)}
                  </span>
                  {' '}of{' '}
                  <span className="font-medium">{filteredCustomers.length}</span>
                  {' '}results
                </p>
              </div>
              <div className="ml-4">
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  {[...Array(Math.ceil(filteredCustomers.length / customersPerPage))].map((_, idx) => (
                    <button
                      key={idx}
                      onClick={() => paginate(idx + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium
                        ${currentPage === idx + 1 
                          ? 'z-10 bg-blue-600 text-white' 
                          : 'bg-gray-800 text-gray-300 hover:bg-gray-700'}`}
                    >
                      {idx + 1}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* Customer Modal */}
        <CustomerModal
          customer={selectedCustomerForModal}
          isOpen={isCustomerModalOpen}
          onClose={() => setIsCustomerModalOpen(false)}
          onUpdate={handleUpdateCustomer}
          onDelete={handleDeleteCustomerFromModal}
        />

        {/* Confirm Dialog */}
        <ConfirmDialog
          isOpen={confirmDialog.isOpen}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={() => {
            if (confirmDialog.onConfirm) {
              confirmDialog.onConfirm();
            }
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          }}
          onCancel={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
      </main>
    </div>
  );
};

export default ManageCustomers;
