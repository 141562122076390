import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Switch, Avatar, Divider, Spin, Empty, Radio } from 'antd';
import { UserOutlined, TeamOutlined, BellOutlined, MailOutlined, CalendarOutlined } from '@ant-design/icons';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-hot-toast';

const { Option } = Select;

/**
 * Modal for assigning employees or crews to jobs
 */
const AssignJobModal = ({ isOpen, onClose, job, onAssign, selectedEventId = null }) => {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [crews, setCrews] = useState([]);
  const [selectedType, setSelectedType] = useState('employee');
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [notifyAssignee, setNotifyAssignee] = useState(true);
  const [updateScope, setUpdateScope] = useState('all');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const isRecurring = job?.isRecurring || false;
  
  useEffect(() => {
    if (isOpen) {
      console.log('[DEBUG] AssignJobModal - Opening modal with:', {
        jobId: job?._id,
        selectedEventId,
        jobAssignedEmployee: job?.assignedEmployee,
        jobAssignedCrew: job?.assignedCrew,
      });
      
      fetchEmployeesAndCrews();
      
      if (selectedEventId) {
        // If we have a specific event ID, fetch that event's assignment
        fetchEventAssignment(selectedEventId);
      } else {
        // Otherwise, set initial selection based on job's current assignment
        setAssignmentFromJob(job);
      }
    }
  }, [isOpen, job, selectedEventId]);
  
  // Fetch the specific event's assignment data
  const fetchEventAssignment = async (eventId) => {
    try {
      console.log('[DEBUG] AssignJobModal - Fetching event assignment for:', eventId);
      
      const response = await axiosInstance.get(`/calendar/events?eventId=${eventId}`);
      if (response.data && response.data.length > 0) {
        const eventData = response.data[0];
        setSelectedEvent(eventData);
        
        console.log('[DEBUG] AssignJobModal - Event data received:', {
          eventId: eventData._id,
          assignedEmployee: eventData.assignedEmployee,
          assignedCrew: eventData.assignedCrew
        });
        
        // Set assignment based on this specific event's assignment
        if (eventData.assignedEmployee) {
          setSelectedType('employee');
          setSelectedAssignee(typeof eventData.assignedEmployee === 'object' 
            ? eventData.assignedEmployee._id 
            : eventData.assignedEmployee);
          
          console.log('[DEBUG] AssignJobModal - Set employee from event:', selectedAssignee);
        } else if (eventData.assignedCrew) {
          setSelectedType('crew');
          setSelectedAssignee(typeof eventData.assignedCrew === 'object' 
            ? eventData.assignedCrew._id 
            : eventData.assignedCrew);
          
          console.log('[DEBUG] AssignJobModal - Set crew from event:', selectedAssignee);
        } else {
          // If event has no assignment, fall back to job's assignment
          setAssignmentFromJob(job);
        }
      } else {
        console.log('[DEBUG] AssignJobModal - No event data found, using job data');
        setAssignmentFromJob(job);
      }
    } catch (error) {
      console.error('Error fetching event assignment:', error);
      // Fall back to job's assignment
      setAssignmentFromJob(job);
    }
  };
  
  // Set assignment from job data
  const setAssignmentFromJob = (jobData) => {
    if (!jobData) return;
    
    console.log('[DEBUG] AssignJobModal - Setting assignment from job:', {
      assignedEmployee: jobData.assignedEmployee,
      assignedCrew: jobData.assignedCrew
    });
    
    if (jobData.assignedEmployee) {
      setSelectedType('employee');
      setSelectedAssignee(typeof jobData.assignedEmployee === 'object' 
        ? jobData.assignedEmployee._id 
        : jobData.assignedEmployee);
    } else if (jobData.assignedCrew) {
      setSelectedType('crew');
      setSelectedAssignee(typeof jobData.assignedCrew === 'object' 
        ? jobData.assignedCrew._id 
        : jobData.assignedCrew);
    } else {
      // Default to employee if no assignment
      setSelectedType('employee');
      setSelectedAssignee(null);
    }
  };
  
  const fetchEmployeesAndCrews = async () => {
    try {
      setLoading(true);
      
      // Fetch employees
      const employeesResponse = await axiosInstance.get('/employees');
      setEmployees(employeesResponse.data.filter(emp => emp.status === 'Active'));
      
      // Fetch crews
      const crewsResponse = await axiosInstance.get('/crews');
      setCrews(crewsResponse.data);
      
    } catch (error) {
      console.error('Error fetching employees and crews:', error);
      toast.error('Failed to load employees and crews');
    } finally {
      setLoading(false);
    }
  };
  
  const handleAssign = async () => {
    if (!selectedAssignee) {
      toast.error('Please select an assignee');
      return;
    }
    
    if (!job) {
      toast.error('No job selected for assignment');
      return;
    }
    
    try {
      setLoading(true);
      console.log('[DEBUG] AssignJobModal - Assigning job:', {
        jobId: job._id,
        type: selectedType,
        assigneeId: selectedAssignee,
        eventId: selectedEventId,
        updateAll: updateScope === 'all'
      });
      
      // Update the job with the assignment - only if we're updating all events
      // or if there's no specific event selected
      if (updateScope === 'all' || !selectedEventId) {
        const assignmentData = {
          [selectedType === 'employee' ? 'assignedEmployee' : 'assignedCrew']: selectedAssignee
        };
        
        // If the other type was previously assigned, clear it
        if (selectedType === 'employee') {
          assignmentData.assignedCrew = null;
        } else {
          assignmentData.assignedEmployee = null;
        }
        
        console.log('[DEBUG] AssignJobModal - Updating job with assignment data:', assignmentData);
        
        // Update the job
        const updatedJobResponse = await axiosInstance.put(`/jobs/${job._id}`, assignmentData);
        console.log('[DEBUG] AssignJobModal - Job updated successfully:', updatedJobResponse.data);
      }
      
      // Update calendar events for this job
      console.log('[DEBUG] AssignJobModal - Updating calendar events with assignment', {
        jobId: job._id,
        assignmentType: selectedType,
        assigneeId: selectedAssignee,
        eventId: selectedEventId,
        updateAllInSeries: updateScope === 'all'
      });
      
      const eventsResponse = await axiosInstance.put(`/calendar/events/update-assignment`, {
        jobId: job._id,
        assignmentType: selectedType,
        assigneeId: selectedAssignee,
        eventId: selectedEventId,
        updateAllInSeries: updateScope === 'all'
      });
      
      console.log('[DEBUG] AssignJobModal - Calendar events updated:', eventsResponse.data);
      // Log the event returned from the API to debug assignment issues
      if (eventsResponse.data.event) {
        console.log('[DEBUG] AssignJobModal - Updated event details:', {
          eventId: eventsResponse.data.event._id,
          assignedEmployee: eventsResponse.data.event.assignedEmployee,
          assignedCrew: eventsResponse.data.event.assignedCrew,
          jobId: eventsResponse.data.event.job?._id
        });
      }
      
      // Send notification if notifyAssignee is checked
      if (notifyAssignee) {
        try {
          // Get the assignee details
          const assignee = selectedType === 'employee' 
            ? employees.find(emp => emp._id === selectedAssignee)
            : crews.find(crew => crew._id === selectedAssignee);
          
          console.log('Assignee details for notification:', assignee);
          
          // Early check for crew without email
          if (selectedType === 'crew') {
            console.warn('Crew assignments do not receive email notifications');
            toast.info('Crew assigned successfully (no notification sent as crews have no email address)');
            // Skip the rest of the notification process
          } else if (assignee && assignee.email) {
            console.log('Sending notification to assignee:', assignee.email);
            
            // Prepare notification data
            const notificationData = {
              recipients: [{ email: assignee.email, name: assignee.name }],
              subject: `New Job Assignment: ${job.service}`,
              message: `
                Hello ${assignee.name},
                
                You have been assigned to a new job:
                
                Service: ${job.service}
                Customer: ${job.customer?.name || 'N/A'}
                Date: ${job.scheduledDay || 'To be scheduled'}
                
                Please check your calendar for more details.
              `,
              type: 'email',
              businessInfo: {
                name: 'AutoLawn',
                phoneNumber: ''  // You can add your business phone here
              }
            };
            
            console.log('Notification data being sent:', JSON.stringify(notificationData, null, 2));
            
            // Send the notification
            try {
              const notificationResponse = await axiosInstance.post('/notifications/send', notificationData);
              console.log('Notification API response:', notificationResponse.data);
              toast.success(`Assignment notification sent to ${assignee.name}`);
            } catch (apiError) {
              console.error('API error sending notification:', apiError);
              console.error('API error details:', {
                status: apiError.response?.status,
                statusText: apiError.response?.statusText,
                data: apiError.response?.data,
                message: apiError.message
              });
              toast.error(`Failed to send notification: ${apiError.response?.data?.message || apiError.message}`);
            }
          } else {
            console.warn('Cannot send notification: Assignee has no email address', {
              assigneeId: selectedAssignee,
              assigneeType: selectedType,
              hasAssignee: !!assignee,
              email: assignee?.email
            });
            toast.warning('Job assigned but notification not sent: Assignee has no email address');
          }
        } catch (notificationError) {
          console.error('Error in notification process:', notificationError);
          console.error('Error stack:', notificationError.stack);
          toast.error('Job assigned but notification could not be sent');
        }
      } else {
        console.log('Notification not sent because notifyAssignee is unchecked');
      }
      
      const assignmentScope = selectedEventId && updateScope === 'single' ? 'this event' : 'all events';
      toast.success(`Job assigned to ${selectedType === 'employee' ? 'employee' : 'crew'} for ${assignmentScope} successfully`);
      
      // Close the modal first
      onClose();
      
      // Then trigger the onAssign callback to refresh the calendar
      if (onAssign) {
        console.log('[DEBUG] AssignJobModal - Calling onAssign callback to refresh calendar');
        setTimeout(() => {
          // Pass assignment information in the callback for better UI refresh
          onAssign(selectedType, selectedAssignee, selectedEventId, updateScope === 'all');
        }, 100);
      }
    } catch (error) {
      console.error('Error assigning job:', error);
      toast.error('Failed to assign job: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };
  
  const handleUnassign = async () => {
    try {
      setLoading(true);
      
      await axiosInstance.post('/jobs/unassign', {
        jobId: job._id
      });
      
      toast.success('Job unassigned successfully');
      onAssign(null, null);
      onClose();
      
    } catch (error) {
      console.error('Error unassigning job:', error);
      toast.error('Failed to unassign job');
    } finally {
      setLoading(false);
    }
  };
  
  if (!job) return null;
  
  return (
    <Modal
      title={<div className="text-white">Assign Job</div>}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={500}
      centered
      className="assign-job-modal"
    >
      <div className="p-4">
        <div className="bg-gray-700/30 p-4 rounded-lg mb-6">
          <h3 className="text-white font-medium">{job.service}</h3>
          <p className="text-gray-300">{job.customer?.name || 'No customer'}</p>
          {job.scheduledTime && (
            <p className="text-gray-400 text-sm mt-1">
              Scheduled: {new Date(job.scheduledTime).toLocaleString()}
            </p>
          )}
        </div>
        
        <div className="mb-6">
          <div className="flex justify-center space-x-4 mb-4">
            <Button
              type={selectedType === 'employee' ? 'primary' : 'default'}
              icon={<UserOutlined />}
              onClick={() => setSelectedType('employee')}
              className={selectedType === 'employee' ? '' : 'text-gray-300'}
            >
              Assign Employee
            </Button>
            <Button
              type={selectedType === 'crew' ? 'primary' : 'default'}
              icon={<TeamOutlined />}
              onClick={() => setSelectedType('crew')}
              className={selectedType === 'crew' ? '' : 'text-gray-300'}
            >
              Assign Crew
            </Button>
          </div>
          
          {loading ? (
            <div className="text-center py-8">
              <Spin />
              <p className="text-gray-400 mt-2">Loading...</p>
            </div>
          ) : (
            <>
              {selectedType === 'employee' && (
                <>
                  <label className="block text-gray-300 mb-2">Select Employee:</label>
                  <Select
                    placeholder="Select an employee"
                    className="w-full mb-4"
                    value={selectedAssignee}
                    onChange={setSelectedAssignee}
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.props.children[1].toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent={<Empty description="No employees found" />}
                    dropdownStyle={{ backgroundColor: '#1f2937', borderColor: '#374151' }}
                  >
                    {employees.map(employee => (
                      <Option key={employee._id} value={employee._id}>
                        <div className="flex items-center">
                          <Avatar 
                            icon={<UserOutlined />} 
                            className="mr-2"
                            style={{ backgroundColor: employee.color || '#1890ff' }}
                          />
                          {employee.name}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </>
              )}
              
              {selectedType === 'crew' && (
                <>
                  <label className="block text-gray-300 mb-2">Select Crew:</label>
                  <Select
                    placeholder="Select a crew"
                    className="w-full mb-4"
                    value={selectedAssignee}
                    onChange={setSelectedAssignee}
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      option.children.props.children[1].toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent={<Empty description="No crews found" />}
                    dropdownStyle={{ backgroundColor: '#1f2937', borderColor: '#374151' }}
                  >
                    {crews.map(crew => (
                      <Option key={crew._id} value={crew._id}>
                        <div className="flex items-center">
                          <Avatar 
                            icon={<TeamOutlined />} 
                            className="mr-2"
                            style={{ backgroundColor: crew.color || '#52c41a' }}
                          />
                          {crew.name} ({crew.members?.length || 0} members)
                        </div>
                      </Option>
                    ))}
                  </Select>
                </>
              )}

              {/* Recurring job assignment scope selection */}
              {isRecurring && selectedEventId && (
                <div className="mt-4 p-3 bg-gray-700/30 rounded">
                  <div className="flex items-center mb-2">
                    <CalendarOutlined className="text-blue-400 mr-2" />
                    <span className="text-gray-200 font-medium">Assignment Scope</span>
                  </div>
                  <Radio.Group 
                    value={updateScope} 
                    onChange={(e) => setUpdateScope(e.target.value)}
                    className="w-full"
                  >
                    <div className="grid grid-cols-1 gap-2">
                      <Radio value="single" className="text-gray-300">
                        This event only
                        <div className="text-xs text-gray-400 ml-6">
                          Only change the assignment for this specific event
                        </div>
                      </Radio>
                      <Radio value="all" className="text-gray-300">
                        All events in series
                        <div className="text-xs text-gray-400 ml-6">
                          Change assignment for all events in this recurring job
                        </div>
                      </Radio>
                    </div>
                  </Radio.Group>
                </div>
              )}
              
              <div className="flex items-center justify-between mt-4">
                <div className="flex items-center">
                  <BellOutlined className="text-gray-400 mr-2" />
                  <span className="text-gray-300">Notify assignee</span>
                </div>
                <Switch 
                  checked={notifyAssignee} 
                  onChange={setNotifyAssignee} 
                />
              </div>
              
              {notifyAssignee && (
                <div className="mt-2 p-3 bg-gray-700/30 rounded text-sm text-gray-400 flex items-start">
                  <MailOutlined className="mr-2 mt-0.5 text-blue-400" />
                  <span>
                    An email notification will be sent to the assignee with job details.
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        
        <Divider className="border-gray-700" />
        
        <div className="flex justify-between">
          {(job.assignedEmployee || job.assignedCrew) && (
            <Button 
              danger
              onClick={handleUnassign}
              loading={loading}
            >
              Unassign
            </Button>
          )}
          <div className="flex space-x-2 ml-auto">
            <Button onClick={onClose}>
              Cancel
            </Button>
            <Button 
              type="primary" 
              onClick={handleAssign}
              disabled={!selectedAssignee}
              loading={loading}
            >
              Assign
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssignJobModal; 