import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  notification,
  Drawer,
  Dropdown,
  Menu,
  Tooltip,
  Tag,
  Select,
} from 'antd';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  FileTextOutlined,
  PlusOutlined,
  EnvironmentOutlined,
  BankOutlined,
  CheckSquareOutlined,
  BuildOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import axiosInstance from '../utils/axiosInstance';
import Header from '../components/Header';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/calendar.css';
import { Badge, Spin } from 'antd';
import { toast } from 'react-hot-toast';
import JobDetailsModal from '../components/JobDetailsModal';
import JobPool from '../components/JobPool';
import DayJobsModal from '../components/DayJobsModal';
import AssignJobModal from '../components/AssignJobModal';
import ContextMenu from '../components/ContextMenu';
import QuickAddModal from '../components/QuickAddModal';
import CalendarItemModal from '../components/CalendarItemModal';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

// Color map for assignments
const ASSIGNMENT_COLORS = [
  '#3B82F6', // blue
  '#10B981', // green
  '#F59E0B', // yellow
  '#EF4444', // red
  '#8B5CF6', // purple
  '#EC4899', // pink
  '#14B8A6', // teal
  '#F97316', // orange
  '#6366F1', // indigo
  '#D946EF'  // fuchsia
];

// Map to store assignment colors
const assignmentColorMap = new Map();
let colorIndex = 0;

const getAssignmentColor = (assigneeId) => {
  if (!assigneeId) {
    return '#3182ce'; // Default blue for unassigned
  }
  
  // Check if we already assigned a color to this assignee
  if (assignmentColorMap.has(assigneeId)) {
    return assignmentColorMap.get(assigneeId);
  }
  
  // Assign new color
  const color = ASSIGNMENT_COLORS[colorIndex % ASSIGNMENT_COLORS.length];
  assignmentColorMap.set(assigneeId, color);
  colorIndex++;
  
  return color;
};

// Modal for scheduling recurring jobs
const RecurringJobModal = ({ isOpen, onClose, job, date, onSchedule }) => {
  const [occurrences, setOccurrences] = useState(8);
  const [duration, setDuration] = useState(6); // Default 6 months
  const [durationType, setDurationType] = useState('month(s)');
  const [repeatPattern, setRepeatPattern] = useState('weekly');
  const [repeatDay, setRepeatDay] = useState(date ? moment(date).format('dddd') : 'Monday');
  const [assignedTeam, setAssignedTeam] = useState([]);
  const [notifyTeam, setNotifyTeam] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [crews, setCrews] = useState([]);
  const [loading, setLoading] = useState(false);
  // Add state for assignment type (employee or crew)
  const [assignmentType, setAssignmentType] = useState('employee');
  const [selectedCrew, setSelectedCrew] = useState(null);
  
  // Calculate total visits based on pattern and duration
  const totalVisits = useMemo(() => {
    // Convert duration to months for consistent calculation
    let durationInMonths = duration;
    if (durationType === 'week(s)') {
      durationInMonths = duration / 4.33; // Convert weeks to months
    } else if (durationType === 'year(s)') {
      durationInMonths = duration * 12; // Convert years to months
    }
    
    // Calculate based on pattern
    if (repeatPattern === 'weekly') {
      return Math.round(durationInMonths * 4.33); // ~4.33 weeks in a month
    } else if (repeatPattern === 'biweekly') {
      return Math.round(durationInMonths * 2.16); // ~2.16 biweekly periods in a month
    } else if (repeatPattern === 'monthly') {
      return Math.round(durationInMonths);
    }
    
    return occurrences;
  }, [repeatPattern, duration, durationType, occurrences]);

  // Format duration for display
  const formatDuration = (minutes) => {
    if (!minutes && minutes !== 0) return '1 hour'; // Default
    
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours === 0) {
      return `${remainingMinutes} minutes`;
    } else if (remainingMinutes === 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else {
      return `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes} min`;
    }
  };
  
  useEffect(() => {
    if (isOpen) {
      fetchEmployeesAndCrews();
      // Set default repeat pattern based on job's recurrence pattern
      if (job?.recurrencePattern) {
        // Normalize the recurrence pattern
        const normalizePattern = (pattern) => {
          if (!pattern) return 'weekly';
          
          const lowercasePattern = pattern.toLowerCase();
          if (lowercasePattern === 'onetime' || lowercasePattern === 'one time' || lowercasePattern === 'one-time') {
            return 'one-time';
          } else if (lowercasePattern === 'bi-weekly' || lowercasePattern === 'biweekly') {
            return 'biweekly';
          } else if (lowercasePattern === 'weekly') {
            return 'weekly';
          } else if (lowercasePattern === 'monthly') {
            return 'monthly';
          }
          return 'weekly';
        };
        
        const normalizedPattern = normalizePattern(job.recurrencePattern);
        setRepeatPattern(normalizedPattern);
        
        // Set appropriate default duration based on job recurrence pattern
        if (normalizedPattern === 'weekly') {
          setDuration(12);
          setDurationType('week(s)');
        } else if (normalizedPattern === 'biweekly') {
          setDuration(12);
          setDurationType('week(s)');
        } else if (normalizedPattern === 'monthly') {
          setDuration(6);
          setDurationType('month(s)');
        }
      }
      
      // Set default assignment type based on job's current assignment
      if (job?.assignedCrew) {
        setAssignmentType('crew');
        setSelectedCrew(typeof job.assignedCrew === 'object' ? job.assignedCrew._id : job.assignedCrew);
      } else {
        setAssignmentType('employee');
        // If job has an assigned employee, add it to the team
        if (job?.assignedEmployee) {
          const employeeId = typeof job.assignedEmployee === 'object' ? job.assignedEmployee._id : job.assignedEmployee;
          // We'll add the employee to the team when employees are loaded
        }
      }
    }
  }, [isOpen, job]);
  
  const fetchEmployeesAndCrews = async () => {
    try {
      setLoading(true);
      
      // Fetch both employees and crews in parallel
      const [employeesResponse, crewsResponse] = await Promise.all([
        axiosInstance.get('/employees'),
        axiosInstance.get('/crews')
      ]);
      
      const activeEmployees = employeesResponse.data.filter(emp => emp.status === 'Active');
      setEmployees(activeEmployees);
      setCrews(crewsResponse.data);
      
      // If job has an assigned employee, add it to the team when employees are loaded
      if (job?.assignedEmployee && assignmentType === 'employee') {
        const employeeId = typeof job.assignedEmployee === 'object' ? job.assignedEmployee._id : job.assignedEmployee;
        const employee = activeEmployees.find(emp => emp._id === employeeId);
        if (employee && !assignedTeam.some(emp => emp._id === employeeId)) {
          setAssignedTeam([employee]);
        }
      }
      
    } catch (error) {
      console.error('Error fetching employees and crews:', error);
      toast.error('Failed to load employees and crews');
    } finally {
      setLoading(false);
    }
  };
  
  const handleAssignEmployee = (employeeId) => {
    const employee = employees.find(emp => emp._id === employeeId);
    if (employee && !assignedTeam.some(emp => emp._id === employeeId)) {
      setAssignedTeam([...assignedTeam, employee]);
    }
  };
  
  const handleRemoveEmployee = (employeeId) => {
    setAssignedTeam(assignedTeam.filter(emp => emp._id !== employeeId));
  };
  
  const handleSchedule = () => {
    // Prepare the options based on assignment type
    const options = {
      occurrences: totalVisits,
      repeatPattern,
      duration,
      durationType,
      notifyTeam
    };
    
    // Add either team or crew assignment based on selection
    if (assignmentType === 'employee') {
      options.team = assignedTeam;
    } else if (assignmentType === 'crew' && selectedCrew) {
      options.assignedCrew = selectedCrew;
    }
    
    onSchedule(options);
    onClose();
  };
  
  if (!isOpen || !job) return null;
  
  return (
    <Modal 
      open={isOpen} 
      onCancel={onClose}
      centered
      width={700}
      footer={null}
      title={
        <div className="text-white text-lg font-semibold flex items-center">
          <span className="bg-green-500/20 text-green-400 p-1.5 rounded-md mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
          </span>
          Schedule Recurring Job
        </div>
      }
      className="modern-modal"
      closeIcon={<span className="text-gray-400 hover:text-white">&times;</span>}
      styles={{
        content: {
          background: '#1e2533',
          borderRadius: '8px',
        },
        header: {
          background: '#1e2533',
          borderBottom: '1px solid #2d3748',
          padding: '16px 24px',
        },
        body: {
          padding: '0',
          background: '#1e2533',
        },
        mask: {
          backdropFilter: 'blur(4px)',
          background: 'rgba(0, 0, 0, 0.6)',
        },
      }}
    >
      <div className="p-5">
        <div className="mb-6">
          <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-5 rounded-lg border border-gray-700 shadow-lg">
            <div className="flex justify-between items-start mb-4">
              <div>
                <span className="text-gray-400 text-xs uppercase tracking-wider">SERVICE</span>
                <h3 className="text-white font-semibold text-lg mt-1">{job.service}</h3>
              </div>
              <div>
                <span className="text-gray-400 text-xs uppercase tracking-wider">CUSTOMER</span>
                <p className="text-white font-medium mt-1">{job.customer?.name || 'No customer'}</p>
              </div>
            </div>
            
            <div className="mb-4">
              <span className="text-gray-400 text-xs uppercase tracking-wider">RECURRENCE PATTERN</span>
              <div className="flex items-center mt-1">
                <span className="bg-green-600/30 text-green-400 px-3 py-1 rounded-full text-xs font-medium mr-2">
                  {job.recurrencePattern || 'Weekly'}
                </span>
                <span className="text-white">
                  {job.estimatedDuration ? `${formatDuration(job.estimatedDuration)} per visit` : '1 hour per visit'}
                </span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Add the new scheduling options section */}
        <div className="mb-6">
          <h3 className="text-white text-base font-medium mb-4 flex items-center">
            <span className="bg-blue-500/20 text-blue-400 p-1.5 rounded-md mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>
            </span>
            Scheduling Options
          </h3>
          
          <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700 mb-4">
            <div className="mb-4">
              <label className="block text-gray-300 text-sm mb-2 font-medium">Schedule For</label>
              <div className="flex items-center">
                <div className="w-1/3 mr-2">
                  <input
                    type="number"
                    min="1"
                    max="60"
                    value={duration}
                    onChange={(e) => setDuration(parseInt(e.target.value) || 1)}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="w-2/3">
                  <select
                    value={durationType}
                    onChange={(e) => setDurationType(e.target.value)}
                    className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="week(s)">Week(s)</option>
                    <option value="month(s)">Month(s)</option>
                    <option value="year(s)">Year(s)</option>
                  </select>
                </div>
              </div>
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-300 text-sm mb-2 font-medium">Recurrence Pattern</label>
              <select
                value={repeatPattern}
                onChange={(e) => setRepeatPattern(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="weekly">Weekly</option>
                <option value="biweekly">Bi-weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>
            
            <div className="bg-gray-700/30 p-3 rounded-md border border-gray-600">
              <div className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span className="text-gray-300">
                  This will create <span className="text-white font-semibold">{totalVisits}</span> recurring {job.service} visits, scheduled {repeatPattern} for {duration} {durationType}.
                </span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="mb-6">
          <h3 className="text-white text-base font-medium mb-4 flex items-center">
            <span className="bg-purple-500/20 text-purple-400 p-1.5 rounded-md mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </span>
            Team Assignment
          </h3>
          
          <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700 mb-4">
            <div className="mb-4">
              <label className="block text-gray-300 text-sm mb-2 font-medium">Assignment Type</label>
              <div className="flex space-x-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="assign-employee"
                    name="assignment-type"
                    checked={assignmentType === 'employee'}
                    onChange={() => setAssignmentType('employee')}
                    className="mr-2 h-4 w-4 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor="assign-employee" className="text-gray-300">
                    Assign Employee(s)
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="assign-crew"
                    name="assignment-type"
                    checked={assignmentType === 'crew'}
                    onChange={() => setAssignmentType('crew')}
                    className="mr-2 h-4 w-4 rounded border-gray-600 text-purple-600 focus:ring-purple-500"
                  />
                  <label htmlFor="assign-crew" className="text-gray-300">
                    Assign Crew
                  </label>
                </div>
              </div>
            </div>
            
            {assignmentType === 'employee' ? (
              <>
                <label className="block text-gray-300 text-sm mb-3 font-medium">Assign Employee(s) (Optional)</label>
                {loading ? (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
                  </div>
                ) : (
                  <Select
                    placeholder="Select employees to assign"
                    className="w-full mb-3"
                    onChange={handleAssignEmployee}
                    optionFilterProp="children"
                    showSearch
                    style={{ background: '#374151' }}
                  >
                    {employees.map(employee => (
                      <Select.Option key={employee._id} value={employee._id}>
                        {employee.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
                
                <div className="flex flex-wrap gap-2 mt-3 mb-4">
                  {assignedTeam.map(employee => (
                    <Badge
                      key={employee._id}
                      count="×"
                      onClick={() => handleRemoveEmployee(employee._id)}
                      style={{ backgroundColor: '#f5222d', cursor: 'pointer' }}
                    >
                      <div className="bg-gray-700 px-3 py-1.5 rounded-full text-white text-sm flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                        {employee.name}
                      </div>
                    </Badge>
                  ))}
                </div>
              </>
            ) : (
              <>
                <label className="block text-gray-300 text-sm mb-3 font-medium">Assign Crew (Optional)</label>
                {loading ? (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-purple-500"></div>
                  </div>
                ) : (
                  <Select
                    placeholder="Select a crew to assign"
                    className="w-full mb-3"
                    value={selectedCrew}
                    onChange={(value) => setSelectedCrew(value)}
                    optionFilterProp="children"
                    showSearch
                    style={{ background: '#374151' }}
                  >
                    {crews.map(crew => (
                      <Select.Option key={crew._id} value={crew._id}>
                        <div className="flex items-center">
                          <span className="mr-2 bg-purple-500 h-3 w-3 rounded-full"></span>
                          {crew.name} ({crew.members?.length || 0} members)
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </>
            )}
              
            <div className="flex items-center p-3 bg-gray-700/50 rounded-md border border-gray-600">
              <input
                type="checkbox"
                id="notifyTeam"
                checked={notifyTeam}
                onChange={(e) => setNotifyTeam(e.target.checked)}
                className="mr-3 h-4 w-4 rounded border-gray-600 text-blue-600 focus:ring-blue-500"
              />
              <label htmlFor="notifyTeam" className="text-gray-300 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                Notify team members via email
              </label>
            </div>
          </div>
        </div>
        
        <div className="p-4 bg-blue-900/20 border border-blue-800/30 rounded-md mb-5">
          <div className="flex items-start text-sm">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-400 mr-2 mt-0.5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="text-gray-300">
              This will create {totalVisits} recurring job events on your calendar. You can edit or cancel this series later if needed.
            </span>
          </div>
        </div>
      </div>
      
      <div className="flex justify-end space-x-3 pt-4 border-t border-gray-700">
        <button
          onClick={onClose}
          className="px-5 py-2.5 rounded-md border border-gray-600 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={handleSchedule}
          className="px-5 py-2.5 rounded-md bg-green-600 text-white hover:bg-green-700 transition-colors flex items-center"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          Schedule Job Series
        </button>
      </div>
    </Modal>
  );
};

// Modal for selecting job duration
const DurationModal = ({ isOpen, onClose, job, date, onConfirm }) => {
  console.log('DurationModal render with props:', { isOpen, job, date });
  
  const [durationHours, setDurationHours] = useState(1);
  const [durationMinutes, setDurationMinutes] = useState(0);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [startTime, setStartTime] = useState('09:00');
  const [overlapWarning, setOverlapWarning] = useState(null);
  const [loading, setLoading] = useState(false);
  
  // Reset values when modal opens
  useEffect(() => {
    console.log('DurationModal useEffect triggered with isOpen:', isOpen);
    
    if (isOpen && job) {
      // If job has an estimated duration, use it as initial value
      if (job.estimatedDuration) {
        const totalMinutes = job.estimatedDuration;
        setDurationHours(Math.floor(totalMinutes / 60));
        setDurationMinutes(totalMinutes % 60);
      } else {
        // Default to 1 hour
        setDurationHours(1);
        setDurationMinutes(0);
      }
      
      // Set default start time to 9:00 AM or current time if date is today
      const now = new Date();
      const isToday = date && new Date(date).toDateString() === now.toDateString();
      
      if (isToday) {
        // Round to nearest half hour
        const hours = now.getHours();
        const minutes = now.getMinutes() >= 30 ? 30 : 0;
        const roundedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        setStartTime(roundedTime);
      } else {
        setStartTime('09:00');
      }
      
      setShowAdvanced(false);
      setOverlapWarning(null);
      
      // Check for overlaps
      if (job.assignedEmployee || job.assignedCrew) {
        checkForOverlaps();
      }
    }
  }, [isOpen, job, date]);
  
  // Check for overlapping jobs
  const checkForOverlaps = async () => {
    if (!job || !date) return;
    
    try {
      setLoading(true);
      
      // Get the assignee ID (employee or crew)
      const assigneeId = job.assignedEmployee?._id || job.assignedEmployee || job.assignedCrew?._id || job.assignedCrew;
      if (!assigneeId) return;
      
      // Format date for API query
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const startDateTime = `${formattedDate}T${startTime}:00`;
      const totalMinutes = (durationHours * 60) + durationMinutes;
      const endDateTime = moment(startDateTime).add(totalMinutes, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
      
      // Query for events on this date for this assignee
      const response = await axiosInstance.get(`/calendar/events?date=${formattedDate}&assigneeId=${assigneeId}`);
      
      // Check for overlaps
      const events = response.data;
      const overlaps = events.filter(event => {
        // Skip the current job if it's being rescheduled
        if (event.job && event.job._id === job._id) return false;
        
        const eventStart = new Date(event.start);
        const eventEnd = event.end ? new Date(event.end) : new Date(eventStart.getTime() + ((event.job?.estimatedDuration || 60) * 60 * 1000));
        const newStart = new Date(startDateTime);
        const newEnd = new Date(endDateTime);
        
        // Check for overlap
        return (newStart < eventEnd && newEnd > eventStart);
      });
      
      if (overlaps.length > 0) {
        // Format warning message
        const assigneeName = job.assignedEmployee?.name || 'This employee';
        setOverlapWarning(`Warning: ${assigneeName} already has ${overlaps.length} job(s) scheduled during this time.`);
      } else {
        setOverlapWarning(null);
      }
    } catch (error) {
      console.error('Error checking for overlaps:', error);
    } finally {
      setLoading(false);
    }
  };
  
  // Update overlap check when time or duration changes
  useEffect(() => {
    if (isOpen && job && (job.assignedEmployee || job.assignedCrew)) {
      const timeoutId = setTimeout(() => {
        checkForOverlaps();
      }, 500); // Debounce to avoid too many API calls
      
      return () => clearTimeout(timeoutId);
    }
  }, [startTime, durationHours, durationMinutes]);
  
  const handleConfirm = () => {
    // Convert to minutes for storage
    const totalMinutes = (durationHours * 60) + durationMinutes;
    
    // Create a date object with the selected time
    const scheduledDateTime = new Date(date);
    const [hours, minutes] = startTime.split(':').map(Number);
    scheduledDateTime.setHours(hours, minutes, 0, 0);
    
    onConfirm(totalMinutes, scheduledDateTime);
    onClose();
  };
  
  // Calculate total time in human-readable format
  const totalTimeDisplay = () => {
    const hours = durationHours;
    const minutes = durationMinutes;
    
    if (hours === 0) {
      return `${minutes} minutes`;
    } else if (minutes === 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else {
      return `${hours} hour${hours !== 1 ? 's' : ''} and ${minutes} minutes`;
    }
  };
  
  // Calculate end time based on start time and duration
  const endTimeDisplay = () => {
    if (!date) return '';
    
    const [hours, minutes] = startTime.split(':').map(Number);
    const startDateTime = new Date(date);
    startDateTime.setHours(hours, minutes, 0, 0);
    
    const endDateTime = new Date(startDateTime.getTime() + ((durationHours * 60 + durationMinutes) * 60 * 1000));
    return moment(endDateTime).format('h:mm A');
  };
  
  if (!isOpen || !job) return null;
  
  return (
    <Modal 
      open={isOpen} 
      onCancel={onClose}
      centered
      width={550}
      footer={null}
      title={
        <div className="text-white text-lg font-semibold flex items-center">
          <span className="bg-blue-500/20 text-blue-400 p-1.5 rounded-md mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </span>
          Set Job Time & Duration
        </div>
      }
      className="modern-modal"
      closeIcon={<span className="text-gray-400 hover:text-white">&times;</span>}
      styles={{
        content: {
          background: '#1e2533',
          borderRadius: '8px',
        },
        header: {
          background: '#1e2533',
          borderBottom: '1px solid #2d3748',
          padding: '16px 24px',
        },
        body: {
          padding: '0',
          background: '#1e2533',
        },
        mask: {
          backdropFilter: 'blur(4px)',
          background: 'rgba(0, 0, 0, 0.6)',
        },
      }}
    >
      <div className="p-5">
        {/* Job Preview Card */}
        <div className="mb-6">
          <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-5 rounded-lg border border-gray-700 shadow-lg">
            <div className="flex justify-between items-start mb-4">
              <div>
                <span className="text-gray-400 text-xs uppercase tracking-wider">Service</span>
                <h3 className="text-white font-semibold text-lg mt-1">{job.service}</h3>
              </div>
              {job.isRecurring ? (
                <span className="bg-green-600/30 text-green-400 px-3 py-1 rounded-full text-xs font-medium">
                  Recurring
                </span>
              ) : (
                <span className="bg-blue-600/30 text-blue-400 px-3 py-1 rounded-full text-xs font-medium">
                  One-time
                </span>
              )}
            </div>
            
            <div className="flex justify-between">
              <div>
                <span className="text-gray-400 text-xs uppercase tracking-wider">Customer</span>
                <p className="text-white font-medium mt-1">{job.customer?.name || 'No customer'}</p>
              </div>
              <div>
                <span className="text-gray-400 text-xs uppercase tracking-wider">Date</span>
                <p className="text-white font-medium mt-1">{moment(date).format('MMM D, YYYY')}</p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Time Selection */}
        <div className="mb-6">
          <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
            <h3 className="text-white text-base font-medium mb-4">Schedule Time</h3>
            
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block text-gray-300 text-sm mb-2 font-medium">Start Time</label>
                <input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="w-full p-3 rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors"
                />
              </div>
              <div>
                <label className="block text-gray-300 text-sm mb-2 font-medium">End Time</label>
                <div className="w-full p-3 rounded-md bg-gray-700/50 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors">
                  {endTimeDisplay()}
                </div>
              </div>
            </div>
            
            {overlapWarning && (
              <div className="mb-4 p-3 bg-yellow-900/30 border border-yellow-700/50 rounded-md text-yellow-300 text-sm">
                <div className="flex items-start">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 mt-0.5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                  <span>{overlapWarning}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* Duration Selection */}
        <div className="mb-6">
          <div className="bg-gray-800/50 p-4 rounded-lg border border-gray-700">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-white text-base font-medium">Job Duration</h3>
              <button
                onClick={() => setShowAdvanced(!showAdvanced)}
                className="text-blue-400 hover:text-blue-300 text-sm flex items-center"
              >
                {showAdvanced ? 'Simple' : 'Advanced'} Options
                <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ml-1 transition-transform ${showAdvanced ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
            </div>
            
            {/* Simple Duration Buttons */}
            <div className={`grid grid-cols-4 gap-2 mb-4 ${showAdvanced ? 'hidden' : ''}`}>
              {[15, 30, 45, 60, 90, 120, 180, 240].map(minutes => {
                const hours = Math.floor(minutes / 60);
                const mins = minutes % 60;
                const label = hours > 0 
                  ? `${hours}h${mins > 0 ? ` ${mins}m` : ''}`
                  : `${mins}m`;
                
                const isSelected = durationHours * 60 + durationMinutes === minutes;
                
                return (
                  <button
                    key={minutes}
                    onClick={() => {
                      setDurationHours(Math.floor(minutes / 60));
                      setDurationMinutes(minutes % 60);
                    }}
                    className={`py-2 px-3 rounded-md text-sm font-medium transition-colors ${
                      isSelected 
                        ? 'bg-blue-600 text-white' 
                        : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                    }`}
                  >
                    {label}
                  </button>
                );
              })}
            </div>
            
            {/* Advanced Duration Controls */}
            <div className={`transition-all duration-300 ${showAdvanced ? 'max-h-60 opacity-100 mb-5' : 'max-h-0 opacity-0 overflow-hidden'}`}>
              <div className="grid grid-cols-2 gap-4 mb-4 bg-gray-800/50 p-4 rounded-lg border border-gray-700">
                <div>
                  <label className="block text-gray-300 text-sm mb-2 font-medium">Hours</label>
                  <div className="relative">
                    <input
                      type="number"
                      min="0"
                      max="12"
                      value={durationHours}
                      onChange={(e) => setDurationHours(parseInt(e.target.value) || 0)}
                      className="w-full p-3 pl-4 rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-gray-300 text-sm mb-2 font-medium">Minutes</label>
                  <div className="relative">
                    <input
                      type="number"
                      min="0"
                      max="59"
                      step="5"
                      value={durationMinutes}
                      onChange={(e) => setDurationMinutes(parseInt(e.target.value) || 0)}
                      className="w-full p-3 pl-4 rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors"
                    />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-blue-900/20 p-3 rounded-md border border-blue-800/30">
              <div className="flex items-center text-blue-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Total time: <strong>{totalTimeDisplay()}</strong></span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="flex justify-end space-x-3 pt-4 border-t border-gray-700">
          <button
            onClick={onClose}
            className="px-5 py-2.5 rounded-md border border-gray-600 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-5 py-2.5 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors flex items-center"
            disabled={loading}
          >
            {loading ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Checking...
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Confirm
              </>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const CalendarPage = () => {
  const calendarRef = useRef(null);
  const [currentView, setCurrentView] = useState('month');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [unscheduledJobs, setUnscheduledJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jobToSchedule, setJobToSchedule] = useState(null);
  const [dropDateToSchedule, setDropDateToSchedule] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedCalendarItem, setSelectedCalendarItem] = useState(null);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [showDurationModal, setShowDurationModal] = useState(false);
  const [showCalendarItemModal, setShowCalendarItemModal] = useState(false);
  const [showQuickAddModal, setShowQuickAddModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [quickAddDate, setQuickAddDate] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [crews, setCrews] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  
  // Add date change debugging
  useEffect(() => {
    console.log('%c[DATE DEBUG] selectedDate changed:', 'background: #f59e0b; color: white; padding: 2px 6px; border-radius: 4px;', {
      date: selectedDate,
      formattedDate: moment(selectedDate).format('YYYY-MM-DD'),
      year: selectedDate.getFullYear(),
      month: selectedDate.getMonth() + 1, // 1-indexed for readability
      day: selectedDate.getDate(),
      view: currentView
    });
  }, [selectedDate, currentView]);
  
  // Continue with the existing hooks
  // Initial data fetch
  useEffect(() => {
    fetchInitialData();
  }, []);

  // Add debugging for drag and drop interactions
  useEffect(() => {
    // Only add event debugging for day and week views
    if (currentView === 'day' || currentView === 'week') {
      addEventClickDebugging();
      addDragDropDebugging();
      
      // Scroll to the current time in day and week views
      if (calendarRef.current) {
        const scrollTo = document.querySelector('.rbc-current-time-indicator');
        if (scrollTo) {
          scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [currentView]);
  
  // Add debugging for drag and drop mouse operations
  const addDragDropDebugging = () => {
    setTimeout(() => {
      console.log('%c[DRAG DEBUG] Setting up drag/drop debugging', 'color: #3b82f6;');
      
      // Debug mouse down on events (start of drag)
      document.querySelectorAll('.rbc-event').forEach(eventEl => {
        eventEl.addEventListener('mousedown', (e) => {
          const title = eventEl.querySelector('.rbc-event-content')?.textContent;
          console.log(`%c[DRAG DEBUG] Mouse DOWN on event: "${title}"`, 'color: #f59e0b; font-weight: bold;');
        });
      });
      
      // Debug mousemove (during drag)
      let lastPosition = { x: 0, y: 0 };
      let dragCounter = 0;
      
      document.addEventListener('mousemove', (e) => {
        // Only log every 20 pixels of movement to avoid console spam
        const dx = Math.abs(e.clientX - lastPosition.x);
        const dy = Math.abs(e.clientY - lastPosition.y);
        
        if (dx > 20 || dy > 20) {
          lastPosition = { x: e.clientX, y: e.clientY };
          
          // Check if we're currently dragging (look for drag preview element)
          const isDragging = document.querySelector('.rbc-addons-dnd-drag-preview');
          if (isDragging) {
            dragCounter++;
            if (dragCounter % 5 === 0) { // Log only every 5th move to reduce spam
              console.log(`%c[DRAG DEBUG] Dragging event - Position: x=${e.clientX}, y=${e.clientY}`, 
                'color: #8b5cf6; font-style: italic;');
            }
          }
        }
      });
      
      // Debug mouseup (end of drag)
      document.addEventListener('mouseup', (e) => {
        // Check if we were dragging
        const wasDragging = dragCounter > 0;
        if (wasDragging) {
          console.log(`%c[DRAG DEBUG] Mouse UP - End of drag operation at x=${e.clientX}, y=${e.clientY}`, 
            'color: #ef4444; font-weight: bold;');
          dragCounter = 0;
        }
      });
      
      // Debug the resize handles
      document.querySelectorAll('.rbc-addons-dnd-resize-ns-anchor, .rbc-addons-dnd-resize-ew-anchor').forEach(handle => {
        handle.addEventListener('mousedown', (e) => {
          const isVertical = handle.classList.contains('rbc-addons-dnd-resize-ns-anchor');
          console.log(`%c[DRAG DEBUG] Starting RESIZE operation (${isVertical ? 'vertical' : 'horizontal'})`, 
            'color: #22c55e; font-weight: bold;');
        });
      });
      
    }, 1000); // Give the calendar a moment to render
  };

  // Get event classes for styling
  const getEventClasses = (event) => {
    let classes = 'calendar-event';
    
    // Add class based on job type or item type
    if (event.job) {
      classes += ' job-event';
      
      if (event.isRecurring) {
        classes += ' recurring-event';
      }
    } else if (event.itemType) {
      classes += ` ${event.itemType}-event`;
    
    if (event.priority === 'high') {
        classes += ' high-priority';
      } else if (event.priority === 'low') {
        classes += ' low-priority';
      }
    }
    
    return classes;
  };
  
  // Get background color for event
  const getEventBackgroundColor = (event) => {
    // If the event has a color property, use it directly
    if (event.color) {
      return event.color;
    }
    
    // For job events, use the job color system
    if (event.job || event.jobType) {
      return getJobColor(event.jobType || event.job?.service);
    }
    
    // For calendar items, use their defined color with transparency
    const itemType = event.type || event.resource?.type;
    if (itemType) {
      // Use type-specific default colors if no color is specified
      switch (itemType) {
        case 'task':
          return '#1890ff'; // Blue for tasks
        case 'note':
          return '#722ed1'; // Purple for notes
        case 'event':
          return '#52c41a'; // Green for events
        default:
          return '#1890ff'; // Default blue
      }
    }
    
    // Default color
    return '#1e293b';
  };

  // Get job color based on job type
  const getJobColor = (jobType) => {
    if (!jobType) return '#3B82F6'; // Default blue
    
    const type = jobType.toLowerCase();
    
    // Return appropriate color based on job type
    if (type.includes('mow') || type.includes('lawn')) {
      return '#10B981'; // Green for lawn/mowing
    } else if (type.includes('mulch') || type.includes('garden')) {
      return '#F59E0B'; // Amber for mulching/garden
    } else if (type.includes('clean') || type.includes('rake')) {
      return '#6366F1'; // Indigo for cleaning/raking
    } else if (type.includes('snow') || type.includes('plow')) {
      return '#60A5FA'; // Light blue for snow removal
    } else if (type.includes('fertiliz') || type.includes('seed')) {
      return '#34D399'; // Emerald for fertilizing/seeding
    } else if (type.includes('prune') || type.includes('trim')) {
      return '#F97316'; // Orange for pruning/trimming
    } else if (type.includes('irrig') || type.includes('water')) {
      return '#0EA5E9'; // Sky blue for irrigation/watering
    } else if (type.includes('pest') || type.includes('weed')) {
      return '#EF4444'; // Red for pest/weed control
    } else if (type.includes('consult') || type.includes('design')) {
      return '#A855F7'; // Purple for consultation/design
    }
    
    // Default color
    return '#3B82F6'; // Blue as default
  };

  // Get border color for event
  const getEventBorderColor = (event) => {
    // For job events, use assignment color or default
    if (event.job) {
      // Check if there's an assigned employee or crew 
      if (event.assignedEmployee) {
        const employee = employees.find(emp => emp._id === event.assignedEmployee);
        return employee?.color || '#3b82f6'; // Default blue if no color defined
      } 
      
      if (event.assignedCrew) {
        const crew = crews.find(c => c._id === event.assignedCrew);
        return crew?.color || '#10b981'; // Default green if no color defined
      }
      
      return '#64748b'; // Default gray for unassigned jobs
    }
    
    // For calendar items, use their defined color
    if (event.itemType) {
      return event.color || '#1890ff';
    }
    
    return '#64748b'; // Default border color
  };

  // Get text color for event
  const getEventTextColor = (event) => {
    // For job events, use white
    if (event.job) {
      return '#ffffff';
    }
    
    // For calendar items, use dark color that contrasts with the background
    if (event.itemType) {
      return '#1e293b'; // Dark slate for text on colored backgrounds
    }
    
    return '#ffffff'; // Default white text
  };
  
  // Format the title for display in the calendar
  const getEventTitle = (event) => {
    // Check for job events
    if (event.job || event.resource?.job) {
      const jobName = event.job?.service || event.resource?.job?.service || 'Untitled Job';
      const customerName = event.job?.customer?.name || event.resource?.job?.customer?.name;
      return customerName ? `${jobName} - ${customerName}` : jobName;
    }
    
    // Check for calendar items by type
    const itemType = event.type || event.resource?.type;
    const title = event.title || 'Untitled';
    
    if (itemType) {
      // For tasks, don't need to add a prefix since we're using icons
      return title;
    }
    
    // Default title
    return title || 'Untitled Event';
  };

  // Update fetchInitialData to also fetch employees and crews
  const fetchInitialData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Add a timestamp to prevent caching
      const timestamp = new Date().getTime();
      
      // Create a much wider date range to fetch events - use 12 months ahead instead of just current month
      // This ensures all recurring events are retrieved
      const startDate = moment().startOf('month').subtract(1, 'month').toISOString();
      const endDate = moment().add(12, 'months').endOf('month').toISOString();
      
      console.log('Fetching all calendar events and items for extended date range:', { 
        startDate: moment(startDate).format('YYYY-MM-DD'), 
        endDate: moment(endDate).format('YYYY-MM-DD') 
      });
      
      // Use the all-events endpoint to get both job events and calendar items with a wider date range
      const eventsResponse = await axiosInstance.get(
        `/calendar/all-events?start=${encodeURIComponent(startDate)}&end=${encodeURIComponent(endDate)}&_t=${timestamp}`
      );
      
      console.log(`Fetched ${eventsResponse.data.length} events/items, processing...`);
      
      // Format the events for the calendar - ensure they don't stretch over days
      const updatedFormattedEvents = eventsResponse.data
        .map(event => formatEvent(event))
        .filter(Boolean);
      
      // Set events with new array reference to force re-render
      setEvents(eventsResponse.data);
      setFormattedEvents(updatedFormattedEvents);
      
      // Fetch employees and crews for assignment display
      await fetchEmployeesAndCrews();
      
      // Fetch unscheduled jobs
      const jobsResponse = await axiosInstance.get(`/calendar/unscheduled-jobs?_t=${timestamp}`);
      setUnscheduledJobs(jobsResponse.data);
      
    } catch (err) {
      console.error('Error fetching initial data:', err);
      setError('Failed to load calendar data. Please try refreshing the page.');
    } finally {
      setLoading(false);
    }
  };
  
  // Add function to fetch employees and crews
  const fetchEmployeesAndCrews = async () => {
    try {
      const [employeesResponse, crewsResponse] = await Promise.all([
        axiosInstance.get('/employees'),
        axiosInstance.get('/crews')
      ]);
      
      setEmployees(employeesResponse.data);
      setCrews(crewsResponse.data);
      
      console.log('Fetched employees and crews:', {
        employees: employeesResponse.data.length,
        crews: crewsResponse.data.length
      });
    } catch (error) {
      console.error('Error fetching employees and crews:', error);
    }
  };

  // Add this function to make the event debugging easier
  const addEventClickDebugging = () => {
    console.log('[DEBUG] Setting up event click debugging');
    
    // Use a timeout to ensure the calendar has rendered
    setTimeout(() => {
      // Get all event elements in the calendar
      const eventElements = document.querySelectorAll('.fc-event');
      console.log(`[DEBUG] Found ${eventElements.length} event elements in the DOM`);
      
      // Add a click handler to log which element was clicked
      eventElements.forEach((element, index) => {
        element.addEventListener('click', (e) => {
          console.log(`[DEBUG] DOM Event clicked:`, {
            index,
            element,
            classList: element.className,
            eventId: element.getAttribute('data-event-id') || 'unknown'
          });
        });
      });
    }, 1000);
  };

  // Fetch all calendar events (jobs, tasks, notes, etc.)
  const fetchCalendarEvents = async (specificEventId = null) => {
    try {
      setLoading(true);
      
      const range = calendarRef.current ? calendarRef.current.getView() : null;
      let startDate, endDate;
      
      if (range) {
        if (Array.isArray(range.startDate)) {
          // Week or day view
          startDate = moment(range.startDate[0]).startOf('day');
          endDate = moment(range.endDate[range.endDate.length - 1]).endOf('day');
        } else {
          // Month view
          startDate = moment(range.startDate).startOf('day');
          endDate = moment(range.endDate).endOf('day');
        }
      } else {
        // Default to current month if no range is available
        startDate = moment().startOf('month');
        endDate = moment().add(1, 'month').endOf('month');
      }
      
      // If we're refreshing after a specific event update, use a wider date range
      // to ensure we capture any series or related events
      if (specificEventId) {
        startDate = moment(startDate).subtract(1, 'month');
        endDate = moment(endDate).add(1, 'month');
      }
      
      // Format dates for API request
      const formattedStartDate = startDate.toISOString();
      const formattedEndDate = endDate.toISOString();
      
      console.log('Fetching calendar events for date range:', { 
        startDate: startDate.format('YYYY-MM-DD'), 
        endDate: endDate.format('YYYY-MM-DD'),
        isSpecificRefresh: specificEventId ? true : false,
        specificEventId 
      });
      
      // Add a timestamp to prevent caching issues
      const timestamp = new Date().getTime();
      
      // Use the all-events endpoint to get both job events and other calendar items
      // Add a cache-busting parameter to ensure fresh data
      const response = await axiosInstance.get(
        `/calendar/all-events?start=${encodeURIComponent(formattedStartDate)}&end=${encodeURIComponent(formattedEndDate)}&_t=${timestamp}`
      );
      
      console.log(`Fetched ${response.data.length} calendar events`);
      
      // Small delay to ensure state updates properly
      setTimeout(() => {
        try {
          // Format the events for the calendar
          const updatedFormattedEvents = response.data
            .map(event => formatEvent(event))
            .filter(Boolean);
          
          // Update both the raw events data and the formatted events for display
          setEvents(response.data);
          setFormattedEvents(updatedFormattedEvents);
          
          // If we were refreshing for a specific event, verify it's in the returned data
          if (specificEventId) {
            const foundEvent = response.data.find(event => event._id === specificEventId);
            if (!foundEvent) {
              console.log(`%c[REFRESH DEBUG] Warning: Event ${specificEventId} not found in refreshed data`, 
                'color: #f59e0b;');
            } else {
              console.log(`%c[REFRESH DEBUG] Success: Event ${specificEventId} found in refreshed data`, 
                'color: #22c55e;');
            }
          }
          
          setLoading(false);
        } catch (formatError) {
          console.error('Error formatting calendar events:', formatError);
          setLoading(false);
        }
      }, 250); // Short delay to ensure backend changes are reflected
    } catch (error) {
      console.error('Error fetching calendar events:', error);
      toast.error('Failed to load calendar events');
      setLoading(false);
    }
  };

  const fetchUnscheduledJobs = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Fetch unscheduled jobs
      const jobsResponse = await axiosInstance.get(`/calendar/unscheduled-jobs?_t=${new Date().getTime()}`);
      setUnscheduledJobs(jobsResponse.data);
      
    } catch (err) {
      console.error('Error fetching unscheduled jobs:', err);
      setError('Failed to load unscheduled jobs. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Format events for display in calendar
  const formatEvent = (event) => {
    try {
      let formattedEvent = { ...event };
      
      // Handle job events (original logic)
      if (event.type === 'job' || event.jobType || event.job) {
        // Default title formatting
        if (event.job) {
          const jobService = event.job.service || 'Job';
          const customerName = event.job.customer?.name || '';
          formattedEvent.title = customerName ? `${jobService} - ${customerName}` : jobService;
        } else {
          formattedEvent.title = event.customer ? `${event.jobType} - ${event.customer}` : (event.jobType || 'Job');
        }
        
        // If the event has a job but no jobType, extract it from the job
        if (event.job && !event.jobType) {
          formattedEvent.jobType = event.job.service;
        }
        
        // Ensure start and end times are valid Date objects - critically important
        formattedEvent.start = new Date(event.start);
        
        // Calculate end date - make sure it doesn't go past midnight to prevent multi-day stretching
        if (event.end) {
          formattedEvent.end = new Date(event.end);
          
          // If end date is on a different day, set it to 11:59:59 PM of the start date
          if (formattedEvent.end.getDate() !== formattedEvent.start.getDate() || 
              formattedEvent.end.getMonth() !== formattedEvent.start.getMonth() || 
              formattedEvent.end.getFullYear() !== formattedEvent.start.getFullYear()) {
            
            console.log('Adjusting multi-day event to single day:', {
              title: formattedEvent.title,
              originalStart: formattedEvent.start.toISOString(),
              originalEnd: formattedEvent.end.toISOString()
            });
            
            const adjustedEnd = new Date(formattedEvent.start);
            adjustedEnd.setHours(23, 59, 59, 999);
            formattedEvent.end = adjustedEnd;
            
            console.log('Event adjusted to:', {
              newEnd: formattedEvent.end.toISOString()
            });
          }
        } else {
          // If no end date, default to start + 1 hour
          const endDate = new Date(formattedEvent.start);
          endDate.setHours(endDate.getHours() + 1);
          
          // If this goes to next day, cap at 11:59:59 PM
          if (endDate.getDate() !== formattedEvent.start.getDate()) {
            endDate.setHours(23, 59, 59, 999);
          }
          
          formattedEvent.end = endDate;
        }
        
        // Set color and other properties
        formattedEvent.color = event.color || getJobColor(formattedEvent.jobType);
        formattedEvent.textColor = "#ffffff";
        formattedEvent.borderColor = "#000000";
        formattedEvent.id = event._id;
        formattedEvent.allDay = false; // Explicitly set to false to ensure it's treated as a time-specific event
        formattedEvent.resource = event;
        formattedEvent.type = 'job'; // Explicitly mark as job type
      }
      // Handle custom calendar items (new logic)
      else if (event.type === 'task' || event.type === 'note' || event.type === 'event') {
        formattedEvent.title = event.title || 'Untitled';
        formattedEvent.start = new Date(event.start);
        formattedEvent.end = new Date(event.end);
        
        // Check for multi-day events and adjust if needed
        if (formattedEvent.end.getDate() !== formattedEvent.start.getDate() || 
            formattedEvent.end.getMonth() !== formattedEvent.start.getMonth() || 
            formattedEvent.end.getFullYear() !== formattedEvent.start.getFullYear()) {
          
          const adjustedEnd = new Date(formattedEvent.start);
          adjustedEnd.setHours(23, 59, 59, 999);
          formattedEvent.end = adjustedEnd;
        }
        
        // Use the event's actual color without modification - this is critical
        formattedEvent.color = event.color || getEventBackgroundColor({ type: event.type });
        formattedEvent.textColor = "#ffffff";
        formattedEvent.borderColor = getPriorityColor(event.priority);
        formattedEvent.id = event._id;
        formattedEvent.allDay = false;
        // Preserve the original event data in the resource property
        formattedEvent.resource = { ...event };
        formattedEvent.priority = event.priority || 'medium';
        // Make sure type is preserved at top level too
        formattedEvent.type = event.type;
      }
      // Default fallback
      else {
        // For any other event that might be a calendar item but doesn't have a type
        const isCalendarItem = !event.job && (event._id || event.id);
        
        // Check if it's likely a calendar item
        if (isCalendarItem) {
          formattedEvent.title = event.title || 'Untitled Event';
          formattedEvent.start = new Date(event.start);
          
          // Ensure end date is valid and doesn't stretch to next day
          if (event.end) {
            formattedEvent.end = new Date(event.end);
            
            // Prevent multi-day stretching
            if (formattedEvent.end.getDate() !== formattedEvent.start.getDate() ||
                formattedEvent.end.getMonth() !== formattedEvent.start.getMonth() ||
                formattedEvent.end.getFullYear() !== formattedEvent.start.getFullYear()) {
              
              const adjustedEnd = new Date(formattedEvent.start);
              adjustedEnd.setHours(23, 59, 59, 999);
              formattedEvent.end = adjustedEnd;
      }
    } else {
            // Default to 1 hour duration
            const endDate = new Date(formattedEvent.start);
            endDate.setHours(endDate.getHours() + 1);
            
            // Cap at end of day if needed
            if (endDate.getDate() !== formattedEvent.start.getDate()) {
              endDate.setHours(23, 59, 59, 999);
            }
            
            formattedEvent.end = endDate;
          }
          
          formattedEvent.color = event.color || '#1890ff'; // Use the event's color or default blue
          formattedEvent.textColor = "#ffffff";
          formattedEvent.borderColor = getPriorityColor(event.priority);
          formattedEvent.id = event._id || event.id;
          formattedEvent.allDay = false;
          formattedEvent.resource = { ...event };
          formattedEvent.priority = event.priority || 'medium';
          // If we can detect the type, preserve it
          if (event.type) {
            formattedEvent.type = event.type;
          }
        } else {
          // Regular event fallback
          formattedEvent.title = event.title || 'Untitled Event';
          formattedEvent.start = new Date(event.start);
          
          // Set end date safely
          if (event.end) {
            formattedEvent.end = new Date(event.end);
            
            // Prevent multi-day events
            if (formattedEvent.end.getDate() !== formattedEvent.start.getDate() ||
                formattedEvent.end.getMonth() !== formattedEvent.start.getMonth() ||
                formattedEvent.end.getFullYear() !== formattedEvent.start.getFullYear()) {
              
              const adjustedEnd = new Date(formattedEvent.start);
              adjustedEnd.setHours(23, 59, 59, 999);
              formattedEvent.end = adjustedEnd;
            }
          } else {
            formattedEvent.end = new Date(formattedEvent.start);
            formattedEvent.end.setHours(formattedEvent.end.getHours() + 1);
            
            // Cap at end of day if needed
            if (formattedEvent.end.getDate() !== formattedEvent.start.getDate()) {
              formattedEvent.end.setHours(23, 59, 59, 999);
            }
          }
          
          formattedEvent.color = '#1890ff'; // Default blue color
          formattedEvent.textColor = "#ffffff";
          formattedEvent.borderColor = "#000000";
          formattedEvent.id = event._id || event.id;
          formattedEvent.allDay = false;
          formattedEvent.resource = { ...event };
        }
      }
      
      // Final check to ensure event isn't stretched over multiple days (catch-all safety)
      if (formattedEvent.start && formattedEvent.end) {
        const startDay = formattedEvent.start.getDate();
        const endDay = formattedEvent.end.getDate();
        
        if (startDay !== endDay || 
            formattedEvent.start.getMonth() !== formattedEvent.end.getMonth() || 
            formattedEvent.start.getFullYear() !== formattedEvent.end.getFullYear()) {
          
          console.log('Final adjustment for multi-day event:', {
            id: formattedEvent.id,
            title: formattedEvent.title
          });
          
          const adjustedEnd = new Date(formattedEvent.start);
          adjustedEnd.setHours(23, 59, 59, 999);
          formattedEvent.end = adjustedEnd;
        }
      }
      
      console.log('Formatted event:', { 
        id: formattedEvent.id,
        title: formattedEvent.title,
        type: formattedEvent.type,
        color: formattedEvent.color,
        priority: formattedEvent.priority,
        start: formattedEvent.start?.toLocaleString(),
        end: formattedEvent.end?.toLocaleString()
      });
      
      return formattedEvent;
    } catch (error) {
      console.error('Error formatting event:', error, event);
      return null;
    }
  };

  // Get priority color based on priority level
  const getPriorityColor = (priority) => {
    if (!priority) return '#4b5563'; // Default gray
    
    switch (priority.toLowerCase()) {
      case 'high':
        return '#ef4444'; // Red for high priority
      case 'medium':
        return '#f59e0b'; // Amber for medium priority
      case 'low':
        return '#10b981'; // Green for low priority
      default:
        return '#4b5563'; // Default gray
    }
  };

  const handleDragStart = async (e, job) => {
    try {
      // Store the dragged job ID in the backend
      await axiosInstance.post('/calendar/drag-start', { jobId: job._id });
      
      const eventData = {
        title: job.service,
        resource: { job }
      };
      e.dataTransfer.setData('event', JSON.stringify(eventData));
      e.dataTransfer.setData('text/plain', ''); // Required for Firefox
    } catch (err) {
      console.error('Error in handleDragStart:', err);
    }
  };

  const handleDrop = async (dropEvent) => {
    try {
      console.log('handleDrop called with:', dropEvent);
      
      // Get the dragged job
      const draggedJobResponse = await axiosInstance.get('/calendar/last-dragged');
      console.log('Dragged job response:', draggedJobResponse.data);
      
      const job = draggedJobResponse.data.job;

      if (!job) {
        toast.error('Could not retrieve job information. Please try again.');
        return;
      }

      // Check if the job has assignments
      if (job.assignedEmployee || job.assignedCrew) {
        console.log('Job has assignment:', { 
          employee: job.assignedEmployee, 
          crew: job.assignedCrew 
        });
      }

      // Set drop date
      const dropDate = dropEvent.start;
      console.log('Setting up job for date:', dropDate);
      
      // Show toast notification
      toast.success(
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <span>Setting up job: <strong>{job.service}</strong></span>
        </div>,
        { duration: 2000 }
      );
      
      // First, always show the duration modal
      setJobToSchedule(job);
      setDropDateToSchedule(dropDate);
      
      console.log('About to show duration modal with:', {
        job: job,
        date: dropDate,
        isRecurring: job.isRecurring
      });
      
      // Force a small delay to ensure state is updated before showing modal
      setTimeout(() => {
        setShowDurationModal(true);
        console.log('Duration modal state set to:', true);
      }, 100);

    } catch (error) {
      console.error('Error handling drop:', error);
      toast.error('Failed to process drop. Please try again.');
    }
  };

  const handleDurationConfirm = (durationMinutes, scheduledDateTime) => {
    console.log('[DEBUG] handleDurationConfirm - Confirming duration:', {
      durationMinutes,
      scheduledDateTime,
      jobToSchedule: jobToSchedule
    });
    
    if (!jobToSchedule || !dropDateToSchedule) {
      toast.error('Missing job information. Please try again.');
      return;
    }
    
    // Update the job's estimated duration
    const updatedJob = { ...jobToSchedule, estimatedDuration: durationMinutes };
    
    // For recurring jobs, show the recurring job modal
    if (updatedJob.isRecurring) {
      // Show a transition toast
      toast.success(
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <span>Duration set! Now configure the recurring schedule.</span>
        </div>,
        { duration: 3000 }
      );
      
      setSelectedJob(updatedJob);
      setSelectedDate(scheduledDateTime || dropDateToSchedule);
      setShowRecurringModal(true);
    } else {
      // For one-time jobs, schedule directly with a loading toast
      const loadingToast = toast.loading('Scheduling job...');
      
      scheduleJob(updatedJob, scheduledDateTime || dropDateToSchedule, 1)
        .then(() => {
          toast.dismiss(loadingToast);
          toast.success(
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <span>Job scheduled successfully!</span>
            </div>
          );
        })
        .catch((error) => {
          toast.dismiss(loadingToast);
          toast.error(`Failed to schedule job: ${error.message || 'Unknown error'}`);
        });
    }
  };

  const scheduleJob = async (job, date, occurrences = 1, options = {}) => {
    console.log('[DEBUG] scheduleJob - Scheduling job:', {
      jobId: job._id,
      date: date.toISOString(),
      occurrences,
      options
    });
    
    if (!job || !date) {
      console.error('[DEBUG] scheduleJob - Missing job or date');
      toast.error('Missing job information. Please try again.');
      return;
    }
    
    try {
      // Instead of using the date object directly, create an ISO string with the exact time
      // This prevents timezone issues when creating recurring events
      const dateISOString = date.toISOString();
      
      // Log the exact time we're using
      console.log('[DEBUG] scheduleJob - Using exact ISO date string:', dateISOString);
      
      const { repeatPattern, team, notifyTeam, duration, durationType } = options;
      
      // Normalize recurrence pattern to ensure it matches the backend enum values
      const normalizePattern = (pattern) => {
        if (!pattern) return job.isRecurring ? 'weekly' : 'one-time';
        
        const lowercasePattern = pattern.toLowerCase();
        if (lowercasePattern === 'onetime' || lowercasePattern === 'one time') {
          return 'one-time';
        } else if (lowercasePattern === 'bi-weekly' || lowercasePattern === 'biweekly') {
          return 'biweekly';
        } else if (lowercasePattern === 'weekly') {
          return 'weekly';
        } else if (lowercasePattern === 'monthly') {
          return 'monthly';
        }
        return job.isRecurring ? 'weekly' : 'one-time';
      };
      
      // Prepare assignment data
      const assignmentData = {};
      
      // First check if team assignment is provided through options
      if (team && team.length > 0) {
        console.log('[DEBUG] scheduleJob - Team assignment provided:', team);
        // Get the first team member for assignment (current logic only supports one assignee)
        const firstTeamMember = team[0];
        assignmentData.assignedEmployee = firstTeamMember._id;
        console.log('[DEBUG] scheduleJob - Using team member for assignment:', firstTeamMember.name, firstTeamMember._id);
      }
      // Then check job's existing assignments if no team was provided
      else if (job.assignedEmployee) {
        console.log('[DEBUG] scheduleJob - Using job\'s employee assignment:', job.assignedEmployee);
        assignmentData.assignedEmployee = typeof job.assignedEmployee === 'object' 
          ? job.assignedEmployee._id 
          : job.assignedEmployee;
      }
      else if (job.assignedCrew) {
        console.log('[DEBUG] scheduleJob - Using job\'s crew assignment:', job.assignedCrew);
        assignmentData.assignedCrew = typeof job.assignedCrew === 'object' 
          ? job.assignedCrew._id 
          : job.assignedCrew;
      }
      
      // Add any explicit assignment data from options (these override other assignments)
      if (options.assignedEmployee) {
        console.log('[DEBUG] scheduleJob - Including employee assignment from options:', options.assignedEmployee);
        assignmentData.assignedEmployee = options.assignedEmployee;
        assignmentData.assignedCrew = null; // Clear crew assignment
      } else if (options.assignedCrew) {
        console.log('[DEBUG] scheduleJob - Including crew assignment from options:', options.assignedCrew);
        assignmentData.assignedCrew = options.assignedCrew;
        assignmentData.assignedEmployee = null; // Clear employee assignment
      }
      
      console.log('[DEBUG] scheduleJob - Final assignment data:', assignmentData);
      
      // For one-time jobs or single occurrence of recurring jobs
      if (occurrences === 1 || !job.isRecurring) {
        await axiosInstance.post('/calendar/events', {
          jobId: job._id,
          date: dateISOString, // Use the ISO string instead of the date object
          createSeries: false,
          recurrencePattern: 'one-time', // Explicitly set to 'one-time' with hyphen
          team: team || [],
          notifyTeam: notifyTeam || false,
          estimatedDuration: job.estimatedDuration, // Pass the duration from the modal
          ...assignmentData // Include assignment data
        });
        
        toast.success(
          <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <span>Job scheduled successfully!</span>
          </div>,
          { duration: 3000 }
        );
      }
      // For multiple occurrences of recurring jobs
      else if (job.isRecurring && occurrences > 1) {
        // Normalize the frequency
        const normalizedFrequency = normalizePattern(repeatPattern || job.recurrencePattern);
        
        // Send scheduling info including duration type
        const schedulingData = {
          jobId: job._id,
          date: dateISOString, // Use the ISO string instead of the date object
          createSeries: true,
          occurrences: occurrences, // Make sure we're passing the full number of occurrences
          frequency: normalizedFrequency,
          recurrencePattern: normalizedFrequency, // Explicitly set recurrencePattern
          team: team || [],
          notifyTeam: notifyTeam || false,
          estimatedDuration: job.estimatedDuration, // Pass the duration from the modal
          preserveTime: true, // Add a flag to indicate time should be preserved
          ...assignmentData // Include assignment data
        };
        
        // Add duration information if provided
        if (duration && durationType) {
          schedulingData.scheduleDuration = duration;
          schedulingData.scheduleDurationType = durationType;
          console.log('[DEBUG] scheduleJob - Including schedule duration:', `${duration} ${durationType}`);
        }
        
        // Show loading toast for longer operations
        const loadingToast = toast.loading(
          `Scheduling job for ${occurrences} ${normalizedFrequency} occurrences...`
        );
        
        try {
          const response = await axiosInstance.post('/calendar/events', schedulingData);
          toast.dismiss(loadingToast);
          
          // Check if we received the expected number of events
          const eventsCreated = response.data?.events?.length || 0;
          console.log(`[DEBUG] scheduleJob - Created ${eventsCreated} events out of ${occurrences} requested`);
          
          toast.success(
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <span>
                Job scheduled for {eventsCreated} {normalizedFrequency} occurrences!
                {eventsCreated < occurrences && 
                  ` (Note: Only ${eventsCreated} of ${occurrences} events were created)`
                }
              </span>
            </div>,
            { duration: 4000 }
          );
        } catch (error) {
          toast.dismiss(loadingToast);
          console.error('[DEBUG] scheduleJob - Error posting calendar events:', error);
          toast.error(`Failed to schedule recurring job: ${error.message || 'Unknown error'}`);
          throw error;
        }
      }
      
      // Switch to month view to better display recurring events
      setCurrentView('month');
      
      // Do a thorough refresh of calendar data to ensure all events are visible
      // First fetch initial data
      await fetchInitialData();
      
      // Then get specific events for this job to verify all were created
      try {
        const jobEventsResponse = await axiosInstance.get(`/calendar/events?jobId=${job._id}`);
        const jobEvents = jobEventsResponse.data;
        console.log(`[DEBUG] scheduleJob - Verified ${jobEvents.length} events for job ${job._id}`);
        
        // If expected occurrences don't match actual events, log a warning
        if (occurrences > 1 && jobEvents.length < occurrences) {
          console.warn(`[DEBUG] scheduleJob - Warning: Expected ${occurrences} events but found ${jobEvents.length}`);
        }
      } catch (err) {
        console.error('[DEBUG] scheduleJob - Error fetching job events:', err);
      }
      
      // Return success
      return { success: true };
      
    } catch (error) {
      console.error('[DEBUG] scheduleJob - Error scheduling job:', error);
      throw error;
    }
  };

  const handleEventClick = async (event) => {
    console.log('[DEBUG] handleEventClick - Event clicked:', {
      eventId: event.id || event._id,
      jobId: event.job?._id || event.jobId || event.resource?.job?._id,
      title: event.title,
      type: event.type,
      start: event.start,
      fullEvent: event // Log the full event object to see its structure
    });
    
    // Determine if this is a calendar item (task, note, event) or a job event
    if (event.type === 'task' || event.type === 'note' || event.type === 'event') {
      // This is a calendar item - show the calendar item modal
      setSelectedCalendarItem(event);
      setShowCalendarItemModal(true);
      return;
    }
    
    // Determine the job ID from various possible locations in the event object
    const jobId = event.job?._id || event.jobId || event.resource?.job?._id;
    
    if (!jobId) {
      console.error('[DEBUG] handleEventClick - No job ID found in event:', event);
      
      // Check if this might be a calendar item even though it doesn't have the type field set
      if (!event.job && (event._id || event.id)) {
        // This might be a calendar item - try to treat it as such
        setSelectedCalendarItem(event);
        setShowCalendarItemModal(true);
        return;
      }
      
      toast.error('Could not find job details for this event');
      return;
    }
    
    try {
      // Get full job details to ensure we have the latest data
      console.log('[DEBUG] handleEventClick - Fetching job details for ID:', jobId);
      const response = await axiosInstance.get(`/jobs/${jobId}`);
      const jobDetails = response.data;
      
      console.log('[DEBUG] handleEventClick - Fetched job details:', {
        jobId: jobDetails._id,
        service: jobDetails.service,
        assignedEmployee: jobDetails.assignedEmployee,
        assignedCrew: jobDetails.assignedCrew
      });
      
      // Store the selected job and event ID
      setSelectedJob(jobDetails);
      setSelectedEventId(event.id || event._id);
      
      // Open the job details modal
      console.log('[DEBUG] handleEventClick - Opening job details modal');
      setShowJobDetails(true);
    } catch (error) {
      console.error('[DEBUG] handleEventClick - Error fetching job details:', error);
      toast.error('Could not load job details');
    }
  };

  const handleJobDetailsUpdate = async (action, job, eventId) => {
    console.log('Job details update action:', action, 'for job:', job?._id, 'event:', eventId);
    
    // Refresh calendar data if action is 'refresh'
    if (action === 'refresh') {
      await fetchInitialData();
      return;
    }
    
    // Handle assignment actions
    if (action === 'assign' || action === 'assignCrew') {
      console.log('Opening assignment modal for job:', job, 'and event:', eventId);
      setSelectedJob(job);
      if (eventId) {
        setSelectedEventId(eventId);
      }
      setShowAssignModal(true);
    }
  };

  // Handle clicks on calendar day cells
  const handleDayClick = async (slotInfo) => {
    try {
      // Get the date from the slot info
      const date = new Date(slotInfo.start);
      
      // Format date for display and for the modal
      const formattedDate = moment(date).format('YYYY-MM-DD');
      console.log(`Day clicked: ${formattedDate}`);
      
      // Show the quick add modal with the selected date
      setSelectedDate(date);
      setQuickAddDate(date);
      setShowQuickAddModal(true);
      
    } catch (error) {
      console.error('Error preparing day click action:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  // Handle adding a new calendar item
  const handleAddCalendarItem = async (item) => {
    try {
      console.log('Adding calendar item:', item);
      
      // If item type is job and we have jobId + modal flags
      if (item.type === 'job' && item.jobId) {
        console.log('Processing job item with ID:', item.jobId);
        
        // For recurring jobs, show the recurring modal
        if (item.showRecurringModal) {
          try {
            const jobResponse = await axiosInstance.get(`/jobs/${item.jobId}`);
            const job = jobResponse.data;
            
            // Set job and date for the recurring modal
            setSelectedJob(job);
            setSelectedDate(new Date(item.start));
            
            // Show the recurring modal after a small delay
            setTimeout(() => {
              setShowRecurringModal(true);
            }, 100);
          } catch (err) {
            console.error('Error fetching job details for recurring modal:', err);
            toast.error('Could not load job details. Please try again.');
          }
        }
        // For one-time jobs, show the duration modal
        else if (item.showDurationModal) {
          try {
            const jobResponse = await axiosInstance.get(`/jobs/${item.jobId}`);
            const job = jobResponse.data;
            
            // Set job and date for the duration modal
            setJobToSchedule(job);
            setDropDateToSchedule(new Date(item.start));
            
            // Show the duration modal after a small delay
            setTimeout(() => {
              setShowDurationModal(true);
            }, 100);
          } catch (err) {
            console.error('Error fetching job details for duration modal:', err);
            toast.error('Could not load job details. Please try again.');
        }
      } else {
          // If we have a job ID but no modal flags, refresh calendar events
          toast.success('Job created successfully!');
          await fetchInitialData();
        }
        
        return;
      }
      // Legacy path for job creation - redirect to jobs page
      else if (item.type === 'job') {
        window.location.href = `/jobs/new?date=${moment(item.start).format('YYYY-MM-DD')}&time=${moment(item.start).format('HH:mm')}`;
        return;
      }
      
      // For other item types (task, note, event), create directly in the API
      const loadingToast = toast.loading(`Creating ${item.type}...`);
      
      const response = await axiosInstance.post('/calendar/items', {
        type: item.type,
        title: item.title,
        description: item.description,
        start: item.start,
        end: item.end,
        color: item.color,
        priority: item.priority
      });
      
      toast.dismiss(loadingToast);
      toast.success(
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          <span>{item.type.charAt(0).toUpperCase() + item.type.slice(1)} created successfully!</span>
        </div>
      );
      
      // Refresh calendar events
      await fetchCalendarEvents();
      
    } catch (error) {
      console.error('Error adding calendar item:', error);
      toast.error(`Failed to create ${item.type}: ${error.message || 'Unknown error'}`);
    }
  };

  const onDropFromOutside = (dropEvent) => {
    console.log('onDropFromOutside called with:', dropEvent);
    
    if (!dropEvent || !dropEvent.start) {
      console.error('Invalid drop event:', dropEvent);
      return;
    }
    
    handleDrop(dropEvent);
  };

  const handleRangeChange = async (range) => {
    // Create a unique ID for this range change operation
    const rangeId = Date.now().toString().slice(-4);
    
    try {
      setLoading(true);
      let start, end;
      
      console.log(`%c[RANGE DEBUG #${rangeId}] Range change received:`, 'background: #6366f1; color: white; padding: 2px 6px; border-radius: 4px;', range);
      console.log(`%c[RANGE DEBUG #${rangeId}] Current state:`, 'color: #6366f1;', {
        selectedDate: moment(selectedDate).format('YYYY-MM-DD'),
        view: currentView
      });
      
      if (Array.isArray(range)) {
        // Week or day view
        start = moment(range[0]).startOf('day').format('YYYY-MM-DD');
        // For week view, extend the range by 2 more weeks to ensure all events are visible
        end = moment(range[range.length - 1]).add(2, 'weeks').endOf('day').format('YYYY-MM-DD');
        console.log(`%c[RANGE DEBUG #${rangeId}] Array range detected (week/day view):`, 'color: #6366f1;', { 
          start, 
          end,
          length: range.length
        });
        
        // Update the selected date to the first day of the range
        if (range[0] && selectedDate.getTime() !== range[0].getTime()) {
          console.log(`%c[RANGE DEBUG #${rangeId}] Updating selected date for week/day view:`, 'color: #6366f1;', {
            from: moment(selectedDate).format('YYYY-MM-DD'),
            to: moment(range[0]).format('YYYY-MM-DD')
          });
        setSelectedDate(new Date(range[0]));
        }
      } else {
        // Month view
        // Extract the year and month from the start date
        const viewDate = moment(range.start);
        const year = viewDate.year();
        const month = viewDate.month(); // 0-based (0 = January)
        
        // Get current date within the viewed month
        // Ensure we're setting to the first day of the month currently being viewed
        const firstDayOfMonth = moment().year(year).month(month).date(1);
        
        console.log(`%c[RANGE DEBUG #${rangeId}] Month view details:`, 'color: #6366f1;', {
          rangeStart: moment(range.start).format('YYYY-MM-DD'),
          rangeEnd: moment(range.end).format('YYYY-MM-DD'),
          computedMonth: firstDayOfMonth.format('YYYY-MM-DD'),
          monthName: firstDayOfMonth.format('MMMM'),
          year: year
        });
        
        // Update selected date to the first day of this month (not January)
        if (selectedDate.getMonth() !== month || selectedDate.getFullYear() !== year || selectedDate.getDate() !== 1) {
          const newSelectedDate = firstDayOfMonth.toDate();
          console.log(`%c[RANGE DEBUG #${rangeId}] Updating selected date for month view:`, 'color: #6366f1;', {
            from: moment(selectedDate).format('YYYY-MM-DD'),
            to: moment(newSelectedDate).format('YYYY-MM-DD'),
            month: month + 1, // 1-indexed for logging
            year: year
          });
          setSelectedDate(newSelectedDate);
        }
        
        // Get start date (beginning of visible calendar, may include days from previous month)
        // and end date (end of visible calendar, may include days from next month)
        start = moment(range.start).startOf('day').format('YYYY-MM-DD');
        
        // Extend the range to include two more months for recurring events
        // This helps ensure we capture all events that might be part of a series
        end = moment(range.end).add(2, 'months').endOf('day').format('YYYY-MM-DD');
        
        console.log(`%c[RANGE DEBUG #${rangeId}] Month view API request range:`, 'color: #6366f1;', { 
          start, 
          end,
          viewedMonth: firstDayOfMonth.format('MMMM YYYY') 
        });
      }
      
      // Add a timestamp to prevent caching
      const timestamp = new Date().getTime();
      
      // Create ISO date strings for the API call
      const startISO = moment(start).startOf('day').toISOString();
      const endISO = moment(end).endOf('day').toISOString();
      
      // Ensure we're using the correct URL format with properly encoded dates
      const url = `/calendar/all-events?start=${encodeURIComponent(startISO)}&end=${encodeURIComponent(endISO)}&_t=${timestamp}`;
      console.log(`%c[RANGE DEBUG #${rangeId}] API request URL:`, 'color: #6366f1;', url);
      
      const eventsResponse = await axiosInstance.get(url);
      
      // Make sure we're updating the state with the new events
      console.log(`%c[RANGE DEBUG #${rangeId}] Fetched ${eventsResponse.data.length} events for the new date range`, 'color: #6366f1;');
      
      // Format events properly using the formatEvent function
      const formattedEvents = eventsResponse.data.map(event => formatEvent(event)).filter(Boolean);
      
      // Update both the raw events and formatted events
      setEvents(eventsResponse.data);
      setFormattedEvents(formattedEvents);
      
      console.log(`%c[RANGE DEBUG #${rangeId}] Calendar updated with new events`, 'background: #22c55e; color: white; padding: 2px 6px; border-radius: 4px;');
      
    } catch (err) {
      console.error(`%c[RANGE DEBUG #${rangeId}] Error fetching events:`, 'color: #ef4444;', err);
      toast.error('Failed to load calendar data for this period');
    } finally {
      setLoading(false);
    }
  };
  
  const handleContextMenu = (event) => {
    event.preventDefault();
    const { x, y } = event;
    
    // Get the date from the calendar cell that was right-clicked
    const cellElement = event.target.closest('.rbc-day-bg');
    if (!cellElement) return;
    
    // Extract date from the cell's data attribute or other means
    const dateAttr = cellElement.getAttribute('data-date');
    if (!dateAttr) return;
    
    const date = new Date(dateAttr);
    setContextMenu({ x, y, date });
  };
  
  const handleContextMenuAction = async (action, date) => {
    setContextMenu(null);
    
    switch (action) {
      case 'newJob':
        // Navigate to job creation page with date pre-filled
        window.location.href = `/jobs/new?date=${moment(date).format('YYYY-MM-DD')}`;
        break;
      case 'newRequest':
        // Navigate to request creation page with date pre-filled
        window.location.href = `/requests/new?date=${moment(date).format('YYYY-MM-DD')}`;
        break;
      case 'newTask':
        // Navigate to task creation page with date pre-filled
        window.location.href = `/tasks/new?date=${moment(date).format('YYYY-MM-DD')}`;
        break;
      case 'newCalendarEvent':
        // Open a modal for creating a calendar event
        toast.success(`Creating new calendar event for ${moment(date).format('MMM D, YYYY')}`);
        break;
      case 'showDayView':
        // Set the calendar date to the selected date and switch to day view
        setCurrentView('day');
        // In a real implementation, you would also set the calendar date
        toast.success(`Showing day view for ${moment(date).format('MMM D, YYYY')}`);
        break;
      case 'showMapView':
        // Navigate to map view with the selected date
        window.location.href = `/map?date=${moment(date).format('YYYY-MM-DD')}`;
        break;
      case 'assignTeam':
        // Show the day jobs modal to assign team members
        handleDayClick(date);
        break;
      default:
        break;
    }
  };
  
  // Reset selected event data when modals are closed
  const resetSelectedData = () => {
    console.log('Resetting selected event data');
    setSelectedEventId(null);
    // We don't want to reset selectedJob here as it may still be needed for other operations
  };
  
  // Handle assignment update from AssignJobModal
  const handleAssignmentUpdate = async (assignmentType, assigneeId, eventId, updateAllInSeries) => {
    console.log('[DEBUG] handleAssignmentUpdate - Called with:', {
      assignmentType,
      assigneeId,
      eventId,
      updateAllInSeries
    });
    
    try {
      if (!selectedJob) {
        console.error('No job selected for assignment update');
        return;
      }
      
      // Prepare the request data
      const requestData = {
        jobId: selectedJob._id,
        assignmentType: assignmentType,
        assigneeId: assigneeId,
        updateAllInSeries: updateAllInSeries
      };
      
      // If we're updating a specific event (not the whole series),
      // include the event ID in the request
      if (eventId && !updateAllInSeries) {
        console.log('[DEBUG] handleAssignmentUpdate - Updating single event:', eventId);
        requestData.eventId = eventId;
      }
      
      console.log('[DEBUG] handleAssignmentUpdate - Request data:', requestData);
      
      // Make the API call to update assignments
      const response = await axiosInstance.put('/calendar/events/update-assignment', requestData);
      
      console.log('[DEBUG] handleAssignmentUpdate - Response:', response.data);
      
      // Show success message
      toast.success(
        updateAllInSeries
          ? 'All events in series updated with new assignment'
          : 'Event assignment updated successfully'
      );
      
      // Refresh the calendar to show the updated assignments
      await fetchInitialData();
      
    } catch (error) {
      console.error('Error updating assignment:', error);
      toast.error('Failed to update assignment');
    }
  };

  // Handle calendar item update from the CalendarItemModal
  const handleCalendarItemUpdate = async (updatedItem) => {
    // Refresh calendar events to reflect the updates
    await fetchCalendarEvents();
  };

  // Handle calendar item deletion from the CalendarItemModal
  const handleCalendarItemDelete = async (itemId) => {
    // Refresh calendar events to remove the deleted item
    await fetchCalendarEvents();
  };

  // Utility functions that should be defined before any return statements
  // Add eventPropGetter for custom styling of events
  const eventPropGetter = useCallback((event) => {
    // Use the event's color directly if available
    const backgroundColor = event.color || getEventBackgroundColor(event);
    const priority = event.priority || event.resource?.priority;
    
    // Base styles with 5% taller height, but normal width
    let style = {
      backgroundColor,
      borderRadius: '4px',
      opacity: 0.9,
      color: '#fff',
      border: '1px solid #1a1a1a',
      paddingTop: '3px',    // Add padding to top
      paddingBottom: '3px', // Add padding to bottom
      height: '105%',       // Increase height by 5%
      width: '100%',        // Ensure normal width
      zIndex: 1,            // Ensure items appear above others
      marginBottom: '2px',  // Add a bit of margin to prevent overlap
      maxWidth: '100%',     // Prevent horizontal overflow
      boxSizing: 'border-box', // Include padding in element's total width/height
      whiteSpace: 'nowrap',  // Prevent text wrapping
      overflow: 'hidden',    // Hide overflow text
      textOverflow: 'ellipsis', // Show ellipsis for overflow text
    };
    
    // Add a left border for priority if present
    if (priority) {
      let borderColor;
      switch (priority.toLowerCase()) {
        case 'high':
          borderColor = '#f5222d'; // Red
          break;
        case 'medium':
          borderColor = '#faad14'; // Orange
          break;
        case 'low':
          borderColor = '#52c41a'; // Green
          break;
        default:
          borderColor = backgroundColor;
      }
      
      style.borderLeft = `4px solid ${borderColor}`;
    }
    
    // Special handling for job events to ensure they use the right color
    if (event.job || event.resource?.job || event.type === 'job') {
      // For job events, use the job color if provided, or get from the job type
      const jobType = event.jobType || event.resource?.jobType || 
                     (event.resource?.job && event.resource.job.service);
      
      if (jobType) {
        style.backgroundColor = event.color || getJobColor(jobType);
      }
    }
    
    console.log('Event styling:', {
      id: event.id,
      title: event.title,
      type: event.type || event.resource?.type,
      color: backgroundColor,
      priority: priority
    });
    
    return { style };
  }, []);

  // Custom event component to display events with proper formatting
  const EventComponent = ({ event }) => {
    return (
      <div className="event-content">
        <div className="event-title" title={event.description || event.title}>
          {getEventTitle(event)}
        </div>
      </div>
    );
  };

  // Get the appropriate icon for the event type
  const getEventIcon = (event) => {
    // Check for calendar item types
    const itemType = event.type || event.resource?.type;
    
    if (itemType === 'task') {
      return <CheckSquareOutlined />;
    } else if (itemType === 'note') {
      return <FileTextOutlined />;
    } else if (itemType === 'event') {
      return <CalendarOutlined />;
    }
    
    // For job events
    if (event.job || event.resource?.job) {
      return <BankOutlined />;
    }
    
    // Default
    return <CalendarOutlined />;
  };

  // Get a badge for priority if applicable
  const getPriorityBadge = (event) => {
    const priority = event.priority || event.resource?.priority;
    
    if (!priority) return null;
    
    let color;
    let content;
    
    switch (priority.toLowerCase()) {
      case 'high':
        color = '#f5222d'; // Red
        content = 'H';
        break;
      case 'medium':
        color = '#faad14'; // Orange
        content = 'M';
        break;
      case 'low':
        color = '#52c41a'; // Green
        content = 'L';
        break;
      default:
        return null;
    }
    
    return (
      <Tag style={{ marginLeft: 4, padding: '0 4px', fontSize: '10px', lineHeight: '14px' }} color={color}>
        {content}
      </Tag>
    );
  };

  // Handle loading and error state
  if (loading) {
    return (
      <div className="h-screen flex flex-col items-center justify-center bg-gray-900">
        <Header />
        <div className="flex-1 flex flex-col items-center justify-center">
      <Spin size="large" />
          <p className="mt-4 text-gray-400">Loading calendar data...</p>
        </div>
    </div>
  );
  }

  if (error) {
    return (
      <div className="h-screen flex flex-col items-center justify-center bg-gray-900">
        <Header />
        <div className="flex-1 flex flex-col items-center justify-center p-4">
          <ExclamationCircleOutlined style={{ fontSize: 48, color: '#f5222d' }} />
          <h2 className="text-white text-xl mt-4">Error Loading Calendar</h2>
          <p className="text-gray-400 mt-2 text-center">{error}</p>
        <Button 
          variant="primary" 
          className="mt-4"
          onClick={fetchInitialData}
        >
          Retry
        </Button>
      </div>
    </div>
  );
  }

  // Handle event resize or drag to a new time
  const handleEventResize = async ({ event, start, end }) => {
    // Enhanced debugging information
    console.log('%c[DRAG DEBUG] Event Drag/Resize Operation', 'background: #3b82f6; color: white; padding: 2px 6px; border-radius: 4px; font-weight: bold;');
    
    // Log the full event object structure
    console.log('%c[DRAG DEBUG] Full event object:', 'background: #8b5cf6; color: white; padding: 2px 6px; border-radius: 4px;');
    console.log(event);
    
    console.log('Event being moved/resized:', {
      id: event.id,
      title: event.title,
      type: event.type,
      isJob: event.job ? 'Yes' : 'No',
      jobId: event.job ? (typeof event.job === 'object' ? JSON.stringify(event.job) : event.job) : 'N/A',
      jobInfo: event.job,
      oldStart: event.start,
      oldEnd: event.end,
      newStart: start,
      newEnd: end,
      formattedOldStart: moment(event.start).format('MMM D, YYYY h:mm A'),
      formattedOldEnd: moment(event.end).format('MMM D, YYYY h:mm A'),
      formattedNewStart: moment(start).format('MMM D, YYYY h:mm A'),
      formattedNewEnd: moment(end).format('MMM D, YYYY h:mm A')
    });

    // Calculate event duration in minutes (original)
    const originalDuration = moment(event.end).diff(moment(event.start), 'minutes');
    
    // Calculate new duration based on drag operation
    const newDuration = moment(end).diff(moment(start), 'minutes');
    
    console.log('%c[DRAG DEBUG] Duration Analysis', 'background: #8b5cf6; color: white; padding: 2px 6px; border-radius: 4px;');
    console.log({
      originalDuration: `${originalDuration} minutes`,
      newDuration: `${newDuration} minutes`, 
      isDragging: Math.abs(originalDuration - newDuration) < 5 ? 'Yes (duration preserved)' : 'No (resize operation)',
      dayChanged: moment(event.start).format('YYYY-MM-DD') !== moment(start).format('YYYY-MM-DD') ? 'Yes' : 'No'
    });

    // Determine if this is a drag operation (duration roughly the same) or a resize operation
    const isDragOperation = Math.abs(originalDuration - newDuration) < 5;

    // Copy start and end to avoid modifying the original objects
    let adjustedStart = moment(start);
    let adjustedEnd = moment(end);
    
    // If the event is being dragged (not resized), ensure we maintain the original duration
    if (isDragOperation) {
      // Recalculate the end time based on the original duration
      adjustedEnd = moment(start).add(originalDuration, 'minutes');
      console.log(`%c[DRAG DEBUG] Adjusting end time to maintain original duration`, 'color: #22c55e;');
    }

    // Prevent events from crossing day boundaries (cap end time at end of day)
    const endOfDay = moment(start).endOf('day');
    if (adjustedEnd.isAfter(endOfDay)) {
      const wasAdjusted = !adjustedEnd.isSame(endOfDay);
      adjustedEnd = endOfDay;
      console.log(`%c[DRAG DEBUG] End time adjusted to prevent crossing day boundary: ${endOfDay.format('MMM D, YYYY h:mm A')}`, 
        wasAdjusted ? 'color: #ef4444;' : 'color: #22c55e;');
    }

    console.log('%c[DRAG DEBUG] Final Adjusted Times', 'background: #22c55e; color: white; padding: 2px 6px; border-radius: 4px;');
    console.log({
      adjustedStart: adjustedStart.format('MMM D, YYYY h:mm A'),
      adjustedEnd: adjustedEnd.format('MMM D, YYYY h:mm A'),
      finalDuration: `${adjustedEnd.diff(adjustedStart, 'minutes')} minutes`
    });

    // Check if this is a job event (requiring special handling)
    if (event.job) {
      console.log('%c[DRAG DEBUG] This is a job event, showing job details modal', 'color: #f59e0b;');
      toast.loading('Updating job time...', { id: 'job-update' });
      
      // For jobs, we need to update the calendar event (not the job itself)
      try {
        // Extract the job ID correctly - it might be an object or a string
        let jobId = event.job;
        if (typeof jobId === 'object' && jobId !== null) {
          // If it's an object, try to get the _id property
          jobId = jobId._id || jobId.id || JSON.stringify(jobId);
          console.log(`[DRAG DEBUG] Extracted job ID from object: ${jobId}`);
        }
        
        // Check if the event is using the calendar event API or calendar item API
        if (event.id && event.type === 'job') {
          // Use calendar events API - this handles job events on the calendar
          console.log(`[DRAG DEBUG] Updating job event via calendar events API: ${event.id}`);
          
          // Update the calendar event with the adjusted times
          const response = await axiosInstance.put(`/calendar/events/${event.id}`, {
            start: adjustedStart.toDate(),
            end: adjustedEnd.toDate()
          });
          
          console.log(`[DRAG DEBUG] API Response from calendar events:`, response.data);
          toast.success(`Job updated to ${moment(adjustedStart).format('h:mm A')}`, { id: 'job-update' });
          
          // Optimistic UI update first
          setFormattedEvents(prevEvents => {
            return prevEvents.map(e => {
              if (e.id === event.id) {
                return {
                  ...e,
                  start: adjustedStart.toDate(),
                  end: adjustedEnd.toDate()
                };
              }
              return e;
            });
          });
          
          // Then refresh from server with the specific event ID
          fetchCalendarEvents(event.id);
        } else {
          // Try to get job details first for verification
          console.log(`[DRAG DEBUG] Fetching job with ID: ${jobId}`);
          try {
            const jobResponse = await axiosInstance.get(`/jobs/${jobId}`);
            const job = jobResponse.data;
            
            console.log(`[DRAG DEBUG] Retrieved job data:`, job);
            toast.success(`Job moved to ${moment(adjustedStart).format('MMM D, YYYY h:mm A')}`, { id: 'job-update' });
          } catch (jobError) {
            console.error(`[DRAG DEBUG] Error fetching job, will try updating anyway:`, jobError);
            // We'll continue with the update even if fetching job fails
          }
          
          // Try updating using calendar events API since we know it's a job
          try {
            console.log(`[DRAG DEBUG] Trying alternative update method for job event`);
            const response = await axiosInstance.put(`/calendar/events/${event.id}`, {
              start: adjustedStart.toDate(),
              end: adjustedEnd.toDate()
            });
            
            console.log(`[DRAG DEBUG] API Response from alternative method:`, response.data);
            toast.success(`Job scheduled for ${moment(adjustedStart).format('h:mm A')}`, { id: 'job-update' });
            
            // Optimistic UI update
            setFormattedEvents(prevEvents => {
              return prevEvents.map(e => {
                if (e.id === event.id) {
                  return {
                    ...e,
                    start: adjustedStart.toDate(),
                    end: adjustedEnd.toDate()
                  };
                }
                return e;
              });
            });
            
            // Refresh with specific event ID
            fetchCalendarEvents(event.id);
          } catch (alternativeError) {
            console.error(`[DRAG DEBUG] Alternative update method failed:`, alternativeError);
            toast.error('Could not update job time. Try refreshing the page.', { id: 'job-update' });
          }
        }
        
        // Refresh events to see the change
        fetchCalendarEvents();
      } catch (updateError) {
        console.error(`[DRAG DEBUG] Error handling job event move:`, updateError);
        toast.error('Failed to update job', { id: 'job-update' });
        fetchCalendarEvents(event.id); // Refresh to revert to original position
      }
      return;
    }

    // For regular calendar items (tasks, notes, events)
    toast.loading('Updating event...', { id: 'event-update' });
    console.log('%c[DRAG DEBUG] Regular calendar item, updating via API', 'color: #3b82f6;');
    
    try {
      // Update the calendar item with the new start and end times
      const response = await axiosInstance.put(`/calendar/items/${event.id}`, {
        start: adjustedStart.toDate(),
        end: adjustedEnd.toDate()
      });
      
      console.log(`[DRAG DEBUG] API Response:`, response.data);
      toast.success(`Event updated to ${moment(adjustedStart).format('h:mm A')}`, { id: 'event-update' });
      
      // Optimistic UI update
      setFormattedEvents(prevEvents => {
        return prevEvents.map(e => {
          if (e.id === event.id) {
            return {
              ...e,
              start: adjustedStart.toDate(),
              end: adjustedEnd.toDate()
            };
          }
          return e;
        });
      });
      
      // Refresh calendar events with the specific event ID
      fetchCalendarEvents(event.id);
    } catch (updateError) {
      console.error('[DRAG DEBUG] Error updating calendar item:', updateError);
      toast.error('Failed to update calendar item', { id: 'event-update' });
      
      // Refresh to revert to original position with specific event ID
      fetchCalendarEvents(event.id);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="flex h-[calc(100vh-64px)]">
        {/* Job Pool Sidebar */}
        <div className="w-1/4 bg-gray-800 overflow-y-auto border-r border-gray-700">
          <JobPool 
            jobs={unscheduledJobs} 
            onJobDragStart={handleDragStart}
            onRefresh={fetchInitialData}
          />
        </div>

        {/* Calendar */}
        <div className="flex-1 p-4 bg-gray-900">
          <div className="mb-4 flex flex-col md:flex-row justify-between items-center gap-3">
            {/* Remove duplicate month/week/day buttons */}
            <div className="flex space-x-2 invisible">
              {/* These buttons are hidden with 'invisible' class but kept for layout consistency */}
              <button className="px-3 py-1.5 rounded-md bg-gray-700 text-white">Month</button>
              <button className="px-3 py-1.5 rounded-md bg-gray-700 text-white">Week</button>
              <button className="px-3 py-1.5 rounded-md bg-gray-700 text-white">Day</button>
            </div>
              <div className="flex space-x-2">
                <button 
                className="px-3 py-1.5 rounded-md bg-gray-700 text-white hover:bg-blue-700 transition-colors"
                onClick={() => handleDayClick({start: new Date()})}
              >
                Quick Add
                </button>
            </div>
          </div>
          
          <div className="relative h-[calc(100vh-160px)] bg-gray-900 rounded-xl overflow-hidden border border-gray-700">
            <div className="calendar-container custom-calendar">
            <DragAndDropCalendar
              localizer={localizer}
              events={formattedEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '100%' }}
                views={{
                  month: true,
                  week: true,
                  day: true
                }}
              view={currentView}
                onView={(view) => setCurrentView(view)}
              date={selectedDate}
                onNavigate={(date, view, action) => {
                  // Create an ID to track this specific navigation action
                  const navId = Date.now().toString().slice(-4);
                  
                  console.log(`%c[NAV DEBUG #${navId}] Calendar navigation started:`, 'background: #3b82f6; color: white; padding: 2px 6px; border-radius: 4px;', { 
                    date: date, 
                    formattedDate: moment(date).format('YYYY-MM-DD'),
                    view: view || currentView, 
                    action: action,
                    currentSelectedDate: moment(selectedDate).format('YYYY-MM-DD')
                  });
                  
                  // Store the previous date for comparison
                  const prevDate = selectedDate;
                  
                  // Make a local copy of the date to avoid reference issues
                  const newDate = new Date(date.getTime());
                  
                  // Reset hours to prevent time-related issues
                  newDate.setHours(0, 0, 0, 0);
                  
                  // If we're in month view and using prev/next navigation
                  if ((action === 'PREV' || action === 'NEXT') && (view === 'month' || currentView === 'month')) {
                    // For month navigation, ensure we're on the first day of the month
                    const year = newDate.getFullYear();
                    const month = newDate.getMonth(); // 0-indexed
                    
                    // Create a new date set to the first day of the month
                    const firstOfMonth = new Date(year, month, 1);
                    firstOfMonth.setHours(0, 0, 0, 0);
                    
                    console.log(`%c[NAV DEBUG #${navId}] Month navigation - Setting date:`, 'color: #8b5cf6;', {
                      from: moment(newDate).format('YYYY-MM-DD'),
                      to: moment(firstOfMonth).format('YYYY-MM-DD'),
                      action: action,
                      month: month + 1, // 1-indexed for readability
                      year: year
                    });
                    
                    // Set the new date to the first of the month
                    setSelectedDate(firstOfMonth);
                    
                    // Force reload events for the current month view
                    setTimeout(() => {
                      const rangeStart = new Date(year, month, 1);
                      const rangeEnd = new Date(year, month + 1, 0); // Last day of month
                      
                      console.log(`%c[NAV DEBUG #${navId}] Triggering manual range change:`, 'color: #ef4444;', {
                        start: moment(rangeStart).format('YYYY-MM-DD'),
                        end: moment(rangeEnd).format('YYYY-MM-DD')
                      });
                      
                      // Manually trigger a range change event
                      handleRangeChange({
                        start: rangeStart,
                        end: rangeEnd
                      });
                    }, 100);
                  } else {
                    // For day/week view or TODAY navigation
                    console.log(`%c[NAV DEBUG #${navId}] Standard navigation:`, 'color: #10b981;', {
                      newDate: moment(newDate).format('YYYY-MM-DD'),
                      action: action,
                      view: view || currentView
                    });
                    
                    setSelectedDate(newDate);
                  }
                  
                  // Log the change that was made
                  setTimeout(() => {
                    console.log(`%c[NAV DEBUG #${navId}] Navigation complete:`, 'background: #22c55e; color: white; padding: 2px 6px; border-radius: 4px;', {
                      from: moment(prevDate).format('YYYY-MM-DD'),
                      to: moment(selectedDate).format('YYYY-MM-DD'),
                      didChange: prevDate.getTime() !== selectedDate.getTime()
                    });
                  }, 200);
                }}
              onRangeChange={handleRangeChange}
                onSelectSlot={handleDayClick}
                selectable={true}
                onSelectEvent={handleEventClick}
                eventPropGetter={eventPropGetter}
              components={{
                  event: EventComponent
                }}
                formats={{
                  timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'h:mm a', culture),
                  dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd D', culture)
                }}
                toolbar={true} // Ensure toolbar is visible
                onDragStart={() => false} // Prevent default drag behavior
                draggableAccessor={() => true} // Make all events draggable
                resizable={true} // Enable event resizing
                onEventDrop={handleEventResize} // Handle event drops (moving to a different time)
                onEventResize={handleEventResize} // Handle event resizing
                drilldownView={null} // Disable the drill-down effect when clicking on a date
                onDropFromOutside={onDropFromOutside}
                className="custom-calendar"
                dayPropGetter={(date) => {
                  if (date.getDate() === new Date().getDate() && 
                      date.getMonth() === new Date().getMonth() && 
                      date.getFullYear() === new Date().getFullYear()) {
                    return {
                      style: {
                        backgroundColor: 'rgba(59, 130, 246, 0.1)'
                      }
                    };
                  }
                  return {};
                }}
                dayLayoutAlgorithm="no-overlap"
                showMultiDayTimes={true}
                step={30} // 30-minute steps in the time view
                timeslots={2} // 2 slots per step (showing every 15 minutes)
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Modals */}
      <RecurringJobModal
        isOpen={showRecurringModal}
        onClose={() => setShowRecurringModal(false)}
        job={selectedJob}
        date={selectedDate}
        onSchedule={(occurrences, options) => {
          scheduleJob(selectedJob, selectedDate, occurrences, options)
            .then(() => {
              setShowRecurringModal(false);
              toast.success('Job scheduled successfully');
            })
            .catch(error => {
              console.error('Error scheduling job:', error);
              toast.error('Failed to schedule job');
            });
        }}
      />
      
      <DurationModal
        isOpen={showDurationModal}
        onClose={() => setShowDurationModal(false)}
        job={jobToSchedule}
        date={dropDateToSchedule}
        onConfirm={handleDurationConfirm}
      />
      
      <JobDetailsModal
        isOpen={showJobDetails}
        onClose={() => setShowJobDetails(false)}
        job={selectedJob}
        eventId={selectedEventId}
        onUpdate={handleJobDetailsUpdate}
      />

      <CalendarItemModal
        isOpen={showCalendarItemModal}
        onClose={() => {
          setShowCalendarItemModal(false);
          setSelectedCalendarItem(null);
        }}
        calendarItem={selectedCalendarItem}
        onUpdate={handleCalendarItemUpdate}
        onDelete={handleCalendarItemDelete}
      />

      <QuickAddModal
        isOpen={showQuickAddModal}
        onClose={() => setShowQuickAddModal(false)}
        date={quickAddDate}
        onAddItem={handleAddCalendarItem}
      />

      {/* Context Menu */}
      {contextMenu && (
        <div
          className="absolute z-50 bg-gray-800 rounded-lg border border-gray-700 shadow-lg overflow-hidden"
          style={{ top: contextMenu.y, left: contextMenu.x }}
        >
          <div className="py-1">
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
              onClick={() => handleContextMenuAction('newCalendarEvent', contextMenu.date)}
            >
              New Calendar Event
            </button>
            <button
              className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
              onClick={() => handleContextMenuAction('newJob', contextMenu.date)}
            >
              New Job
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarPage;