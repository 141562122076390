import React from 'react';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import {
  FaCalendarAlt, FaUsers, FaRoute, FaChartBar, 
  FaMobileAlt, FaClipboardList, FaClock, FaTools,
  FaBell, FaFileInvoiceDollar, FaMapMarkedAlt, FaUsersCog
} from 'react-icons/fa';

const FeatureCard = ({ icon, title, description, comingSoon }) => (
  <div className={`bg-gradient-to-br from-surface to-gray-800 p-8 rounded-lg transform hover:scale-105 transition duration-200 hover:shadow-xl border border-gray-700 group relative ${comingSoon ? 'opacity-75' : ''}`}>
    <div className="text-blue-400 text-4xl mb-4 group-hover:scale-110 transition-transform duration-200">{icon}</div>
    <h3 className="text-xl font-semibold mb-3 text-white flex items-center gap-2">
      {title}
      {comingSoon && (
        <span className="text-xs bg-blue-500 text-white px-2 py-1 rounded-full">Coming Soon</span>
      )}
    </h3>
    <p className="text-blue-100">{description}</p>
  </div>
);

const Features = () => {
  const features = [
    {
      icon: <FaCalendarAlt />,
      title: "Intuitive Scheduling",
      description: "User-friendly scheduling system that helps organize your team's time and manage service appointments efficiently."
    },
    {
      icon: <FaUsers />,
      title: "Customer Management",
      description: "Comprehensive CRM to manage customer relationships, properties, service history, and communication preferences."
    },
    {
      icon: <FaRoute />,
      title: "Route Planning",
      description: "Visual route planning tools with customer mapping to help organize your service routes effectively.",
    },
    {
      icon: <FaChartBar />,
      title: "Business Analytics",
      description: "Track revenue, job completions, and customer metrics to make informed business decisions.",
    },
    {
      icon: <FaMobileAlt />,
      title: "Mobile App",
      description: "Access your business data on the go with our upcoming mobile application.",
      comingSoon: true
    },
    {
      icon: <FaClipboardList />,
      title: "Job Tracking",
      description: "Monitor job status, track completions, and maintain detailed service records for each customer."
    },
    {
      icon: <FaClock />,
      title: "Time Management",
      description: "Basic time tracking for jobs and services with integration capabilities for advanced time management systems."
    },
    {
      icon: <FaTools />,
      title: "Equipment Management",
      description: "Track your equipment inventory and maintenance schedules.",
      comingSoon: true
    },
    {
      icon: <FaBell />,
      title: "Smart Notifications",
      description: "Automated customer communications for appointments, invoices, and service updates via SMS and email."
    },
    {
      icon: <FaFileInvoiceDollar />,
      title: "Invoicing & Payments",
      description: "Professional invoicing with Stripe integration for secure online payments and automated payment tracking."
    },
    {
      icon: <FaMapMarkedAlt />,
      title: "Interactive Maps",
      description: "Google Maps integration for customer locations and route visualization to streamline your service planning."
    },
    {
      icon: <FaUsersCog />,
      title: "Team Management",
      description: "Manage crew assignments, track job assignments, and coordinate your service teams."
    }
  ];

  const integrations = [
    {
      name: "Stripe",
      description: "Secure payment processing"
    },
    {
      name: "Twilio",
      description: "Customer communications"
    },
    {
      name: "Google Maps",
      description: "Location services"
    }
  ];

  return (
    <div className="bg-gradient-to-b from-gray-900 to-background text-text min-h-screen">
      <Navbar />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Hero Section with gradient overlay */}
        <div className="text-center mb-16 relative">
          <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform -rotate-12"></div>
          <h1 className="relative text-5xl font-bold mb-6">
            Streamline Your
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400"> Lawn Care Business</span>
          </h1>
          <p className="text-xl text-blue-100 max-w-3xl mx-auto relative">
            AUTOLAWN provides the essential tools you need to manage customers, schedule services, 
            and grow your lawn care business efficiently.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        {/* Integration Section with new styling */}
        <div className="bg-gradient-to-br from-surface to-gray-800 rounded-lg p-8 text-center mb-16 border border-gray-700 shadow-lg">
          <h2 className="text-3xl font-bold mb-4 text-white">Powerful Integrations</h2>
          <p className="text-blue-100 mb-6">
            AUTOLAWN leverages industry-leading services to provide reliable, secure functionality.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {integrations.map((integration, index) => (
              <div key={index} className="p-6 bg-white/10 backdrop-blur-sm rounded-lg text-left">
                <h3 className="text-xl font-semibold text-white mb-2">{integration.name}</h3>
                <p className="text-blue-200">{integration.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section with enhanced gradient */}
        <div className="text-center bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg p-12 shadow-lg relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 backdrop-blur-sm"></div>
          <div className="relative">
            <h2 className="text-3xl font-bold mb-6 text-white">Ready to Get Started?</h2>
            <p className="text-lg mb-8 max-w-2xl mx-auto text-blue-100">
              Join other lawn care professionals who are streamlining their business operations with AUTOLAWN.
            </p>
            <div className="flex justify-center gap-4">
              <Link 
                to="/pricing" 
                className="bg-white text-blue-600 px-8 py-3 rounded-md font-semibold hover:bg-blue-50 transition duration-200 shadow-lg"
              >
                View Pricing
              </Link>
              <Link 
                to="/contact" 
                className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-md font-semibold hover:bg-white/10 transition duration-200"
              >
                Contact Sales
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Features;