import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, TimePicker, Button, Popconfirm, Space, Row, Col } from 'antd';
import { CalendarOutlined, DeleteOutlined, EditOutlined, SaveOutlined, ClockCircleOutlined, FileTextOutlined, CheckSquareOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ChromePicker } from 'react-color';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-hot-toast';

const { Option } = Select;
const { TextArea } = Input;

/**
 * CalendarItemModal for viewing, editing, and deleting calendar items
 * Supports tasks, notes, and general calendar events
 */
const CalendarItemModal = ({ isOpen, onClose, calendarItem, onUpdate, onDelete }) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [color, setColor] = useState('#1890ff');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [duration, setDuration] = useState(60);

  useEffect(() => {
    // Reset state when the modal is opened or the item changes
    if (calendarItem && isOpen) {
      setIsEditing(false);
      setColor(calendarItem.color || '#1890ff');
      
      // Set date and time values from the calendar item
      const itemStart = moment(calendarItem.start);
      const itemEnd = moment(calendarItem.end);
      
      setSelectedDate(itemStart.clone());
      setStartTime(itemStart.clone());
      
      // Calculate duration in minutes
      const durationMinutes = itemEnd.diff(itemStart, 'minutes');
      setDuration(durationMinutes || 60);
      setEndTime(itemEnd.clone());
      
      // Set form values
      form.setFieldsValue({
        title: calendarItem.title,
        description: calendarItem.description || '',
        date: itemStart,
        startTime: itemStart,
        duration: String(durationMinutes || 60),
        priority: calendarItem.priority || 'medium'
      });
    }
  }, [calendarItem, isOpen, form]);

  const getItemTypeIcon = (type) => {
    switch (type) {
      case 'task':
        return <CheckSquareOutlined style={{ color: '#1890ff' }} />;
      case 'note':
        return <FileTextOutlined style={{ color: '#722ed1' }} />;
      case 'event':
      default:
        return <CalendarOutlined style={{ color: '#52c41a' }} />;
    }
  };

  const getModalTitle = () => {
    if (!calendarItem) return 'Calendar Item';
    
    const typeIcon = getItemTypeIcon(calendarItem.type);
    const typeName = calendarItem.type ? calendarItem.type.charAt(0).toUpperCase() + calendarItem.type.slice(1) : 'Item';
    
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {typeIcon}
        <span style={{ marginLeft: 8 }}>
          {isEditing ? `Edit ${typeName}` : typeName}
        </span>
      </div>
    );
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };

  const handleDurationChange = (value) => {
    setDuration(Number(value));
    // Update end time
    if (startTime) {
      const newEndTime = startTime.clone().add(Number(value), 'minutes');
      setEndTime(newEndTime);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    
    // Reset form to original values
    if (calendarItem) {
      const itemStart = moment(calendarItem.start);
      form.setFieldsValue({
        title: calendarItem.title,
        description: calendarItem.description || '',
        date: itemStart,
        startTime: itemStart,
        duration: String(moment(calendarItem.end).diff(itemStart, 'minutes') || 60),
        priority: calendarItem.priority || 'medium'
      });
      setColor(calendarItem.color || '#1890ff');
    }
  };

  const handleDelete = async () => {
    if (!calendarItem || !calendarItem._id) return;
    
    try {
      setLoading(true);
      await axiosInstance.delete(`/calendar/items/${calendarItem._id}`);
      
      toast.success(`${calendarItem.type.charAt(0).toUpperCase() + calendarItem.type.slice(1)} deleted successfully`);
      
      if (onDelete) {
        onDelete(calendarItem._id);
      }
      
      onClose();
    } catch (error) {
      console.error('Error deleting calendar item:', error);
      toast.error(`Failed to delete: ${error.message || 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
      
      if (!calendarItem || !calendarItem._id) {
        console.error('No calendar item to update');
        return;
      }
      
      // Combine date and time
      const startDateTime = selectedDate
        .clone()
        .hours(startTime.hours())
        .minutes(startTime.minutes())
        .seconds(0);
      
      const endDateTime = startDateTime.clone().add(duration, 'minutes');
      
      const updatedItem = {
        title: values.title,
        description: values.description || '',
        start: startDateTime.toDate(),
        end: endDateTime.toDate(),
        color: color,
        priority: values.priority || 'medium'
      };
      
      // Send update to API
      const response = await axiosInstance.put(`/calendar/items/${calendarItem._id}`, updatedItem);
      
      toast.success(`${calendarItem.type.charAt(0).toUpperCase() + calendarItem.type.slice(1)} updated successfully`);
      
      // Call the onUpdate callback with the updated item
      if (onUpdate) {
        onUpdate(response.data);
      }
      
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating calendar item:', error);
      toast.error(`Failed to update: ${error.message || 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  // Custom styling to match app theme
  const modalStyle = {
    content: {
      backgroundColor: '#1f2937', // Dark background
      color: '#e5e7eb' // Light text
    },
    header: {
      backgroundColor: '#111827',
      borderBottom: '1px solid #374151',
      color: '#f9fafb'
    },
    footer: {
      backgroundColor: '#111827',
      borderTop: '1px solid #374151'
    }
  };

  if (!calendarItem) return null;

  return (
    <Modal
      title={<div style={{ color: '#f9fafb' }}>{getModalTitle()}</div>}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button 
          key="close" 
          onClick={onClose}
          style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
        >
          Close
        </Button>,
        !isEditing ? (
          <Button
            key="edit"
            icon={<EditOutlined />}
            onClick={handleEdit}
            style={{ backgroundColor: '#3b82f6', borderColor: '#2563eb', color: 'white' }}
          >
            Edit
          </Button>
        ) : (
          <Space>
            <Button 
              key="cancel" 
              onClick={handleCancel}
              style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
            >
              Cancel
            </Button>
            <Button
              key="save"
              type="primary"
              icon={<SaveOutlined />}
              onClick={handleSave}
              loading={loading}
              style={{ backgroundColor: '#3b82f6', borderColor: '#2563eb' }}
            >
              Save
            </Button>
          </Space>
        ),
        <Popconfirm
          key="delete"
          title={`Delete this ${calendarItem.type}?`}
          description="This action cannot be undone."
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ style: { backgroundColor: '#ef4444', borderColor: '#dc2626' } }}
        >
          <Button
            danger
            icon={<DeleteOutlined />}
            loading={loading}
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            Delete
          </Button>
        </Popconfirm>
      ]}
      width={600}
      styles={{
        header: modalStyle.header,
        footer: modalStyle.footer,
        content: modalStyle.content,
        body: { backgroundColor: '#1f2937', color: '#e5e7eb', padding: '20px' }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        disabled={!isEditing}
        style={{ color: '#e5e7eb' }}
      >
        <Form.Item
          label={<span style={{ color: '#e5e7eb' }}>Title</span>}
          name="title"
          rules={[{ required: true, message: 'Please enter a title' }]}
        >
          <Input 
            style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
          />
        </Form.Item>

        {isEditing ? (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<span style={{ color: '#e5e7eb' }}>Date</span>} name="date">
                  <DatePicker 
                    style={{ width: '100%', backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }} 
                    value={selectedDate} 
                    onChange={setSelectedDate} 
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<span style={{ color: '#e5e7eb' }}>Start Time</span>} name="startTime">
                  <TimePicker 
                    style={{ width: '100%', backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }} 
                    format="h:mm A" 
                    use12Hours 
                    minuteStep={5} 
                    value={startTime} 
                    onChange={handleStartTimeChange} 
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label={<span style={{ color: '#e5e7eb' }}>Duration</span>} name="duration">
                  <Select 
                    onChange={handleDurationChange} 
                    value={String(duration)}
                    style={{ backgroundColor: '#374151', color: '#e5e7eb' }}
                    dropdownStyle={{ backgroundColor: '#1f2937' }}
                  >
                    <Option value="15">15 minutes</Option>
                    <Option value="30">30 minutes</Option>
                    <Option value="45">45 minutes</Option>
                    <Option value="60">1 hour</Option>
                    <Option value="90">1.5 hours</Option>
                    <Option value="120">2 hours</Option>
                    <Option value="180">3 hours</Option>
                    <Option value="240">4 hours</Option>
                    <Option value="300">5 hours</Option>
                    <Option value="360">6 hours</Option>
                    <Option value="480">8 hours</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<span style={{ color: '#e5e7eb' }}>Color</span>}>
                  <div className="space-y-2">
                    <div 
                      onClick={() => isEditing && setShowColorPicker(!showColorPicker)} 
                      style={{ 
                        width: '100%', 
                        height: '32px', 
                        backgroundColor: color,
                        borderRadius: '4px',
                        cursor: isEditing ? 'pointer' : 'default',
                        border: '1px solid #4b5563',
                        boxShadow: isEditing ? '0 0 0 2px rgba(59, 130, 246, 0.5)' : 'none',
                        transition: 'all 0.2s'
                      }} 
                    />
                    {isEditing && (
                      <div style={{ fontSize: '12px', color: '#9ca3af', marginTop: '4px' }}>
                        Click on the color box above to change the event color
                      </div>
                    )}
                  </div>
                  {showColorPicker && (
                    <div style={{ position: 'absolute', zIndex: 2 }}>
                      <div
                        style={{
                          position: 'fixed',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                        onClick={() => setShowColorPicker(false)}
                      />
                      <ChromePicker
                        color={color}
                        onChange={(colorObj) => setColor(colorObj.hex)}
                        disableAlpha={true}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>

            {(calendarItem.type === 'task' || calendarItem.type === 'note') && (
              <Form.Item label={<span style={{ color: '#e5e7eb' }}>Priority</span>} name="priority">
                <Select
                  style={{ backgroundColor: '#374151', color: '#e5e7eb' }}
                  dropdownStyle={{ backgroundColor: '#1f2937' }}
                >
                  <Option value="low">Low</Option>
                  <Option value="medium">Medium</Option>
                  <Option value="high">High</Option>
                </Select>
              </Form.Item>
            )}
          </>
        ) : (
          <div className="mb-4">
            <div className="flex justify-between">
              <div style={{ color: '#d1d5db' }}>
                <ClockCircleOutlined style={{ marginRight: 8 }} />
                {moment(calendarItem.start).format('MMM D, YYYY [at] h:mm A')} - 
                {moment(calendarItem.end).format(' h:mm A')}
              </div>
              {(calendarItem.type === 'task' || calendarItem.type === 'note') && (
                <div>
                  <span 
                    style={{ 
                      padding: '2px 8px', 
                      borderRadius: '4px', 
                      backgroundColor: 
                        calendarItem.priority === 'high' ? '#ef4444' : 
                        calendarItem.priority === 'low' ? '#10b981' : 
                        '#f59e0b',
                      color: 'white',
                      fontSize: '12px'
                    }}
                  >
                    {calendarItem.priority?.toUpperCase()}
                  </span>
                </div>
              )}
            </div>
            
            {/* Color display */}
            <div style={{ marginTop: '12px', marginBottom: '12px' }}>
              <div style={{ marginBottom: '4px', color: '#9ca3af', fontSize: '13px', display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '4px' }}>Event Color</span>
                <span style={{ fontSize: '11px', fontStyle: 'italic' }}>(Edit to change)</span>
              </div>
              <div style={{ 
                height: '8px', 
                backgroundColor: color, 
                marginBottom: '2px',
                borderRadius: '4px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
              }}></div>
            </div>
          </div>
        )}

        <Form.Item label={<span style={{ color: '#e5e7eb' }}>Description</span>} name="description">
          <TextArea 
            rows={4} 
            style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CalendarItemModal; 