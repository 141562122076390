// src/App.js
import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleMapsProvider } from './contexts/GoogleMapsContext';
import ProtectedRoute from './components/ProtectedRoute';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ChatBubble from './components/ChatBubble';

// Public Pages
import Home from './pages/Home';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import About from './pages/About';
import PublicProfile from './pages/PublicProfile';
import PublicQuoteView from './pages/PublicQuoteView';

// Auth Pages
import SignIn from './components/SignIn';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import OAuthSuccess from './pages/OAuthSuccess';
import LoginSuccess from './components/LoginSuccess';
import PaymentSuccess from './pages/PaymentSuccess';

// Protected Pages
import Dashboard from './components/Dashboard';
import Jobs from './pages/Jobs';
import CustomerHub from './pages/CustomerHub';
import ManageJobs from './pages/ManageJobs';
import Profile from './pages/Profile';
import EmployeeHub from './pages/EmployeeHub';
import ManageEmployees from './pages/ManageEmployees';
import Employee from './pages/Employee';
import QuoteBuilderPage from './pages/QuoteBuilderPage';
import RouteMap from './pages/RouteMap';
import SendNotifications from './pages/SendNotifications';
import MaterialCalculator from './pages/MaterialCalculator';
import Calendar from './pages/Calendar';
import Invoices from './pages/Invoices';
import JobsHub from './pages/JobsHub';
import ConnectRefresh from './pages/ConnectRefresh';
import ConnectReturn from './pages/ConnectReturn';
import FAQ from './pages/FAQ';
import Training from './pages/Training';
import QuotesHub from './pages/QuotesHub';

// Create a context for global notification sounds
export const NotificationSoundContext = React.createContext();

function App() {
  const notificationAudioRef = useRef(null);

  // Preload notification sound
  useEffect(() => {
    // Create and preload the notification sound
    if (!notificationAudioRef.current) {
      notificationAudioRef.current = new Audio('/MessageNotification.mp3');
      notificationAudioRef.current.load();
      console.log('Preloaded global notification sound');
    }
    
    return () => {
      // Clean up audio on unmount
      if (notificationAudioRef.current) {
        notificationAudioRef.current.pause();
        notificationAudioRef.current = null;
      }
    };
  }, []);

  const playNotificationSound = () => {
    try {
      // Reset the audio to the beginning
      if (notificationAudioRef.current) {
        notificationAudioRef.current.currentTime = 0;
        
        // Play the sound
        notificationAudioRef.current.play().catch(error => {
          console.error('Failed to play global notification sound:', error);
          // Try to load and play again
          notificationAudioRef.current.load();
          setTimeout(() => {
            notificationAudioRef.current.play().catch(e => 
              console.error('Second attempt to play global sound failed:', e)
            );
          }, 100);
        });
      }
    } catch (error) {
      console.error('Error playing global notification sound:', error);
    }
  };

  return (
    <AuthProvider>
      <GoogleMapsProvider>
        <NotificationSoundContext.Provider value={{ playNotificationSound }}>
          <DndProvider backend={HTML5Backend}>
            <Router>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/features" element={<Features />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/profile/:username" element={<PublicProfile />} />
                <Route path="/quote/:quoteId" element={<PublicQuoteView />} />
                <Route path="/quotes/view/:identifier" element={<PublicQuoteView />} />
                
                {/* Auth Routes */}
                <Route path="/signin" element={<SignIn />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/oauth-success" element={<OAuthSuccess />} />
                <Route path="/login-success" element={<LoginSuccess />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                
                {/* Protected Routes */}
                <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path="/calendar" element={<ProtectedRoute><Calendar /></ProtectedRoute>} />
                <Route path="/route-map" element={<ProtectedRoute><RouteMap /></ProtectedRoute>} />
                <Route path="/jobs" element={<ProtectedRoute><JobsHub /></ProtectedRoute>} />
                <Route path="/quotes" element={<ProtectedRoute><QuotesHub /></ProtectedRoute>} />
                <Route path="/manage-jobs" element={<ProtectedRoute><Navigate to="/jobs?tab=manage" replace /></ProtectedRoute>} />
                <Route path="/invoices" element={<ProtectedRoute><Navigate to="/jobs?tab=invoices" replace /></ProtectedRoute>} />
                
                {/* Customer Routes */}
                <Route path="/customers" element={<ProtectedRoute><CustomerHub /></ProtectedRoute>} />
                <Route path="/manage-customers" element={<ProtectedRoute><Navigate to="/customers?tab=manage" replace /></ProtectedRoute>} />
                <Route path="/add-customer" element={<ProtectedRoute><Navigate to="/customers?tab=create" replace /></ProtectedRoute>} />
                <Route path="/customer-map" element={<ProtectedRoute><Navigate to="/customers?tab=map" replace /></ProtectedRoute>} />
                
                {/* Employee Routes */}
                <Route path="/employees" element={<ProtectedRoute><EmployeeHub /></ProtectedRoute>} />
                <Route path="/manage-employees" element={<ProtectedRoute><Navigate to="/employees?tab=crews" replace /></ProtectedRoute>} />
                <Route path="/add-employee" element={<ProtectedRoute><Navigate to="/employees?tab=create" replace /></ProtectedRoute>} />
                
                {/* Other Protected Routes */}
                <Route path="/send-notifications" element={<ProtectedRoute><SendNotifications /></ProtectedRoute>} />
                <Route path="/material-calculator" element={<ProtectedRoute><MaterialCalculator /></ProtectedRoute>} />
                
                {/* Stripe Connect Routes */}
                <Route path="/payments/connect/refresh" element={<ProtectedRoute><ConnectRefresh /></ProtectedRoute>} />
                <Route path="/payments/connect/return" element={<ProtectedRoute><ConnectReturn /></ProtectedRoute>} />
                
                {/* Fallback Route */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
              
              {/* ChatBubble component will appear on all pages */}
              <ChatBubble />
            </Router>
          </DndProvider>
        </NotificationSoundContext.Provider>
      </GoogleMapsProvider>
    </AuthProvider>
  );
}

export default App;
