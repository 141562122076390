import React, { useState, useEffect } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QuotePDF from './QuotePDF';
import axiosInstance from '../utils/axiosInstance';
import { v4 as uuidv4 } from 'uuid';
import Modal from './Modal';
import SimpleServiceForm from './SimpleServiceForm';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

const InputField = ({ label, name, value, onChange, type = "text", placeholder, required = false }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-300 mb-2" htmlFor={name}>
      {label}
    </label>
    <input
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors"
    />
  </div>
);

const InternalQuoteBuilder = ({ onSave, initialData = null }) => {
  const [services, setServices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [quoteData, setQuoteData] = useState({
    quoteNumber: `QUOTE-${uuidv4().slice(0, 8).toUpperCase()}`,
    expirationDate: (() => {
      const d = new Date();
      d.setDate(d.getDate() + 30);
      return d.toISOString().slice(0,10);
    })(),
    terms: 'Payment due upon completion. Valid for 30 days.',
    lineItems: [{ service: '', description: '', price: '' }]
  });

  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [templateData, setTemplateData] = useState({
    name: '',
    description: ''
  });

  const [customerData, setCustomerData] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: ''
    }
  });
  const [useExistingCustomer, setUseExistingCustomer] = useState(true);

  const [quoteStatus, setQuoteStatus] = useState('draft');

  // Add new state for service creation
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [serviceError, setServiceError] = useState('');
  
  useEffect(() => {
    fetchServices();
    fetchCustomers();
    if (initialData) {
      setQuoteData(initialData);
    }
  }, [initialData]);

  const fetchServices = async () => {
    try {
      const response = await axiosInstance.get('/services');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
      setError('Failed to fetch services. Please try again.');
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to fetch customers. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerSelect = (customerId) => {
    const customer = customers.find(c => c._id === customerId);
    setSelectedCustomer(customer);
  };

  const addLineItem = () => {
    setQuoteData({
      ...quoteData,
      lineItems: [...quoteData.lineItems, { service: '', description: '', price: '' }]
    });
  };

  const removeLineItem = (index) => {
    const newLineItems = quoteData.lineItems.filter((_, i) => i !== index);
    setQuoteData({
      ...quoteData,
      lineItems: newLineItems
    });
  };

  const updateLineItem = (index, field, value) => {
    const newLineItems = [...quoteData.lineItems];
    
    // Convert price to number if it's the price field
    if (field === 'price') {
      newLineItems[index][field] = Number(value) || 0;
    } else {
      newLineItems[index][field] = value;
    }

    // If service is selected, auto-fill price from services list
    if (field === 'service') {
      const selectedService = services.find(s => s.name === value);
      if (selectedService) {
        newLineItems[index].price = Number(selectedService.defaultPrice) || 0;
        newLineItems[index].description = selectedService.description;
      }
    }

    setQuoteData({
      ...quoteData,
      lineItems: newLineItems
    });
  };

  const calculateTotal = () => {
    return quoteData.lineItems.reduce((sum, item) => {
      const price = Number(item.price) || 0;
      return sum + price;
    }, 0);
  };

  const handleSave = async () => {
    try {
      if (!useExistingCustomer && (!customerData.name || !customerData.email)) {
        setError('Customer name and email are required');
        return;
      }

      // Validate line items
      if (!quoteData.lineItems.some(item => item.service && item.price)) {
        setError('At least one line item with service and price is required');
        return;
      }

      // Clean up line items to ensure all prices are numbers
      const cleanedLineItems = quoteData.lineItems
        .filter(item => item.service && item.price) // Only keep items with service and price
        .map(item => ({
          ...item,
          price: Number(item.price) || 0
        }));

      const quoteToSave = {
        ...quoteData,
        status: quoteStatus || 'draft',
        totalAmount: calculateTotal(),
        customerInfo: useExistingCustomer ? undefined : customerData,
        customerId: useExistingCustomer ? selectedCustomer?._id : undefined,
        lineItems: cleanedLineItems,
        expirationDate: quoteData.expirationDate || (() => {
          const d = new Date();
          d.setDate(d.getDate() + 30);
          return d.toISOString();
        })()
      };

      console.log('Saving quote:', quoteToSave);

      if (onSave) {
        await onSave(quoteToSave);
      } else {
        const response = await axiosInstance.post('/quotes', quoteToSave);
        setSuccess('Quote saved successfully!');
        return response.data;
      }
    } catch (error) {
      console.error('Error saving quote:', error);
      setError(error.response?.data?.message || 'Failed to save quote. Please try again.');
    }
  };

  const handleSaveAsTemplate = async () => {
    setShowTemplateModal(true);
  };

  const handleTemplateSubmit = async () => {
    try {
      if (!templateData.name) {
        setError('Template name is required');
        return;
      }

      // Validate line items before saving
      const invalidItems = quoteData.lineItems.filter(item => !item.service || !item.price);
      if (invalidItems.length === quoteData.lineItems.length) {
        setError('Please add at least one line item with both service and price');
        return;
      }

      // Filter out invalid items
      const validLineItems = quoteData.lineItems.filter(item => item.service && item.price);
      
      const templateToSave = {
        ...templateData,
        lineItems: validLineItems,
        terms: quoteData.terms
      };

      const response = await axiosInstance.post('/quotes/templates', templateToSave);
      setSuccess('Template saved successfully!');
      setShowTemplateModal(false);
      setTemplateData({ name: '', description: '' });
      return response.data;
    } catch (error) {
      console.error('Error saving template:', error);
      const errorMessage = error.response?.data?.details || error.response?.data?.message;
      setError(errorMessage || 'Please ensure each line item has both a service name and a price.');
    }
  };

  if (loading) {
    return <div className="text-center py-4 text-white">Loading...</div>;
  }

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-6">
      {error && (
        <div className="bg-red-900/50 border border-red-500 text-red-200 p-4 rounded-md">{error}</div>
      )}
      {success && (
        <div className="bg-green-900/50 border border-green-500 text-green-200 p-4 rounded-md">{success}</div>
      )}

      <div className="mb-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium text-white">Customer Information</h3>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={useExistingCustomer}
              onChange={(e) => setUseExistingCustomer(e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600 bg-gray-700 border-gray-600 rounded focus:ring-blue-500"
            />
            <span className="text-gray-300">Use Existing Customer</span>
          </label>
        </div>

        {useExistingCustomer ? (
          <select
            value={selectedCustomer?._id || ''}
            onChange={(e) => handleCustomerSelect(e.target.value)}
            className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          >
            <option value="">Select a customer</option>
            {customers.map((customer) => (
              <option key={customer._id} value={customer._id}>
                {customer.name}
              </option>
            ))}
          </select>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <InputField
              label="Name"
              name="name"
              value={customerData.name}
              onChange={(e) => setCustomerData({...customerData, name: e.target.value})}
              required
            />
            <InputField
              label="Email"
              name="email"
              type="email"
              value={customerData.email}
              onChange={(e) => setCustomerData({...customerData, email: e.target.value})}
              required
            />
            <InputField
              label="Phone"
              name="phone"
              value={customerData.phone}
              onChange={(e) => setCustomerData({...customerData, phone: e.target.value})}
            />
            <InputField
              label="Street Address"
              name="street"
              value={customerData.address.street}
              onChange={(e) => setCustomerData({
                ...customerData,
                address: {...customerData.address, street: e.target.value}
              })}
            />
            <InputField
              label="City"
              name="city"
              value={customerData.address.city}
              onChange={(e) => setCustomerData({
                ...customerData,
                address: {...customerData.address, city: e.target.value}
              })}
            />
            <InputField
              label="State"
              name="state"
              value={customerData.address.state}
              onChange={(e) => setCustomerData({
                ...customerData,
                address: {...customerData.address, state: e.target.value}
              })}
            />
            <InputField
              label="ZIP Code"
              name="zipCode"
              value={customerData.address.zipCode}
              onChange={(e) => setCustomerData({
                ...customerData,
                address: {...customerData.address, zipCode: e.target.value}
              })}
            />
          </div>
        )}
      </div>

      <div className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-medium text-white">Services</h3>
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => setShowServiceModal(true)}
              className="flex items-center text-sm text-blue-400 hover:text-blue-300"
            >
              <PlusCircleIcon className="h-4 w-4 mr-1" />
              New Service
            </button>
            <button
              type="button"
              onClick={addLineItem}
              className="px-3 py-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-500 transition-colors"
            >
              Add Line Item
            </button>
          </div>
        </div>

        {quoteData.lineItems.map((item, index) => (
          <div key={index} className="bg-gray-700 p-4 rounded-md mb-3">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Service
                </label>
                <select
                  value={item.service}
                  onChange={(e) => updateLineItem(index, 'service', e.target.value)}
                  className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="">Select a service</option>
                  {services.map((service) => (
                    <option key={service._id} value={service.name}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Description
                </label>
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => updateLineItem(index, 'description', e.target.value)}
                  className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  Price
                </label>
                <input
                  type="number"
                  step="0.01"
                  value={item.price}
                  onChange={(e) => updateLineItem(index, 'price', e.target.value)}
                  className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
            {index > 0 && (
              <button
                onClick={() => removeLineItem(index)}
                className="mt-2 text-red-400 hover:text-red-300"
              >
                Remove Item
              </button>
            )}
          </div>
        ))}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Terms & Conditions
        </label>
        <textarea
          value={quoteData.terms}
          onChange={(e) => setQuoteData({ ...quoteData, terms: e.target.value })}
          rows="4"
          className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-300 mb-2">
          Status
        </label>
        <select
          value={quoteStatus}
          onChange={(e) => setQuoteStatus(e.target.value)}
          className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        >
          <option value="draft">Draft</option>
          <option value="sent">Sent</option>
          <option value="approved">Approved</option>
          <option value="declined">Declined</option>
          <option value="expired">Expired</option>
        </select>
      </div>

      <div className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
        <div>
          <p className="text-lg font-medium text-white">Total: ${calculateTotal().toFixed(2)}</p>
        </div>
        <div className="space-x-4">
          <button
            onClick={handleSaveAsTemplate}
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
          >
            Save as Template
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
          >
            Save Quote
          </button>
          {selectedCustomer && (
            <PDFDownloadLink
              document={
                <QuotePDF
                  quote={{
                    ...quoteData,
                    totalPrice: calculateTotal()
                  }}
                  customerInfo={selectedCustomer}
                  businessInfo={{
                    name: 'Your Company Name', // TODO: Get from user profile
                    email: 'email@example.com',
                    phone: '123-456-7890',
                    website: 'www.example.com',
                    address: '123 Main St, City, State 12345'
                  }}
                />
              }
              fileName={`${quoteData.quoteNumber}.pdf`}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Generating PDF...' : 'Download PDF'
              }
            </PDFDownloadLink>
          )}
        </div>
      </div>

      {/* Template Modal */}
      <Modal
        isOpen={showTemplateModal}
        onClose={() => setShowTemplateModal(false)}
        title="Save as Template"
      >
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Template Name
            </label>
            <input
              type="text"
              value={templateData.name}
              onChange={(e) => setTemplateData({ ...templateData, name: e.target.value })}
              className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter template name"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Description
            </label>
            <textarea
              value={templateData.description}
              onChange={(e) => setTemplateData({ ...templateData, description: e.target.value })}
              className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              placeholder="Enter template description"
              rows="3"
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setShowTemplateModal(false)}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={handleTemplateSubmit}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Save Template
            </button>
          </div>
        </div>
      </Modal>

      {/* Service Form Modal */}
      <Modal
        isOpen={showServiceModal}
        onClose={() => setShowServiceModal(false)}
        title="Create New Service"
      >
        <SimpleServiceForm
          onServiceCreated={(newService) => {
            setServices([...services, newService]);
            setSuccess('Service created successfully');
            setTimeout(() => setSuccess(''), 3000);
            setShowServiceModal(false);
          }}
          onCancel={() => setShowServiceModal(false)}
        />
      </Modal>
    </div>
  );
};

export default InternalQuoteBuilder; 