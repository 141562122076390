// src/pages/SendNotifications.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import Header from '../components/Header';
import { useAuth } from '../contexts/AuthContext';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, InformationCircleIcon, ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from 'react-router-dom';

const SendNotifications = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState(''); // New state for email subject
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [notificationType, setNotificationType] = useState('email');
  const [sending, setSending] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [apiCallInfo, setApiCallInfo] = useState(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    // Filter customers based on notification type
    if (notificationType === 'sms') {
      // For SMS, only show customers with SMS consent and not opted out
      setFilteredCustomers(customers.filter(customer => 
        customer.smsConsent && !customer.smsOptOut && customer.phone
      ));
      
      // Remove any selected customers who don't have SMS consent
      setSelectedCustomers(prev => 
        prev.filter(id => 
          customers.find(c => c._id === id && c.smsConsent && !c.smsOptOut && c.phone)
        )
      );
    } else {
      // For email, show all customers
      setFilteredCustomers(customers);
    }
  }, [notificationType, customers]);

  useEffect(() => {
    // Check for customer IDs in URL parameters
    const searchParams = new URLSearchParams(location.search);
    const customerIds = searchParams.get('customers');
    const type = searchParams.get('type');
    
    if (customerIds) {
      // If multiple IDs are comma-separated
      const idsArray = customerIds.split(',');
      setSelectedCustomers(idsArray);
    }
    
    // Set notification type if specified in URL
    if (type === 'sms') {
      setNotificationType('sms');
    }
  }, [location.search]);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/customers');
      setCustomers(response.data);
      
      // Initialize filtered customers based on current notification type
      if (notificationType === 'sms') {
        setFilteredCustomers(response.data.filter(customer => 
          customer.smsConsent && !customer.smsOptOut && customer.phone
        ));
      } else {
        setFilteredCustomers(response.data);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError(
        `Failed to fetch customers. ${error.response?.data?.message || 'Please try again.'}`
      );
    }
  };

  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers((prev) =>
      prev.includes(customerId)
        ? prev.filter((id) => id !== customerId)
        : [...prev, customerId]
    );
  };

  const handleSelectAll = () => {
    setSelectedCustomers(
      selectedCustomers.length === filteredCustomers.length
        ? []
        : filteredCustomers.map((customer) => customer._id)
    );
  };

  const handleSendNotification = async () => {
    if (selectedCustomers.length === 0) {
      setError('Please select at least one customer');
      return;
    }

    if (!message.trim()) {
      setError('Please enter a message');
      return;
    }

    if (notificationType === 'email' && !subject.trim()) {
      setError('Please enter a subject for the email');
      return;
    }

    try {
      setSending(true);
      setError('');
      setApiCallInfo(null);
      
      const selectedCustomerData = customers.filter(c => 
        selectedCustomers.includes(c._id)
      );

      const response = await axiosInstance.post('/notifications/send', {
        customers: selectedCustomerData,
        message,
        subject, // Include subject for emails
        type: notificationType,
        businessInfo: {
          name: user.name,
          phoneNumber: user.phoneNumber
        }
      });

      setResult({
        success: true,
        message: 'Notifications sent successfully!',
        details: response.data.results
      });

      // Show API call information
      setApiCallInfo({
        totalRecipients: selectedCustomerData.length,
        apiCalls: notificationType === 'email' ? 1 : selectedCustomerData.length,
        type: notificationType,
        successCount: response.data.results.filter(r => r.status === 'sent').length,
        failureCount: response.data.results.filter(r => r.status === 'failed').length
      });

      // Clear form after successful send
      if (response.data.results.some(r => r.status === 'sent')) {
        setMessage('');
        setSubject('');
        setSelectedCustomers([]);
      }
    } catch (error) {
      console.error('Error sending notifications:', error);
      setResult({
        success: false,
        message: 'Failed to send notifications. Please try again.',
        error: error.response?.data?.message || error.message
      });
    } finally {
      setSending(false);
    }
  };

  const handleReturnToCustomers = () => {
    navigate('/customers?tab=manage&action=notification&status=success');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
      <Header />
      <main className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="relative mb-8">
            <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
            <div className="flex justify-between items-center">
              <h1 className="relative text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Send Notifications
              </h1>
              <button
                onClick={() => navigate('/customers?tab=manage')}
                className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600 transition-colors flex items-center"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-2" />
                Back to Customers
              </button>
            </div>
            <p className="mt-2 text-gray-400">Send bulk notifications to your customers</p>
          </div>

          <div className="bg-gradient-to-br from-surface to-gray-800 shadow-lg rounded-lg p-8 border border-gray-700">
            {/* Notification Type Selection */}
            <div className="mb-6">
              <label className="block text-lg font-medium text-blue-400 mb-3">Notification Type</label>
              <div className="flex space-x-4">
                <button
                  className={`px-4 py-2 rounded ${
                    notificationType === 'email'
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white transform scale-105 shadow-lg'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600 transition-all duration-200'
                  }`}
                  onClick={() => setNotificationType('email')}
                >
                  Email
                </button>
                <button
                  className={`px-4 py-2 rounded ${
                    notificationType === 'sms'
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white transform scale-105 shadow-lg'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600 transition-all duration-200'
                  }`}
                  onClick={() => setNotificationType('sms')}
                >
                  SMS
                </button>
              </div>
              {notificationType === 'sms' && (
                <div className="mt-2 bg-blue-900/30 border border-blue-500/50 rounded-lg p-3 flex items-start">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400 mr-2 flex-shrink-0 mt-0.5" />
                  <p className="text-sm text-blue-300">
                    Only showing customers who have consented to receive SMS messages and have a valid phone number.
                  </p>
                </div>
              )}
            </div>

            {/* Subject field for email */}
            {notificationType === 'email' && (
              <div className="mb-6">
                <label htmlFor="subject" className="block text-lg font-medium text-blue-400 mb-3">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholder="Enter email subject..."
                />
              </div>
            )}

            {/* Message Input */}
            <div className="mb-6">
              <label htmlFor="message" className="block text-lg font-medium text-blue-400 mb-3">
                Message
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full px-4 py-3 bg-gray-800 text-white border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                rows="4"
                placeholder="Enter your message here..."
                maxLength={notificationType === 'sms' ? 160 : undefined}
              ></textarea>
              {notificationType === 'sms' && (
                <p className="text-sm text-gray-400 mt-2">
                  {160 - message.length} characters remaining
                </p>
              )}
            </div>

            {/* Customer Selection */}
            <div className="mb-6">
              <div className="flex justify-between items-center mb-3">
                <label className="text-lg font-medium text-blue-400">Select Recipients</label>
                <div className="flex items-center">
                  <button
                    onClick={handleSelectAll}
                    className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-200"
                  >
                    {selectedCustomers.length === filteredCustomers.length
                      ? 'Deselect All'
                      : 'Select All'}
                  </button>
                  {notificationType === 'sms' && (
                    <span className="ml-4 text-sm text-gray-400">
                      {filteredCustomers.length} eligible customers
                    </span>
                  )}
                </div>
              </div>
              <div className="max-h-60 overflow-y-auto bg-gray-800/50 rounded-lg p-4 border border-gray-700 custom-scrollbar">
                {filteredCustomers.length > 0 ? (
                  filteredCustomers.map((customer) => (
                    <div key={customer._id} className="flex items-center mb-3 hover:bg-gray-700/50 p-2 rounded-lg transition-all duration-200">
                      <input
                        type="checkbox"
                        id={customer._id}
                        checked={selectedCustomers.includes(customer._id)}
                        onChange={() => handleSelectCustomer(customer._id)}
                        className="mr-3 h-4 w-4 text-blue-500 border-gray-700 rounded focus:ring-blue-500 focus:ring-offset-gray-800"
                      />
                      <label htmlFor={customer._id} className="text-white cursor-pointer hover:text-blue-300 transition-colors duration-200">
                        {customer.name} ({notificationType === 'email' ? customer.email : customer.phone})
                        {notificationType === 'sms' && (
                          <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-900 text-green-200">
                            <CheckCircleIcon className="h-3 w-3 mr-1" />
                            SMS Enabled
                          </span>
                        )}
                      </label>
                    </div>
                  ))
                ) : (
                  <div className="text-center py-4 text-gray-400">
                    {notificationType === 'sms' 
                      ? "No customers have opted in for SMS notifications. Please enable SMS consent for customers first."
                      : "No customers found."}
                  </div>
                )}
              </div>
            </div>

            {/* Error Display */}
            {error && (
              <div className="mb-6 bg-red-900/50 border border-red-500 text-red-200 px-4 py-3 rounded-lg">
                {error}
              </div>
            )}

            {/* Send Button */}
            <button
              onClick={handleSendNotification}
              disabled={sending}
              className={`w-full py-3 px-4 rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                sending ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {sending ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin mr-2 h-5 w-5 border-2 border-white border-opacity-20 rounded-full border-t-white"></div>
                  Sending...
                </div>
              ) : (
                'Send Notification'
              )}
            </button>

            {/* Results Display */}
            {result && (
              <div
                className={`mt-6 p-4 rounded-lg border ${
                  result.success 
                    ? 'bg-green-900/50 border-green-500 text-green-200' 
                    : 'bg-red-900/50 border-red-500 text-red-200'
                }`}
              >
                <p className="font-medium mb-2">{result.message}</p>
                {result.details && (
                  <ul className="mt-3 space-y-2">
                    {result.details.map((item, index) => (
                      <li key={index} className="flex items-center">
                        <span className={`inline-block w-2 h-2 rounded-full mr-2 ${
                          item.status === 'sent' ? 'bg-green-400' : 'bg-red-400'
                        }`}></span>
                        {item.customerId} - {item.status}
                        {item.error && ` - ${item.error}`}
                      </li>
                    ))}
                  </ul>
                )}
                
                {result.success && (
                  <button
                    onClick={handleReturnToCustomers}
                    className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center"
                  >
                    <ArrowLeftIcon className="h-4 w-4 mr-2" />
                    Return to Customers
                  </button>
                )}
              </div>
            )}

            {/* API Usage Information */}
            {apiCallInfo && (
              <div className="mt-4 p-4 bg-gray-900/50 rounded-lg border border-gray-700">
                <div className="flex items-center mb-2">
                  <InformationCircleIcon className="h-5 w-5 text-blue-400 mr-2" />
                  <h3 className="text-lg font-medium text-blue-400">API Usage Information</h3>
                </div>
                <div className="space-y-2 text-gray-300">
                  <p>Total Recipients: {apiCallInfo.totalRecipients}</p>
                  <p>API Calls Made: {apiCallInfo.apiCalls}</p>
                  <p>Type: {apiCallInfo.type === 'email' ? 'Bulk Email (1 API call)' : 'Individual SMS'}</p>
                  <p>Success Rate: {((apiCallInfo.successCount / apiCallInfo.totalRecipients) * 100).toFixed(1)}%</p>
                  {apiCallInfo.type === 'sms' && (
                    <p className="text-yellow-400">Note: SMS messages are sent individually and each counts as one API call</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default SendNotifications;
