import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QuotePDF from '../components/QuotePDF';
import { 
  CheckCircleIcon, 
  XCircleIcon, 
  DocumentTextIcon, 
  ArrowDownTrayIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';

// Simple public navbar component
const PublicNavbar = () => (
  <nav className="bg-gray-900 shadow-lg">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16">
        <div className="flex items-center">
          <Link to="/" className="flex items-center space-x-2">
            <img
              className="h-8 w-auto"
              src="/logo.png"
              alt="AutoLawn"
              onError={(e) => e.target.style.display = 'none'}
            />
            <span className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
              AutoLawn
            </span>
          </Link>
        </div>
      </div>
    </div>
  </nav>
);

const PublicQuoteView = () => {
  const params = useParams();
  // Get the identifier from either quoteId or identifier parameter
  const identifier = params.identifier || params.quoteId;
  const navigate = useNavigate();
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [verifying, setVerifying] = useState(true);
  const [verified, setVerified] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [actionResult, setActionResult] = useState(null);

  // For manual quote number entry
  const [manualQuoteNumber, setManualQuoteNumber] = useState('');
  const [showManualEntry, setShowManualEntry] = useState(!identifier);

  useEffect(() => {
    // If we have an identifier in the URL, use that
    if (identifier && !showManualEntry) {
      fetchQuote(identifier);
    }
  }, [identifier]);

  const fetchQuote = async (quoteId) => {
    setLoading(true);
    setError(null);
    try {
      // Use the base URL from your environment
      const baseURL = process.env.REACT_APP_API_URL || 'https://autolawn.app/api';
      const response = await axios.get(`${baseURL}/quotes/public/${quoteId}`);
      setQuote(response.data);
      setVerifying(false); // Skip verification for direct links
      setVerified(true);
    } catch (err) {
      console.error('Error fetching quote:', err);
      setError('Quote not found or has expired. Please check the quote number and try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    if (manualQuoteNumber.trim()) {
      fetchQuote(manualQuoteNumber.trim());
      setShowManualEntry(false);
    }
  };

  const handleVerifyCode = (e) => {
    e.preventDefault();
    // In a real implementation, you would verify this code against the backend
    // For now, we'll just simulate verification
    if (verificationCode.trim()) {
      setVerified(true);
      setVerifying(false);
    }
  };

  const handleAcceptQuote = async () => {
    if (!quote) return;
    
    setActionLoading(true);
    try {
      const baseURL = process.env.REACT_APP_API_URL || 'https://autolawn.app/api';
      const response = await axios.post(`${baseURL}/quotes/public/${quote._id}/accept`, {
        // We'll use the frequency that was set when creating the quote
        frequency: quote.frequency || 'one-time'
      });
      
      setActionResult({
        success: true,
        message: 'Quote accepted successfully! We will contact you shortly to schedule your service.',
        details: response.data
      });
    } catch (err) {
      console.error('Error accepting quote:', err);
      setActionResult({
        success: false,
        message: 'There was an error accepting your quote. Please try again or contact us directly.',
        error: err.response?.data?.message || err.message
      });
    } finally {
      setActionLoading(false);
    }
  };

  const handleDeclineQuote = async () => {
    if (!quote) return;
    
    setActionLoading(true);
    try {
      const baseURL = process.env.REACT_APP_API_URL || 'https://autolawn.app/api';
      await axios.post(`${baseURL}/quotes/public/${quote._id}/decline`);
      
      setActionResult({
        success: true,
        message: 'Quote declined. Thank you for considering our services.',
        declined: true
      });
    } catch (err) {
      console.error('Error declining quote:', err);
      setActionResult({
        success: false,
        message: 'There was an error processing your request. Please try again or contact us directly.',
        error: err.response?.data?.message || err.message
      });
    } finally {
      setActionLoading(false);
    }
  };

  // Render the manual quote number entry form
  if (showManualEntry) {
    return (
      <>
        <PublicNavbar />
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center px-4">
          <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8 border border-gray-700">
            <div className="text-center mb-8">
              <DocumentTextIcon className="h-16 w-16 text-blue-500 mx-auto mb-4" />
              <h1 className="text-2xl font-bold text-white mb-2">View Your Quote</h1>
              <p className="text-gray-400">Enter your quote number to view details</p>
            </div>
            
            <form onSubmit={handleManualSubmit} className="space-y-6">
              <div>
                <label htmlFor="quoteNumber" className="block text-sm font-medium text-gray-400 mb-2">
                  Quote Number
                </label>
                <input
                  type="text"
                  id="quoteNumber"
                  value={manualQuoteNumber}
                  onChange={(e) => setManualQuoteNumber(e.target.value)}
                  placeholder="Enter quote number (e.g., QUOTE-123ABC)"
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
              
              {error && (
                <div className="bg-red-900/30 border border-red-500 text-red-200 px-4 py-3 rounded-lg">
                  <div className="flex items-center">
                    <ExclamationCircleIcon className="h-5 w-5 mr-2" />
                    <span>{error}</span>
                  </div>
                </div>
              )}
              
              <button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition duration-200"
                disabled={loading}
              >
                {loading ? 'Loading...' : 'View Quote'}
              </button>
            </form>
          </div>
        </div>
      </>
    );
  }

  // Render verification step if needed
  if (verifying && !verified) {
    return (
      <>
        <PublicNavbar />
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center px-4">
          <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8 border border-gray-700">
            <div className="text-center mb-8">
              <DocumentTextIcon className="h-16 w-16 text-blue-500 mx-auto mb-4" />
              <h1 className="text-2xl font-bold text-white mb-2">Verify Your Identity</h1>
              <p className="text-gray-400">Enter the verification code sent to your phone</p>
            </div>
            
            <form onSubmit={handleVerifyCode} className="space-y-6">
              <div>
                <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-400 mb-2">
                  Verification Code
                </label>
                <input
                  type="text"
                  id="verificationCode"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter 6-digit code"
                  className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  required
                />
              </div>
              
              <button
                type="submit"
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 px-4 rounded-lg transition duration-200"
              >
                Verify
              </button>
              
              <div className="text-center">
                <button 
                  type="button"
                  onClick={() => setShowManualEntry(true)}
                  className="text-blue-400 hover:text-blue-300 text-sm"
                >
                  Enter a different quote number
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }

  // Show loading state
  if (loading) {
    return (
      <>
        <PublicNavbar />
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center">
          <div className="text-center">
            <div className="animate-spin h-12 w-12 border-4 border-blue-500 border-t-transparent rounded-full mx-auto mb-4"></div>
            <p className="text-white">Loading quote details...</p>
          </div>
        </div>
      </>
    );
  }

  // Show error state
  if (error) {
    return (
      <>
        <PublicNavbar />
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center px-4">
          <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8 border border-gray-700 text-center">
            <XCircleIcon className="h-16 w-16 text-red-500 mx-auto mb-4" />
            <h1 className="text-2xl font-bold text-white mb-4">Quote Not Found</h1>
            <p className="text-gray-400 mb-6">{error}</p>
            <button
              onClick={() => setShowManualEntry(true)}
              className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition duration-200"
            >
              Try Again
            </button>
          </div>
        </div>
      </>
    );
  }

  // Show action result (accepted/declined)
  if (actionResult) {
    return (
      <>
        <PublicNavbar />
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center px-4">
          <div className="max-w-md w-full bg-gray-800 rounded-lg shadow-xl p-8 border border-gray-700 text-center">
            {actionResult.success ? (
              <>
                {actionResult.declined ? (
                  <XCircleIcon className="h-16 w-16 text-yellow-500 mx-auto mb-4" />
                ) : (
                  <CheckCircleIcon className="h-16 w-16 text-green-500 mx-auto mb-4" />
                )}
                <h1 className="text-2xl font-bold text-white mb-4">
                  {actionResult.declined ? 'Quote Declined' : 'Quote Accepted'}
                </h1>
                <p className="text-gray-400 mb-6">{actionResult.message}</p>
              </>
            ) : (
              <>
                <ExclamationCircleIcon className="h-16 w-16 text-red-500 mx-auto mb-4" />
                <h1 className="text-2xl font-bold text-white mb-4">Error</h1>
                <p className="text-gray-400 mb-6">{actionResult.message}</p>
                <p className="text-red-400 text-sm mb-6">{actionResult.error}</p>
              </>
            )}
            <button
              onClick={() => navigate('/')}
              className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition duration-200"
            >
              Return to Home
            </button>
          </div>
        </div>
      </>
    );
  }

  // Show quote details
  return (
    <>
      <PublicNavbar />
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-6 px-3 sm:py-12 sm:px-4">
        <div className="max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-xl border border-gray-700 overflow-hidden">
          {/* Quote Header */}
          <div className="bg-gray-900 p-4 sm:p-6 border-b border-gray-700">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
              <div>
                <h1 className="text-xl sm:text-2xl font-bold text-white">Quote #{quote.quoteNumber}</h1>
                <p className="text-sm sm:text-base text-gray-400 mt-1">
                  Valid until: {new Date(quote.expirationDate).toLocaleDateString()}
                </p>
              </div>
              <div className="mt-2 md:mt-0">
                <span className={`inline-flex items-center px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium ${
                  quote.status === 'draft' ? 'bg-gray-700 text-gray-200' :
                  quote.status === 'sent' ? 'bg-blue-900/50 text-blue-200' :
                  quote.status === 'approved' ? 'bg-green-900/50 text-green-200' :
                  quote.status === 'declined' ? 'bg-red-900/50 text-red-200' :
                  quote.status === 'expired' ? 'bg-yellow-900/50 text-yellow-200' :
                  'bg-gray-700 text-gray-200'
                }`}>
                  {quote.status?.toUpperCase()}
                </span>
              </div>
            </div>
          </div>

          {/* Quote Content */}
          <div className="p-4 sm:p-6">
            {/* Business Info */}
            <div className="mb-6">
              <h2 className="text-base sm:text-lg font-semibold text-white mb-2">From</h2>
              <div className="bg-gray-900/50 rounded-lg p-3 sm:p-4 border border-gray-700">
                <p className="text-white font-medium">{quote.createdBy?.name || 'Your Service Provider'}</p>
                <p className="text-sm text-gray-400">{quote.createdBy?.email || 'contact@example.com'}</p>
                <p className="text-sm text-gray-400">{quote.createdBy?.phoneNumber || 'Phone not provided'}</p>
              </div>
            </div>

            {/* Customer Info */}
            <div className="mb-6">
              <h2 className="text-base sm:text-lg font-semibold text-white mb-2">Customer</h2>
              <div className="bg-gray-900/50 rounded-lg p-3 sm:p-4 border border-gray-700">
                <p className="text-white font-medium">
                  {quote.customerId?.name || quote.customerInfo?.name || 'Customer'}
                </p>
                {(quote.customerId?.address || quote.customerInfo?.address) && (
                  <p className="text-sm text-gray-400">
                    {quote.customerId?.address?.street || quote.customerInfo?.address?.street}<br />
                    {quote.customerId?.address?.city || quote.customerInfo?.address?.city}, 
                    {quote.customerId?.address?.state || quote.customerInfo?.address?.state} 
                    {quote.customerId?.address?.zipCode || quote.customerInfo?.address?.zipCode}
                  </p>
                )}
              </div>
            </div>

            {/* Line Items */}
            <div className="mb-6">
              <h2 className="text-base sm:text-lg font-semibold text-white mb-2">Services</h2>
              <div className="bg-gray-900/50 rounded-lg border border-gray-700 overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead>
                    <tr className="bg-gray-800/50">
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Service</th>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">Description</th>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-right text-xs font-medium text-gray-400 uppercase tracking-wider">Price</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-700">
                    {quote.lineItems.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-800/30">
                        <td className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm font-medium text-white">{item.service}</td>
                        <td className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm text-gray-300">{item.description || '-'}</td>
                        <td className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm text-right text-gray-300">${Number(item.price).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="bg-gray-800/50">
                    <tr>
                      <td colSpan="2" className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm font-medium text-white text-right">Subtotal</td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm font-medium text-white text-right">${quote.totalAmount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm font-medium text-white text-right">Tax (5%)</td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm font-medium text-white text-right">${(quote.totalAmount * 0.05).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm font-bold text-white text-right">Total</td>
                      <td className="px-3 sm:px-6 py-2 sm:py-4 text-xs sm:text-sm font-bold text-white text-right">${(quote.totalAmount * 1.05).toFixed(2)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            {/* Terms */}
            <div className="mb-6">
              <h2 className="text-base sm:text-lg font-semibold text-white mb-2">Terms & Conditions</h2>
              <div className="bg-gray-900/50 rounded-lg p-3 sm:p-4 border border-gray-700">
                <p className="text-xs sm:text-sm text-gray-300 whitespace-pre-line">{quote.terms}</p>
              </div>
            </div>

            {/* Actions */}
            <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex space-x-4">
                <PDFDownloadLink
                  document={
                    <QuotePDF
                      quote={{
                        ...quote,
                        totalPrice: quote.totalAmount
                      }}
                      customerInfo={quote.customerId || quote.customerInfo}
                      businessInfo={{
                        name: quote.createdBy?.name || 'Your Service Provider',
                        email: quote.createdBy?.email || 'contact@example.com',
                        phone: quote.createdBy?.phoneNumber || 'Phone not provided',
                        website: 'www.autolawn.app',
                        address: quote.createdBy?.address || 'Address not provided'
                      }}
                    />
                  }
                  fileName={`${quote.quoteNumber}.pdf`}
                  className="inline-flex items-center px-3 sm:px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm rounded-lg transition duration-200"
                >
                  {({ blob, url, loading, error }) => (
                    <>
                      <ArrowDownTrayIcon className="h-4 w-4 mr-1 sm:mr-2" />
                      {loading ? 'Generating...' : 'Download PDF'}
                    </>
                  )}
                </PDFDownloadLink>
              </div>
              
              <div className="flex space-x-4 w-full sm:w-auto">
                <button
                  onClick={handleDeclineQuote}
                  disabled={actionLoading}
                  className="flex-1 sm:flex-none px-3 sm:px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white text-sm rounded-lg transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {actionLoading ? 'Processing...' : 'Decline Quote'}
                </button>
                <button
                  onClick={handleAcceptQuote}
                  disabled={actionLoading}
                  className="flex-1 sm:flex-none px-3 sm:px-4 py-2 bg-green-600 hover:bg-green-700 text-white text-sm rounded-lg transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {actionLoading ? 'Processing...' : 'Accept Quote'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicQuoteView; 