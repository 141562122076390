// Profile.js
import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../utils/axiosInstance';
import Header from '../components/Header';
import { 
  User, Package, CreditCard, DollarSign, 
  Save, Edit2, X, CheckCircle, AlertCircle, Loader,
  FileText, Mail, Phone, BarChart, Users, MessageCircle,
  RefreshCw, ArrowUpCircle, ChartBar, MapPin
} from 'lucide-react';
import { CreditCard as CreditCardIcon } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';

const tabs = [
  { name: 'Profile Settings', key: 'settings', icon: User },
  { name: 'Membership', key: 'membership', icon: CreditCard },
  { name: 'Payments', key: 'payments', icon: DollarSign },
  { name: 'Usage Statistics', key: 'usage', icon: BarChart }
];

const ProfileSection = ({ title, children, onEdit, onSave, onCancel, isEditing, description }) => (
  <div className="bg-gray-800 rounded-lg p-6 mb-6 border border-gray-700">
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-xl font-bold text-white">{title}</h3>
      <div>
        {isEditing ? (
          <div className="flex gap-2">
            <button
              onClick={onSave}
              className="flex items-center gap-2 px-3 py-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
            >
              <Save size={16} />
              Save
            </button>
            <button
              onClick={onCancel}
              className="flex items-center gap-2 px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition-colors"
            >
              <X size={16} />
              Cancel
            </button>
          </div>
        ) : (
          <button
            onClick={onEdit}
            className="flex items-center gap-2 px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            <Edit2 size={16} />
            Edit
          </button>
        )}
      </div>
    </div>
    {description && (
      <p className="text-gray-400 text-sm mb-4">{description}</p>
    )}
    {children}
  </div>
);

const InputField = ({ label, name, value, onChange, disabled, type = "text", placeholder, description, readOnly = false }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor={name}>
      {label}
    </label>
    {description && (
      <p className="text-xs text-gray-400 mb-2">{description}</p>
    )}
    <input
      type={type}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
      placeholder={placeholder}
      className={`w-full px-4 py-2 rounded-md ${
        readOnly 
          ? 'bg-gray-900 border-gray-700 text-gray-500 cursor-not-allowed'
          : disabled 
            ? 'border-gray-600 bg-gray-800 text-gray-500' 
            : 'border-gray-600 text-white bg-gray-700'
      } border focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-colors`}
    />
  </div>
);

const Profile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState('settings');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const { user, refreshUser } = useAuth();
  const [formData, setFormData] = useState({
    // Account info (read-only)
    accountName: '',
    accountEmail: '',
    
    // Profile info (editable)
    displayName: '',
    contactEmail: '',
    contactPhone: '',
    customerBaseSize: '',
    
    // Business info
    businessName: '',
    businessWebsite: '',
    businessAddress: ''
  });
  const [originalData, setOriginalData] = useState(null);
  const [stripeStatus, setStripeStatus] = useState({
    isConnected: false,
    accountId: null,
    isOnboarded: false
  });
  
  // Usage statistics state
  const [usageData, setUsageData] = useState(null);
  const [usageLoading, setUsageLoading] = useState(true);
  const [usageError, setUsageError] = useState('');

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/profile');
      const profileData = {
        // Account info (read-only)
        accountName: response.data.accountName || '',
        accountEmail: response.data.accountEmail || '',
        
        // Profile info (editable)
        displayName: response.data.displayName || '',
        contactEmail: response.data.contactEmail || '',
        contactPhone: response.data.contactPhone || '',
        customerBaseSize: response.data.customerBaseSize || '',
        
        // Business info
        businessName: response.data.businessInfo?.name || '',
        businessWebsite: response.data.businessInfo?.website || '',
        businessAddress: response.data.businessInfo?.address || ''
      };
      setFormData(profileData);
      setOriginalData(profileData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('Failed to fetch profile data.');
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if there's a tab parameter in the URL
    const params = new URLSearchParams(location.search);
    const tabParam = params.get('tab');
    if (tabParam && tabs.some(tab => tab.key === tabParam)) {
      setCurrentTab(tabParam);
    }
    
    if (user) {
      fetchProfile();
      
      // Check if we need to refresh Stripe status based on cache
      const cachedStripeStatus = getStripeStatusFromCache();
      if (cachedStripeStatus) {
        console.log('Profile: Using cached Stripe status');
        setStripeStatus(cachedStripeStatus);
      } else {
        console.log('Profile: No cached Stripe status, fetching fresh data');
        checkStripeStatus();
      }
      
      // If the current tab is 'usage', fetch usage statistics
      if (currentTab === 'usage' || tabParam === 'usage') {
        fetchUsageStats();
      }
      
      setLoading(false);
    }
  }, [user, location.search, currentTab]);

  // Add a new useEffect to refresh Stripe status when tab changes to 'payments'
  useEffect(() => {
    if (currentTab === 'payments') {
      // Force refresh Stripe status when switching to payments tab
      checkStripeStatus(true);
    }
  }, [currentTab]);

  // Function to get cached Stripe status
  const getStripeStatusFromCache = () => {
    try {
      const cachedData = localStorage.getItem('stripeStatus');
      if (!cachedData) return null;
      
      const { status, timestamp } = JSON.parse(cachedData);
      const cacheAge = Date.now() - timestamp;
      
      // Cache expires after 30 minutes (1800000 ms)
      if (cacheAge < 1800000) {
        return status;
      } else {
        console.log('Profile: Stripe status cache expired');
        return null;
      }
    } catch (error) {
      console.error('Profile: Error reading Stripe status from cache:', error);
      return null;
    }
  };

  // Function to cache Stripe status
  const cacheStripeStatus = (status) => {
    try {
      const cacheData = {
        status,
        timestamp: Date.now()
      };
      localStorage.setItem('stripeStatus', JSON.stringify(cacheData));
    } catch (error) {
      console.error('Profile: Error caching Stripe status:', error);
    }
  };
  
  // Add this function to fetch usage statistics
  const fetchUsageStats = async () => {
    try {
      setUsageLoading(true);
      setUsageError('');
      const response = await axiosInstance.get('/usage');
      setUsageData(response.data);
    } catch (error) {
      console.error('Error fetching usage statistics:', error);
      setUsageError('Failed to fetch usage statistics. Please try again.');
    } finally {
      setUsageLoading(false);
    }
  };
  
  // Format date for usage statistics
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const checkStripeStatus = async (forceRefresh = false) => {
    try {
      // If not forcing refresh, check cache first
      if (!forceRefresh) {
        const cachedStatus = getStripeStatusFromCache();
        if (cachedStatus) {
          console.log('Profile: Using cached Stripe status');
          setStripeStatus(cachedStatus);
          return;
        }
      }
      
      console.log('Profile: Checking Stripe status');
      const response = await axiosInstance.get('/connect/status');
      console.log('Profile: Stripe status response:', response.data);
      
      const statusData = {
        isConnected: !!response.data.accountId,
        accountId: response.data.accountId,
        isOnboarded: response.data.isOnboarded
      };
      
      setStripeStatus(statusData);
      
      // Cache the status
      cacheStripeStatus(statusData);
    } catch (error) {
      console.error('Profile: Error checking Stripe status:', error);
      setStripeStatus({
        isConnected: false,
        accountId: null,
        isOnboarded: false
      });
    }
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEdit = () => {
    setOriginalData(formData);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setFormData(originalData);
    setIsEditing(false);
    setError('');
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      setLoading(true);
      const response = await axiosInstance.put('/profile', {
        displayName: formData.displayName,
        contactEmail: formData.contactEmail,
        contactPhone: formData.contactPhone,
        customerBaseSize: formData.customerBaseSize,
        businessName: formData.businessName,
        businessWebsite: formData.businessWebsite,
        businessAddress: formData.businessAddress
      });
      
      const updatedData = {
        // Account info (read-only)
        accountName: response.data.accountName || '',
        accountEmail: response.data.accountEmail || '',
        
        // Profile info (editable)
        displayName: response.data.displayName || '',
        contactEmail: response.data.contactEmail || '',
        contactPhone: response.data.contactPhone || '',
        customerBaseSize: response.data.customerBaseSize || '',
        
        // Business info
        businessName: response.data.businessInfo?.name || '',
        businessWebsite: response.data.businessInfo?.website || '',
        businessAddress: response.data.businessInfo?.address || ''
      };
      
      setFormData(updatedData);
      setOriginalData(updatedData);
      setIsEditing(false);
      setSuccess('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpgradeSubscription = async (tier) => {
    try {
      const response = await axiosInstance.post('/subscription/create-checkout-session', {
        tier,
        successUrl: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/profile`,
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Failed to initiate upgrade. Please try again.');
    }
  };

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        await axiosInstance.post('/subscription/cancel');
        setSuccess('Subscription cancelled successfully.');
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        setError('Failed to cancel subscription. Please try again.');
      }
    }
  };

  const handleConnectStripe = async () => {
    try {
      setError('');
      setSuccess('');
      console.log('Profile: Initializing Stripe Connect...');
      
      // Clear the cache before connecting
      localStorage.removeItem('stripeStatus');
      
      const response = await axiosInstance.post('/connect/init');
      
      if (response.data.status === 'already_onboarded') {
        console.log('Profile: Stripe already connected:', response.data.message);
        setSuccess(response.data.message);
        checkStripeStatus(true); // Force refresh the status
        return;
      }
      
      if (response.data.url) {
        console.log('Profile: Redirecting to Stripe Connect onboarding...');
        window.location.href = response.data.url;
      } else {
        setError('Failed to get Stripe Connect URL. Please try again.');
      }
    } catch (error) {
      console.error('Profile: Error connecting to Stripe:', error);
      if (error.response?.data?.error?.includes('platform-profile')) {
        setError('Stripe platform setup is incomplete. Please contact support to complete the setup.');
      } else {
        setError('Failed to initialize Stripe Connect. Please try again.');
      }
    }
  };

  const handleCompleteStripeSetup = async () => {
    try {
      // Clear the cache before completing setup
      localStorage.removeItem('stripeStatus');
      
      const response = await axiosInstance.post('/connect/refresh-link');
      if (response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      setError('Failed to get Stripe setup link. Please try again.');
    }
  };

  const handleViewStripeAccount = async () => {
    try {
      const response = await axiosInstance.get('/connect/login-link');
      if (response.data.url) {
        window.open(response.data.url, '_blank');
      }
    } catch (error) {
      setError('Failed to access Stripe dashboard. Please try again.');
    }
  };

  const handleDisconnectStripe = async () => {
    if (window.confirm('Are you sure you want to disconnect your Stripe account? This is for testing purposes only.')) {
      try {
        setError('');
        setSuccess('');
        
        // Clear the cache before disconnecting
        localStorage.removeItem('stripeStatus');
        
        const response = await axiosInstance.post('/connect/disconnect');
        setSuccess('Stripe account disconnected for testing purposes.');
        checkStripeStatus(true); // Force refresh the status
        refreshUser(); // Refresh the user data to update the UI
      } catch (error) {
        setError('Failed to disconnect Stripe account: ' + (error.response?.data?.message || error.message));
      }
    }
  };

  const handleTabChange = (tabKey) => {
    setCurrentTab(tabKey);
    
    // Update URL with tab parameter
    const params = new URLSearchParams(location.search);
    params.set('tab', tabKey);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    
    // If switching to usage tab, fetch usage statistics
    if (tabKey === 'usage') {
      fetchUsageStats();
    }
  };

  const renderSubscriptionDetails = () => {
    const plans = [
      {
        name: 'Basic',
        price: '$49.00/month',
        features: [
          'Up to 100 customers',
          'Basic job scheduling',
          'Core features',
          'Email support'
        ],
        tier: 'basic'
      },
      {
        name: 'Pro',
        price: '$79.00/month',
        features: [
          'Up to 500 customers',
          'Advanced scheduling',
          'All basic features',
          'Priority support',
          'Analytics dashboard',
          'Route optimization'
        ],
        tier: 'pro'
      },
      {
        name: 'Enterprise',
        price: '$199.00/month',
        features: [
          'Unlimited customers',
          'All pro features',
          'Custom solutions',
          'Dedicated support',
          'Advanced analytics',
          'API access',
          'Custom integrations'
        ],
        tier: 'enterprise'
      }
    ];

    return (
      <>
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">Current Subscription</h2>
          <div className="space-y-2 text-gray-300">
            <p><span className="font-medium">Plan:</span> {user.subscriptionTier ? user.subscriptionTier.charAt(0).toUpperCase() + user.subscriptionTier.slice(1) : 'None'}</p>
            <p><span className="font-medium">Status:</span> {user.subscriptionActive ? 'Active' : 'Inactive'}</p>
          </div>
          {user.subscriptionActive && (
            <button
              onClick={handleCancelSubscription}
              className="mt-4 px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
            >
              Cancel Subscription
            </button>
          )}
        </div>

        <div>
          <h2 className="text-2xl font-bold text-white mb-6">Available Plans</h2>
          <div className="grid md:grid-cols-3 gap-6">
            {plans.map((plan) => (
              <div key={plan.tier} className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
                <h3 className="text-xl font-bold text-white mb-2">{plan.name}</h3>
                <p className="text-2xl font-bold text-blue-400 mb-4">{plan.price}</p>
                <ul className="mb-6 space-y-2">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-300">
                      <CheckCircle className="h-5 w-5 text-green-400 mr-2 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleUpgradeSubscription(plan.tier)}
                  disabled={user.subscriptionTier === plan.tier && user.subscriptionActive}
                  className={`w-full px-4 py-2 rounded-md ${
                    user.subscriptionTier === plan.tier && user.subscriptionActive
                      ? 'bg-gray-600 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  } text-white transition-colors`}
                >
                  {user.subscriptionTier === plan.tier && user.subscriptionActive 
                    ? 'Current Plan' 
                    : 'Upgrade'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="flex flex-col items-center gap-4">
            <Loader className="w-8 h-8 text-blue-500 animate-spin" />
            <p className="text-gray-400">Loading profile data...</p>
          </div>
        </div>
      );
    }

    switch (currentTab) {
      case 'settings':
        return (
          <>
            {(error || success) && (
              <div className={`mb-6 p-4 rounded-lg flex items-center gap-3 ${
                error ? 'bg-red-900/50 border-red-500 text-red-200' : 'bg-green-900/50 border-green-500 text-green-200'
              } border`}>
                {error ? <AlertCircle className="w-5 h-5" /> : <CheckCircle className="w-5 h-5" />}
                <p>{error || success}</p>
              </div>
            )}

            <form onSubmit={handleFormSubmit}>
              {/* Business Information (Editable) */}
              <ProfileSection 
                title="Business Information" 
                isEditing={isEditing}
                onEdit={handleEdit}
                onSave={handleFormSubmit}
                onCancel={handleCancel}
                description="This information will appear on your quotes and invoices sent to customers."
              >
                <div className="p-3 mb-4 bg-blue-900/20 border border-blue-800 rounded-md">
                  <div className="flex items-start">
                    <FileText className="h-5 w-5 text-blue-400 mr-2 mt-0.5" />
                    <p className="text-sm text-blue-200">
                      The information below will be displayed on quotes and invoices sent to your customers.
                      Make sure it accurately represents your business.
                    </p>
                  </div>
                </div>
                
                <div className="grid md:grid-cols-2 gap-4">
                  <InputField
                    label="Business Display Name"
                    name="displayName"
                    value={formData.displayName}
                    onChange={handleFormChange}
                    disabled={!isEditing}
                    placeholder="Enter your business display name"
                    description="Name displayed on quotes and invoices"
                  />
                  <InputField
                    label="Business Contact Email"
                    name="contactEmail"
                    type="email"
                    value={formData.contactEmail}
                    onChange={handleFormChange}
                    disabled={!isEditing}
                    placeholder="Enter your business contact email"
                    description="Email displayed on quotes and invoices"
                  />
                  <InputField
                    label="Business Phone Number"
                    name="contactPhone"
                    value={formData.contactPhone}
                    onChange={handleFormChange}
                    disabled={!isEditing}
                    placeholder="Enter your business phone number"
                    description="Phone number displayed on quotes and invoices"
                  />
                  <InputField
                    label="Customer Base Size"
                    name="customerBaseSize"
                    type="number"
                    value={formData.customerBaseSize}
                    onChange={handleFormChange}
                    disabled={!isEditing}
                    placeholder="Enter your customer base size"
                    description="Used to determine your subscription tier"
                  />
                  <InputField
                    label="Business Name"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleFormChange}
                    disabled={!isEditing}
                    placeholder="Enter your business name"
                    description="Legal business name for quotes and invoices"
                  />
                  <InputField
                    label="Business Website"
                    name="businessWebsite"
                    value={formData.businessWebsite}
                    onChange={handleFormChange}
                    disabled={!isEditing}
                    placeholder="Enter your business website"
                    description="Website URL displayed on documents"
                  />
                  <div className="md:col-span-2">
                    <InputField
                      label="Business Address"
                      name="businessAddress"
                      value={formData.businessAddress}
                      onChange={handleFormChange}
                      disabled={!isEditing}
                      placeholder="Enter your business address"
                      description="Physical address displayed on documents"
                    />
                  </div>
                </div>
              </ProfileSection>
              
              {/* Document Preview */}
              <div className="bg-gray-800 rounded-lg p-6 mb-6 border border-gray-700">
                <div className="flex items-center mb-4">
                  <FileText className="h-5 w-5 text-blue-400 mr-2" />
                  <h3 className="text-xl font-bold text-white">Document Preview</h3>
                </div>
                <p className="text-gray-400 text-sm mb-4">
                  This is how your business information will appear on quotes and invoices sent to customers.
                </p>
                
                <div className="bg-white p-6 rounded-md shadow-lg">
                  <div className="border-b border-gray-200 pb-4 mb-4">
                    <h4 className="text-xl font-bold text-gray-800">
                      {formData.displayName || "Your Business Name"}
                    </h4>
                    {formData.businessName && (
                      <p className="text-gray-600 text-sm">{formData.businessName}</p>
                    )}
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700">
                    <div>
                      <h5 className="font-semibold text-gray-900 mb-2">Contact Information</h5>
                      <div className="space-y-1">
                        {formData.contactEmail && (
                          <div className="flex items-center">
                            <Mail className="h-4 w-4 text-blue-500 mr-2" />
                            <span>{formData.contactEmail}</span>
                          </div>
                        )}
                        {formData.contactPhone && (
                          <div className="flex items-center">
                            <Phone className="h-4 w-4 text-blue-500 mr-2" />
                            <span>{formData.contactPhone}</span>
                          </div>
                        )}
                        {formData.businessWebsite && (
                          <div className="flex items-center">
                            <svg className="h-4 w-4 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                            </svg>
                            <span>{formData.businessWebsite}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    
                    <div>
                      {formData.businessAddress && (
                        <>
                          <h5 className="font-semibold text-gray-900 mb-2">Business Address</h5>
                          <p className="whitespace-pre-line">{formData.businessAddress}</p>
                        </>
                      )}
                    </div>
                  </div>
                  
                  <div className="mt-6 pt-4 border-t border-gray-200">
                    <div className="flex justify-between items-center">
                      <div>
                        <h5 className="font-semibold text-gray-900">INVOICE #12345</h5>
                        <p className="text-sm text-gray-600">Date: {new Date().toLocaleDateString()}</p>
                      </div>
                      <div className="text-right">
                        <div className="text-sm text-gray-600">Amount Due</div>
                        <div className="text-2xl font-bold text-gray-900">$149.99</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        );

      case 'membership':
        return (
          <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
            {renderSubscriptionDetails()}
          </div>
        );

      case 'payments':
        return (
          <div className="bg-gray-800 rounded-lg p-6 border border-gray-700">
            <div className="space-y-6">
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-2xl font-bold text-white">Stripe Connect</h3>
                  <div className="flex items-center space-x-2 px-3 py-1 rounded-full border border-gray-600">
                    <div className={`w-4 h-4 rounded-full ${stripeStatus.isOnboarded ? 'bg-green-500' : 'bg-red-500'}`}></div>
                    <span className={`text-sm font-medium ${stripeStatus.isOnboarded ? 'text-green-400' : 'text-red-400'}`}>
                      {stripeStatus.isOnboarded ? 'Stripe connected' : 'Stripe not connected'}
                    </span>
                  </div>
                </div>
                
                {stripeStatus.isOnboarded ? (
                  <div className="space-y-4">
                    <div className="flex items-start space-x-4 bg-gradient-to-r from-green-900/30 to-green-800/30 p-4 rounded-lg border border-green-700">
                      <div className="flex-shrink-0 mt-1">
                        <CheckCircle className="h-6 w-6 text-green-500" />
                      </div>
                      <div>
                        <h4 className="text-lg font-medium text-green-400 mb-1">Successfully Connected to Stripe</h4>
                        <p className="text-gray-300 mb-4">Your account is ready to accept payments from customers.</p>
                        <div className="flex flex-wrap gap-3">
                          <button
                            onClick={handleViewStripeAccount}
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center"
                          >
                            <CreditCardIcon className="h-5 w-5 mr-2" />
                            View Stripe Dashboard
                          </button>
                          {/* Remove the Complete Setup button since account is already onboarded */}
                          {/* For testing purposes only */}
                          <button
                            onClick={handleDisconnectStripe}
                            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                          >
                            Disconnect (Testing)
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className="flex items-start space-x-4 bg-gradient-to-r from-red-900/30 to-red-800/30 p-4 rounded-lg border border-red-700">
                      <div className="flex-shrink-0 mt-1">
                        <AlertCircle className="h-6 w-6 text-red-500" />
                      </div>
                      <div>
                        <h4 className="text-lg font-medium text-red-400 mb-1">Not Connected to Stripe</h4>
                        <p className="text-gray-300">Connect your Stripe account to start accepting payments from your customers.</p>
                      </div>
                    </div>
                    
                    <div className="bg-gradient-to-r from-blue-600/20 to-purple-600/20 p-4 rounded-lg">
                      <h4 className="text-lg font-medium text-white mb-2">Benefits of Stripe Connect</h4>
                      <ul className="list-disc list-inside space-y-2 text-gray-300">
                        <li>Accept credit card payments securely</li>
                        <li>Automatic payouts to your bank account</li>
                        <li>Real-time payment tracking</li>
                        <li>Professional invoicing</li>
                      </ul>
                    </div>
                    <button
                      onClick={handleConnectStripe}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors flex items-center space-x-2"
                    >
                      <CreditCardIcon className="h-5 w-5" />
                      <span>Connect with Stripe</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        );

      case 'usage':
        return (
          <>
            <div className="bg-gray-800 rounded-lg p-6 mb-6 border border-gray-700">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <ChartBar className="h-5 w-5 text-blue-400 mr-2" />
                  <h3 className="text-xl font-bold text-white">Current Usage Statistics</h3>
                </div>
                <button 
                  onClick={fetchUsageStats} 
                  className="text-blue-400 hover:text-blue-300 flex items-center"
                >
                  <RefreshCw className="h-4 w-4 mr-1" />
                  Refresh
                </button>
              </div>

              {usageLoading ? (
                <div className="flex items-center justify-center py-8">
                  <Loader className="w-6 h-6 text-blue-500 animate-spin" />
                </div>
              ) : usageError ? (
                <div className="bg-red-900/30 border border-red-500 text-red-200 p-4 rounded-md mb-4">
                  <p className="flex items-center">
                    <AlertCircle className="w-5 h-5 mr-2" />
                    {usageError}
                  </p>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                    {/* Route Optimization Usage Card */}
                    <div className="bg-gray-700/50 rounded-lg p-4 border border-gray-600">
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="font-medium text-gray-300 flex items-center">
                          <MapPin className="h-4 w-4 text-yellow-400 mr-2" />
                          Route Optimizations
                        </h4>
                        <span className={`text-xs px-2 py-0.5 rounded ${
                          usageData?.routeOptimization?.percentage >= 80 ? 'bg-red-500/20 text-red-300' : 
                          usageData?.routeOptimization?.percentage >= 50 ? 'bg-yellow-500/20 text-yellow-300' : 
                          'bg-green-500/20 text-green-300'
                        }`}>
                          {usageData?.routeOptimization?.percentage || 0}%
                        </span>
                      </div>
                      <div className="flex items-end justify-between">
                        <div className="text-2xl font-bold text-white">
                          {usageData?.routeOptimization?.used || 0} 
                          <span className="text-sm text-gray-400 ml-1">/{usageData?.routeOptimization?.limit || 10}</span>
                        </div>
                        <div className="text-sm text-gray-400">
                          {usageData?.routeOptimization?.remaining || 10} remaining
                        </div>
                      </div>
                      <div className="mt-2 w-full bg-gray-600 rounded-full h-2">
                        <div 
                          className={`h-2 rounded-full ${
                            usageData?.routeOptimization?.percentage >= 80 ? 'bg-red-500' : 
                            usageData?.routeOptimization?.percentage >= 50 ? 'bg-yellow-500' : 
                            'bg-green-500'
                          }`} 
                          style={{ width: `${usageData?.routeOptimization?.percentage || 0}%` }}
                        ></div>
                      </div>
                    </div>

                    {/* Customers Usage Card */}
                    <div className="bg-gray-700/50 rounded-lg p-4 border border-gray-600">
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="font-medium text-gray-300 flex items-center">
                          <Users className="h-4 w-4 text-blue-400 mr-2" />
                          Customers
                        </h4>
                        <span className={`text-xs px-2 py-0.5 rounded ${
                          usageData?.currentPeriod?.usage?.customers?.percentage >= 80 ? 'bg-red-500/20 text-red-300' : 
                          usageData?.currentPeriod?.usage?.customers?.percentage >= 50 ? 'bg-yellow-500/20 text-yellow-300' : 
                          'bg-green-500/20 text-green-300'
                        }`}>
                          {usageData?.currentPeriod?.usage?.customers?.percentage || 0}%
                        </span>
                      </div>
                      <div className="flex items-end justify-between">
                        <div className="text-2xl font-bold text-white">
                          {usageData?.currentPeriod?.usage?.customers?.used || 0} 
                          <span className="text-sm text-gray-400 ml-1">/{usageData?.currentPeriod?.usage?.customers?.limit || 25}</span>
                        </div>
                        <div className="text-sm text-gray-400">
                          {usageData?.currentPeriod?.usage?.customers?.limit - (usageData?.currentPeriod?.usage?.customers?.used || 0)} remaining
                        </div>
                      </div>
                      <div className="mt-2 w-full bg-gray-600 rounded-full h-2">
                        <div 
                          className={`h-2 rounded-full ${
                            usageData?.currentPeriod?.usage?.customers?.percentage >= 80 ? 'bg-red-500' : 
                            usageData?.currentPeriod?.usage?.customers?.percentage >= 50 ? 'bg-yellow-500' : 
                            'bg-green-500'
                          }`} 
                          style={{ width: `${usageData?.currentPeriod?.usage?.customers?.percentage || 0}%` }}
                        ></div>
                      </div>
                    </div>

                    {/* SMS Usage Card */}
                    <div className="bg-gray-700/50 rounded-lg p-4 border border-gray-600">
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="font-medium text-gray-300 flex items-center">
                          <MessageCircle className="h-4 w-4 text-green-400 mr-2" />
                          SMS Messages
                        </h4>
                        <span className={`text-xs px-2 py-0.5 rounded ${
                          usageData?.currentPeriod?.usage?.sms?.percentage >= 80 ? 'bg-red-500/20 text-red-300' : 
                          usageData?.currentPeriod?.usage?.sms?.percentage >= 50 ? 'bg-yellow-500/20 text-yellow-300' : 
                          'bg-green-500/20 text-green-300'
                        }`}>
                          {usageData?.currentPeriod?.usage?.sms?.percentage || 0}%
                        </span>
                      </div>
                      <div className="flex items-end justify-between">
                        <div className="text-2xl font-bold text-white">
                          {usageData?.currentPeriod?.usage?.sms?.used || 0} 
                          <span className="text-sm text-gray-400 ml-1">/{usageData?.currentPeriod?.usage?.sms?.limit || 150}</span>
                        </div>
                        <div className="text-sm text-gray-400">
                          {usageData?.currentPeriod?.usage?.sms?.limit - (usageData?.currentPeriod?.usage?.sms?.used || 0)} remaining
                        </div>
                      </div>
                      <div className="mt-2 w-full bg-gray-600 rounded-full h-2">
                        <div 
                          className={`h-2 rounded-full ${
                            usageData?.currentPeriod?.usage?.sms?.percentage >= 80 ? 'bg-red-500' : 
                            usageData?.currentPeriod?.usage?.sms?.percentage >= 50 ? 'bg-yellow-500' : 
                            'bg-green-500'
                          }`} 
                          style={{ width: `${usageData?.currentPeriod?.usage?.sms?.percentage || 0}%` }}
                        ></div>
                      </div>
                    </div>

                    {/* Email Usage Card */}
                    <div className="bg-gray-700/50 rounded-lg p-4 border border-gray-600">
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="font-medium text-gray-300 flex items-center">
                          <Mail className="h-4 w-4 text-blue-400 mr-2" />
                          Emails
                        </h4>
                        <span className={`text-xs px-2 py-0.5 rounded ${
                          usageData?.currentPeriod?.usage?.email?.percentage >= 80 ? 'bg-red-500/20 text-red-300' : 
                          usageData?.currentPeriod?.usage?.email?.percentage >= 50 ? 'bg-yellow-500/20 text-yellow-300' : 
                          'bg-green-500/20 text-green-300'
                        }`}>
                          {usageData?.currentPeriod?.usage?.email?.percentage || 0}%
                        </span>
                      </div>
                      <div className="flex items-end justify-between">
                        <div className="text-2xl font-bold text-white">
                          {usageData?.currentPeriod?.usage?.email?.used || 0} 
                          <span className="text-sm text-gray-400 ml-1">/{usageData?.currentPeriod?.usage?.email?.limit || 150}</span>
                        </div>
                        <div className="text-sm text-gray-400">
                          {usageData?.currentPeriod?.usage?.email?.limit - (usageData?.currentPeriod?.usage?.email?.used || 0)} remaining
                        </div>
                      </div>
                      <div className="mt-2 w-full bg-gray-600 rounded-full h-2">
                        <div 
                          className={`h-2 rounded-full ${
                            usageData?.currentPeriod?.usage?.email?.percentage >= 80 ? 'bg-red-500' : 
                            usageData?.currentPeriod?.usage?.email?.percentage >= 50 ? 'bg-yellow-500' : 
                            'bg-green-500'
                          }`} 
                          style={{ width: `${usageData?.currentPeriod?.usage?.email?.percentage || 0}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-white">Profile Settings</h1>
          <p className="mt-2 text-gray-400">Manage your account and business information</p>
        </div>

        {/* Tabs */}
        <div className="mb-6 border-b border-gray-700">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.key}
                  onClick={() => handleTabChange(tab.key)}
                  className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center ${
                    currentTab === tab.key
                      ? 'border-blue-500 text-blue-400'
                      : 'border-transparent text-gray-500 hover:text-gray-300 hover:border-gray-300'
                  }`}
                >
                  <Icon className="w-5 h-5 mr-2" />
                  {tab.name}
                </button>
              );
            })}
          </nav>
        </div>

        {/* Tab Content */}
        {renderContent()}
      </main>
    </div>
  );
};

export default Profile;
