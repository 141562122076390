import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import invoiceApi from '../api/invoiceApi';
import { 
  DocumentTextIcon, 
  PaperAirplaneIcon, 
  XCircleIcon,
  FunnelIcon,
  ArrowPathIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  ClockIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';
import { format, formatDistanceToNow } from 'date-fns';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const ConfirmationModal = ({ isOpen, closeModal, onConfirm, title, message, confirmText = 'Confirm', confirmColor = 'red' }) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog as="div" className="relative z-50" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-75" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white mb-4">
                {title}
              </Dialog.Title>

              <div className="mt-2">
                <p className="text-gray-300">{message}</p>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500 transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => {
                    onConfirm();
                    closeModal();
                  }}
                  className={`px-4 py-2 bg-${confirmColor}-600 text-white rounded-lg hover:bg-${confirmColor}-700 transition-colors`}
                >
                  {confirmText}
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

const SendInvoiceModal = ({ isOpen, closeModal, invoice, onSend, onVoid }) => {
  const [sendSMS, setSendSMS] = useState(true);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSend = async () => {
    setSending(true);
    setError('');
    setSuccess('');

    try {
      const response = await invoiceApi.sendInvoice(invoice._id, {
        sendSMS
      });

      setSuccess('Invoice sent successfully!');
      setTimeout(() => {
        closeModal();
        onSend();
      }, 1500);
    } catch (err) {
      setError(err.message || 'Failed to send invoice');
    } finally {
      setSending(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-xl bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center mb-4">
                  <Dialog.Title as="h3" className="text-lg font-medium text-white">
                    Invoice #{invoice?._id?.slice(-6) || 'Loading...'}
                  </Dialog.Title>
                  {invoice?.status === 'draft' && (
                    <button
                      onClick={() => onVoid(invoice._id)}
                      className="px-3 py-1 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center space-x-1"
                    >
                      <XCircleIcon className="h-4 w-4" />
                      <span>Void Invoice</span>
                    </button>
                  )}
                </div>

                <div className="space-y-4">
                  {/* Notification Options */}
                  <div className="flex space-x-4">
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={sendSMS}
                        onChange={(e) => setSendSMS(e.target.checked)}
                        className="form-checkbox h-4 w-4 text-blue-600 bg-gray-700 border-gray-600 rounded"
                      />
                      <span className="text-white">Send SMS Notification</span>
                    </label>
                  </div>

                  {/* Preview Panel */}
                  <div className="bg-gray-900 rounded-lg p-4 h-64 overflow-y-auto">
                    <div className="text-white">
                      <div className="text-gray-400 mb-2">To: {invoice?.customerId?.phone}</div>
                      <p>
                        New invoice #{invoice?._id?.slice(-6)} for ${invoice?.amount?.toFixed(2) || '0.00'}. 
                        Due: {invoice?.dueDate ? new Date(invoice.dueDate).toLocaleDateString() : 'Not set'}. 
                        Payment link will be included.
                      </p>
                      <p className="mt-2 text-gray-400">Note: Invoice will be sent via email automatically.</p>
                    </div>
                  </div>

                  {/* Recipient Information */}
                  <div className="bg-gray-700 rounded-lg p-4">
                    <h4 className="text-sm font-medium text-gray-300 mb-2">Recipient Information</h4>
                    <div className="grid grid-cols-2 gap-4 text-sm">
                      <div>
                        <span className="text-gray-400">Email: </span>
                        <span className="text-white">{invoice?.customerId?.email || 'Not available'}</span>
                      </div>
                      <div>
                        <span className="text-gray-400">Phone: </span>
                        <span className="text-white">{invoice?.customerId?.phone || 'Not available'}</span>
                      </div>
                    </div>
                  </div>

                  {/* Error/Success Messages */}
                  {error && (
                    <div className="bg-red-900/50 border border-red-500 text-red-200 px-4 py-2 rounded">
                      {error}
                    </div>
                  )}
                  {success && (
                    <div className="bg-green-900/50 border border-green-500 text-green-200 px-4 py-2 rounded">
                      {success}
                    </div>
                  )}

                  {/* Actions */}
                  <div className="flex justify-end space-x-3 mt-4">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleSend}
                      disabled={sending}
                      className={`px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500 flex items-center space-x-2 ${
                        sending ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {sending ? (
                        <>
                          <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent"></div>
                          <span>Sending...</span>
                        </>
                      ) : (
                        <>
                          <PaperAirplaneIcon className="h-4 w-4" />
                          <span>Send Invoice</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

// Add a new modal for marking invoices as paid
const MarkAsPaidModal = ({ isOpen, closeModal, invoice, onMarkAsPaid }) => {
  const [paymentMethod, setPaymentMethod] = useState('other');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleMarkAsPaid = async () => {
    setProcessing(true);
    setError('');
    setSuccess('');

    try {
      await invoiceApi.markAsPaid(invoice._id, paymentMethod);
      setSuccess('Invoice marked as paid successfully!');
      setTimeout(() => {
        closeModal();
        onMarkAsPaid();
      }, 1500);
    } catch (err) {
      setError(err.message || 'Failed to mark invoice as paid');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Mark Invoice as Paid
                </Dialog.Title>
                
                {invoice && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 mb-4">
                      Mark invoice #{invoice._id.substring(0, 8)} for ${invoice.amount.toFixed(2)} as paid.
                    </p>
                    
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Payment Method
                      </label>
                      <select
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      >
                        <option value="cash">Cash</option>
                        <option value="check">Check</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    
                    {error && (
                      <div className="mb-4 p-2 bg-red-100 text-red-700 rounded">
                        {error}
                      </div>
                    )}
                    
                    {success && (
                      <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">
                        {success}
                      </div>
                    )}
                  </div>
                )}

                <div className="mt-4 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    onClick={handleMarkAsPaid}
                    disabled={processing}
                  >
                    {processing ? 'Processing...' : 'Mark as Paid'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [filters, setFilters] = useState({
    status: '',
    startDate: '',
    endDate: ''
  });
  const [activeTab, setActiveTab] = useState('unpaid');
  const [sendingInvoice, setSendingInvoice] = useState(null);
  const [markingAsPaidInvoice, setMarkingAsPaidInvoice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: () => {},
    confirmText: '',
    confirmColor: 'red'
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetchInvoices();
  }, [filters]);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const data = await invoiceApi.getInvoices(filters);
      setInvoices(data);
      setError('');
    } catch (err) {
      setError('Failed to fetch invoices');
      console.error('Error fetching invoices:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSendInvoice = async (invoice) => {
    setSendingInvoice(invoice);
  };

  const handleVoidInvoice = async (invoiceId) => {
    setConfirmModal({
      isOpen: true,
      title: 'Void Invoice',
      message: 'Are you sure you want to void this invoice? This action cannot be undone.',
      confirmText: 'Void Invoice',
      confirmColor: 'red',
      onConfirm: async () => {
        try {
          setLoading(true);
          await invoiceApi.voidInvoice(invoiceId);
          setSuccess('Invoice voided successfully');
          fetchInvoices();
          setSendingInvoice(null);
        } catch (err) {
          setError('Failed to void invoice');
          console.error('Error voiding invoice:', err);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleMarkAsPaid = async (invoice) => {
    setMarkingAsPaidInvoice(invoice);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getStatusIcon = (status, paymentStatus) => {
    switch (status) {
      case 'paid':
        return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
      case 'sent':
        return <PaperAirplaneIcon className="h-5 w-5 text-blue-500" />;
      case 'draft':
        return <DocumentTextIcon className="h-5 w-5 text-gray-500" />;
      case 'void':
        return <XCircleIcon className="h-5 w-5 text-red-500" />;
      case 'overdue':
        return <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />;
      default:
        return <ClockIcon className="h-5 w-5 text-gray-500" />;
    }
  };

  const getStatusColor = (status, paymentStatus) => {
    switch (status) {
      case 'paid':
        return 'bg-green-100 text-green-800';
      case 'sent':
        return paymentStatus === 'processing' ? 'bg-yellow-100 text-yellow-800' : 'bg-blue-100 text-blue-800';
      case 'draft':
        return 'bg-gray-100 text-gray-800';
      case 'void':
        return 'bg-red-100 text-red-800';
      case 'overdue':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getPaymentStatusText = (status, paymentStatus) => {
    if (status === 'paid') return 'Paid';
    if (status === 'void') return 'Void';
    if (paymentStatus === 'processing') return 'Processing';
    if (status === 'overdue') return 'Overdue';
    if (status === 'sent') return 'Unpaid';
    return 'Draft';
  };

  const getInvoiceAge = (invoice) => {
    if (invoice.status === 'paid') {
      return `Paid ${formatDistanceToNow(new Date(invoice.paidAt))} ago`;
    }
    if (invoice.status === 'sent') {
      const age = formatDistanceToNow(new Date(invoice.createdAt));
      const dueIn = formatDistanceToNow(new Date(invoice.dueDate));
      if (new Date(invoice.dueDate) < new Date()) {
        return `Overdue by ${dueIn}`;
      }
      return `Outstanding for ${age}`;
    }
    return formatDistanceToNow(new Date(invoice.createdAt));
  };

  const getNotificationStatus = (invoice) => {
    const statuses = [];
    if (invoice.emailSent) statuses.push('Email sent');
    if (invoice.smsSent) statuses.push('SMS sent');
    if (statuses.length === 0) return 'No notifications sent';
    return statuses.join(', ');
  };

  const filteredInvoices = invoices.filter(invoice => {
    if (activeTab === 'paid') {
      return invoice.status === 'paid';
    } else {
      return invoice.status !== 'paid' && invoice.status !== 'void';
    }
  });

  const indexOfLastInvoice = currentPage * pageSize;
  const indexOfFirstInvoice = indexOfLastInvoice - pageSize;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
  const totalPages = Math.ceil(filteredInvoices.length / pageSize);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };

  const renderInvoiceTable = () => (
    <div className="bg-gray-800 rounded-lg shadow overflow-hidden">
      <table className="min-w-full divide-y divide-gray-700">
        <thead className="bg-gray-900">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Invoice
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Customer
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Amount
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Due Date
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Notifications
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Age
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-gray-800 divide-y divide-gray-700">
          {loading ? (
            <tr>
              <td colSpan="8" className="px-6 py-4 text-center text-gray-400">
                <div className="flex justify-center items-center space-x-2">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                  <span>Loading...</span>
                </div>
              </td>
            </tr>
          ) : filteredInvoices.length === 0 ? (
            <tr>
              <td colSpan="8" className="px-6 py-4 text-center text-gray-400">
                No invoices found
              </td>
            </tr>
          ) : (
            currentInvoices.map((invoice) => (
              <tr 
                key={invoice._id} 
                onClick={() => handleViewInvoice(invoice)}
                className="hover:bg-gray-700 cursor-pointer transition-colors group"
              >
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    {getStatusIcon(invoice.status, invoice.paymentStatus)}
                    <div className="ml-2">
                      <div className="font-medium text-white group-hover:text-blue-400 transition-colors">
                        {invoice.description}
                      </div>
                      <div className="text-sm text-gray-400">
                        #{invoice._id.slice(-6)}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-white group-hover:text-blue-400 transition-colors">
                    {invoice.customerId?.name || 'Unknown Customer'}
                  </div>
                  <div className="text-sm text-gray-400">{invoice.customerId?.email}</div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-white font-medium">
                    ${invoice.amount.toFixed(2)}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 text-xs font-medium rounded-full inline-flex items-center space-x-1
                    ${getStatusColor(invoice.status, invoice.paymentStatus)}`}
                  >
                    <span className="h-2 w-2 rounded-full bg-current"/>
                    <span>{getPaymentStatusText(invoice.status, invoice.paymentStatus)}</span>
                  </span>
                </td>
                <td className="px-6 py-4 text-white">
                  {new Date(invoice.dueDate).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">
                  <div className="text-white">
                    {getNotificationStatus(invoice)}
                    {invoice.lastReminderSent && (
                      <div className="text-sm text-gray-400">
                        Last reminder: {formatDistanceToNow(new Date(invoice.lastReminderSent))} ago
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="text-white">
                    {getInvoiceAge(invoice)}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="flex space-x-2">
                    {invoice.status === 'draft' && (
                      <>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSendInvoice(invoice);
                          }}
                          className="p-1 text-blue-400 hover:text-blue-300 transition-colors"
                          title="Send Invoice"
                        >
                          <PaperAirplaneIcon className="h-5 w-5" />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteInvoice(invoice._id);
                          }}
                          className="p-1 text-red-400 hover:text-red-300 transition-colors"
                          title="Delete Invoice"
                        >
                          <XCircleIcon className="h-5 w-5" />
                        </button>
                      </>
                    )}
                    {['sent', 'overdue'].includes(invoice.status) && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMarkAsPaid(invoice);
                        }}
                        className="p-1 text-green-400 hover:text-green-300 transition-colors"
                        title="Mark as Paid"
                      >
                        <CheckCircleIcon className="h-5 w-5" />
                      </button>
                    )}
                    {invoice.status === 'sent' && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleVoidInvoice(invoice._id);
                        }}
                        className="p-1 text-red-400 hover:text-red-300 transition-colors"
                        title="Void Invoice"
                      >
                        <XCircleIcon className="h-5 w-5" />
                      </button>
                    )}
                    {invoice.stripePaymentUrl && (
                      <a
                        href={invoice.stripePaymentUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                        className="p-1 text-green-400 hover:text-green-300 transition-colors"
                        title="View Payment Page"
                      >
                        <DocumentTextIcon className="h-5 w-5" />
                      </a>
                    )}
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      
      {filteredInvoices.length > 0 && (
        <div className="bg-gray-900 px-4 py-3 flex items-center justify-between border-t border-gray-700 sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className={`relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium rounded-md ${
                currentPage === 1 ? 'text-gray-500 bg-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-gray-700'
              }`}
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium rounded-md ${
                currentPage === totalPages ? 'text-gray-500 bg-gray-800' : 'text-gray-400 bg-gray-800 hover:bg-gray-700'
              }`}
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-400">
                Showing <span className="font-medium">{indexOfFirstInvoice + 1}</span> to{' '}
                <span className="font-medium">
                  {Math.min(indexOfLastInvoice, filteredInvoices.length)}
                </span>{' '}
                of <span className="font-medium">{filteredInvoices.length}</span> results
              </p>
            </div>
            <div className="flex items-center space-x-4">
              <select
                value={pageSize}
                onChange={handlePageSizeChange}
                className="bg-gray-700 border border-gray-600 text-white rounded-md px-2 py-1 text-sm"
              >
                <option value={5}>5 per page</option>
                <option value={10}>10 per page</option>
                <option value={25}>25 per page</option>
                <option value={50}>50 per page</option>
              </select>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-700 bg-gray-800 text-sm font-medium ${
                    currentPage === 1 ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 hover:bg-gray-700'
                  }`}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                  <button
                    key={number}
                    onClick={() => paginate(number)}
                    className={`relative inline-flex items-center px-4 py-2 border ${
                      currentPage === number
                        ? 'bg-blue-600 text-white border-blue-500'
                        : 'bg-gray-800 text-gray-400 border-gray-700 hover:bg-gray-700'
                    } text-sm font-medium`}
                  >
                    {number}
                  </button>
                ))}
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-700 bg-gray-800 text-sm font-medium ${
                    currentPage === totalPages ? 'text-gray-500 cursor-not-allowed' : 'text-gray-400 hover:bg-gray-700'
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const handleViewInvoice = (invoice) => {
    setConfirmModal({
      isOpen: true,
      title: `Invoice #${invoice._id.substring(0, 8)}`,
      message: (
        <div className="space-y-4 text-left">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-gray-400 text-sm">Customer</p>
              <p className="text-white font-medium">{invoice.customerId?.name || 'Unknown'}</p>
            </div>
            <div>
              <p className="text-gray-400 text-sm">Amount</p>
              <p className="text-white font-medium">${invoice.amount.toFixed(2)}</p>
            </div>
            <div>
              <p className="text-gray-400 text-sm">Status</p>
              <p className="flex items-center">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(invoice.status, invoice.paymentStatus)}`}>
                  {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                </span>
              </p>
            </div>
            <div>
              <p className="text-gray-400 text-sm">Due Date</p>
              <p className="text-white">{format(new Date(invoice.dueDate), 'MMM dd, yyyy')}</p>
            </div>
          </div>
          
          <div>
            <p className="text-gray-400 text-sm">Description</p>
            <p className="text-white">{invoice.description}</p>
          </div>
          
          {invoice.items && invoice.items.length > 0 && (
            <div>
              <p className="text-gray-400 text-sm mb-2">Items</p>
              <div className="bg-gray-900 rounded-md p-3">
                {invoice.items.map((item, index) => (
                  <div key={index} className="flex justify-between py-1 border-b border-gray-700 last:border-0">
                    <span className="text-white">{item.description}</span>
                    <span className="text-white">${(item.amount * (item.quantity || 1)).toFixed(2)}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {invoice.notes && (
            <div>
              <p className="text-gray-400 text-sm">Notes</p>
              <p className="text-white">{invoice.notes}</p>
            </div>
          )}
          
          <div className="flex justify-between pt-4 border-t border-gray-700">
            {invoice.status === 'draft' ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setConfirmModal({ isOpen: false });
                  handleDeleteInvoice(invoice._id);
                }}
                className="inline-flex items-center px-3 py-2 border border-red-500 text-sm leading-4 font-medium rounded-md text-red-400 bg-gray-800 hover:bg-red-900/30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <XCircleIcon className="mr-2 h-4 w-4" />
                Delete
              </button>
            ) : (
              <div></div>
            )}
            
            <div className="space-x-2">
              {invoice.status === 'draft' && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmModal({ isOpen: false });
                    handleSendInvoice(invoice);
                  }}
                  className="inline-flex items-center px-3 py-2 border border-blue-500 text-sm leading-4 font-medium rounded-md text-blue-400 bg-gray-800 hover:bg-blue-900/30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <PaperAirplaneIcon className="mr-2 h-4 w-4" />
                  Send
                </button>
              )}
              
              {(invoice.status === 'sent' || invoice.status === 'overdue') && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmModal({ isOpen: false });
                    handleMarkAsPaid(invoice);
                  }}
                  className="inline-flex items-center px-3 py-2 border border-green-500 text-sm leading-4 font-medium rounded-md text-green-400 bg-gray-800 hover:bg-green-900/30 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <CheckCircleIcon className="mr-2 h-4 w-4" />
                  Mark as Paid
                </button>
              )}
              
              <button
                onClick={() => setConfirmModal({ isOpen: false })}
                className="inline-flex items-center px-3 py-2 border border-gray-500 text-sm leading-4 font-medium rounded-md text-gray-400 bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ),
      confirmText: '',
      confirmColor: '',
      onConfirm: () => {}
    });
  };

  // Add function to handle invoice deletion
  const handleDeleteInvoice = async (invoiceId) => {
    try {
      setConfirmModal({
        isOpen: true,
        title: 'Delete Invoice',
        message: 'Are you sure you want to delete this invoice? This action cannot be undone.',
        confirmText: 'Delete',
        confirmColor: 'red',
        onConfirm: async () => {
          setLoading(true);
          await invoiceApi.deleteInvoice(invoiceId);
          setSuccess('Invoice deleted successfully');
          fetchInvoices();
          setConfirmModal({ isOpen: false });
        }
      });
    } catch (error) {
      setError(error.message || 'Failed to delete invoice');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen">
      <main className="py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold text-white">Invoices</h1>
          <button
            onClick={() => navigate('/jobs')}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Create New Invoice
          </button>
        </div>

        <div className="mb-6 bg-gray-800 rounded-lg p-4 border border-gray-700">
          <h2 className="text-lg font-medium text-white mb-3">Invoice Status Guide</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex items-center space-x-2">
              <span className="h-2 w-2 rounded-full bg-gray-400"/>
              <span className="text-gray-300">Draft - Not sent to customer</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="h-2 w-2 rounded-full bg-blue-400"/>
              <span className="text-gray-300">Sent - Awaiting payment</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="h-2 w-2 rounded-full bg-green-400"/>
              <span className="text-gray-300">Paid - Payment received</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="h-2 w-2 rounded-full bg-yellow-400"/>
              <span className="text-gray-300">Processing - Payment in progress</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="h-2 w-2 rounded-full bg-red-400"/>
              <span className="text-gray-300">Void - Invoice cancelled</span>
            </div>
            <div className="flex items-center space-x-2">
              <span className="h-2 w-2 rounded-full bg-orange-400"/>
              <span className="text-gray-300">Overdue - Past due date</span>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-700 mb-6">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => setActiveTab('unpaid')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'unpaid'
                  ? 'border-blue-500 text-blue-500'
                  : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
              }`}
            >
              Unpaid Invoices
            </button>
            <button
              onClick={() => setActiveTab('paid')}
              className={`py-4 px-1 border-b-2 font-medium text-sm ${
                activeTab === 'paid'
                  ? 'border-green-500 text-green-500'
                  : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'
              }`}
            >
              Paid Invoices
            </button>
          </nav>
        </div>

        <div className="bg-gray-800 rounded-lg p-4 mb-6">
          <div className="flex items-center space-x-4">
            <FunnelIcon className="h-5 w-5 text-gray-400" />
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="bg-gray-700 text-white rounded-lg px-3 py-2"
            >
              <option value="">All Statuses</option>
              <option value="draft">Draft</option>
              <option value="sent">Sent</option>
              <option value="paid">Paid</option>
              <option value="void">Void</option>
              <option value="overdue">Overdue</option>
            </select>
            <input
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
              className="bg-gray-700 text-white rounded-lg px-3 py-2"
            />
            <input
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
              className="bg-gray-700 text-white rounded-lg px-3 py-2"
            />
            <button
              onClick={fetchInvoices}
              className="p-2 text-gray-400 hover:text-white transition-colors"
            >
              <ArrowPathIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-600 text-white px-4 py-3 rounded-lg mb-4">
            {error}
          </div>
        )}
        {success && (
          <div className="bg-green-600 text-white px-4 py-3 rounded-lg mb-4">
            {success}
          </div>
        )}

        {renderInvoiceTable()}

        <SendInvoiceModal
          isOpen={!!sendingInvoice}
          closeModal={() => setSendingInvoice(null)}
          invoice={sendingInvoice}
          onSend={() => {
            fetchInvoices();
            setSendingInvoice(null);
          }}
          onVoid={handleVoidInvoice}
        />

        <MarkAsPaidModal
          isOpen={!!markingAsPaidInvoice}
          closeModal={() => setMarkingAsPaidInvoice(null)}
          invoice={markingAsPaidInvoice}
          onMarkAsPaid={() => {
            fetchInvoices();
            setMarkingAsPaidInvoice(null);
            setSuccess('Invoice marked as paid successfully');
          }}
        />

        <ConfirmationModal
          isOpen={confirmModal.isOpen}
          closeModal={() => setConfirmModal(prev => ({ ...prev, isOpen: false }))}
          title={confirmModal.title}
          message={confirmModal.message}
          onConfirm={confirmModal.onConfirm}
          confirmText={confirmModal.confirmText}
          confirmColor={confirmModal.confirmColor}
        />
      </main>
    </div>
  );
};

export default Invoices; 