import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, TimePicker, Button, Radio, Space, Col, Row, Divider, Switch } from 'antd';
import { CalendarOutlined, BankOutlined, FileTextOutlined, CheckSquareOutlined, ClockCircleOutlined, DeleteOutlined, EditOutlined, UserOutlined, ToolOutlined, DollarOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ChromePicker } from 'react-color';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const { Option } = Select;
const { TextArea } = Input;

/**
 * QuickAddModal component for quickly adding different types of items to the calendar
 * Supports adding jobs, tasks, notes, and general calendar events
 */
const QuickAddModal = ({ isOpen, onClose, date, onAddItem }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [itemType, setItemType] = useState('event');
  const [color, setColor] = useState('#52c41a'); // Default green color for events
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [duration, setDuration] = useState(60); // Default 60 minutes
  
  // For job creation
  const [customers, setCustomers] = useState([]);
  const [services, setServices] = useState([]);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringPattern, setRecurringPattern] = useState('weekly');
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [titleChanged, setTitleChanged] = useState(false);

  // Initialize the form with the provided date
  useEffect(() => {
    if (date) {
      const momentDate = moment(date);
      setSelectedDate(momentDate);
      
      // Set default start time to current time rounded to nearest 30 min
      const currentTime = moment();
      const minutes = currentTime.minutes();
      const roundedMinutes = Math.round(minutes / 30) * 30;
      const startTimeMoment = momentDate.clone().hours(currentTime.hours()).minutes(roundedMinutes);
      setStartTime(startTimeMoment);
      
      // Set default end time to 1 hour after start time
      const endTimeMoment = startTimeMoment.clone().add(duration, 'minutes');
      setEndTime(endTimeMoment);
      
      // Reset the form with the new values
      form.setFieldsValue({
        type: itemType,
        date: momentDate,
        startTime: startTimeMoment,
        duration: String(duration),
        priority: 'medium',
        isRecurring: false,
        recurringPattern: 'weekly'
      });
    }
  }, [date, form, duration, itemType]);
  
  // Fetch customers and services when in job mode
  useEffect(() => {
    if (itemType === 'job' && isOpen) {
      const fetchData = async () => {
        // Only fetch if not already loaded
        if (customers.length === 0) {
          await fetchCustomers();
        }
        if (services.length === 0) {
          await fetchServices();
        }
      };
      
      fetchData();
    }
  }, [itemType, isOpen]); // Remove customers.length and services.length from dependencies

  // Update end time when start time or duration changes
  useEffect(() => {
    if (startTime) {
      const newEndTime = startTime.clone().add(duration, 'minutes');
      setEndTime(newEndTime);
    }
  }, [startTime, duration]);
  
  // Update title when service changes if the user hasn't manually changed it
  useEffect(() => {
    if (itemType === 'job' && !titleChanged) {
      const serviceValue = form.getFieldValue('service');
      if (serviceValue) {
        form.setFieldsValue({ title: serviceValue });
      }
    }
  }, [form, itemType, titleChanged]);
  
  // Fetch customers for job creation
  const fetchCustomers = async () => {
    try {
      setLoadingCustomers(true);
      const response = await axiosInstance.get('/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setLoadingCustomers(false);
    }
  };
  
  // Fetch services for job creation
  const fetchServices = async () => {
    try {
      setLoadingServices(true);
      const response = await axiosInstance.get('/services');
      setServices(response.data);
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoadingServices(false);
    }
  };

  const handleTypeChange = (e) => {
    setItemType(e.target.value);
    setTitleChanged(false);
    
    // Set default color based on type
    switch (e.target.value) {
      case 'task':
        setColor('#1890ff'); // Blue for tasks
        break;
      case 'note':
        setColor('#722ed1'); // Purple for notes
        break;
      case 'event':
        setColor('#52c41a'); // Green for events
        break;
      case 'job':
        setColor('#fa8c16'); // Orange for jobs
        break;
      default:
        setColor('#1890ff');
    }
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };

  const handleDurationChange = (value) => {
    setDuration(Number(value));
  };
  
  const handleRecurringChange = (checked) => {
    setIsRecurring(checked);
    if (!checked) {
      setRecurringPattern('weekly');
      form.setFieldsValue({ recurringPattern: 'weekly' });
    }
  };
  
  const handleServiceChange = (value) => {
    // Update title to match service if user hasn't manually edited the title
    if (!titleChanged) {
      form.setFieldsValue({ title: value });
    }
  };
  
  const handleTitleChange = (e) => {
    const serviceValue = form.getFieldValue('service');
    // If title is different from service, mark it as changed by user
    if (e.target.value !== serviceValue) {
      setTitleChanged(true);
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(async values => {
      try {
        // Combine date and time
        const startDateTime = selectedDate
          .clone()
          .hours(startTime.hours())
          .minutes(startTime.minutes())
          .seconds(0);
        
        const endDateTime = startDateTime.clone().add(duration, 'minutes');
        
        // For job type, create the job directly
        if (itemType === 'job') {
          try {
            setLoadingServices(true); // Use loading indicator
            
            // First, fetch the customer details to get the address
            let customerDetails = null;
            try {
              const customerResponse = await axiosInstance.get(`/customers/${values.customer}`);
              customerDetails = customerResponse.data;
              console.log('Customer details:', customerDetails);
            } catch (error) {
              console.error('Error fetching customer details:', error);
              // Continue without customer details
            }
            
            // Format the date and day of week
            const scheduledDay = startDateTime.format('dddd');
            
            // Prepare job data
            const jobData = {
              title: values.title,
              service: values.service,
              customer: values.customer,
              description: values.description || '',
              estimatedDuration: duration,
              scheduledDay: scheduledDay,
              price: values.price || '',
              isRecurring: isRecurring,
              recurrencePattern: isRecurring ? values.recurringPattern : 'one-time',
              status: 'Pending'
            };
            
            // Add customer address if available
            if (customerDetails && customerDetails.address) {
              jobData.location = {
                address: customerDetails.address.street ? 
                  `${customerDetails.address.street || ''}, ${customerDetails.address.city || ''}, ${customerDetails.address.state || ''} ${customerDetails.address.zipCode || ''}`.trim() : 
                  'Address not available',
                coordinates: customerDetails.address.coordinates || []
              };
            }
            
            console.log('Creating job directly:', jobData);
            
            // Create the job
            const response = await axiosInstance.post('/jobs', jobData);
            const createdJob = response.data;
            
            // Schedule the job on the calendar if applicable
            if (isRecurring) {
              // Show the recurring job modal after creation (handled by onAddItem)
              onAddItem({
                type: 'job',
                jobId: createdJob._id,
                title: values.title,
                start: startDateTime.toDate(),
                isRecurring: true,
                recurrencePattern: values.recurringPattern,
                showRecurringModal: true,
                service: values.service,
                price: values.price
              });
            } else {
              // Show the duration modal for one-time job (handled by onAddItem)
              onAddItem({
                type: 'job',
                jobId: createdJob._id,
                title: values.title,
                start: startDateTime.toDate(),
                duration: duration,
                showDurationModal: true,
                service: values.service,
                price: values.price
              });
            }
            
            // Close the modal
            onClose();
            form.resetFields();
            setTitleChanged(false);
          } catch (error) {
            console.error('Error creating job:', error);
            // You could show an error message here
          } finally {
            setLoadingServices(false);
          }
          
          return;
        }
        
        // For other item types, create a calendar item
        const newItem = {
          type: itemType,
          title: values.title,
          description: values.description || '',
          start: startDateTime.toDate(),
          end: endDateTime.toDate(),
          color: color,
          priority: values.priority || 'medium'
        };
        
        console.log('Submitting new calendar item:', {
          type: itemType,
          title: values.title,
          color: color,
          priority: values.priority || 'medium',
          start: startDateTime.format('YYYY-MM-DD HH:mm:ss'),
          end: endDateTime.format('YYYY-MM-DD HH:mm:ss')
        });
        
        onAddItem(newItem);
        onClose();
        form.resetFields();
        setTitleChanged(false);
      } catch (error) {
        console.error('Error in form submission:', error);
        // Handle any unexpected errors
      }
    }).catch(formError => {
      console.error('Form validation failed:', formError);
      // Form validation failed
    });
  };

  const getModalTitle = () => {
    switch (itemType) {
      case 'task':
        return 'Add New Task';
      case 'note':
        return 'Add New Note';
      case 'event':
        return 'Add New Event';
      case 'job':
        return 'Create New Job';
      default:
        return 'Add Calendar Item';
    }
  };

  // Custom styling to match app theme
  const modalStyle = {
    content: {
      backgroundColor: '#1f2937', // Dark background
      color: '#e5e7eb' // Light text
    },
    header: {
      backgroundColor: '#111827',
      borderBottom: '1px solid #374151',
      color: '#f9fafb'
    },
    footer: {
      backgroundColor: '#111827',
      borderTop: '1px solid #374151'
    }
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', color: '#f9fafb' }}>
          <CalendarOutlined style={{ marginRight: 8 }} />
          {getModalTitle()}
        </div>
      }
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit} style={{ backgroundColor: '#3b82f6', borderColor: '#2563eb' }}>
          {itemType === 'job' ? 'Create Job' : 'Add Item'}
        </Button>
      ]}
      width={700}
      styles={{
        header: modalStyle.header,
        footer: modalStyle.footer,
        content: modalStyle.content,
        body: { backgroundColor: '#1f2937', color: '#e5e7eb', padding: '20px', maxHeight: '80vh', overflowY: 'auto' }
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          type: itemType,
          priority: 'medium',
          date: selectedDate,
          startTime: startTime,
          duration: '60',
          isRecurring: false,
          recurringPattern: 'weekly'
        }}
        style={{ color: '#e5e7eb' }}
      >
        <Form.Item label={<span style={{ color: '#e5e7eb' }}>Item Type</span>} name="type">
          <Radio.Group onChange={handleTypeChange} value={itemType} buttonStyle="solid">
            <Radio.Button value="event" style={{ backgroundColor: itemType === 'event' ? '#52c41a' : '#374151', color: '#fff' }}>
              <CalendarOutlined /> Event
            </Radio.Button>
            <Radio.Button value="task" style={{ backgroundColor: itemType === 'task' ? '#1890ff' : '#374151', color: '#fff' }}>
              <CheckSquareOutlined /> Task
            </Radio.Button>
            <Radio.Button value="note" style={{ backgroundColor: itemType === 'note' ? '#722ed1' : '#374151', color: '#fff' }}>
              <FileTextOutlined /> Note
            </Radio.Button>
            <Radio.Button value="job" style={{ backgroundColor: itemType === 'job' ? '#fa8c16' : '#374151', color: '#fff' }}>
              <BankOutlined /> Job
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {itemType === 'job' ? (
          // Job form fields
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ color: '#e5e7eb' }}><UserOutlined /> Customer</span>}
                  name="customer"
                  rules={[{ required: true, message: 'Please select a customer' }]}
                >
                  <Select
                    placeholder="Select a customer"
                    loading={loadingCustomers}
                    style={{ backgroundColor: '#374151', color: '#e5e7eb' }}
                    dropdownStyle={{ backgroundColor: '#1f2937' }}
                  >
                    {customers.map(customer => (
                      <Option key={customer._id} value={customer._id}>
                        {customer.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ color: '#e5e7eb' }}><ToolOutlined /> Service</span>}
                  name="service"
                  rules={[{ required: true, message: 'Please select a service' }]}
                >
                  <Select
                    placeholder="Select a service"
                    loading={loadingServices}
                    style={{ backgroundColor: '#374151', color: '#e5e7eb' }}
                    dropdownStyle={{ backgroundColor: '#1f2937' }}
                    onChange={handleServiceChange}
                  >
                    {services.map(service => (
                      <Option key={service._id} value={service.name}>
                        {service.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span style={{ color: '#e5e7eb' }}><DollarOutlined /> Price ($)</span>}
                  name="price"
                  rules={[{ required: true, message: 'Please enter a price' }]}
                >
                  <Input 
                    type="number"
                    placeholder="Enter price" 
                    style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={<span style={{ color: '#e5e7eb' }}>Job Title</span>} name="title" rules={[{ required: true, message: 'Please enter a title' }]}>
                  <Input 
                    placeholder="Enter job title" 
                    style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
                    onChange={handleTitleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            
            <Divider style={{ borderColor: '#4b5563', margin: '12px 0' }} />
            
            <Form.Item label={<span style={{ color: '#e5e7eb' }}>Is this a recurring job?</span>} name="isRecurring" valuePropName="checked">
              <Switch 
                checkedChildren="Yes" 
                unCheckedChildren="No" 
                onChange={handleRecurringChange}
                checked={isRecurring}
              />
            </Form.Item>
            
            {isRecurring && (
              <Form.Item
                label={<span style={{ color: '#e5e7eb' }}>Recurrence Pattern</span>}
                name="recurringPattern"
                rules={[{ required: isRecurring, message: 'Please select a recurrence pattern' }]}
              >
                <Select
                  style={{ backgroundColor: '#374151', color: '#e5e7eb' }}
                  dropdownStyle={{ backgroundColor: '#1f2937' }}
                  onChange={(value) => setRecurringPattern(value)}
                >
                  <Option value="weekly">Weekly</Option>
                  <Option value="biweekly">Bi-weekly</Option>
                  <Option value="monthly">Monthly</Option>
                </Select>
              </Form.Item>
            )}
          </>
        ) : (
          // Regular calendar item fields
          <Form.Item
            label={<span style={{ color: '#e5e7eb' }}>Title</span>}
            name="title"
            rules={[{ required: true, message: 'Please enter a title' }]}
          >
            <Input 
              placeholder={`Enter ${itemType} title`} 
              style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
            />
          </Form.Item>
        )}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={<span style={{ color: '#e5e7eb' }}>Date</span>} name="date">
              <DatePicker 
                style={{ width: '100%', backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }} 
                value={selectedDate} 
                onChange={setSelectedDate} 
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<span style={{ color: '#e5e7eb' }}>Start Time</span>} name="startTime">
              <TimePicker 
                style={{ width: '100%', backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }} 
                format="h:mm A" 
                use12Hours 
                minuteStep={5} 
                value={startTime} 
                onChange={handleStartTimeChange} 
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label={<span style={{ color: '#e5e7eb' }}>Duration</span>} name="duration">
              <Select 
                onChange={handleDurationChange} 
                value={String(duration)}
                style={{ backgroundColor: '#374151', color: '#e5e7eb' }}
                dropdownStyle={{ backgroundColor: '#1f2937' }}
              >
                <Option value="15">15 minutes</Option>
                <Option value="30">30 minutes</Option>
                <Option value="45">45 minutes</Option>
                <Option value="60">1 hour</Option>
                <Option value="90">1.5 hours</Option>
                <Option value="120">2 hours</Option>
                <Option value="180">3 hours</Option>
                <Option value="240">4 hours</Option>
                <Option value="300">5 hours</Option>
                <Option value="360">6 hours</Option>
                <Option value="480">8 hours</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {itemType !== 'job' && (
              <Form.Item label={<span style={{ color: '#e5e7eb' }}>Color</span>}>
                <div 
                  onClick={() => setShowColorPicker(!showColorPicker)} 
                  style={{ 
                    width: '100%', 
                    height: '32px', 
                    backgroundColor: color,
                    borderRadius: '2px',
                    cursor: 'pointer',
                    border: '1px solid #4b5563'
                  }} 
                />
                {showColorPicker && (
                  <div style={{ position: 'absolute', zIndex: 2 }}>
                    <div
                      style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                      onClick={() => setShowColorPicker(false)}
                    />
                    <ChromePicker
                      color={color}
                      onChange={(colorObj) => setColor(colorObj.hex)}
                      disableAlpha={true}
                    />
                  </div>
                )}
              </Form.Item>
            )}
          </Col>
        </Row>

        {itemType !== 'job' && (
          <Form.Item label={<span style={{ color: '#e5e7eb' }}>Priority</span>} name="priority">
            <Select
              style={{ backgroundColor: '#374151', color: '#e5e7eb' }}
              dropdownStyle={{ backgroundColor: '#1f2937' }}
            >
              <Option value="low">Low</Option>
              <Option value="medium">Medium</Option>
              <Option value="high">High</Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item label={<span style={{ color: '#e5e7eb' }}>Description</span>} name="description">
          <TextArea 
            rows={4} 
            placeholder={`Enter ${itemType} description (optional)`} 
            style={{ backgroundColor: '#374151', borderColor: '#4b5563', color: '#e5e7eb' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default QuickAddModal; 