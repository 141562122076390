import React, { useState, useEffect, useRef, useContext } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { 
  UserIcon, 
  EnvelopeIcon, 
  PhoneIcon, 
  MapPinIcon, 
  XMarkIcon,
  CheckIcon,
  PencilIcon,
  TrashIcon,
  PaperAirplaneIcon,
  DocumentTextIcon,
  ChatBubbleLeftRightIcon,
  ClockIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  BellIcon,
  ArrowPathIcon,
  BriefcaseIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import { PatternFormat } from 'react-number-format';
import { format, formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { NotificationSoundContext } from '../App';
import { useAuth } from '../contexts/AuthContext';

const CustomerModal = ({ customer, isOpen, onClose, onUpdate, onDelete }) => {
  const [activeTab, setActiveTab] = useState('details');
  const [isEditing, setIsEditing] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  
  // SMS Messages state
  const [messages, setMessages] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [messagesError, setMessagesError] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messagePage, setMessagePage] = useState(1);
  const [messagesPagination, setMessagesPagination] = useState({
    total: 0,
    pages: 1,
    page: 1,
    limit: 50
  });
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const [notificationSoundEnabled, setNotificationSoundEnabled] = useState(true);
  
  // Jobs state
  const [jobs, setJobs] = useState([]);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobsError, setJobsError] = useState('');
  
  // Email state
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  
  const modalRef = useRef(null);
  const messagesEndRef = useRef(null);
  const pollingIntervalRef = useRef(null);
  const lastMessageIdRef = useRef(null);
  const { playNotificationSound } = useContext(NotificationSoundContext);
  const { user } = useAuth();

  useEffect(() => {
    if (isOpen && customer) {
      setEditedCustomer({ ...customer });
      
      // Check if initialTab is set and valid
      if (customer.initialTab && ['details', 'messages', 'history', 'jobs'].includes(customer.initialTab)) {
        setActiveTab(customer.initialTab);
      }
      
      // If the active tab is messages, fetch messages
      if (activeTab === 'messages') {
        fetchMessages(false);
        startMessagesPolling();
      } else {
        stopMessagesPolling();
      }
      
      // If the active tab is jobs or history, fetch jobs
      if (activeTab === 'jobs' || activeTab === 'history') {
        fetchJobs();
      }
    }
    
    return () => {
      stopMessagesPolling();
    };
  }, [isOpen, customer, activeTab]);

  // Request notification permissions when modal opens
  useEffect(() => {
    if (isOpen && "Notification" in window && Notification.permission === "default") {
      // We'll show a button instead of auto-requesting to avoid browser restrictions
      console.log('Notification permission not granted yet');
    }
  }, [isOpen]);

  // Reset new message indicator when switching to messages tab
  useEffect(() => {
    if (activeTab === 'messages') {
      setHasNewMessages(false);
    }
  }, [activeTab]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Scroll to bottom of messages when new messages are loaded
  useEffect(() => {
    if (activeTab === 'messages' && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, activeTab]);
  
  // Start polling for new messages
  const startMessagesPolling = () => {
    // Clear any existing interval
    stopMessagesPolling();
    
    // Poll every 5 seconds for new messages (increased from 10 seconds)
    pollingIntervalRef.current = setInterval(() => {
      if (customer?._id) {
        fetchMessages(true); // true = silent refresh (no loading indicator)
      }
    }, 5000); // 5 seconds
    
    console.log('Started polling for new messages');
  };
  
  // Stop polling for new messages
  const stopMessagesPolling = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
      console.log('Stopped polling for new messages');
    }
  };

  const fetchMessages = async (silent = false) => {
    if (!customer?._id) return;
    
    if (!silent) {
      setMessagesLoading(true);
    }
    setMessagesError('');
    
    try {
      // Format the phone number to ensure it's consistent
      const phoneNumber = customer.phone ? customer.phone.replace(/\D/g, '') : '';
      
      console.log('Fetching messages for customer:', {
        id: customer._id,
        phone: customer.phone,
        formattedPhone: phoneNumber,
        page: messagePage
      });
      
      const response = await axiosInstance.get(`/notifications/sms/${customer._id}`, {
        params: { 
          page: messagePage, 
          limit: 50,
          phone: phoneNumber // Send the cleaned phone number
        }
      });
      
      console.log('Messages response:', {
        count: response.data.messages.length,
        pagination: response.data.pagination,
        firstMessageId: response.data.messages[0]?._id
      });
      
      // Store the ID of the most recent message for comparison
      if (response.data.messages.length > 0) {
        const newestMessageId = response.data.messages[0]._id;
        
        // Check if we have new messages by comparing with the last known message ID
        if (lastMessageIdRef.current && 
            lastMessageIdRef.current !== newestMessageId && 
            activeTab !== 'messages') {
          setHasNewMessages(true);
          
          // Only show desktop notification for inbound messages when not viewing the messages tab
          const newestMessage = response.data.messages[0];
          if (newestMessage.direction === 'inbound') {
            // Note: We don't play sound here anymore as it's handled by the global notification system
            showDesktopNotification(newestMessage);
          }
        }
        
        // Update the last message ID reference
        lastMessageIdRef.current = newestMessageId;
      }
      
      // Check if we have new messages by comparing the first message ID
      const hasNewMessages = 
        messages.length === 0 || 
        response.data.messages.length === 0 ||
        response.data.messages[0]?._id !== messages[0]?._id;
      
      if (hasNewMessages) {
        console.log('Setting new messages:', {
          oldCount: messages.length,
          newCount: response.data.messages.length,
          hasChanged: hasNewMessages
        });
        
        // Reverse the messages array to display oldest first
        const chronologicalMessages = [...response.data.messages].reverse();
        setMessages(chronologicalMessages);
        setMessagesPagination(response.data.pagination);
        
        // If we're on page 1 and there are new messages, scroll to bottom
        if (messagePage === 1) {
          setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 100);
        }
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      if (!silent) {
        setMessagesError('Failed to load messages: ' + (error.response?.data?.message || error.message));
      } else {
        console.error('Silent fetch messages error:', error);
      }
    } finally {
      if (!silent) {
        setMessagesLoading(false);
      }
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!newMessage.trim()) return;
    if (!customer.smsConsent || customer.smsOptOut) {
      setMessagesError('Cannot send SMS: Customer has not consented to SMS or has opted out');
      return;
    }
    
    setSendingMessage(true);
    setMessagesError('');
    
    try {
      const response = await axiosInstance.post(`/notifications/sms/${customer._id}`, {
        message: newMessage
      });
      
      // Add the new message to the messages list (at the end for chronological order)
      setMessages(prevMessages => [...prevMessages, response.data.smsMessage]);
      setNewMessage('');
      
      // Scroll to the bottom to show the new message
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
      
    } catch (error) {
      setMessagesError('Failed to send message: ' + (error.response?.data?.message || error.message));
    } finally {
      setSendingMessage(false);
    }
  };

  // Function to show desktop notification
  const showDesktopNotification = (message) => {
    // Check if browser supports notifications
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notifications");
      return;
    }
    
    // Check if permission is already granted
    if (Notification.permission === "granted") {
      createNotification(message);
    } 
    // Otherwise, request permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          createNotification(message);
        }
      });
    }
  };
  
  // Helper to create the notification
  const createNotification = (message) => {
    try {
      const notification = new Notification(`New message from ${customer.name}`, {
        body: message.body,
        icon: '/logo.png'
      });
      
      notification.onclick = () => {
        window.focus();
        setActiveTab('messages');
        notification.close();
      };
      
      // Auto close after 5 seconds
      setTimeout(() => {
        notification.close();
      }, 5000);
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  // Function to request notification permissions
  const requestNotificationPermission = () => {
    if ("Notification" in window) {
      Notification.requestPermission().then(permission => {
        console.log('Notification permission:', permission);
      });
    }
  };

  if (!isOpen || !customer) return null;

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setEditedCustomer(prev => ({ ...prev, [name]: checked }));
    } else if (name.includes('.')) {
      const [objectKey, nestedKey] = name.split('.');
      setEditedCustomer(prev => ({
        ...prev,
        [objectKey]: {
          ...prev[objectKey],
          [nestedKey]: value
        }
      }));
    } else {
      setEditedCustomer(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    
    try {
      const response = await axiosInstance.put(`/customers/${customer._id}`, editedCustomer);
      setSuccess('Customer updated successfully');
      setIsEditing(false);
      if (onUpdate) {
        onUpdate(response.data);
      }
    } catch (error) {
      setError('Failed to update customer: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCustomer = async () => {
    if (window.confirm('Are you sure you want to delete this customer? This action cannot be undone.')) {
      setLoading(true);
      setError('');
      
      try {
        await axiosInstance.delete(`/customers/${customer._id}`);
        setSuccess('Customer deleted successfully');
        setTimeout(() => {
          onClose();
          if (onDelete) {
            onDelete(customer._id);
          }
        }, 1500);
      } catch (error) {
        setError('Failed to delete customer: ' + (error.response?.data?.message || error.message));
      } finally {
        setLoading(false);
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return 'bg-green-100 text-green-800';
      case 'Inactive':
        return 'bg-yellow-100 text-yellow-800';
      case 'Discontinued':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  // Function to play notification sound
  const handlePlayNotificationSound = () => {
    if (notificationSoundEnabled) {
      playNotificationSound();
    }
  };

  // Function to fetch active jobs for the customer
  const fetchJobs = async () => {
    if (!customer?._id) return;
    
    setJobsLoading(true);
    setJobsError('');
    
    try {
      // Get all jobs for this customer, not just active ones
      const response = await axiosInstance.get(`/jobs`, {
        params: { 
          customer: customer._id,
          includeDetails: true // Request detailed job information
        }
      });
      
      console.log('Jobs response:', {
        count: response.data.length,
        jobs: response.data
      });
      
      // Sort jobs by date (newest first)
      const sortedJobs = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      
      setJobs(sortedJobs);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setJobsError('Failed to load jobs. Please try again.');
    } finally {
      setJobsLoading(false);
    }
  };

  // Add this new function to handle job completion
  const handleCompleteJob = async (jobId, isRecurring) => {
    try {
      setJobsLoading(true);
      setJobsError('');
      
      console.log('Completing job:', { jobId, isRecurring });
      
      const response = await axiosInstance.post(`/jobs/${jobId}/complete`, {
        isRecurring,
        completionDate: new Date().toISOString()
      });
      
      console.log('Job completion response:', response.data);
      
      // Update the job in the jobs list
      setJobs(prevJobs => 
        prevJobs.map(job => 
          job._id === jobId ? response.data : job
        )
      );
      
      // Force update customer analytics
      try {
        // First, trigger analytics calculation on the backend
        await axiosInstance.post(`/customers/${customer._id}/calculate-metrics`);
        
        // Then fetch updated customer data to refresh activity history and analytics
        const customerResponse = await axiosInstance.get(`/customers/${customer._id}`);
        if (customerResponse.data && onUpdate) {
          onUpdate(customerResponse.data);
        }
      } catch (error) {
        console.error('Error updating customer analytics:', error);
      }
      
      setSuccess(`Job ${isRecurring ? 'service completion recorded' : 'completed successfully'}. Check the Invoices tab for the new invoice.`);
    } catch (error) {
      console.error('Error completing job:', error);
      setJobsError('Failed to complete job: ' + (error.response?.data?.message || error.message));
    } finally {
      setJobsLoading(false);
    }
  };

  // Function to send an email to the customer
  const handleSendEmail = async () => {
    if (!customer?._id) return;
    
    // Validate inputs
    if (!emailSubject.trim()) {
      setEmailError('Please enter an email subject');
      return;
    }
    
    if (!emailBody.trim()) {
      setEmailError('Please enter an email message');
      return;
    }
    
    setSendingEmail(true);
    setEmailError('');
    setEmailSuccess('');
    
    try {
      // Use the user from the component scope
      const businessInfo = {
        name: user?.businessName || user?.name || 'Your Company',
        phoneNumber: user?.phoneNumber || ''
      };
      
      // Send the email with business info from auth context
      const response = await axiosInstance.post('/notifications/send', {
        customers: [customer],
        message: emailBody,
        subject: emailSubject,
        type: 'email',
        businessInfo
      });
      
      console.log('Email response:', response.data);
      
      // Show success message
      setEmailSuccess('Email sent successfully!');
      
      // Clear the form
      setEmailSubject('');
      setEmailBody('');
    } catch (error) {
      console.error('Error sending email:', error.response?.data?.message || error.message);
      setEmailError(error.response?.data?.message || 'Failed to send email. Please try again.');
    } finally {
      setSendingEmail(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4 overflow-y-auto">
      <div 
        ref={modalRef}
        className="bg-gray-800 rounded-xl shadow-2xl w-full max-w-4xl max-h-[90vh] flex flex-col"
      >
        {/* Modal Header */}
        <div className="p-6 border-b border-gray-700 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-white flex items-center">
            <UserIcon className="h-6 w-6 mr-2 text-blue-400" />
            {customer.name}
            <span className="ml-3 px-3 py-1 text-xs rounded-full bg-gray-700 text-gray-300">
              ID: #{customer._id.slice(-6)}
            </span>
          </h2>
          <div className="flex items-center">
            {/* Notification permission button */}
            {"Notification" in window && Notification.permission === "default" && (
              <button
                onClick={requestNotificationPermission}
                className="mr-4 px-3 py-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-700 transition-colors flex items-center"
                title="Enable notifications"
              >
                <BellIcon className="h-4 w-4 mr-1" />
                Enable Notifications
              </button>
            )}
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-700">
          <div className="flex">
            <button
              className={`px-6 py-3 font-medium text-sm ${
                activeTab === 'details'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('details')}
            >
              Customer Details
            </button>
            <button
              onClick={() => setActiveTab('messages')}
              className={`px-6 py-3 font-medium text-sm ${
                activeTab === 'messages'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              <span className="flex items-center">
                <ChatBubbleLeftRightIcon className="h-5 w-5 mr-2" />
                SMS Messages
                {hasNewMessages && (
                  <span className="ml-2 bg-red-500 text-white text-xs px-2 py-0.5 rounded-full animate-pulse">
                    New
                  </span>
                )}
              </span>
            </button>
            <button
              onClick={() => setActiveTab('email')}
              className={`px-6 py-3 font-medium text-sm ${
                activeTab === 'email'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
            >
              <span className="flex items-center">
                <EnvelopeIcon className="h-5 w-5 mr-2" />
                Email
              </span>
            </button>
            <button
              className={`px-6 py-3 font-medium text-sm ${
                activeTab === 'history'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('history')}
            >
              Activity History
            </button>
            <button
              className={`px-6 py-3 font-medium text-sm ${
                activeTab === 'jobs'
                  ? 'text-blue-400 border-b-2 border-blue-400'
                  : 'text-gray-400 hover:text-gray-300'
              }`}
              onClick={() => setActiveTab('jobs')}
            >
              Jobs
            </button>
          </div>
        </div>

        {/* Alerts */}
        {error && (
          <div className="m-4 p-3 bg-red-900/30 border border-red-500 text-red-400 rounded-lg flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 mr-2 flex-shrink-0" />
            {error}
          </div>
        )}
        {success && (
          <div className="m-4 p-3 bg-green-900/30 border border-green-500 text-green-400 rounded-lg flex items-center">
            <CheckIcon className="h-5 w-5 mr-2 flex-shrink-0" />
            {success}
          </div>
        )}

        {/* Tab Content */}
        <div className="flex-1 overflow-y-auto p-6">
          {activeTab === 'details' && (
            <div className="space-y-6">
              {/* Action Buttons */}
              <div className="flex justify-between items-center">
                <div className="flex space-x-3">
                  {isEditing ? (
                    <>
                      <button
                        onClick={handleSaveChanges}
                        disabled={loading}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center"
                      >
                        <CheckIcon className="h-5 w-5 mr-2" />
                        Save Changes
                      </button>
                      <button
                        onClick={() => {
                          setIsEditing(false);
                          setEditedCustomer({ ...customer });
                        }}
                        className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => setIsEditing(true)}
                        className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center"
                      >
                        <PencilIcon className="h-5 w-5 mr-2" />
                        Edit Customer
                      </button>
                      <Link
                        to={`/send-notifications?customers=${customer._id}`}
                        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center"
                      >
                        <EnvelopeIcon className="h-5 w-5 mr-2" />
                        Send Email
                      </Link>
                      <Link
                        to={`/jobs?tab=invoices&customer=${customer._id}`}
                        className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center"
                      >
                        <DocumentTextIcon className="h-5 w-5 mr-2" />
                        View Invoices
                      </Link>
                    </>
                  )}
                </div>
                <div className="flex space-x-3">
                  <button
                    onClick={handleDeleteCustomer}
                    disabled={loading}
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center"
                  >
                    <TrashIcon className="h-5 w-5 mr-2" />
                    Delete Customer
                  </button>
                </div>
              </div>

              {/* Customer Details Form */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Basic Info Section */}
                <div className="bg-gray-700/30 p-4 rounded-lg">
                  <h3 className="text-lg font-medium text-blue-400 mb-4">Basic Information</h3>
                  
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">Name</label>
                      {isEditing ? (
                        <input
                          type="text"
                          name="name"
                          value={editedCustomer.name}
                          onChange={handleInputChange}
                          className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                        />
                      ) : (
                        <div className="text-white">{customer.name}</div>
                      )}
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">Email</label>
                      {isEditing ? (
                        <input
                          type="email"
                          name="email"
                          value={editedCustomer.email}
                          onChange={handleInputChange}
                          className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                        />
                      ) : (
                        <div className="flex items-center text-white">
                          <EnvelopeIcon className="h-4 w-4 mr-2 text-gray-400" />
                          {customer.email}
                        </div>
                      )}
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">Phone</label>
                      {isEditing ? (
                        <PatternFormat
                          format="(###) ###-####"
                          mask="_"
                          name="phone"
                          value={editedCustomer.phone}
                          onValueChange={(values) => {
                            handleInputChange({
                              target: {
                                name: 'phone',
                                value: values.value
                              }
                            });
                          }}
                          className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                        />
                      ) : (
                        <div className="flex items-center text-white">
                          <PhoneIcon className="h-4 w-4 mr-2 text-gray-400" />
                          {customer.phone}
                        </div>
                      )}
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">Status</label>
                      {isEditing ? (
                        <select
                          name="status"
                          value={editedCustomer.status}
                          onChange={handleInputChange}
                          className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                          <option value="Discontinued">Discontinued</option>
                        </select>
                      ) : (
                        <div className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(customer.status)}`}>
                          {customer.status}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Address Section */}
                <div className="bg-gray-700/30 p-4 rounded-lg">
                  <h3 className="text-lg font-medium text-blue-400 mb-4">Address Information</h3>
                  
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">Street</label>
                      {isEditing ? (
                        <input
                          type="text"
                          name="address.street"
                          value={editedCustomer.address?.street || ''}
                          onChange={handleInputChange}
                          className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                        />
                      ) : (
                        <div className="text-white">{customer.address?.street}</div>
                      )}
                    </div>
                    
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-300 mb-1">City</label>
                        {isEditing ? (
                          <input
                            type="text"
                            name="address.city"
                            value={editedCustomer.address?.city || ''}
                            onChange={handleInputChange}
                            className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                          />
                        ) : (
                          <div className="text-white">{customer.address?.city}</div>
                        )}
                      </div>
                      
                      <div>
                        <label className="block text-sm font-medium text-gray-300 mb-1">State</label>
                        {isEditing ? (
                          <input
                            type="text"
                            name="address.state"
                            value={editedCustomer.address?.state || ''}
                            onChange={handleInputChange}
                            className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                          />
                        ) : (
                          <div className="text-white">{customer.address?.state}</div>
                        )}
                      </div>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">Zip Code</label>
                      {isEditing ? (
                        <input
                          type="text"
                          name="address.zipCode"
                          value={editedCustomer.address?.zipCode || ''}
                          onChange={handleInputChange}
                          className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                        />
                      ) : (
                        <div className="text-white">{customer.address?.zipCode}</div>
                      )}
                    </div>
                    
                    {!isEditing && (
                      <div className="mt-2">
                        <a 
                          href={`https://maps.google.com/?q=${encodeURIComponent(
                            `${customer.address?.street}, ${customer.address?.city}, ${customer.address?.state} ${customer.address?.zipCode}`
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 flex items-center"
                        >
                          <MapPinIcon className="h-4 w-4 mr-1" />
                          View on Map
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                {/* SMS Consent Section */}
                <div className="bg-gray-700/30 p-4 rounded-lg">
                  <h3 className="text-lg font-medium text-blue-400 mb-4">SMS Communication</h3>
                  
                  <div className="space-y-4">
                    {isEditing ? (
                      <>
                        <div>
                          <label className="flex items-center space-x-3">
                            <input
                              type="checkbox"
                              name="smsConsent"
                              checked={editedCustomer.smsConsent || false}
                              onChange={handleInputChange}
                              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-600 rounded"
                            />
                            <span className="text-white">SMS Consent</span>
                          </label>
                        </div>
                        
                        <div>
                          <label className="flex items-center space-x-3">
                            <input
                              type="checkbox"
                              name="smsOptOut"
                              checked={editedCustomer.smsOptOut || false}
                              onChange={handleInputChange}
                              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-600 rounded"
                            />
                            <span className="text-white">Opted Out</span>
                          </label>
                        </div>
                        
                        <div>
                          <label className="block text-sm font-medium text-gray-300 mb-1">Consent Source</label>
                          <select
                            name="smsConsentSource"
                            value={editedCustomer.smsConsentSource || 'web_form'}
                            onChange={handleInputChange}
                            className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                          >
                            <option value="web_form">Web Form</option>
                            <option value="service_agreement">Service Agreement</option>
                            <option value="verbal">Verbal Consent</option>
                          </select>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex items-center space-x-2">
                          <span className="text-gray-300">SMS Status:</span>
                          {customer.smsConsent && !customer.smsOptOut ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-900 text-green-200">
                              <CheckCircleIcon className="h-4 w-4 mr-1" />
                              Enabled
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-900 text-red-200">
                              <XCircleIcon className="h-4 w-4 mr-1" />
                              Disabled
                            </span>
                          )}
                        </div>
                        
                        {customer.smsConsent && (
                          <div className="text-gray-300">
                            <span className="text-gray-400">Consent Date:</span>{' '}
                            {customer.smsConsentDate ? format(new Date(customer.smsConsentDate), 'MMM d, yyyy') : 'Not recorded'}
                          </div>
                        )}
                        
                        {customer.smsOptOut && (
                          <div className="text-gray-300">
                            <span className="text-gray-400">Opt-out Date:</span>{' '}
                            {customer.smsOptOutDate ? format(new Date(customer.smsOptOutDate), 'MMM d, yyyy') : 'Not recorded'}
                          </div>
                        )}
                        
                        <div className="text-gray-300">
                          <span className="text-gray-400">Consent Source:</span>{' '}
                          {customer.smsConsentSource === 'web_form' ? 'Web Form' : 
                           customer.smsConsentSource === 'service_agreement' ? 'Service Agreement' : 
                           customer.smsConsentSource === 'verbal' ? 'Verbal Consent' : 'Not specified'}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* Notes Section */}
                <div className="bg-gray-700/30 p-4 rounded-lg">
                  <h3 className="text-lg font-medium text-blue-400 mb-4">Notes</h3>
                  
                  {isEditing ? (
                    <textarea
                      name="notes"
                      value={editedCustomer.notes || ''}
                      onChange={handleInputChange}
                      rows="5"
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg p-2 text-white"
                      placeholder="Add notes about this customer..."
                    ></textarea>
                  ) : (
                    <div className="text-white whitespace-pre-wrap">
                      {customer.notes || 'No notes available.'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'messages' && (
            <div className="space-y-4">
              {/* SMS Consent Warning */}
              {(!customer.smsConsent || customer.smsOptOut) && (
                <div className="bg-yellow-900/30 border border-yellow-500/50 text-yellow-400 p-4 rounded-lg flex items-start">
                  <ExclamationCircleIcon className="h-5 w-5 mr-2 flex-shrink-0 mt-0.5" />
                  <div>
                    <p className="font-medium">SMS Communication Disabled</p>
                    <p className="text-sm mt-1">
                      {customer.smsOptOut 
                        ? 'This customer has opted out of SMS communications.' 
                        : 'This customer has not provided consent for SMS communications.'}
                    </p>
                    <button
                      onClick={() => {
                        setActiveTab('details');
                        setIsEditing(true);
                      }}
                      className="text-yellow-400 underline text-sm mt-2 hover:text-yellow-300"
                    >
                      Edit SMS preferences
                    </button>
                  </div>
                </div>
              )}

              {/* Debug Info */}
              <div className="bg-gray-700/30 border border-gray-700 p-2 rounded-lg mb-4 text-xs text-gray-400">
                <div><span className="font-medium">Customer ID:</span> {customer._id}</div>
                <div><span className="font-medium">Phone:</span> {customer.phone}</div>
                <div><span className="font-medium">Formatted Phone:</span> {customer.phone ? customer.phone.replace(/\D/g, '') : 'None'}</div>
              </div>

              {/* Controls */}
              <div className="flex justify-between items-center">
                <button
                  onClick={() => fetchMessages(false)}
                  className="px-3 py-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-700 transition-colors flex items-center"
                  title="Refresh messages"
                >
                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                  Refresh Messages
                </button>
              </div>

              {/* Error Message */}
              {messagesError && (
                <div className="bg-red-900/30 border border-red-500/50 text-red-400 p-4 rounded-lg flex items-center">
                  <ExclamationCircleIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                  {messagesError}
                </div>
              )}

              {/* Messages Container */}
              <div className="bg-gray-700/30 rounded-lg border border-gray-700 h-96 flex flex-col">
                {/* Messages List */}
                <div className="flex-1 overflow-y-auto p-4 space-y-4">
                  {messagesLoading && messages.length === 0 ? (
                    <div className="flex justify-center items-center h-full">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                  ) : messages.length === 0 ? (
                    <div className="flex flex-col justify-center items-center h-full text-gray-400">
                      <ChatBubbleLeftRightIcon className="h-12 w-12 mb-3" />
                      <p>No messages yet</p>
                      {customer.smsConsent && !customer.smsOptOut && (
                        <p className="text-sm mt-2">Send a message to start the conversation</p>
                      )}
                    </div>
                  ) : (
                    <>
                      {/* Pagination */}
                      {messagesPagination.pages > 1 && (
                        <div className="flex justify-center mb-4">
                          <div className="inline-flex rounded-md shadow-sm">
                            <button
                              onClick={() => setMessagePage(prev => Math.max(prev - 1, 1))}
                              disabled={messagePage === 1 || messagesLoading}
                              className="px-3 py-1 text-sm font-medium text-gray-300 bg-gray-700 rounded-l-md border border-gray-600 hover:bg-gray-600 disabled:opacity-50"
                            >
                              Previous
                            </button>
                            <span className="px-3 py-1 text-sm font-medium text-gray-300 bg-gray-800 border-t border-b border-gray-600">
                              Page {messagePage} of {messagesPagination.pages}
                            </span>
                            <button
                              onClick={() => setMessagePage(prev => Math.min(prev + 1, messagesPagination.pages))}
                              disabled={messagePage === messagesPagination.pages || messagesLoading}
                              className="px-3 py-1 text-sm font-medium text-gray-300 bg-gray-700 rounded-r-md border border-gray-600 hover:bg-gray-600 disabled:opacity-50"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      )}

                      {/* Messages - now in chronological order (oldest first) */}
                      {messages.map((message) => (
                        <div 
                          key={message._id} 
                          className={`flex ${message.direction === 'outbound' ? 'justify-end' : 'justify-start'}`}
                        >
                          <div 
                            className={`max-w-[75%] rounded-lg px-4 py-2 ${
                              message.direction === 'outbound' 
                                ? 'bg-blue-600 text-white' 
                                : 'bg-gray-700 text-gray-200'
                            }`}
                          >
                            <div className="text-sm">{message.body}</div>
                            <div className="text-xs mt-1 opacity-75">
                              {format(new Date(message.createdAt), 'MMM d, h:mm a')}
                              {message.direction === 'outbound' && (
                                <span className="ml-2">
                                  {message.status === 'delivered' ? (
                                    <span className="text-green-300">✓ Delivered</span>
                                  ) : message.status === 'failed' ? (
                                    <span className="text-red-300">Failed</span>
                                  ) : (
                                    <span>Sent</span>
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div ref={messagesEndRef} />
                    </>
                  )}
                </div>

                {/* Message Input */}
                <div className="border-t border-gray-700 p-3">
                  <form onSubmit={handleSendMessage} className="flex items-center">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      disabled={!customer.smsConsent || customer.smsOptOut || sendingMessage}
                      placeholder={
                        !customer.smsConsent || customer.smsOptOut
                          ? "SMS communication is disabled"
                          : "Type a message..."
                      }
                      className="flex-1 bg-gray-700 border border-gray-600 rounded-l-lg p-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <button
                      type="submit"
                      disabled={!customer.smsConsent || customer.smsOptOut || !newMessage.trim() || sendingMessage}
                      className="bg-blue-600 text-white px-4 py-2 rounded-r-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {sendingMessage ? (
                        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white"></div>
                      ) : (
                        <PaperAirplaneIcon className="h-5 w-5" />
                      )}
                    </button>
                  </form>
                </div>
              </div>

              {/* SMS Usage Info */}
              <div className="text-sm text-gray-400">
                <p>
                  <span className="font-medium">Note:</span> SMS messages are charged according to your subscription plan.
                  Standard message rates apply.
                </p>
              </div>
            </div>
          )}

          {activeTab === 'email' && (
            <div className="space-y-6 p-6">
              {/* Email Form */}
              <div className="bg-gray-700/30 p-6 rounded-lg">
                <div className="flex items-center mb-6">
                  <div className="w-10 h-10 rounded-full bg-blue-600 flex items-center justify-center flex-shrink-0 mr-4">
                    <EnvelopeIcon className="h-5 w-5 text-white" />
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-white">Send Email to {customer.name}</h3>
                    <p className="text-gray-400 text-sm">Email will be sent to: {customer.email}</p>
                  </div>
                </div>
                
                <div className="space-y-4">
                  {/* Email Subject */}
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">Subject</label>
                    <input
                      type="text"
                      value={emailSubject}
                      onChange={(e) => setEmailSubject(e.target.value)}
                      placeholder="Enter email subject"
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  {/* Email Body */}
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">Message</label>
                    <textarea
                      value={emailBody}
                      onChange={(e) => setEmailBody(e.target.value)}
                      rows="8"
                      placeholder="Enter your message here..."
                      className="w-full bg-gray-700 border border-gray-600 rounded-lg p-3 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    ></textarea>
                  </div>

                  {/* Email Error */}
                  {emailError && (
                    <div className="p-3 bg-red-900/30 border border-red-500 text-red-400 rounded-lg flex items-center">
                      <ExclamationCircleIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                      {emailError}
                    </div>
                  )}

                  {/* Email Success */}
                  {emailSuccess && (
                    <div className="p-3 bg-green-900/30 border border-green-500 text-green-400 rounded-lg flex items-center">
                      <CheckIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                      {emailSuccess}
                    </div>
                  )}

                  {/* Send Email Button */}
                  <div className="flex justify-end mt-6">
                    <button
                      onClick={handleSendEmail}
                      disabled={sendingEmail}
                      className={`px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center ${
                        sendingEmail ? 'opacity-75 cursor-not-allowed' : ''
                      }`}
                    >
                      {sendingEmail ? (
                        <>
                          <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mr-2"></div>
                          Sending...
                        </>
                      ) : (
                        <>
                          <PaperAirplaneIcon className="h-5 w-5 mr-2" />
                          Send Email
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Email Templates */}
              <div className="bg-gray-700/30 p-6 rounded-lg">
                <h3 className="text-lg font-medium text-blue-400 mb-4">Email Templates</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <button
                    onClick={() => {
                      setEmailSubject('Thank you for your business');
                      setEmailBody(`Dear ${customer.name},\n\nThank you for choosing our services. We appreciate your business and look forward to serving you again in the future.\n\nBest regards,\nYour Company`);
                    }}
                    className="p-4 bg-gray-800 rounded-lg border border-gray-700 hover:border-blue-500 transition-colors text-left"
                  >
                    <h4 className="text-white font-medium mb-1">Thank You</h4>
                    <p className="text-gray-400 text-sm">A simple thank you message for your customer</p>
                  </button>
                  
                  <button
                    onClick={() => {
                      setEmailSubject('Your upcoming service appointment');
                      setEmailBody(`Dear ${customer.name},\n\nThis is a reminder about your upcoming service appointment. Please let us know if you need to reschedule or have any questions.\n\nBest regards,\nYour Company`);
                    }}
                    className="p-4 bg-gray-800 rounded-lg border border-gray-700 hover:border-blue-500 transition-colors text-left"
                  >
                    <h4 className="text-white font-medium mb-1">Appointment Reminder</h4>
                    <p className="text-gray-400 text-sm">Remind customers about upcoming appointments</p>
                  </button>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'history' && (
            <div className="space-y-6">
              <div className="bg-gray-700/30 p-4 rounded-lg">
                <h3 className="text-lg font-medium text-blue-400 mb-4">Status History</h3>
                
                {customer.statusHistory && customer.statusHistory.length > 0 ? (
                  <div className="space-y-4">
                    {customer.statusHistory.map((statusChange, index) => (
                      <div key={index} className="flex items-start">
                        <div className={`w-3 h-3 mt-1.5 rounded-full flex-shrink-0 ${
                          statusChange.status === 'Active' ? 'bg-green-500' :
                          statusChange.status === 'Inactive' ? 'bg-yellow-500' :
                          'bg-red-500'
                        }`}></div>
                        <div className="ml-4">
                          <div className="flex items-center">
                            <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                              statusChange.status === 'Active' ? 'bg-green-100 text-green-800' :
                              statusChange.status === 'Inactive' ? 'bg-yellow-100 text-yellow-800' :
                              'bg-red-100 text-red-800'
                            }`}>
                              {statusChange.status}
                            </span>
                            <span className="ml-2 text-sm text-gray-400">
                              {format(new Date(statusChange.date), 'MMM d, yyyy')}
                            </span>
                          </div>
                          {statusChange.reason && (
                            <p className="mt-1 text-sm text-gray-300">{statusChange.reason}</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-400">No status changes recorded.</p>
                )}
              </div>

              <div className="bg-gray-700/30 p-4 rounded-lg">
                <h3 className="text-lg font-medium text-blue-400 mb-4">Customer Analytics</h3>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="text-sm font-medium text-gray-300 mb-2">Financial</h4>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-400">Total Spent:</span>
                        <span className="text-white font-medium">${customer.analytics?.totalSpent?.toFixed(2) || '0.00'}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Lifetime Value:</span>
                        <span className="text-white font-medium">${customer.analytics?.lifetimeValue?.toFixed(2) || '0.00'}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Avg. Job Value:</span>
                        <span className="text-white font-medium">${customer.analytics?.averageJobValue?.toFixed(2) || '0.00'}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <h4 className="text-sm font-medium text-gray-300 mb-2">Service</h4>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="text-gray-400">Jobs Completed:</span>
                        <span className="text-white font-medium">{customer.analytics?.jobCount || 0}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Last Service:</span>
                        <span className="text-white font-medium">
                          {customer.analytics?.lastJobDate 
                            ? format(new Date(customer.analytics.lastJobDate), 'MMM d, yyyy')
                            : 'Never'}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Customer Since:</span>
                        <span className="text-white font-medium">
                          {customer.startDate 
                            ? format(new Date(customer.startDate), 'MMM d, yyyy')
                            : format(new Date(customer.createdAt), 'MMM d, yyyy')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-700/30 p-4 rounded-lg">
                <h3 className="text-lg font-medium text-blue-400 mb-4">Recent Activity</h3>
                
                <div className="space-y-4">
                  {/* Job Completions */}
                  {jobs.filter(job => job.status === 'completed' || job.completionCount > 0).length > 0 ? (
                    jobs
                      .filter(job => job.status === 'completed' || job.completionCount > 0)
                      .sort((a, b) => new Date(b.lastServiceDate || b.completedAt || b.updatedAt) - new Date(a.lastServiceDate || a.completedAt || a.updatedAt))
                      .slice(0, 5) // Show only the 5 most recent completions
                      .map((job, index) => (
                        <div key={`${job._id}-${index}`} className="flex items-start">
                          <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center flex-shrink-0">
                            <BriefcaseIcon className="h-4 w-4 text-white" />
                          </div>
                          <div className="ml-4">
                            <p className="text-white">
                              {job.isRecurring ? `Service Completed (${job.completionCount || 1}x)` : 'Job Completed'}: {job.service || 'Untitled Job'}
                            </p>
                            <p className="text-sm text-gray-400">
                              {format(new Date(job.lastServiceDate || job.completedAt || job.updatedAt), 'MMM d, yyyy')}
                            </p>
                          </div>
                        </div>
                      ))
                  ) : customer.analytics?.lastJobDate ? (
                    <div className="flex items-start">
                      <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center flex-shrink-0">
                        <DocumentTextIcon className="h-4 w-4 text-white" />
                      </div>
                      <div className="ml-4">
                        <p className="text-white">Service Completed</p>
                        <p className="text-sm text-gray-400">
                          {format(new Date(customer.analytics.lastJobDate), 'MMM d, yyyy')}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  
                  {/* Status Changes */}
                  {customer.statusHistory && customer.statusHistory.length > 0 && (
                    customer.statusHistory
                      .slice(-2) // Show only the 2 most recent status changes
                      .map((statusChange, index) => (
                        <div key={`status-${index}`} className="flex items-start">
                          <div className={`w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0 ${
                            statusChange.status === 'Active' ? 'bg-green-600' :
                            statusChange.status === 'Inactive' ? 'bg-yellow-600' :
                            'bg-red-600'
                          }`}>
                            <ClockIcon className="h-4 w-4 text-white" />
                          </div>
                          <div className="ml-4">
                            <p className="text-white">Status Changed to {statusChange.status}</p>
                            <p className="text-sm text-gray-400">
                              {format(new Date(statusChange.date), 'MMM d, yyyy')}
                            </p>
                            {statusChange.reason && (
                              <p className="text-sm text-gray-500 italic">"{statusChange.reason}"</p>
                            )}
                          </div>
                        </div>
                      ))
                  )}
                  
                  {/* Customer Creation */}
                  <div className="flex items-start">
                    <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center flex-shrink-0">
                      <UserIcon className="h-4 w-4 text-white" />
                    </div>
                    <div className="ml-4">
                      <p className="text-white">Customer Created</p>
                      <p className="text-sm text-gray-400">
                        {format(new Date(customer.createdAt), 'MMM d, yyyy')}
                      </p>
                    </div>
                  </div>
                  
                  {/* No Activity Fallback */}
                  {jobs.filter(job => job.status === 'completed' || job.completionCount > 0).length === 0 && 
                   !customer.analytics?.lastJobDate && 
                   (!customer.statusHistory || customer.statusHistory.length === 0) && (
                    <p className="text-gray-400">No additional activity recorded.</p>
                  )}
                </div>
              </div>
            </div>
          )}

          {activeTab === 'jobs' && (
            <div className="space-y-6 p-6">
              {jobsError && (
                <div className="p-3 bg-red-900/30 border border-red-500 text-red-400 rounded-lg flex items-center">
                  <ExclamationCircleIcon className="h-5 w-5 mr-2 flex-shrink-0" />
                  {jobsError}
                </div>
              )}
              
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold text-white">Customer Jobs</h2>
                <Link
                  to={`/jobs?customer=${customer._id}`}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center"
                >
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Create Job
                </Link>
              </div>
              
              {jobsLoading ? (
                <div className="flex justify-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              ) : jobs.length > 0 ? (
                <div className="space-y-6">
                  {jobs.map((job) => (
                    <div key={job._id} className="bg-gray-800 rounded-lg p-6 border border-gray-700 hover:border-blue-500/50 transition-all duration-200">
                      <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center">
                          <div className="w-12 h-12 rounded-full bg-blue-600 flex items-center justify-center flex-shrink-0 mr-4">
                            <BriefcaseIcon className="h-6 w-6 text-white" />
                          </div>
                          <div>
                            <h3 className="text-xl font-bold text-white">{job.service || 'Untitled Job'}</h3>
                            <div className="flex items-center mt-1">
                              <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                job.status === 'completed' ? 'bg-green-900/50 text-green-400' :
                                job.status === 'in_progress' ? 'bg-blue-900/50 text-blue-400' :
                                job.status === 'scheduled' ? 'bg-yellow-900/50 text-yellow-400' :
                                'bg-gray-700 text-gray-400'
                              }`}>
                                {job.status === 'in_progress' ? 'In Progress' : 
                                 job.status ? job.status.charAt(0).toUpperCase() + job.status.slice(1) : 'Unknown'}
                              </span>
                              {job.createdAt && (
                                <span className="ml-3 text-gray-400 text-xs">
                                  Created {format(new Date(job.createdAt), 'MMM d, yyyy')}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="text-2xl font-bold text-white">${job.price?.toFixed(2) || '0.00'}</div>
                      </div>
                      
                      {job.description && (
                        <div className="mb-4 p-4 bg-gray-700/30 rounded-lg">
                          <p className="text-gray-300">{job.description}</p>
                        </div>
                      )}
                      
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                        <div className="bg-gray-700/20 p-4 rounded-lg">
                          <h4 className="text-sm font-medium text-gray-400 mb-2">Job Details</h4>
                          <div className="space-y-2">
                            <div className="flex justify-between">
                              <span className="text-gray-400">Service:</span>
                              <span className="text-white font-medium">{job.service}</span>
                            </div>
                            <div className="flex justify-between">
                              <span className="text-gray-400">Frequency:</span>
                              <span className="text-white font-medium">
                                {job.frequency ? job.frequency.charAt(0).toUpperCase() + job.frequency.slice(1) : 'One-time'}
                              </span>
                            </div>
                            <div className="flex justify-between">
                              <span className="text-gray-400">Start Date:</span>
                              <span className="text-white font-medium">
                                {job.startDate ? format(new Date(job.startDate), 'MMM d, yyyy') : 'Not scheduled'}
                              </span>
                            </div>
                            {job.endDate && (
                              <div className="flex justify-between">
                                <span className="text-gray-400">End Date:</span>
                                <span className="text-white font-medium">
                                  {format(new Date(job.endDate), 'MMM d, yyyy')}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        
                        <div className="bg-gray-700/20 p-4 rounded-lg">
                          <h4 className="text-sm font-medium text-gray-400 mb-2">Additional Information</h4>
                          <div className="space-y-2">
                            {job.assignedTo && (
                              <div className="flex justify-between">
                                <span className="text-gray-400">Assigned To:</span>
                                <span className="text-white font-medium">{job.assignedTo.name || 'Unknown'}</span>
                              </div>
                            )}
                            {job.crew && (
                              <div className="flex justify-between">
                                <span className="text-gray-400">Crew:</span>
                                <span className="text-white font-medium">{job.crew.name || 'Unknown'}</span>
                              </div>
                            )}
                            {job.completedAt && (
                              <div className="flex justify-between">
                                <span className="text-gray-400">Completed:</span>
                                <span className="text-white font-medium">
                                  {format(new Date(job.completedAt), 'MMM d, yyyy')}
                                </span>
                              </div>
                            )}
                            {job.invoiceId && (
                              <div className="flex justify-between">
                                <span className="text-gray-400">Invoice:</span>
                                <Link 
                                  to={`/jobs?tab=invoices&invoice=${job.invoiceId}`}
                                  className="text-blue-400 hover:text-blue-300"
                                >
                                  View Invoice
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      
                      <div className="flex justify-end space-x-3">
                        <Link
                          to={`/jobs?tab=manage&job=${job._id}`}
                          className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center"
                        >
                          View Details
                        </Link>
                        {job.status !== 'completed' && (
                          <button
                            onClick={() => handleCompleteJob(job._id, job.isRecurring)}
                            className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors flex items-center"
                          >
                            <CheckIcon className="h-5 w-5 mr-2" />
                            Mark Complete
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-gray-800 rounded-lg p-8 border border-gray-700 text-center">
                  <BriefcaseIcon className="h-16 w-16 text-gray-600 mx-auto mb-4" />
                  <h3 className="text-xl font-bold text-white mb-2">No Jobs Found</h3>
                  <p className="text-gray-400 mb-6">This customer doesn't have any jobs yet.</p>
                  <Link
                    to={`/jobs?customer=${customer._id}`}
                    className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors inline-flex items-center"
                  >
                    <PlusIcon className="h-5 w-5 mr-2" />
                    Create First Job
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Modal Footer */}
        <div className="p-6 border-t border-gray-700 flex justify-between items-center">
          <div className="text-sm text-gray-400">
            <span className="font-medium">Created:</span>{' '}
            {customer.createdAt ? format(new Date(customer.createdAt), 'MMM d, yyyy') : 'Unknown'}
          </div>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal; 