import axiosInstance from '../utils/axiosInstance';

const invoiceApi = {
  // Create a new invoice from a job
  createInvoice: async (jobId, dueDate, notes) => {
    try {
      const response = await axiosInstance.post('/invoices/create', {
        jobId,
        dueDate,
        notes
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to create invoice';
    }
  },

  // Get all invoices with optional filters
  getInvoices: async (filters = {}) => {
    try {
      const response = await axiosInstance.get('/invoices', { params: filters });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch invoices';
    }
  },

  // Get a single invoice by ID
  getInvoice: async (invoiceId) => {
    try {
      const response = await axiosInstance.get(`/invoices/${invoiceId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to fetch invoice';
    }
  },

  // Send an invoice to customer
  sendInvoice: async (invoiceId, options = {}) => {
    try {
      const response = await axiosInstance.post(`/invoices/${invoiceId}/send`, options);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to send invoice';
    }
  },

  // Void an invoice
  voidInvoice: async (invoiceId) => {
    try {
      const response = await axiosInstance.post(`/invoices/${invoiceId}/void`);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to void invoice';
    }
  },
  
  // Mark an invoice as paid manually
  markAsPaid: async (invoiceId, paymentMethod = 'other') => {
    try {
      const response = await axiosInstance.post(`/invoices/${invoiceId}/mark-as-paid`, {
        paymentMethod
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to mark invoice as paid';
    }
  },
  
  // Delete an invoice
  deleteInvoice: async (invoiceId) => {
    try {
      const response = await axiosInstance.delete(`/invoices/${invoiceId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Failed to delete invoice';
    }
  }
};

export default invoiceApi; 