import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Alert from './Alert';
import Navbar from './Navbar';
import PasswordRequirements from './PasswordRequirements';
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [tokenValid, setTokenValid] = useState(true);
  
  const navigate = useNavigate();
  const { token } = useParams();
  const { verifyResetToken, resetPassword } = useAuth();

  useEffect(() => {
    // Verify token is valid
    const verifyToken = async () => {
      try {
        await verifyResetToken(token);
      } catch (error) {
        console.error('Invalid or expired token:', error);
        setTokenValid(false);
        setAlert({
          type: 'error',
          message: 'This password reset link is invalid or has expired. Please request a new one.'
        });
      }
    };

    if (token) {
      verifyToken();
    } else {
      setTokenValid(false);
      setAlert({
        type: 'error',
        message: 'No reset token provided. Please request a password reset from the sign in page.'
      });
    }
  }, [token, verifyResetToken]);

  // Function to validate password strength
  const validatePasswordStrength = (password) => {
    const criteria = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[^A-Za-z0-9]/.test(password)
    };
    
    return Object.values(criteria).every(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert(null);

    // Validate passwords match
    if (password !== confirmPassword) {
      setAlert({
        type: 'error',
        message: 'Passwords do not match.'
      });
      setLoading(false);
      return;
    }
    
    // Validate password strength
    if (!validatePasswordStrength(password)) {
      setAlert({
        type: 'error',
        message: 'Please ensure your password meets all the requirements.'
      });
      setLoading(false);
      return;
    }

    try {
      await resetPassword(token, password);
      setResetSuccess(true);
      
      // Redirect to sign in page after 3 seconds
      setTimeout(() => {
        navigate('/signin', { 
          state: { message: 'Your password has been reset successfully. You can now sign in with your new password.' } 
        });
      }, 3000);
    } catch (error) {
      console.error('Password reset error:', error);
      setAlert({
        type: 'error',
        message: error.message || 'An error occurred. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  if (!tokenValid) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
        <Navbar />
        <main className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8 relative">
            <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
            <div className="relative">
              <div className="text-center">
                <XCircleIcon className="h-16 w-16 text-red-500 mx-auto mb-4" />
                <h2 className="text-2xl font-bold text-white mb-2">Invalid Reset Link</h2>
                <p className="text-gray-300 mb-6">
                  {alert?.message || 'This password reset link is invalid or has expired.'}
                </p>
                <Link
                  to="/forgot-password"
                  className="inline-block px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                >
                  Request New Reset Link
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
      <Navbar />
      <main className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 relative">
          <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
          <div className="relative">
            <div>
              <h2 className="mt-6 text-center text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Reset your password
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Please enter a new password for your account.
              </p>
            </div>

            {alert && <Alert type={alert.type} message={alert.message} />}

            {resetSuccess ? (
              <div className="mt-8 bg-green-500/10 border border-green-500/30 rounded-lg p-4 text-center">
                <div className="flex justify-center mb-4">
                  <CheckCircleIcon className="h-12 w-12 text-green-400" />
                </div>
                <h3 className="text-xl font-medium text-green-300 mb-2">Password Reset Successful</h3>
                <p className="text-gray-300 mb-4">
                  Your password has been reset successfully.
                </p>
                <p className="text-sm text-gray-400 mb-6">
                  You will be redirected to the sign in page in a few seconds...
                </p>
              </div>
            ) : (
              <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">
                    New Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 placeholder-gray-400 text-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm transition-colors duration-200"
                    placeholder="Enter your new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="space-y-2">
                  <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-300 mb-2">
                    Confirm Password
                  </label>
                  <input
                    id="confirm-password"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    required
                    className="appearance-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 placeholder-gray-400 text-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm transition-colors duration-200"
                    placeholder="Confirm your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {password && confirmPassword && (
                    <div className="flex items-center mt-1">
                      {password === confirmPassword ? (
                        <div className="text-green-400 text-xs flex items-center">
                          <CheckCircleIcon className="h-4 w-4 mr-1" />
                          Passwords match
                        </div>
                      ) : (
                        <div className="text-red-400 text-xs flex items-center">
                          <XCircleIcon className="h-4 w-4 mr-1" />
                          Passwords do not match
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <PasswordRequirements password={password} />

                <div>
                  <button
                    type="submit"
                    disabled={loading}
                    className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                      loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {loading ? (
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        <div className="animate-spin h-5 w-5 border-2 border-white border-opacity-20 rounded-full border-t-white"></div>
                      </span>
                    ) : null}
                    {loading ? 'Resetting...' : 'Reset Password'}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ResetPassword; 