// Pricing component file

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { loadStripe } from '@stripe/stripe-js';
import toast from 'react-hot-toast';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const tiers = [
  {
    name: 'Basic',
    price: '49.99',
    interval: 'month',
    productId: 'prod_R2TeQ4r5iOH6CG',
    priceId: 'price_1QN3tpE1a6rnB8cNhDLi0kO5',
    trialDays: 14,
    features: [
      { text: 'Up to 25 customers', included: true },
      { text: '150 SMS/Email notifications per month', included: true },
      { text: 'Customer Management', included: true },
      { text: 'Job Scheduling', included: true },
      { text: 'Route Planning', included: true },
      { text: 'Job Tracking', included: true },
      { text: 'Team Management', included: true },
      { text: 'Interactive Maps', included: true },
      { text: 'Business Analytics', included: true },
      { text: 'Invoicing & Payments', included: true },
      { text: 'Email support', included: true }
    ],
    recommended: false,
  },
  {
    name: 'Pro',
    price: '79.99',
    interval: 'month',
    productId: 'prod_R2TfmQYMHxix1e',
    priceId: 'price_1QAOhxE1a6rnB8cN0Ceo9AXM',
    features: [
      { text: 'Up to 100 customers', included: true },
      { text: '250 SMS/Email notifications per month', included: true },
      { text: 'Customer Management', included: true },
      { text: 'Job Scheduling', included: true },
      { text: 'Route Planning', included: true },
      { text: 'Job Tracking', included: true },
      { text: 'Team Management', included: true },
      { text: 'Interactive Maps', included: true },
      { text: 'Business Analytics', included: true },
      { text: 'Invoicing & Payments', included: true },
      { text: 'Priority email support', included: true }
    ],
    recommended: true,
  },
  {
    name: 'Enterprise',
    price: '199.99',
    interval: 'month',
    productId: 'prod_R2TgIYi0HUAYxf',
    priceId: 'price_1QAOisE1a6rnB8cNlvqaNaAN',
    features: [
      { text: 'Unlimited customers', included: true },
      { text: 'Unlimited SMS/Email notifications', included: true },
      { text: 'Customer Management', included: true },
      { text: 'Job Scheduling', included: true },
      { text: 'Route Planning', included: true },
      { text: 'Job Tracking', included: true },
      { text: 'Team Management', included: true },
      { text: 'Interactive Maps', included: true },
      { text: 'Business Analytics', included: true },
      { text: 'Invoicing & Payments', included: true },
      { text: 'Priority email & phone support', included: true },
      { text: 'Custom solutions', included: true }
    ],
    recommended: false,
  }
];

const PricingTier = ({ tier, onSelect }) => (
  <div className={`relative bg-gradient-to-br from-surface to-gray-800 rounded-lg border border-gray-700 shadow-xl p-8 h-full flex flex-col ${
    tier.recommended ? 'transform scale-105' : ''
  }`}>
    {tier.recommended && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
        <span className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-1 rounded-full text-sm font-semibold whitespace-nowrap">
          Recommended
        </span>
      </div>
    )}
    {tier.trialDays && (
      <div className="absolute -top-4 -right-4 bg-green-500 text-white px-4 py-1 rounded-full transform rotate-12 shadow-lg">
        <span className="text-sm font-bold whitespace-nowrap">
          {tier.trialDays} Days Free
        </span>
      </div>
    )}
    <div className="flex flex-col h-full">
      <div className="text-center mb-6">
        <h3 className="text-2xl font-bold text-white mb-4">{tier.name}</h3>
        <div className="mb-6">
          <span className="text-4xl font-bold text-blue-400">${tier.price}</span>
          <span className="text-blue-200">/month</span>
        </div>
      </div>
      
      <ul className="flex-grow space-y-4">
        {tier.features.map((feature, index) => (
          <li key={index} className={`flex items-center text-blue-100 ${
            index < 2 ? 'bg-blue-900/30 p-3 rounded-lg border border-blue-500/30' : ''
          }`}>
            <FaCheck className={`${index < 2 ? 'text-blue-400' : 'text-green-400'} mr-3 flex-shrink-0`} />
            <span className={`${index < 2 ? 'text-blue-200 font-medium' : ''}`}>{feature.text}</span>
          </li>
        ))}
      </ul>
      
      <button
        onClick={() => onSelect(tier)}
        className="w-full mt-8 px-6 py-3 rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50"
      >
        {tier.trialDays ? 'Start Free Trial' : 'Select Plan'}
      </button>
    </div>
  </div>
);

const Pricing = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if (location.state?.message) {
      toast(location.state.message);
    }
  }, [location]);

  // Function to create a checkout session
  const createCheckoutSession = async (priceId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Please sign in to continue');
      }

      const response = await fetch('/api/payment/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ priceId }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to create checkout session');
      }

      const data = await response.json();
      return data.sessionId;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  };

  const handleTierSelect = async (tier) => {
    try {
      if (!user) {
        sessionStorage.setItem('selectedPlan', JSON.stringify(tier));
        navigate('/register', {
          state: {
            message: `Please register to continue with ${tier.name} plan`,
            returnPath: '/pricing'
          }
        });
        return;
      }

      const stripe = await stripePromise;
      const sessionId = await createCheckoutSession(tier.priceId);
      const result = await stripe.redirectToCheckout({ sessionId });
      
      if (result.error) {
        throw new Error(result.error.message);
      }

    } catch (error) {
      console.error('Plan selection error:', error);
      if (error.message === 'Please sign in to continue') {
        navigate('/signin', {
          state: { 
            message: 'Please sign in to continue with plan selection',
            returnPath: '/pricing'
          }
        });
      } else {
        setAlert({
          type: 'error',
          message: error.message || 'Failed to process plan selection'
        });
      }
    }
  };

  return (
    <div className="bg-gradient-to-b from-gray-900 to-background text-text min-h-screen">
      <Navbar />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16 relative pt-8">
          <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
          <h1 className="relative text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Simple, Transparent Pricing
          </h1>
          <p className="text-xl text-blue-100 max-w-2xl mx-auto relative">
            Choose the perfect plan for your lawn care business
          </p>
        </div>

        {alert && (
          <div className={`p-4 rounded-md mb-8 ${
            alert.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
          }`}>
            {alert.message}
          </div>
        )}

        <div className="grid lg:grid-cols-3 gap-8 max-w-7xl mx-auto mb-16">
          {tiers.map((tier) => (
            <PricingTier key={tier.name} tier={tier} onSelect={handleTierSelect} />
          ))}
        </div>

        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">
            Need something different?
          </h2>
          <p className="text-blue-100 mb-8">
            Contact us for custom enterprise solutions tailored to your business needs
          </p>
          <button
            onClick={() => navigate('/contact')}
            className="px-8 py-3 rounded-lg bg-white/10 backdrop-blur-sm text-white font-medium hover:bg-white/20 transform hover:scale-105 transition duration-200"
          >
            Contact Sales
          </button>
        </div>
      </main>
    </div>
  );
};

export default Pricing;
