// src/components/Header.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  ChevronDownIcon, 
  ChevronUpIcon, 
  BellIcon, 
  XMarkIcon,
  ChatBubbleLeftRightIcon
} from '@heroicons/react/24/outline';
import axiosInstance from '../utils/axiosInstance';
import { NotificationSoundContext } from '../App';

const Header = () => {
  const { user, logout } = useAuth();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const notificationAudioRef = useRef(null);
  const lastNotificationIdRef = useRef(null);
  const pollingIntervalRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const { playNotificationSound } = useContext(NotificationSoundContext);

  useEffect(() => {
    if (!user?.subscriptionActive || !user?.subscriptionTier) {
      navigate('/pricing', { 
        state: { message: 'Please select a subscription plan to access the application.' }
      });
    }
  }, [user, navigate]);

  // Preload notification sound
  useEffect(() => {
    // Create and preload the notification sound
    if (!notificationAudioRef.current) {
      notificationAudioRef.current = new Audio('/MessageNotification.mp3');
      notificationAudioRef.current.load();
      console.log('Preloaded notification sound in header');
    }
    
    return () => {
      // Clean up audio on unmount
      if (notificationAudioRef.current) {
        notificationAudioRef.current.pause();
        notificationAudioRef.current = null;
      }
    };
  }, []);

  // Handle clicks outside the notification panel
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    if (showNotifications) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications]);

  // Start polling for new notifications
  useEffect(() => {
    if (user) {
      fetchNotifications();
      
      // Poll every 15 seconds for new notifications
      pollingIntervalRef.current = setInterval(() => {
        fetchNotifications(true); // silent refresh
      }, 15000);
    }
    
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [user]);

  const fetchNotifications = async (silent = false) => {
    if (!user) return;
    
    if (!silent) {
      setLoading(true);
    }
    
    try {
      const response = await axiosInstance.get('/notifications/recent');
      
      // Check if we have new notifications by comparing with current state
      if (response.data.length > 0) {
        const newestNotificationId = response.data[0]._id;
        const hasNewNotifications = lastNotificationIdRef.current && 
                                   lastNotificationIdRef.current !== newestNotificationId;
        
        // If we have new notifications
        if (hasNewNotifications) {
          console.log('New notification detected:', {
            previous: lastNotificationIdRef.current,
            current: newestNotificationId
          });
          
          // Play notification sound
          playNotificationSound();
          
          // Show desktop notification for the newest notification
          const newestNotification = response.data[0];
          if (newestNotification.type === 'sms') {
            showDesktopNotification(newestNotification);
          }
          
          // Immediately update the unread count by adding the new notifications
          const newNotifications = response.data.filter(notification => 
            !notifications.some(existing => existing._id === notification._id)
          );
          const newUnreadCount = newNotifications.filter(n => !n.read).length;
          if (newUnreadCount > 0) {
            setUnreadCount(prevCount => prevCount + newUnreadCount);
          }
        }
        
        // Update the last notification ID
        lastNotificationIdRef.current = newestNotificationId;
      }
      
      setNotifications(response.data);
      
      // Update the total unread count
      const unread = response.data.filter(n => !n.read).length;
      setUnreadCount(unread);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      if (!silent) {
        setLoading(false);
      }
    }
  };

  const showDesktopNotification = (notification) => {
    // Check if browser supports notifications
    if (!("Notification" in window)) {
      return;
    }
    
    // Check if permission is already granted
    if (Notification.permission === "granted") {
      createNotification(notification);
    } 
    // Otherwise, request permission
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          createNotification(notification);
        }
      });
    }
  };
  
  // Helper to create the notification
  const createNotification = (notification) => {
    try {
      const title = notification.type === 'sms' 
        ? `New message from ${notification.sender}` 
        : 'New Notification';
        
      const body = notification.type === 'sms'
        ? notification.content
        : notification.content || 'You have a new notification';
        
      const notif = new Notification(title, {
        body: body,
        icon: '/logo.png'
      });
      
      notif.onclick = () => {
        window.focus();
        if (notification.type === 'sms' && notification.customerId) {
          // Navigate to the manage customers page with the customer modal open and messages tab active
          navigate(`/customers?tab=manage&openCustomer=${notification.customerId}&tab=messages`);
          console.log('Desktop notification clicked, navigating to:', notification.customerId);
        }
        notif.close();
      };
      
      // Auto close after 5 seconds
      setTimeout(() => {
        notif.close();
      }, 5000);
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await axiosInstance.post('/notifications/mark-read');
      setNotifications(prev => prev.map(n => ({ ...n, read: true })));
      setUnreadCount(0);
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const handleNotificationClick = (notification) => {
    // Mark as read
    axiosInstance.post(`/notifications/${notification._id}/mark-read`)
      .catch(error => console.error('Error marking notification as read:', error));
    
    // Update local state
    setNotifications(prev => 
      prev.map(n => n._id === notification._id ? { ...n, read: true } : n)
    );
    
    // Navigate based on notification type
    if (notification.type === 'sms' && notification.customerId) {
      // Navigate to the manage customers page with the customer modal open and messages tab active
      navigate(`/customers?tab=manage&openCustomer=${notification.customerId}&tab=messages`);
      console.log('Navigating to customer messages:', notification.customerId);
    }
    
    // Close notification panel
    setShowNotifications(false);
  };

  if (!user?.subscriptionActive || !user?.subscriptionTier) {
    return null;
  }

  const toggleDropdown = (dropdownName) => {
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdownName);
    }
  };

  return (
    <header className="bg-gray-800 shadow-lg relative z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <img
                className="h-8 w-auto"
                src="/logo.png"
                alt="AutoLawn"
              />
              <span className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
                AutoLawn
              </span>
            </Link>
          </div>

          <div className="flex items-center space-x-4">
            <Link to="/dashboard" className="text-blue-100 px-3 py-2 text-sm font-medium hover:text-white transition-colors">
              Dashboard
            </Link>

            {/* Jobs & Invoicing Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('jobs')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Jobs & Invoicing</span>
                {activeDropdown === 'jobs' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'jobs' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50 ring-1 ring-black ring-opacity-5">
                  <Link to="/jobs" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Create Job</Link>
                  <Link to="/jobs?tab=manage" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Manage Jobs</Link>
                  <Link to="/jobs?tab=invoices" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Invoices</Link>
                  <Link to="/jobs?tab=services" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Services</Link>
                </div>
              )}
            </div>

            {/* Quotes Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('quotes')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Quotes</span>
                {activeDropdown === 'quotes' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'quotes' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50 ring-1 ring-black ring-opacity-5">
                  <Link to="/quotes?tab=create" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Create Quote</Link>
                  <Link to="/quotes?tab=manage" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Manage Quotes</Link>
                  <Link to="/quotes?tab=templates" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Templates</Link>
                  <Link to="/quotes?tab=analytics" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Analytics</Link>
                </div>
              )}
            </div>

            {/* Customers Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('customers')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Customers</span>
                {activeDropdown === 'customers' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'customers' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50 ring-1 ring-black ring-opacity-5">
                  <Link to="/customers?tab=create" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Create Customer</Link>
                  <Link to="/customers?tab=manage" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Manage Customers</Link>
                  <Link to="/customers?tab=map" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Customer Map</Link>
                </div>
              )}
            </div>

            {/* Routes Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('routes')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Routes</span>
                {activeDropdown === 'routes' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'routes' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50 ring-1 ring-black ring-opacity-5">
                  <Link to="/route-map" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Route Map</Link>
                  <Link to="/calendar" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Calendar View</Link>
                </div>
              )}
            </div>

            {/* Employees Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('employees')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Employees</span>
                {activeDropdown === 'employees' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'employees' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50 ring-1 ring-black ring-opacity-5">
                  <Link to="/employees?tab=create" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Create Employee</Link>
                  <Link to="/employees?tab=crews" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Manage Crews</Link>
                </div>
              )}
            </div>

            {/* Tools Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('tools')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Tools</span>
                {activeDropdown === 'tools' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'tools' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50 ring-1 ring-black ring-opacity-5">
                  <Link to="/material-calculator" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Material Calculator</Link>
                  <Link to="/generate-report" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Generate Report</Link>
                  <Link to="/send-notifications" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Send Notifications</Link>
                  <Link to="/profile?tab=usage" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Usage Statistics</Link>
                </div>
              )}
            </div>

            {/* Profile */}
            <Link to="/profile" className="text-blue-100 px-3 py-2 text-sm font-medium hover:text-white transition-colors">
              Profile
            </Link>

            {/* Notification Bell */}
            <div className="relative" ref={notificationRef}>
              <button
                onClick={() => setShowNotifications(!showNotifications)}
                className="text-blue-100 p-2 rounded-full hover:bg-gray-700 transition-colors relative"
              >
                <BellIcon className="h-6 w-6" />
                {unreadCount > 0 && (
                  <span className="absolute top-0 right-0 block h-4 w-4 rounded-full bg-red-500 text-white text-xs flex items-center justify-center transform translate-x-1 -translate-y-1">
                    {unreadCount > 9 ? '9+' : unreadCount}
                  </span>
                )}
              </button>
              
              {showNotifications && (
                <div className="absolute right-0 mt-2 w-80 bg-gray-800 rounded-md shadow-lg z-50 ring-1 ring-black ring-opacity-5 max-h-96 overflow-y-auto">
                  <div className="p-3 border-b border-gray-700 flex justify-between items-center">
                    <h3 className="text-white font-medium">Notifications</h3>
                    {unreadCount > 0 && (
                      <button 
                        onClick={handleMarkAllAsRead}
                        className="text-xs text-blue-400 hover:text-blue-300"
                      >
                        Mark all as read
                      </button>
                    )}
                  </div>
                  
                  {loading && notifications.length === 0 ? (
                    <div className="p-4 flex justify-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                  ) : notifications.length === 0 ? (
                    <div className="p-4 text-center text-gray-400">
                      No notifications
                    </div>
                  ) : (
                    <div>
                      {notifications.map(notification => (
                        <div 
                          key={notification._id}
                          onClick={() => handleNotificationClick(notification)}
                          className={`p-3 border-b border-gray-700 hover:bg-gray-700 cursor-pointer ${
                            !notification.read ? 'bg-gray-700/50' : ''
                          }`}
                        >
                          <div className="flex items-start">
                            {notification.type === 'sms' ? (
                              <ChatBubbleLeftRightIcon className="h-5 w-5 text-blue-400 mt-0.5 mr-2 flex-shrink-0" />
                            ) : (
                              <BellIcon className="h-5 w-5 text-blue-400 mt-0.5 mr-2 flex-shrink-0" />
                            )}
                            <div className="flex-1">
                              <p className="text-sm font-medium text-white">
                                {notification.type === 'sms' 
                                  ? `New message from ${notification.sender}` 
                                  : notification.title || 'New Notification'}
                              </p>
                              <p className="text-xs text-gray-400 mt-1 line-clamp-2">
                                {notification.content}
                              </p>
                              <p className="text-xs text-gray-500 mt-1">
                                {new Date(notification.createdAt).toLocaleString()}
                              </p>
                            </div>
                            {!notification.read && (
                              <span className="h-2 w-2 bg-blue-500 rounded-full flex-shrink-0"></span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Logout Button */}
            <button
              onClick={logout}
              className="ml-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:from-blue-600 hover:to-purple-600 transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-blue-500/50"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
