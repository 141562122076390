import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import axios from '../utils/axiosInstance';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const initialVerificationDone = useRef(false);

  // Register new user
  const register = async (userData) => {
    try {
      console.log('Sending registration request:', userData);
      const response = await axios.post('/auth/register', userData);
      console.log('Registration response:', response.data);

      if (!response.data || !response.data.token || !response.data.user) {
        throw new Error('Invalid response from server');
      }

      const { token, user } = response.data;
      
      // Store token
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Set user in state
      setUser(user);

      return { user, token };
    } catch (error) {
      console.error('Registration error:', error);
      
      // If it's a server response with validation errors
      if (error.response?.data?.errors) {
        throw {
          message: 'Validation error',
          errors: error.response.data.errors
        };
      }
      
      // If it's a server response with a message
      if (error.response?.data?.message) {
        throw {
          message: error.response.data.message
        };
      }
      
      // If it's a network error or other type of error
      throw {
        message: 'Registration failed. Please try again later.'
      };
    }
  };

  // Verify token
  const verifyToken = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('[Auth Debug] No token found in verifyToken');
        setLoading(false);
        setUser(null);
        return null;
      }

      console.log('[Auth Debug] Verifying token in AuthContext');
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get('/auth/verify');

      if (!response.data?.user) {
        console.log('[Auth Debug] Invalid token response - no user data');
        throw new Error('Invalid token response');
      }

      console.log('[Auth Debug] Token verified successfully, setting user');
      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('[Auth Debug] Token verification failed in AuthContext:', error);
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
      setUser(null);
      throw error;
    } finally {
      setLoading(false);
      initialVerificationDone.current = true;
    }
  }, []);

  // Initial verification on mount
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        setLoading(true);
        if (!initialVerificationDone.current) {
          console.log('[Auth Debug] Running initial token verification');
          await verifyToken();
        }
      } catch (error) {
        console.error('[Auth Debug] Initial verification failed:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [verifyToken]);

  // Regular login
  const login = async (email, password) => {
    try {
      setLoading(true);
      console.log('[Auth Debug] Attempting login');
      const response = await axios.post('/auth/login', { email, password });
      const { token, user } = response.data;

      console.log('[Auth Debug] Login successful, setting token and user');
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(user);
      initialVerificationDone.current = true;

      return { user };
    } catch (error) {
      console.error('[Auth Debug] Login error:', error);
      throw error.response?.data || error;
    } finally {
      setLoading(false);
    }
  };

  // Utility function to update token
  const updateToken = useCallback((token) => {
    if (token) {
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
    }
  }, []);

  // Google OAuth login
  const loginWithGoogle = () => {
    sessionStorage.setItem('redirectPath', window.location.pathname);
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  // Handle OAuth success
  const handleOAuthSuccess = async (token) => {
    try {
      if (!token) {
        throw new Error('No token provided');
      }
      updateToken(token);
      const userData = await verifyToken();

      const redirectPath = sessionStorage.getItem('redirectPath');
      sessionStorage.removeItem('redirectPath');

      return {
        user: userData,
        redirectPath,
        needsSubscription: !userData.subscriptionTier,
      };
    } catch (error) {
      console.error('OAuth success handling error:', error);
      throw error;
    }
  };

  // Update user profile
  const updateUserProfile = async (profileData) => {
    try {
      const response = await axios.put('/profile', profileData);
      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error('[Auth Debug] Profile update error:', error);
      throw error;
    }
  };

  // Refresh user data
  const refreshUser = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const response = await axios.get('/auth/verify'); // Use verify instead of /user endpoint

      if (!response.data?.user) {
        throw new Error('No user data received');
      }

      setUser(response.data.user);
      return response.data.user;
    } catch (error) {
      console.error('[Auth Debug] User refresh error:', error);
      if (error.response?.status === 404) {
        // If 404, try to verify token instead
        try {
          await verifyToken();
        } catch (verifyError) {
          setUser(null);
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
          throw verifyError;
        }
      } else {
        setUser(null);
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        throw error;
      }
    }
  };

  // Check access status
  const checkAccess = useCallback(() => ({
    isAuthenticated: !!user,
    hasSubscription: user?.subscriptionActive && !!user?.subscriptionTier,
    canAccessApp: user?.subscriptionActive && !!user?.subscriptionTier,
    canAccessQuoteBuilder: !!user,
    subscriptionTier: user?.subscriptionTier || null,
    isSubscriptionActive: user?.subscriptionActive || false,
  }), [user]);

  // Check subscription status
  const checkSubscription = async () => {
    try {
      const response = await axios.get('/payment/subscription-status');
      const { active, tier } = response.data;

      setUser((prev) => ({
        ...prev,
        subscriptionActive: active,
        subscriptionTier: tier,
      }));

      return response.data;
    } catch (error) {
      console.error('Subscription check error:', error);
      throw error;
    }
  };

  // Logout
  const logout = async () => {
    try {
      // Clear token from localStorage
      localStorage.removeItem('token');
      
      // Clear axios default header
      delete axios.defaults.headers.common['Authorization'];
      
      // Clear user from state
      setUser(null);
      
      return true;
    } catch (error) {
      console.error('Logout error:', error);
      return false;
    }
  };

  // Request password reset email
  const forgotPassword = async (email) => {
    try {
      const response = await axios.post('/auth/forgot-password', { email });
      return response.data;
    } catch (error) {
      console.error('Forgot password error:', error);
      
      if (error.response?.data?.message) {
        throw {
          message: error.response.data.message
        };
      }
      
      throw {
        message: 'An error occurred while requesting password reset. Please try again.'
      };
    }
  };
  
  // Reset password with token
  const resetPassword = async (token, newPassword) => {
    try {
      const response = await axios.post(`/auth/reset-password/${token}`, { password: newPassword });
      return response.data;
    } catch (error) {
      console.error('Reset password error:', error);
      
      if (error.response?.data?.message) {
        throw {
          message: error.response.data.message
        };
      }
      
      throw {
        message: 'An error occurred while resetting your password. Please try again.'
      };
    }
  };
  
  // Verify reset token
  const verifyResetToken = async (token) => {
    try {
      const response = await axios.get(`/auth/reset-password/${token}/verify`);
      return response.data;
    } catch (error) {
      console.error('Verify reset token error:', error);
      
      if (error.response?.data?.message) {
        throw {
          message: error.response.data.message
        };
      }
      
      throw {
        message: 'Invalid or expired password reset token.'
      };
    }
  };

  const value = {
    user,
    loading,
    error,
    register,
    login,
    loginWithGoogle,
    handleOAuthSuccess,
    updateUserProfile,
    refreshUser,
    logout,
    checkAccess,
    checkSubscription,
    verifyToken,
    setError,
    forgotPassword,
    resetPassword,
    verifyResetToken,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthProvider;
