import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Tabs, Tab, Badge, Tooltip, Overlay, Popover } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import axiosInstance from '../utils/axiosInstance';
import moment from 'moment';

// Custom styles for fixed modal width
const styles = `
  .job-details-modal-dialog {
    max-width: 800px !important;
    width: 100% !important;
  }
`;

// Delete Confirmation Modal component
const DeleteConfirmationModal = ({ show, onClose, onConfirm, title, message }) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      size="sm"
      className="modern-modal"
    >
      <Modal.Header closeButton className="bg-gray-800 text-white border-gray-700">
        <Modal.Title className="text-lg font-semibold">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-gray-900 text-white">
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer className="bg-gray-900 text-white border-gray-700">
        <Button 
          variant="outline-secondary" 
          onClick={onClose}
          className="px-4 py-2 rounded-md border border-gray-600 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors"
        >
          Cancel
        </Button>
        <Button 
          variant="danger" 
          onClick={onConfirm}
          className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700 transition-colors"
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const JobDetailsModal = ({ isOpen, onClose, job, onUpdate, eventId }) => {
  const [loading, setLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);
  const [formData, setFormData] = useState({
    status: '',
    notes: '',
    estimatedDuration: 60,
    startTime: '',
    endTime: '',
    selectedEventId: null
  });
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const confirmTargetRef = useRef(null);
  
  // Added state to track current event assignment
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  // State for delete confirmation modal
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState({
    title: '',
    message: '',
    eventId: null,
    isAllEvents: false
  });
  
  // State for update all events confirmation
  const [showUpdateAllConfirmation, setShowUpdateAllConfirmation] = useState(false);
  const [updateAllConfirmationData, setUpdateAllConfirmationData] = useState({
    startTime: '',
    endTime: ''
  });
  
  const [employees, setEmployees] = useState([]);
  const [crews, setCrews] = useState([]);
  
  // Get today's date for highlighting today's events
  const today = new Date();
  const todayString = today.toISOString().split('T')[0];
  
  // Store the current date when the modal is opened
  const [currentDate, setCurrentDate] = useState(null);
  
  useEffect(() => {
    if (isOpen && job) {
      console.log('[DEBUG] JobDetailsModal - Job received:', {
        jobId: job._id,
        service: job.service,
        assignedEmployee: job.assignedEmployee,
        assignedCrew: job.assignedCrew
      });
      
      // Initialize form data from job
      setFormData({
        status: job.status || '',
        notes: job.notes || '',
        estimatedDuration: job.estimatedDuration || 60,
        startTime: '',
        endTime: '',
        selectedEventId: eventId || null // Use the passed eventId if available
      });
      
      fetchJobDetails();
      fetchEmployeesAndCrews();
      
      // Clear any stale state
      setShowDeleteOptions(false);
      setDeleteConfirmationData({ title: '', message: '', eventId: null, isAllEvents: false });
      setShowDeleteConfirmation(false);
      setCurrentDate(new Date());
      setSelectedEvent(null); // Reset selected event on modal open
    }
  }, [isOpen, job]);
  
  // When calendar events are fetched, check for the specific event to select
  useEffect(() => {
    if (calendarEvents.length > 0) {
      // Check if we have a specific event ID to look for
      if (eventId) {
        console.log('[DEBUG] JobDetailsModal - Looking for event with ID:', eventId);
        const event = calendarEvents.find(e => e._id === eventId);
        
        if (event) {
          console.log('[DEBUG] JobDetailsModal - Found specific event:', eventId);
          // Select this event
          handleEventSelect(event);
          
          // Scroll to this event in the UI
          setTimeout(() => {
            const eventElement = document.getElementById(`event-${event._id}`);
            if (eventElement) {
              eventElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 300);
        } else {
          // If not found, default to first event
          console.log('[DEBUG] JobDetailsModal - Specific event not found, defaulting to first event');
          handleEventSelect(calendarEvents[0]);
        }
      } else {
        // If no specific event ID, try to find today's event
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        let foundTodayEvent = false;
        
        // Try to find an event scheduled for today
        for (const event of calendarEvents) {
          const eventDate = new Date(event.start);
          eventDate.setHours(0, 0, 0, 0);
          
          if (eventDate.getTime() === today.getTime()) {
            handleEventSelect(event);
            foundTodayEvent = true;
            
            // Scroll to today's event
            setTimeout(() => {
              const eventElement = document.getElementById(`event-${event._id}`);
              if (eventElement) {
                eventElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
            }, 300);
            
            break;
          }
        }
        
        // If no event for today, default to first event
        if (!foundTodayEvent) {
          handleEventSelect(calendarEvents[0]);
        }
      }
    }
  }, [calendarEvents, eventId]);
  
  const fetchJobDetails = async () => {
    try {
      setLoading(true);
      console.log('[DEBUG] JobDetailsModal - Fetching job details for:', job?._id);
      
      const response = await axiosInstance.get(`/jobs/${job._id}`);
      const jobData = response.data;
      
      console.log('[DEBUG] JobDetailsModal - Job details received:', {
        jobId: jobData._id,
        service: jobData.service,
        assignedEmployee: jobData.assignedEmployee,
        assignedCrew: jobData.assignedCrew
      });
      
      setJobDetails(jobData);
      
      // After fetching job details, fetch associated calendar events
      await fetchCalendarEvents();
    } catch (error) {
      console.error('Error fetching job details:', error);
      toast.error('Failed to load job details');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchCalendarEvents = async () => {
    try {
      console.log('[DEBUG] JobDetailsModal - Fetching calendar events for job:', job?._id);
      
      const response = await axiosInstance.get(`/calendar/events?jobId=${job._id}`);
      const events = response.data;
      
      console.log('[DEBUG] JobDetailsModal - Found calendar events:', events.length);
      
      if (events && events.length > 0) {
        // Sort events by start date
        const sortedEvents = events.sort((a, b) => new Date(a.start) - new Date(b.start));
        setCalendarEvents(sortedEvents);
        
        // Event selection will be handled in the useEffect above
      }
    } catch (error) {
      console.error('Error fetching calendar events:', error);
      toast.error('Failed to load calendar events');
    }
  };
  
  const fetchEmployeesAndCrews = async () => {
    try {
      const [employeesResponse, crewsResponse] = await Promise.all([
        axiosInstance.get('/employees'),
        axiosInstance.get('/crews')
      ]);
      
      setEmployees(employeesResponse.data);
      setCrews(crewsResponse.data);
      
      console.log('Fetched employees and crews:', {
        employees: employeesResponse.data.length,
        crews: crewsResponse.data.length
      });
    } catch (error) {
      console.error('Error fetching employees and crews:', error);
    }
  };
  
  const getEmployeeName = (employeeId) => {
    if (!employeeId) return null;
    const employee = employees.find(emp => emp._id === employeeId);
    return employee ? employee.name : `Employee ID: ${employeeId}`;
  };
  
  const getCrewName = (crewId) => {
    if (!crewId) return null;
    const crew = crews.find(c => c._id === crewId);
    return crew ? crew.name : `Crew ID: ${crewId}`;
  };
  
  const handleDeleteSingleEvent = async (eventId) => {
    try {
      console.log('Deleting single event:', eventId);
      setLoading(true);
      const response = await axiosInstance.delete(`/calendar/events/${eventId}`);
      console.log('Delete event response:', response.data);
      
      toast.success('Event removed from calendar');
      const updatedEvents = await axiosInstance.get(`/calendar/events?jobId=${job._id}`);
      
      if (updatedEvents.data.length === 0) {
        toast.success('All events removed - closing details');
        onClose();
      } else {
        setCalendarEvents(updatedEvents.data);
      }
      
      if (onUpdate) onUpdate('refresh');
    } catch (error) {
      console.error('Error removing event:', error);
      toast.error('Failed to remove event: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
      setShowDeleteOptions(false);
      setShowDeleteConfirmation(false);
    }
  };
  
  const handleDeleteAllEvents = async () => {
    try {
      console.log('Deleting all events for job:', job._id);
      setLoading(true);
      const response = await axiosInstance.delete('/calendar/events', {
        data: { jobId: job._id }
      });
      console.log('Delete all events response:', response.data);
      
      toast.success('All events removed from calendar');
      onClose();
      
      if (onUpdate) onUpdate('refresh');
    } catch (error) {
      console.error('Error removing events:', error);
      toast.error('Failed to remove events: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
      setShowDeleteOptions(false);
      setShowDeleteConfirmation(false);
    }
  };
  
  const handleConfirmDelete = () => {
    if (deleteConfirmationData.isAllEvents) {
      handleDeleteAllEvents();
    } else if (deleteConfirmationData.eventId) {
      handleDeleteSingleEvent(deleteConfirmationData.eventId);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEventSelect = (event) => {
    console.log('[DEBUG] JobDetailsModal - Event selected:', event);
    
    // Add more detailed time debugging
    if (event.start && event.end) {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);
      
      console.log('[DEBUG] JobDetailsModal - Time details:', {
        eventId: event._id,
        utcStart: event.start,
        utcEnd: event.end,
        localStartHours: startDate.getHours(),
        localStartMinutes: startDate.getMinutes(),
        localEndHours: endDate.getHours(),
        localEndMinutes: endDate.getMinutes(),
        timezoneOffset: startDate.getTimezoneOffset() / 60 * -1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
    }
    
    setFormData(prevState => ({
      ...prevState,
      selectedEventId: event._id
    }));
    
    // Store the selected event for assignment info
    setSelectedEvent(event);
    
    // Format times in HH:mm format for the time inputs
    if (event.start && event.end) {
      // Use moment.js for consistent time handling
      const startMoment = moment(event.start);
      const endMoment = moment(event.end);
      
      // Format as HH:mm for time inputs
      const formattedStartTime = startMoment.format('HH:mm');
      const formattedEndTime = endMoment.format('HH:mm');
      
      console.log('[DEBUG] JobDetailsModal - Formatted times:', { 
        formattedStartTime, 
        formattedEndTime,
        originalStart: event.start,
        originalEnd: event.end,
        startMomentHours: startMoment.hours(),
        startMomentMinutes: startMoment.minutes(),
        endMomentHours: endMoment.hours(),
        endMomentMinutes: endMoment.minutes()
      });
      
      setFormData(prev => ({
        ...prev,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        selectedEventId: event._id,
        estimatedDuration: Math.round(endMoment.diff(startMoment, 'minutes'))
      }));
    }
  };

  const handleUpdateEventTime = async () => {
    try {
      if (!formData.selectedEventId || !formData.startTime || !formData.endTime) {
        toast.error('Please select an event and set start/end time');
        return;
      }
      
      setLoading(true);
      const selectedEvent = calendarEvents.find(event => event._id === formData.selectedEventId);
      
      if (!selectedEvent) {
        toast.error('Selected event not found');
        setLoading(false);
        return;
      }
      
      // Get the date part from the selected event's start date
      const eventDate = moment(selectedEvent.start).format('YYYY-MM-DD');
      console.log('[DEBUG] JobDetailsModal - Event date for update:', eventDate);
      console.log('[DEBUG] JobDetailsModal - Start/end times for update:', formData.startTime, formData.endTime);
      
      // Show confirmation modal if this is a recurring event
      if (jobDetails.isRecurring) {
        setUpdateAllConfirmationData({
          startTime: formData.startTime,
          endTime: formData.endTime,
          eventDate,
          selectedEventId: formData.selectedEventId
        });
        setShowUpdateAllConfirmation(true);
        setLoading(false);
        return;
      }
      
      // If not recurring, just update the single event
      await updateSingleEvent(formData.selectedEventId, 
        moment(`${eventDate} ${formData.startTime}`).toISOString(), 
        moment(`${eventDate} ${formData.endTime}`).toISOString()
      );
      
    } catch (error) {
      console.error('Error updating event time:', error);
      toast.error('Failed to update event time');
      setLoading(false);
    }
  };
  
  const updateSingleEvent = async (eventId, newStart, newEnd) => {
    try {
      console.log('Updating single event:', { 
        eventId, 
        newStart, 
        newEnd 
      });
      
      // Validate that end time is after start time
      if (moment(newEnd).isBefore(moment(newStart))) {
        toast.error('End time must be after start time');
        setLoading(false);
        return;
      }
      
      const response = await axiosInstance.put(`/calendar/events/${eventId}`, {
        start: newStart,
        end: newEnd
      });
      
      console.log('Updated event:', response.data);
      toast.success('Event time updated successfully');
      
      // Refresh calendar events
      await fetchCalendarEvents();
      
      // Reset form data
      setFormData({
        ...formData,
        startTime: '',
        endTime: '',
        selectedEventId: null
      });
      
      // If this job has an onUpdate callback, call it
      if (onUpdate) {
        onUpdate('refresh');
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error updating event:', error);
      toast.error('Failed to update event: ' + (error.response?.data?.message || error.message));
      setLoading(false);
    }
  };
  
  const updateAllEvents = async () => {
    try {
      setLoading(true);
      const { startTime, endTime } = updateAllConfirmationData;
      const loadingToast = toast.loading('Updating all events in series...');
      const updatePromises = calendarEvents.map(event => {
        const eventDate = moment(event.start).format('YYYY-MM-DD');
        const newStart = moment(`${eventDate} ${startTime}`).toISOString();
        const newEnd = moment(`${eventDate} ${endTime}`).toISOString();
        
        return axiosInstance.put(`/calendar/events/${event._id}`, {
          start: newStart,
          end: newEnd
        });
      });
      
      await Promise.all(updatePromises);
      
      toast.dismiss(loadingToast);
      toast.success(`Updated ${calendarEvents.length} events successfully`);
      await fetchCalendarEvents();
      setFormData({
        ...formData,
        startTime: '',
        endTime: '',
        selectedEventId: null
      });
      if (onUpdate) {
        onUpdate('refresh');
      }
    } catch (error) {
      console.error('Error updating all events:', error);
      toast.error('Failed to update all events: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
      setShowUpdateAllConfirmation(false);
    }
  };
  
  const updateSelectedEventOnly = async () => {
    try {
      const { selectedEventId, startTime, endTime, eventDate } = updateAllConfirmationData;
      const newStart = moment(`${eventDate} ${startTime}`).toISOString();
      const newEnd = moment(`${eventDate} ${endTime}`).toISOString();
      
      await updateSingleEvent(selectedEventId, newStart, newEnd);
      setShowUpdateAllConfirmation(false);
    } catch (error) {
      console.error('Error updating selected event:', error);
      toast.error('Failed to update event: ' + (error.response?.data?.message || error.message));
      setLoading(false);
      setShowUpdateAllConfirmation(false);
    }
  };
  
  const handleSave = async () => {
    try {
      setLoading(true);
      const updatedData = {
        ...formData,
      };
      
      const response = await axiosInstance.put(`/jobs/${job._id}`, updatedData);
      toast.success('Job updated successfully');
      setJobDetails(response.data);
      
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error updating job:', error);
      toast.error('Failed to update job');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Not specified';
    if (dateString instanceof Date || (typeof dateString === 'string' && dateString.includes('T'))) {
      return moment(dateString).format('MMM D, YYYY');
    }
    if (typeof dateString === 'string' && ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].includes(dateString)) {
      return `Every ${dateString}`;
    }
    return dateString;
  };

  const formatCurrency = (amount) => {
    if (amount === undefined || amount === null) return 'Not specified';
    return `$${parseFloat(amount).toFixed(2)}`;
  };
  
  const formatDuration = (minutes) => {
    if (!minutes) return 'Not specified';
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    
    if (hours === 0) {
      return `${mins} minutes`;
    } else if (mins === 0) {
      return `${hours} hour${hours !== 1 ? 's' : ''}`;
    } else {
      return `${hours} hour${hours !== 1 ? 's' : ''} and ${mins} minutes`;
    }
  };

  // Helper function to format time for time input
  const formatTime = (date) => {
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) return '';
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  // Helper function to calculate duration in minutes
  const calculateDuration = (start, end) => {
    if (!start || !end) return 60;
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) return 60;
    
    return Math.round((endDate.getTime() - startDate.getTime()) / 60000);
  };

  const handleAssignToEmployee = () => {
    console.log('Assign to Employee button clicked');
    onClose();
    if (onUpdate) {
      onUpdate('assign', jobDetails, formData.selectedEventId);
    }
  };

  if (!jobDetails) return null;

  return (
    <>
      <style>{styles}</style>
      <Modal 
        show={isOpen} 
        onHide={onClose}
        centered
        size="lg"
        className="modern-modal"
        dialogClassName="job-details-modal-dialog"
        style={{ maxWidth: '900px' }}
      >
        <Modal.Header closeButton className="bg-gray-800 text-white border-gray-700">
          <Modal.Title className="text-lg font-semibold flex items-center">
            <span className="bg-blue-500/20 text-blue-400 p-1.5 rounded-md mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </span>
            {jobDetails.service}
          </Modal.Title>
          <Button 
            variant="link" 
            onClick={onClose}
            className="text-gray-400 hover:text-white p-0 ms-auto"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </Button>
        </Modal.Header>
        <Modal.Body className="bg-gray-900 text-white p-0">
          <div className="p-5 max-h-[70vh] overflow-y-auto">
            <Tabs defaultActiveKey="overview" id="job-details-tabs" className="mb-3">
              <Tab eventKey="overview" title="Overview">
                {/* Job Summary Card */}
                <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-5 rounded-lg border border-gray-700 shadow-lg mb-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <span className="text-gray-400 text-xs uppercase tracking-wider">Customer</span>
                      <h3 className="text-white font-semibold text-lg mt-1">{jobDetails.customer?.name || 'No customer'}</h3>
                    </div>
                    <div>
                      <span className="text-gray-400 text-xs uppercase tracking-wider mb-1 block">Status</span>
                      <select 
                        name="status"
                        value={formData.status}
                        onChange={handleInputChange}
                        className="px-3 py-1 rounded-full text-xs font-medium bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none appearance-none transition-colors"
                      >
                        <option value="Pending">Pending</option>
                        <option value="Scheduled">Scheduled</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                        <option value="Cancelled">Cancelled</option>
                      </select>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <span className="text-gray-400 text-xs uppercase tracking-wider">Service Type</span>
                      <p className="text-white font-medium mt-1">{jobDetails.service}</p>
                    </div>
                    <div>
                      <span className="text-gray-400 text-xs uppercase tracking-wider">Price</span>
                      <p className="text-green-400 font-medium mt-1">{formatCurrency(jobDetails.price)}</p>
                    </div>
                  </div>
                  
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <span className="text-gray-400 text-xs uppercase tracking-wider">Scheduled Date</span>
                      <p className="text-white font-medium mt-1">
                        {calendarEvents.length > 0 && formData.selectedEventId ? (
                          formatDate(calendarEvents.find(e => e._id === formData.selectedEventId)?.start)
                        ) : (
                          formatDate(calendarEvents.length > 0 ? calendarEvents[0].start : jobDetails.scheduledDay)
                        )}
                      </p>
                    </div>
                    <div>
                      <span className="text-gray-400 text-xs uppercase tracking-wider">Duration (minutes)</span>
                      <div className="flex items-center mt-1">
                        <input
                          type="number"
                          name="estimatedDuration"
                          value={formData.estimatedDuration}
                          onChange={handleInputChange}
                          min="15"
                          max="480"
                          className="w-24 p-1 rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors"
                        />
                        <span className="ml-2 text-white">{formatDuration(formData.estimatedDuration)}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="mt-4 pt-4 border-t border-gray-700">
                    <span className="text-gray-400 text-xs uppercase tracking-wider">Job Type</span>
                    <p className="text-white font-medium mt-1 flex items-center">
                      {jobDetails.isRecurring ? (
                        <span className="bg-green-600/30 text-green-400 px-3 py-1 rounded-full text-xs font-medium mr-2">
                          {jobDetails.recurrencePattern || 'Recurring'}
                        </span>
                      ) : (
                        <span className="bg-blue-600/30 text-blue-400 px-3 py-1 rounded-full text-xs font-medium mr-2">
                          One-time
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Customer Information */}
                  <div className="bg-gray-800/50 p-5 rounded-lg border border-gray-700">
                    <h3 className="text-white text-base font-medium mb-4 flex items-center">
                      <span className="bg-blue-500/20 text-blue-400 p-1.5 rounded-md mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                      </span>
                      Customer Information
                    </h3>
                    
                    <div className="space-y-3">
                      <div>
                        <span className="text-gray-400 text-xs uppercase tracking-wider">Name</span>
                        <p className="text-white font-medium mt-1">{jobDetails.customer?.name || 'No customer'}</p>
                      </div>
                      <div>
                        <span className="text-gray-400 text-xs uppercase tracking-wider">Email</span>
                        <p className="text-white font-medium mt-1">{jobDetails.customer?.email || 'No email'}</p>
                      </div>
                      <div>
                        <span className="text-gray-400 text-xs uppercase tracking-wider">Phone</span>
                        <p className="text-white font-medium mt-1">{jobDetails.customer?.phone || 'No phone'}</p>
                      </div>
                      <div>
                        <span className="text-gray-400 text-xs uppercase tracking-wider">Address</span>
                        <p className="text-white font-medium mt-1 flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                          {jobDetails.location?.address || 'No address'}
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  {/* Assignment Information */}
                  <div className="bg-gray-800/50 p-5 rounded-lg border border-gray-700">
                    <h3 className="text-white text-base font-medium mb-4 flex items-center">
                      <span className="bg-purple-500/20 text-purple-400 p-1.5 rounded-md mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                      </span>
                      Assignment
                    </h3>
                    
                    <div className="space-y-3">
                      <div>
                        <span className="text-gray-400 text-xs uppercase tracking-wider">Assigned Employee</span>
                        <p className="text-white font-medium mt-1 flex items-center">
                          {selectedEvent && selectedEvent.assignedEmployee ? (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                              </svg>
                              {getEmployeeName(selectedEvent.assignedEmployee)}
                            </>
                          ) : jobDetails.assignedEmployee ? (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                              </svg>
                              {getEmployeeName(jobDetails.assignedEmployee)}
                            </>
                          ) : (
                            <span className="text-gray-500">Not assigned</span>
                          )}
                        </p>
                      </div>
                      <div>
                        <span className="text-gray-400 text-xs uppercase tracking-wider">Assigned Crew</span>
                        <p className="text-white font-medium mt-1 flex items-center">
                          {selectedEvent && selectedEvent.assignedCrew ? (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                              </svg>
                              {getCrewName(selectedEvent.assignedCrew)}
                            </>
                          ) : jobDetails.assignedCrew ? (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                              </svg>
                              {getCrewName(jobDetails.assignedCrew)}
                            </>
                          ) : (
                            <span className="text-gray-500">Not assigned</span>
                          )}
                        </p>
                      </div>
                    </div>
                    
                    {selectedEvent && (selectedEvent.assignedEmployee || selectedEvent.assignedCrew) && (
                      <div className="mt-2 bg-blue-900/20 p-2 rounded-md border border-blue-600/30">
                        <p className="text-blue-400 text-sm flex items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          This event has a specific assignment that may differ from other events in the series.
                        </p>
                      </div>
                    )}
                    
                    <div className="mt-4 pt-4 border-t border-gray-700">
                      <span className="text-gray-400 text-xs uppercase tracking-wider">Job Description</span>
                      <p className="text-white font-medium mt-1">
                        {jobDetails.description || 'No description available'}
                      </p>
                    </div>
                    
                    <div className="mt-4 pt-4 border-t border-gray-700">
                      <div className="grid grid-cols-1 gap-3">
                        <Button 
                          variant="outline-primary"
                          className="w-full py-2 rounded-md border border-blue-600/50 text-blue-400 hover:bg-blue-900/20 hover:text-blue-300 transition-colors"
                          onClick={handleAssignToEmployee}
                        >
                          <span className="flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                            Assign to Employee
                          </span>
                        </Button>
                        <Button 
                          variant="outline-primary"
                          className="w-full py-2 rounded-md border border-purple-600/50 text-purple-400 hover:bg-purple-900/20 hover:text-purple-300 transition-colors"
                          onClick={() => {
                            console.log('Assign to Crew button clicked');
                            onClose();
                            if (onUpdate) {
                              onUpdate('assignCrew', jobDetails, formData.selectedEventId);
                            }
                          }}
                        >
                          <span className="flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                            Assign to Crew
                          </span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="calendar" title="Calendar">
                {calendarEvents.length > 0 && (
                  <div className="bg-gray-800/50 p-5 rounded-lg border border-gray-700">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-white text-base font-medium flex items-center">
                        <span className="bg-blue-500/20 text-blue-400 p-1.5 rounded-md mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                        </span>
                        Calendar Events ({calendarEvents.length})
                      </h3>
                      <div className="relative">
                        <Button 
                          variant="outline-danger"
                          size="sm"
                          onClick={() => setShowDeleteOptions(!showDeleteOptions)}
                          className="px-3 py-1.5 rounded-md border border-red-600/50 text-red-400 hover:bg-red-900/20 hover:text-red-300 transition-colors"
                          ref={confirmTargetRef}
                        >
                          <span className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                            Remove Events
                          </span>
                        </Button>
                        
                        {showDeleteOptions && (
                          <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-800 border border-gray-700 z-10 max-h-60 overflow-y-auto">
                            <div className="py-1">
                              <button 
                                className="w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-700 hover:text-red-300"
                                onClick={() => {
                                  console.log('Delete all events button clicked');
                                  setShowDeleteOptions(false);
                                  setDeleteConfirmationData({
                                    title: 'Delete All Events',
                                    message: 'Are you sure you want to delete all events for this job?',
                                    eventId: null,
                                    isAllEvents: true
                                  });
                                  setShowDeleteConfirmation(true);
                                }}
                              >
                                Delete All Events
                              </button>
                              
                              {calendarEvents.map(event => (
                                <button 
                                  key={event._id}
                                  className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700"
                                  onClick={() => {
                                    console.log('Delete single event button clicked:', event._id);
                                    setShowDeleteOptions(false);
                                    setDeleteConfirmationData({
                                      title: 'Delete Event',
                                      message: `Are you sure you want to delete the event on ${moment(event.start).format('MMM D, YYYY')}?`,
                                      eventId: event._id,
                                      isAllEvents: false
                                    });
                                    setShowDeleteConfirmation(true);
                                  }}
                                >
                                  Delete event on {moment(event.start).format('MMM D, YYYY')}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    
                    <div id="time-edit-section" className="mb-4 p-3 bg-gray-700/30 rounded-md border border-gray-600">
                      <h4 className="text-white text-sm font-medium mb-3">Edit Event Time</h4>
                      
                      {!formData.selectedEventId && (
                        <div className="bg-blue-900/20 p-3 rounded-md border border-blue-800/30 mb-3">
                          <div className="flex items-start text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-400 mr-2 mt-0.5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span className="text-gray-300">
                              <strong>Pro Tip:</strong> Select an event from the list below before editing the time.
                            </span>
                          </div>
                        </div>
                      )}
                      
                      <div className="grid grid-cols-2 gap-3 mb-3">
                        <div>
                          <label className="block text-gray-400 text-xs uppercase tracking-wider mb-1">Start Time</label>
                          <input
                            type="time"
                            name="startTime"
                            value={formData.startTime}
                            onChange={handleInputChange}
                            className="w-full p-2 rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors"
                          />
                        </div>
                        <div>
                          <label className="block text-gray-400 text-xs uppercase tracking-wider mb-1">End Time</label>
                          <input
                            type="time"
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleInputChange}
                            className="w-full p-2 rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors"
                          />
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={handleUpdateEventTime}
                          disabled={!formData.selectedEventId || !formData.startTime || !formData.endTime || loading}
                          className="px-3 py-1.5 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                        >
                          {loading ? (
                            <span className="flex items-center">
                              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                              Updating...
                            </span>
                          ) : (
                            'Update Time'
                          )}
                        </Button>
                      </div>
                      <p className="text-gray-400 text-xs mt-2">
                        {formData.selectedEventId 
                          ? 'Click "Update Time" to save changes' 
                          : 'Select an event below to edit its time'}
                      </p>
                    </div>
                    
                    <div className="space-y-2 mt-3 max-h-60 overflow-y-auto pr-1">
                      {calendarEvents.map((event, index) => {
                        const eventDate = moment(event.start).format('YYYY-MM-DD');
                        const isToday = eventDate === todayString;
                        const isCurrentDate = currentDate && (eventDate === currentDate.toISOString().split('T')[0]);
                        
                        return (
                          <div 
                            id={`event-${event._id}`}
                            key={event._id} 
                            className={`bg-gray-700/50 p-3 rounded-md border 
                              ${formData.selectedEventId === event._id ? 'border-blue-500' : 'border-gray-600'} 
                              ${isToday ? 'bg-blue-900/30' : ''} 
                              ${isCurrentDate && !formData.selectedEventId ? 'bg-green-900/30 border-green-500' : ''} 
                              flex justify-between items-center cursor-pointer hover:bg-gray-700/80 transition-colors`}
                            onClick={() => handleEventSelect(event)}
                          >
                            <div>
                              <div className="text-white font-medium flex items-center">
                                <span className="px-2 py-0.5 bg-blue-800 rounded-full text-xs mr-2">#{index + 1}</span>
                                {moment(event.start).format('dddd, MMMM D, YYYY')}
                                {isToday && (
                                  <span className="ml-2 bg-blue-500 text-white text-xs px-2 py-0.5 rounded-full">
                                    Today
                                  </span>
                                )}
                                {isCurrentDate && (
                                  <span className="ml-2 bg-green-500 text-white text-xs px-2 py-0.5 rounded-full">
                                    Selected Day
                                  </span>
                                )}
                              </div>
                              <div className="text-gray-400 text-sm">
                                {moment(event.start).format('h:mm A')} - {moment(event.end).format('h:mm A')}
                              </div>
                              <div className="text-xs text-gray-500 mt-1">
                                UTC: {new Date(event.start).toISOString()} 
                              </div>
                              <div className="text-xs text-gray-500">
                                UTC Hours: {new Date(event.start).getUTCHours()}:{String(new Date(event.start).getUTCMinutes()).padStart(2,'0')} - 
                                Local Hours: {new Date(event.start).getHours()}:{String(new Date(event.start).getMinutes()).padStart(2,'0')}
                              </div>
                            </div>
                            <Badge 
                              bg={event.isRecurring ? "success" : "primary"}
                              className="text-xs"
                            >
                              {event.recurrencePattern || 'One-time'}
                            </Badge>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Tab>
              <Tab eventKey="notes" title="Notes">
                <div className="bg-gray-800/50 p-5 rounded-lg border border-gray-700">
                  <h3 className="text-white text-base font-medium mb-4 flex items-center">
                    <span className="bg-yellow-500/20 text-yellow-400 p-1.5 rounded-md mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    </span>
                    Notes
                  </h3>
                  
                  <textarea
                    rows={4}
                    name="notes"
                    value={formData.notes}
                    onChange={handleInputChange}
                    placeholder="Add notes here..."
                    className="w-full p-3 rounded-md bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none transition-colors"
                  ></textarea>
                </div>
              </Tab>
            </Tabs>
            <div className="flex justify-end space-x-3 pt-4 border-t border-gray-700">
              <Button 
                variant="primary"
                onClick={onClose}
                className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors flex items-center"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
        
        {/* Delete Confirmation Modal */}
        <DeleteConfirmationModal
          show={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleConfirmDelete}
          title={deleteConfirmationData.title}
          message={deleteConfirmationData.message}
        />
        
        {/* Update All Events Confirmation Modal */}
        <Modal
          show={showUpdateAllConfirmation}
          onHide={() => setShowUpdateAllConfirmation(false)}
          centered
          size="md"
          className="modern-modal"
        >
          <Modal.Header closeButton className="bg-gray-800 text-white border-gray-700">
            <Modal.Title className="text-lg font-semibold">Update Event Time</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-gray-900 text-white">
            <p className="mb-4">This is a recurring job with {calendarEvents.length} events in the series. How would you like to apply this time change?</p>
            
            <div className="space-y-3">
              <div className="p-3 bg-blue-900/20 border border-blue-800/30 rounded-md">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="update-all"
                    name="update-option"
                    className="mr-3 h-4 w-4 text-blue-600 border-gray-600"
                    defaultChecked
                  />
                  <label htmlFor="update-all" className="text-white">
                    Update all events in series
                  </label>
                </div>
                <p className="text-gray-400 text-sm mt-1 ml-7">
                  All {calendarEvents.length} events will be updated to start at {updateAllConfirmationData.startTime} and end at {updateAllConfirmationData.endTime} on their respective days
                </p>
              </div>
              
              <div className="p-3 bg-gray-800 border border-gray-700 rounded-md">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="update-one"
                    name="update-option"
                    className="mr-3 h-4 w-4 text-blue-600 border-gray-600"
                  />
                  <label htmlFor="update-one" className="text-white">
                    Update only this event
                  </label>
                </div>
                <p className="text-gray-400 text-sm mt-1 ml-7">
                  Only the selected event on {moment(updateAllConfirmationData.eventDate).format('MMM D, YYYY')} will be updated
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-gray-900 text-white border-gray-700">
            <Button 
              variant="outline-secondary" 
              onClick={() => setShowUpdateAllConfirmation(false)}
              className="px-4 py-2 rounded-md border border-gray-600 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors"
            >
              Cancel
            </Button>
            <Button 
              variant="primary" 
              onClick={() => {
                const updateAll = document.getElementById('update-all').checked;
                if (updateAll) {
                  updateAllEvents();
                } else {
                  updateSelectedEventOnly();
                }
              }}
              className="px-4 py-2 rounded-md bg-blue-600 text-white hover:bg-blue-700 transition-colors"
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal>
    </>
  );
};

export default JobDetailsModal;
