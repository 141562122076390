import React, { useState } from 'react';
import { Modal, Button, Tabs, Badge, Tooltip, Empty, Card, Row, Col } from 'antd';
import { format } from 'date-fns';
import { 
  UserOutlined, 
  TeamOutlined, 
  ClockCircleOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  CalendarOutlined,
  FileTextOutlined,
  BellOutlined,
  ToolOutlined,
  ScheduleOutlined,
  CompassOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import axiosInstance from '../utils/axiosInstance';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const { TabPane } = Tabs;

/**
 * Modal that displays all jobs scheduled for a specific day
 * Allows for quick actions like assigning employees, marking as complete, etc.
 */
const DayJobsModal = ({ isOpen, onClose, date, jobs, onAssign, onRefresh, onViewChange }) => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const navigate = useNavigate();
  
  // Return null if no date is provided or if the modal is not open
  if (!date || !isOpen) return null;
  
  // Safely format the date
  let formattedDate;
  try {
    formattedDate = format(new Date(date), 'EEEE, MMMM d, yyyy');
  } catch (error) {
    console.error('Error formatting date:', error, date);
    formattedDate = 'Invalid Date';
  }
  
  // Format date for URL parameters
  const formattedUrlDate = format(new Date(date), 'yyyy-MM-dd');
  
  // Filter jobs based on active tab
  const filteredJobs = jobs ? jobs.filter(job => {
    if (activeTab === 'all') return true;
    if (activeTab === 'completed') return job.status === 'Completed';
    if (activeTab === 'pending') return job.status === 'Pending' || job.status === 'Scheduled';
    return true;
  }) : [];
  
  // Group jobs by time
  const groupedJobs = filteredJobs.reduce((acc, job) => {
    // Extract hour from job's scheduled time
    const scheduledTime = job.scheduledTime ? new Date(job.scheduledTime) : null;
    let timeKey;
    
    try {
      timeKey = scheduledTime ? format(scheduledTime, 'h:mm a') : 'Unspecified Time';
    } catch (error) {
      console.error('Error formatting scheduled time:', error, scheduledTime);
      timeKey = 'Unspecified Time';
    }
    
    if (!acc[timeKey]) {
      acc[timeKey] = [];
    }
    
    acc[timeKey].push(job);
    return acc;
  }, {});
  
  // Sort time slots chronologically
  const sortedTimeSlots = Object.keys(groupedJobs).sort((a, b) => {
    if (a === 'Unspecified Time') return 1;
    if (b === 'Unspecified Time') return -1;
    
    try {
      const timeA = new Date(`1970/01/01 ${a}`);
      const timeB = new Date(`1970/01/01 ${b}`);
      return timeA - timeB;
    } catch (error) {
      console.error('Error sorting time slots:', error, a, b);
      return 0;
    }
  });
  
  const handleStatusChange = async (jobId, newStatus) => {
    try {
      setLoading(true);
      await axiosInstance.put(`/jobs/${jobId}`, { status: newStatus });
      toast.success(`Job marked as ${newStatus}`);
      if (onRefresh) onRefresh();
    } catch (error) {
      console.error('Error updating job status:', error);
      toast.error('Failed to update job status');
    } finally {
      setLoading(false);
    }
  };
  
  const handleRemoveJob = async (jobId) => {
    try {
      setLoading(true);
      await axiosInstance.delete(`/calendar/events/${jobId}`);
      toast.success('Job removed from schedule');
      if (onRefresh) onRefresh();
    } catch (error) {
      console.error('Error removing job:', error);
      toast.error('Failed to remove job from schedule');
    } finally {
      setLoading(false);
    }
  };
  
  const handleCreateNewJob = () => {
    navigate(`/jobs/new?date=${formattedUrlDate}`);
    onClose();
  };
  
  const handleCreateRequest = () => {
    navigate(`/requests/new?date=${formattedUrlDate}`);
    onClose();
  };
  
  const handleCreateTask = () => {
    navigate(`/tasks/new?date=${formattedUrlDate}`);
    onClose();
  };
  
  const handleCreateCalendarEvent = () => {
    // This would be implemented when you have a calendar event creation page
    toast.success('Calendar event creation will be implemented soon');
    onClose();
  };
  
  const handleViewDayView = () => {
    if (onViewChange) {
      onViewChange('day', new Date(date));
    }
    onClose();
  };
  
  const handleViewRouteMap = () => {
    navigate(`/route-map?date=${formattedUrlDate}`);
    onClose();
  };
  
  const handleScheduleExistingJob = () => {
    toast.success('Select a job from the job pool and drag it to this date');
    onClose();
  };
  
  return (
    <Modal
      title={<div className="text-white">{formattedDate}</div>}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={800}
      className="day-jobs-modal"
      centered
    >
      <Tabs 
        activeKey={activeTab} 
        onChange={setActiveTab}
        className="custom-tabs"
      >
        <TabPane tab={`All Jobs (${jobs ? jobs.length : 0})`} key="all" />
        <TabPane 
          tab={`Completed (${jobs ? jobs.filter(j => j.status === 'Completed').length : 0})`} 
          key="completed" 
        />
        <TabPane 
          tab={`Pending (${jobs ? jobs.filter(j => j.status === 'Pending' || j.status === 'Scheduled').length : 0})`} 
          key="pending" 
        />
      </Tabs>
      
      <div className="mb-4">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Button 
              type="primary" 
              icon={<AppstoreOutlined />}
              onClick={handleViewDayView}
              className="w-full"
            >
              View Day Schedule
            </Button>
          </Col>
          <Col span={12}>
            <Button 
              icon={<CompassOutlined />}
              onClick={handleViewRouteMap}
              className="w-full"
            >
              View Route Map
            </Button>
          </Col>
        </Row>
      </div>
      
      <div className="max-h-96 overflow-y-auto pr-2">
        {sortedTimeSlots.length === 0 ? (
          <div className="text-center py-4">
            <Empty 
              image={Empty.PRESENTED_IMAGE_SIMPLE} 
              description={
                <span className="text-gray-400">No jobs scheduled for this day</span>
              }
            />
            
            <div className="mt-6 grid grid-cols-2 gap-4">
              <Card 
                title={<span className="flex items-center"><PlusOutlined className="mr-2" /> Create New</span>}
                className="bg-gray-800 border-gray-700"
                headStyle={{ borderBottom: '1px solid #4a5568', color: 'white' }}
                bodyStyle={{ padding: '16px' }}
              >
                <div className="space-y-3">
                  <Button 
                    type="primary" 
                    icon={<ToolOutlined />}
                    onClick={handleCreateNewJob}
                    className="w-full bg-blue-600 hover:bg-blue-700 border-blue-600"
                  >
                    New Job
                  </Button>
                  
                  <Button 
                    type="primary" 
                    icon={<BellOutlined />}
                    onClick={handleCreateRequest}
                    className="w-full bg-purple-600 hover:bg-purple-700 border-purple-600"
                  >
                    New Request
                  </Button>
                  
                  <Button 
                    type="primary" 
                    icon={<FileTextOutlined />}
                    onClick={handleCreateTask}
                    className="w-full bg-green-600 hover:bg-green-700 border-green-600"
                  >
                    New Task
                  </Button>
                  
                  <Button 
                    type="primary" 
                    icon={<ScheduleOutlined />}
                    onClick={handleCreateCalendarEvent}
                    className="w-full bg-orange-600 hover:bg-orange-700 border-orange-600"
                  >
                    Calendar Event
                  </Button>
                </div>
              </Card>
              
              <Card 
                title={<span className="flex items-center"><CalendarOutlined className="mr-2" /> Schedule Existing</span>}
                className="bg-gray-800 border-gray-700"
                headStyle={{ borderBottom: '1px solid #4a5568', color: 'white' }}
                bodyStyle={{ padding: '16px' }}
              >
                <div className="space-y-3">
                  <p className="text-gray-400 text-sm mb-4">
                    Drag jobs from the job pool to this date on the calendar to schedule them.
                  </p>
                  
                  <Button 
                    icon={<CalendarOutlined />}
                    onClick={handleScheduleExistingJob}
                    className="w-full"
                  >
                    Schedule Existing Job
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4 p-3 bg-gray-800 border border-gray-700 rounded-lg">
              <div className="flex items-center mb-2">
                <InfoCircleIcon className="text-blue-400 mr-2" />
                <span className="text-white font-medium">Event Types</span>
              </div>
              <div className="grid grid-cols-4 gap-2">
                <div className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-blue-500 mr-2"></span>
                  <span className="text-gray-300 text-sm">Jobs</span>
                </div>
                <div className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-purple-500 mr-2"></span>
                  <span className="text-gray-300 text-sm">Requests</span>
                </div>
                <div className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-green-500 mr-2"></span>
                  <span className="text-gray-300 text-sm">Tasks</span>
                </div>
                <div className="flex items-center">
                  <span className="w-3 h-3 rounded-full bg-orange-500 mr-2"></span>
                  <span className="text-gray-300 text-sm">Events</span>
                </div>
              </div>
            </div>
            
            {sortedTimeSlots.map(timeSlot => (
              <div key={timeSlot} className="mb-6">
                <h3 className="text-white text-lg font-medium mb-3 flex items-center">
                  <ClockCircleOutlined className="mr-2 text-blue-400" />
                  {timeSlot}
                  <Badge count={groupedJobs[timeSlot].length} className="ml-2" />
                </h3>
                
                <div className="space-y-3">
                  {groupedJobs[timeSlot].map(job => (
                    <div 
                      key={job._id} 
                      className={`p-4 rounded-lg border ${
                        job.status === 'Completed' ? 'bg-green-900/20 border-green-700' : 
                        job.status === 'Cancelled' ? 'bg-red-900/20 border-red-700' : 
                        'bg-gray-800 border-gray-700'
                      }`}
                      style={{ borderLeftWidth: '4px', borderLeftColor: job.type === 'request' ? '#9f7aea' : job.type === 'task' ? '#48bb78' : job.type === 'event' ? '#ed8936' : '#4299e1' }}
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <h4 className="text-white font-medium">{job.service}</h4>
                          <p className="text-gray-300 text-sm">{job.customer?.name || 'No customer'}</p>
                        </div>
                        
                        <div>
                          <Badge 
                            status={
                              job.status === 'Completed' ? 'success' : 
                              job.status === 'In Progress' ? 'processing' :
                              job.status === 'Cancelled' ? 'error' :
                              'default'
                            } 
                            text={<span className="text-gray-300">{job.status || 'Pending'}</span>} 
                          />
                        </div>
                      </div>
                      
                      <div className="grid grid-cols-2 gap-2 mt-3">
                        <div className="flex items-center text-gray-400 text-sm">
                          <DollarOutlined className="mr-1" />
                          {job.price ? `$${parseFloat(job.price).toFixed(2)}` : 'No price'}
                        </div>
                        
                        <div className="flex items-center text-gray-400 text-sm">
                          <ClockCircleOutlined className="mr-1" />
                          {job.estimatedDuration ? `${Math.round(job.estimatedDuration / 60)} min` : 'No duration'}
                        </div>
                      </div>
                      
                      <div className="flex items-center text-gray-400 text-sm mt-1">
                        <EnvironmentOutlined className="mr-1" />
                        {job.location?.address || 'No address'}
                      </div>
                      
                      <div className="flex items-center text-gray-400 text-sm mt-1">
                        {job.assignedEmployee ? (
                          <div className="flex items-center">
                            <UserOutlined className="mr-1 text-blue-400" />
                            <span>
                              {typeof job.assignedEmployee === 'object' 
                                ? job.assignedEmployee.name 
                                : 'Assigned Employee'}
                            </span>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <UserOutlined className="mr-1" />
                            <span>Unassigned</span>
                          </div>
                        )}
                      </div>
                      
                      <div className="flex justify-end space-x-2 mt-3">
                        <Tooltip title="Assign">
                          <Button 
                            icon={<TeamOutlined />} 
                            onClick={() => onAssign && onAssign(job)}
                            size="small"
                          />
                        </Tooltip>
                        
                        <Tooltip title="Edit">
                          <Button 
                            icon={<EditOutlined />} 
                            onClick={() => window.location.href = `/jobs/edit/${job._id}`}
                            size="small"
                          />
                        </Tooltip>
                        
                        {job.status !== 'Completed' && (
                          <Tooltip title="Mark Complete">
                            <Button 
                              icon={<CheckCircleOutlined />} 
                              onClick={() => handleStatusChange(job._id, 'Completed')}
                              size="small"
                              className="text-green-500"
                              loading={loading}
                            />
                          </Tooltip>
                        )}
                        
                        {job.status !== 'Cancelled' && (
                          <Tooltip title="Cancel">
                            <Button 
                              icon={<CloseCircleOutlined />} 
                              onClick={() => handleStatusChange(job._id, 'Cancelled')}
                              size="small"
                              className="text-red-500"
                              loading={loading}
                            />
                          </Tooltip>
                        )}
                        
                        <Tooltip title="Remove from Schedule">
                          <Button 
                            icon={<DeleteOutlined />} 
                            onClick={() => handleRemoveJob(job._id)}
                            size="small"
                            danger
                            loading={loading}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      
      <div className="flex justify-end mt-4">
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};

// InfoCircle icon component
const InfoCircleIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

export default DayJobsModal; 