import React from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const PasswordRequirements = ({ password, className = '' }) => {
  // Check password strength
  const passwordStrength = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /[0-9]/.test(password),
    special: /[^A-Za-z0-9]/.test(password)
  };

  const getPasswordStrengthScore = () => {
    const criteriaCount = Object.values(passwordStrength).filter(Boolean).length;
    return criteriaCount;
  };

  const renderPasswordStrengthBar = () => {
    const score = getPasswordStrengthScore();
    const percentage = (score / 5) * 100;
    
    let barColor = 'bg-red-500';
    if (score >= 4) barColor = 'bg-green-500';
    else if (score >= 3) barColor = 'bg-yellow-500';
    else if (score >= 2) barColor = 'bg-orange-500';
    
    return (
      <div className="mt-2">
        <div className="w-full bg-gray-700 rounded-full h-2.5">
          <div 
            className={`h-2.5 rounded-full ${barColor}`} 
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <div className="flex justify-between text-xs text-gray-400 mt-1">
          <span>Weak</span>
          <span>Strong</span>
        </div>
      </div>
    );
  };

  return (
    <div className={`bg-gray-800/50 p-4 rounded-lg border border-gray-700 ${className}`}>
      <h4 className="text-sm font-medium text-gray-300 mb-2 flex items-center">
        <ExclamationCircleIcon className="h-5 w-5 mr-1 text-blue-400" />
        Password Requirements
      </h4>
      <ul className="space-y-1 text-xs">
        <li className={`flex items-center ${passwordStrength.length ? 'text-green-400' : 'text-gray-400'}`}>
          {passwordStrength.length ? (
            <CheckCircleIcon className="h-4 w-4 mr-1" />
          ) : (
            <div className="h-4 w-4 mr-1 rounded-full border border-gray-500" />
          )}
          At least 8 characters
        </li>
        <li className={`flex items-center ${passwordStrength.uppercase ? 'text-green-400' : 'text-gray-400'}`}>
          {passwordStrength.uppercase ? (
            <CheckCircleIcon className="h-4 w-4 mr-1" />
          ) : (
            <div className="h-4 w-4 mr-1 rounded-full border border-gray-500" />
          )}
          At least one uppercase letter (A-Z)
        </li>
        <li className={`flex items-center ${passwordStrength.lowercase ? 'text-green-400' : 'text-gray-400'}`}>
          {passwordStrength.lowercase ? (
            <CheckCircleIcon className="h-4 w-4 mr-1" />
          ) : (
            <div className="h-4 w-4 mr-1 rounded-full border border-gray-500" />
          )}
          At least one lowercase letter (a-z)
        </li>
        <li className={`flex items-center ${passwordStrength.number ? 'text-green-400' : 'text-gray-400'}`}>
          {passwordStrength.number ? (
            <CheckCircleIcon className="h-4 w-4 mr-1" />
          ) : (
            <div className="h-4 w-4 mr-1 rounded-full border border-gray-500" />
          )}
          At least one number (0-9)
        </li>
        <li className={`flex items-center ${passwordStrength.special ? 'text-green-400' : 'text-gray-400'}`}>
          {passwordStrength.special ? (
            <CheckCircleIcon className="h-4 w-4 mr-1" />
          ) : (
            <div className="h-4 w-4 mr-1 rounded-full border border-gray-500" />
          )}
          At least one special character (!@#$%^&*)
        </li>
      </ul>
      {password && renderPasswordStrengthBar()}
    </div>
  );
};

export default PasswordRequirements; 