/* global google */ // This line tells the linter that google is globally available
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axiosInstance from '../utils/axiosInstance';
import Header from '../components/Header';
import { 
  GoogleMap, 
  Marker, 
  InfoWindow,
  DirectionsRenderer,
  DirectionsService
} from '@react-google-maps/api';
import { useGoogleMaps } from '../contexts/GoogleMapsContext';
import { 
  DatePicker, 
  Select, 
  Button, 
  Spin, 
  Card, 
  Tag, 
  Tabs, 
  Switch, 
  Badge, 
  Tooltip, 
  Empty, 
  Alert, 
  Space,
  Divider,
  Skeleton,
  Input,
  List,
  message
} from 'antd';
import { 
  CalendarOutlined, 
  UserOutlined, 
  TeamOutlined, 
  EnvironmentOutlined,
  ClockCircleOutlined,
  CarOutlined,
  HomeOutlined,
  AimOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  MenuOutlined,
  WarningOutlined,
  RocketOutlined, 
  DragOutlined,
  ToolOutlined,
  SortAscendingOutlined,
  ThunderboltOutlined,
  ReloadOutlined,
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  EyeOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

// Add global styles for the RouteMap component
import './RouteMap.css';

const { Option } = Select;
const { TabPane } = Tabs;

// Define marker colors for different assignment types
const MARKER_COLORS = {
  EMPLOYEE: {
    default: '#1890ff', // Blue
    colors: [
      '#1890ff', // Blue
      '#13c2c2', // Cyan
      '#52c41a', // Green
      '#722ed1', // Purple
      '#eb2f96'  // Pink
    ]
  },
  CREW: {
    default: '#fa8c16', // Orange
    colors: [
      '#fa8c16', // Orange
      '#faad14', // Yellow
      '#a0d911', // Lime
      '#eb2f96', // Pink
      '#f5222d'  // Red
    ]
  },
  UNASSIGNED: '#8c8c8c' // Gray
};

const RouteMap = () => {
  // Use the Google Maps context
  const { isLoaded, loadError, googleMapsReady } = useGoogleMaps();
  
  // State for date selection
  const [selectedDate, setSelectedDate] = useState(moment());
  const [viewMode, setViewMode] = useState('day'); // 'day' or 'week'
  
  // State for job data
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [crews, setCrews] = useState([]);
  const [companyAddress, setCompanyAddress] = useState(''); // Default address
  const [customStartingPoint, setCustomStartingPoint] = useState('');
  const [useCustomStartingPoint, setUseCustomStartingPoint] = useState(false);
  
  // Map and UI state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  const [mapZoom, setMapZoom] = useState(10);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  
  // Geocoding cache to store previously geocoded addresses
  const [geocodingCache, setGeocodingCache] = useState({});
  
  // Filter state
  const [assignmentFilter, setAssignmentFilter] = useState('all'); // 'all', 'employee', 'crew', 'unassigned'
  const [selectedAssigneeId, setSelectedAssigneeId] = useState(null);
  
  // Optimization usage tracking
  const [optimizationUsage, setOptimizationUsage] = useState({
    used: 0, 
    limit: 10,
    remaining: 10,
    tier: 'basic'
  });
  
  // Route state
  const [markers, setMarkers] = useState([]);
  const [directionsResults, setDirectionsResults] = useState([]);
  const [routeStats, setRouteStats] = useState({});
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(null);
  const [loadingDirections, setLoadingDirections] = useState(false);
  
  // GraphHopper optimization state
  const [optimizedRoute, setOptimizedRoute] = useState(null);
  const [optimizedJobSequence, setOptimizedJobSequence] = useState([]);
  const [loadingOptimization, setLoadingOptimization] = useState(false);
  const [showOptimizedRoute, setShowOptimizedRoute] = useState(false);
  const [manuallyOrderedJobs, setManuallyOrderedJobs] = useState([]);
  const [activeTab, setActiveTab] = useState('jobs'); // 'jobs', 'routes', 'optimized'
  
  // Map API Key
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const navigate = useNavigate();

  // Fetch initial data
  useEffect(() => {
    fetchInitialData();
  }, []);

  // Make fetchInitialData function also refresh calendar events  
  const fetchInitialData = async () => {
    setLoading(true);
    try {
      // Fetch employees
      const employeesResponse = await axiosInstance.get('/employees');
      setEmployees(employeesResponse.data || []);
      
      // Fetch crews
      const crewsResponse = await axiosInstance.get('/crews');
      setCrews(crewsResponse.data || []);
      
      // Try to get business address from profile first
      try {
        const profileResponse = await axiosInstance.get('/profile');
        if (profileResponse.data && profileResponse.data.businessInfo?.address) {
          setCompanyAddress(profileResponse.data.businessInfo.address);
          console.log('Using business address from profile:', profileResponse.data.businessInfo.address);
        } else {
          // Fallback to company settings
          try {
            const settingsResponse = await axiosInstance.get('/settings/company');
            if (settingsResponse.data && settingsResponse.data.address) {
              setCompanyAddress(settingsResponse.data.address);
              console.log('Using address from company settings:', settingsResponse.data.address);
            } else {
              // Default fallback
              setCompanyAddress('123 Main St, Anytown, USA');
              console.log('Using default address');
            }
          } catch (settingsError) {
            console.warn('Could not fetch company settings:', settingsError);
            setCompanyAddress('123 Main St, Anytown, USA');
          }
        }
      } catch (profileError) {
        console.warn('Could not fetch profile data:', profileError);
        
        // Fallback to company settings
        try {
          const settingsResponse = await axiosInstance.get('/settings/company');
          if (settingsResponse.data && settingsResponse.data.address) {
            setCompanyAddress(settingsResponse.data.address);
          } else {
            // Default fallback
            setCompanyAddress('123 Main St, Anytown, USA');
          }
        } catch (settingsError) {
          console.warn('Could not fetch company settings:', settingsError);
          setCompanyAddress('123 Main St, Anytown, USA');
        }
      }
      
      // Now that we have the basic data, fetch calendar events
      if (googleMapsReady) {
        console.log('Fetching calendar events as part of initial data refresh...');
        await fetchCalendarEvents();
      }
      
      message.success('Map data refreshed successfully');
      
    } catch (err) {
      console.error('Error fetching initial data:', err);
      setError('Failed to load data. Please try again.');
      message.error('Failed to refresh map data');
    } finally {
      setLoading(false);
    }
  };

  // Only fetch calendar events when the map is loaded and date/view mode changes
  useEffect(() => {
    if (googleMapsReady) {
      setIsMapLoaded(true);
      fetchCalendarEvents();
    }
  }, [selectedDate, viewMode, googleMapsReady, useCustomStartingPoint, customStartingPoint]);
  
  // Fetch optimization usage
  useEffect(() => {
    const fetchOptimizationUsage = async () => {
      try {
        const response = await axiosInstance.get('/routing/usage');
        setOptimizationUsage(response.data);
        console.log('Optimization usage data:', response.data);
      } catch (error) {
        console.error('Error fetching optimization usage:', error);
        // Fallback to defaults
        setOptimizationUsage({
          used: 0,
          limit: 10,
          remaining: 10,
          tier: 'basic'
        });
      }
    };
    
    fetchOptimizationUsage();
  }, []);

  // Add auto-recalculation when page loads to ensure all locations are loaded properly
  useEffect(() => {
    // Auto refresh the map data every few minutes to keep it current
    const autoRefreshInterval = 300000; // 5 minutes
    
    // Immediate initial load - don't wait for the 2-second delay
    if (googleMapsReady) {
      console.log('Immediately loading map data on component mount...');
      fetchCalendarEvents();
    }
    
    // Additional backup refresh after a short delay to ensure all components are ready
    const initialTimeout = setTimeout(() => {
      if (googleMapsReady) {
        console.log('Backup load to ensure all markers are visible...');
        fetchCalendarEvents();
      }
    }, 2000); // 2 second delay as backup
    
    // Set up recurring refresh
    const intervalId = setInterval(() => {
      if (googleMapsReady) {
        console.log('Auto-refreshing map data...');
        fetchCalendarEvents();
      }
    }, autoRefreshInterval);
    
    // Clean up on component unmount
    return () => {
      clearTimeout(initialTimeout);
      clearInterval(intervalId);
    };
  }, [googleMapsReady]); // Only re-run when googleMapsReady changes

  // Initialize geocoder when map is loaded
  useEffect(() => {
    if (isMapLoaded && window.google && !geocoder) {
      try {
        setGeocoder(new window.google.maps.Geocoder());
      } catch (err) {
        console.error('Error initializing geocoder:', err);
      }
    }
  }, [isMapLoaded, geocoder]);
  
  // Fetch calendar events function
  const fetchCalendarEvents = async () => {
    if (!googleMapsReady) {
      console.log('Google Maps not ready yet, skipping fetchCalendarEvents');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      let url = '/calendar/events?';
      
      if (viewMode === 'day') {
        // For day view, get events for the specific date
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        url += `date=${formattedDate}`;
      } else {
        // For week view, get events for the entire week
        const startOfWeek = selectedDate.clone().startOf('week');
        const endOfWeek = selectedDate.clone().endOf('week');
        url += `start=${startOfWeek.format('YYYY-MM-DD')}&end=${endOfWeek.format('YYYY-MM-DD')}`;
      }
      
      const response = await axiosInstance.get(url);
      console.log('Calendar events fetched:', response.data);
      
      setCalendarEvents(response.data || []);
      
      // Process events into markers and routes
      await processEventsData(response.data);
      
    } catch (err) {
      console.error('Error fetching calendar events:', err);
      setError('Failed to load events. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  // Process events data into markers and routes
  const processEventsData = async (events) => {
    if (!events || events.length === 0 || !googleMapsReady) {
      setMarkers([]);
      setDirectionsResults([]);
      setRouteStats({});
      return;
    }
    
    if (!window.google) {
      console.error('Google Maps API not available yet');
      return;
    }
    
    try {
      console.log(`Processing ${events.length} events for map display`);
      
      // Group events by assignee (employee or crew)
      const eventsByAssignee = {};
      const unassignedEvents = [];
      const processedEvents = [];
      
      events.forEach(event => {
        if (!event.job) return;
        
        // Add customer information to the event
        const customerInfo = event.job.customer || {};
        const address = customerInfo.address || event.job.location?.address;
        
        if (!address) {
          console.warn('No address found for event:', event);
          return;
        }
        
        // Create a standardized event object
        const eventWithDetails = {
          ...event,
          address: address,
          title: event.job.service || 'Untitled Job',
          customerName: customerInfo.name || 'Unknown Customer',
          scheduledTime: event.start ? new Date(event.start) : null,
          duration: event.job.estimatedDuration || 60, // Default to 60 minutes
          assigneeType: event.assignedEmployee ? 'employee' : event.assignedCrew ? 'crew' : 'unassigned',
          assigneeId: event.assignedEmployee || event.assignedCrew || null,
        };
        
        processedEvents.push(eventWithDetails);
        
        // Group by assignee
        if (event.assignedEmployee) {
          const assigneeId = event.assignedEmployee;
          eventsByAssignee[assigneeId] = eventsByAssignee[assigneeId] || [];
          eventsByAssignee[assigneeId].push(eventWithDetails);
        } else if (event.assignedCrew) {
          const assigneeId = event.assignedCrew;
          eventsByAssignee[assigneeId] = eventsByAssignee[assigneeId] || [];
          eventsByAssignee[assigneeId].push(eventWithDetails);
        } else {
          unassignedEvents.push(eventWithDetails);
        }
      });
      
      // First, check if we have any cached markers we can reuse immediately
      let markersFromCache = [];
      const eventsToGeocode = [];
      
      for (const event of processedEvents) {
        if (geocodingCache[event.address]) {
          // We have cached geocode data for this address
          console.log(`Using cached geocode for address: ${event.address}`);
          const cachedResult = geocodingCache[event.address][0];
          const position = {
            lat: cachedResult.geometry.location.lat(),
            lng: cachedResult.geometry.location.lng()
          };
          
          markersFromCache.push({
            position: position,
            event: event,
            title: event.title,
            address: cachedResult.fullAddress || cachedResult.formatted_address || event.address,
            addressComponents: cachedResult.addressComponents || {}
          });
        } else {
          eventsToGeocode.push(event);
        }
      }
      
      // If we have any cached markers, display them immediately
      if (markersFromCache.length > 0) {
        console.log(`Immediately displaying ${markersFromCache.length} cached markers`);
        setMarkers(prevMarkers => {
          // Keep any starting point marker
          const startingPointMarker = prevMarkers.find(m => m.isStartingPoint);
          return startingPointMarker 
            ? [startingPointMarker, ...markersFromCache] 
            : markersFromCache;
        });
      }
      
      // Geocode any remaining addresses in the background
      if (eventsToGeocode.length > 0) {
        console.log(`Geocoding ${eventsToGeocode.length} remaining addresses`);
        geocodeEventAddresses([...eventsToGeocode]);
      } else {
        // If all events were from cache and the starting point is loaded,
        // we can go ahead and fit the map to all markers
        if (markersFromCache.length > 0) {
          const allMarkers = [...(markers.filter(m => m.isStartingPoint)), ...markersFromCache];
          setTimeout(() => {
            console.log('Fitting map to all cached markers');
            fitMapToMarkers(allMarkers);
          }, 500);
        }
      }
      
      // If in day view, calculate routes for assigned events
      if (viewMode === 'day' && window.google) {
        console.log('Day view detected, calculating routes');
        calculateRoutes(eventsByAssignee);
      } else {
        console.log('Not in day view or Google Maps not ready, skipping route calculation');
      }
    } catch (error) {
      console.error('Error processing events data:', error);
      setError('Failed to process job locations. Please try again.');
    }
  };
  
  // Geocode addresses for all events
  const geocodeEventAddresses = async (events) => {
    if (!events || events.length === 0 || !googleMapsReady || !window.google) {
      console.log('Cannot geocode addresses: Google Maps not ready or no events');
      setMarkers([]);
      return;
    }
    
    if (!geocoder) {
      try {
        console.log('Initializing geocoder');
        setGeocoder(new window.google.maps.Geocoder());
        // Return early and let the useEffect that watches geocoder trigger this function again
        return;
      } catch (err) {
        console.error('Failed to initialize geocoder:', err);
        return;
      }
    }
    
    const markersData = [];
    
    // Geocode the starting point (company address or custom) first
    let startingPointLocation = null;
    
    if (companyAddress || (useCustomStartingPoint && customStartingPoint)) {
      try {
        const startingPointAddress = useCustomStartingPoint && customStartingPoint 
          ? customStartingPoint 
          : companyAddress;
        
        console.log('Geocoding starting point address:', startingPointAddress);
        const results = await geocodeAddress(startingPointAddress, geocoder);
        if (results && results.length > 0) {
          const location = results[0].geometry.location;
          startingPointLocation = { 
            lat: location.lat(), 
            lng: location.lng() 
          };
          
          console.log('Starting point geocoded successfully:', startingPointLocation);
          
          // Immediately center the map on the starting point
          if (mapInstance && startingPointLocation) {
            console.log('Centering map on starting point');
            setMapCenter(startingPointLocation);
            setMapZoom(12); // A good zoom level to see surrounding area
          }
          
          // Use the full formatted address from geocoding results
          const fullAddress = results[0].fullAddress || results[0].formatted_address || startingPointAddress;
          const addressComponents = results[0].addressComponents || {};
          
          // Add starting point marker
          markersData.push({
            position: startingPointLocation,
            isStartingPoint: true,
            title: useCustomStartingPoint ? 'Custom Starting Point' : 'Business Address',
            address: fullAddress,
            addressComponents
          });
          
          // Update markers immediately with the starting point
          if (markersData.length > 0) {
            console.log('Setting starting point marker immediately');
            setMarkers(prevMarkers => {
              // Filter out any previous starting point markers
              const filteredMarkers = prevMarkers.filter(m => !m.isStartingPoint);
              return [...filteredMarkers, ...markersData];
            });
          }
        } else {
          console.warn('No geocoding results for starting point address');
        }
      } catch (err) {
        console.error('Geocoding error for starting point:', err);
      }
    }
    
    console.log(`Geocoding ${events.length} event addresses`);
    
    // Geocode all event addresses
    for (const event of events) {
      if (!event.address) {
        console.warn('Event has no address, skipping geocoding:', event);
        continue;
      }
      
      try {
        console.log('Geocoding event address:', event.address);
        const results = await geocodeAddress(event.address, geocoder);
        if (results && results.length > 0) {
          const location = results[0].geometry.location;
          
          // Use the full formatted address from geocoding results
          const fullAddress = results[0].fullAddress || results[0].formatted_address || event.address;
          const addressComponents = results[0].addressComponents || {};
          
          markersData.push({
            position: { lat: location.lat(), lng: location.lng() },
            event: event,
            title: event.title,
            address: fullAddress,
            addressComponents
          });
          console.log('Successfully geocoded address for event:', event.title);
        } else {
          console.warn('No geocoding results found for address:', event.address);
        }
      } catch (err) {
        console.error('Geocoding error for address:', event.address, err);
      }
    }
    
    console.log(`Created ${markersData.length} markers from geocoded addresses`);
    setMarkers(markersData);
    
    // Force auto-focus the map on customers
    setTimeout(() => {
      if (markersData.length > 0 && mapInstance) {
        // Automatically fit bounds to show all markers
        console.log('Auto-focusing map on markers after geocoding');
        fitMapToMarkers(markersData);
      }
    }, 500);
  };
  
  // Function to fit the map to display all markers
  const fitMapToMarkers = (markersData) => {
    if (!markersData || markersData.length === 0 || !window.google || !window.google.maps) {
      return;
    }
    
    console.log('Fitting map to markers');
    
    try {
      const bounds = new window.google.maps.LatLngBounds();
      let validMarkersCount = 0;
      
      // Add all markers to bounds
      markersData.forEach((marker, index) => {
        if (marker && marker.position && 
            typeof marker.position.lat === 'number' && 
            typeof marker.position.lng === 'number') {
          try {
            bounds.extend(new window.google.maps.LatLng(
              marker.position.lat,
              marker.position.lng
            ));
            validMarkersCount++;
            console.log(`Added marker ${index} to bounds: ${marker.position.lat}, ${marker.position.lng}`);
          } catch (err) {
            console.error(`Error adding marker ${index} to bounds:`, err);
          }
        }
      });
      
      if (validMarkersCount === 0) {
        console.warn('No valid markers to fit bounds');
        return;
      }
      
      // If we have a map instance, tell it to fit to these bounds
      if (mapInstance) {
        console.log('Fitting map to bounds using mapInstance');
        mapInstance.fitBounds(bounds);
        
        // Optional: adjust zoom if too close or too far
        const listener = window.google.maps.event.addListener(mapInstance, 'idle', () => {
          if (mapInstance.getZoom() > 16) {
            mapInstance.setZoom(16);
          } else if (mapInstance.getZoom() < 4) {
            mapInstance.setZoom(9);
          }
          window.google.maps.event.removeListener(listener);
        });
      } else {
        // Fallback if mapInstance not available
        setMapBounds(markersData);
      }
    } catch (err) {
      console.error('Error fitting map to markers:', err);
    }
  };
  
  // Helper function for geocoding addresses
  const geocodeAddress = (address, geocoderInstance) => {
    if (!geocoderInstance || !window.google || !window.google.maps) {
      return Promise.reject('Geocoder or google maps not available');
    }
    
    console.log('Geocoding address:', address);
    
    // Check if we already have this address in our cache
    if (geocodingCache[address]) {
      console.log('Using cached geocode result for:', address);
      return Promise.resolve(geocodingCache[address]);
    }
    
    // Extract business name from address if present (typically before a comma)
    let businessName = '';
    let streetAddress = address;
    
    if (address.includes(',')) {
      const firstCommaIndex = address.indexOf(',');
      const potentialBusinessName = address.substring(0, firstCommaIndex).trim();
      
      // Check if the first part might be a business name (not a number)
      if (!/^\d+/.test(potentialBusinessName)) {
        businessName = potentialBusinessName;
        // Keep the rest as the street address
        streetAddress = address.substring(firstCommaIndex + 1).trim();
      }
    }
    
    return new Promise((resolve, reject) => {
      try {
        // First try with full address
        geocoderInstance.geocode({ address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            console.log('Geocoding successful, results:', results.length);
            
            // Process the results to ensure we have complete address information
            const enhancedResults = results.map(result => {
              // Extract full formatted address including city, state, and zip
              const formattedAddress = result.formatted_address;
              
              // Store the full address details in the result object
              if (formattedAddress) {
                result.fullAddress = formattedAddress;
                
                // Extract address components (city, state, zip)
                const addressComponents = {};
                result.address_components.forEach(component => {
                  if (component.types.includes('locality')) {
                    addressComponents.city = component.long_name;
                  } else if (component.types.includes('administrative_area_level_1')) {
                    addressComponents.state = component.short_name;
                  } else if (component.types.includes('postal_code')) {
                    addressComponents.zip = component.long_name;
                  } else if (component.types.includes('route')) {
                    addressComponents.street = component.long_name;
                  } else if (component.types.includes('street_number')) {
                    addressComponents.streetNumber = component.long_name;
                  }
                });
                
                result.addressComponents = addressComponents;
              }
              
              return result;
            });
            
            // Store in cache for future use
            setGeocodingCache(prev => ({
              ...prev,
              [address]: enhancedResults
            }));
            
            resolve(enhancedResults);
          } else {
            console.warn(`Geocode was not successful with full address: ${status}`);
            
            // If we identified a business name, try with just the street address
            if (businessName && streetAddress !== address) {
              console.log('Trying to geocode with street address only:', streetAddress);
              
              geocoderInstance.geocode({ address: streetAddress }, (streetResults, streetStatus) => {
                if (streetStatus === window.google.maps.GeocoderStatus.OK) {
                  console.log('Street address geocoding successful, results:', streetResults.length);
                  
                  // Process results to include full address information
                  const enhancedResults = streetResults.map(result => {
                    result.fullAddress = result.formatted_address;
                    
                    // Extract address components
                    const addressComponents = {};
                    result.address_components.forEach(component => {
                      if (component.types.includes('locality')) {
                        addressComponents.city = component.long_name;
                      } else if (component.types.includes('administrative_area_level_1')) {
                        addressComponents.state = component.short_name;
                      } else if (component.types.includes('postal_code')) {
                        addressComponents.zip = component.long_name;
                      }
                    });
                    
                    result.addressComponents = addressComponents;
                    return result;
                  });
                  
                  resolve(enhancedResults);
                } else {
                  console.warn(`Street address geocode was not successful: ${streetStatus}`);
                  
                  // Last attempt: try a more structured approach
                  // Delay and retry with region bias and slight modifications
                  setTimeout(() => {
                    try {
                      geocoderInstance.geocode({ 
                        address: streetAddress,
                        region: 'us',  // Add region bias for US addresses
                        componentRestrictions: { country: 'US' } // Restrict to US
                      }, (retryResults, retryStatus) => {
                        if (retryStatus === window.google.maps.GeocoderStatus.OK) {
                          console.log('Retry geocoding successful, results:', retryResults.length);
                          
                          // Process results to include full address information
                          const enhancedResults = retryResults.map(result => {
                            result.fullAddress = result.formatted_address;
                            return result;
                          });
                          
                          resolve(enhancedResults);
                        } else {
                          console.error(`Geocode retry was not successful: ${retryStatus}`);
                          reject(`Geocode was not successful: ${retryStatus}`);
                        }
                      });
                    } catch (retryErr) {
                      console.error('Error during geocode retry:', retryErr);
                      reject(`Geocode retry error: ${retryErr.message}`);
                    }
                  }, 1000);
                }
              });
            } else {
              // No business name identified, just retry with delay
              setTimeout(() => {
                try {
                  geocoderInstance.geocode({ 
                    address,
                    region: 'us',  // Add region bias for US addresses
                    componentRestrictions: { country: 'US' } // Restrict to US
                  }, (retryResults, retryStatus) => {
                    if (retryStatus === window.google.maps.GeocoderStatus.OK) {
                      console.log('Retry geocoding successful, results:', retryResults.length);
                      
                      // Process results to include full address information
                      const enhancedResults = retryResults.map(result => {
                        result.fullAddress = result.formatted_address;
                        return result;
                      });
                      
                      resolve(enhancedResults);
                    } else {
                      console.error(`Geocode retry was not successful: ${retryStatus}`);
                      reject(`Geocode was not successful: ${retryStatus}`);
                    }
                  });
                } catch (retryErr) {
                  console.error('Error during geocode retry:', retryErr);
                  reject(`Geocode retry error: ${retryErr.message}`);
                }
              }, 1000);
            }
          }
        });
      } catch (err) {
        console.error('Error during geocode:', err);
        reject(`Geocode error: ${err.message}`);
      }
    });
  };
  
  // Function to set map bounds from markers
  const setMapBounds = (markersData) => {
    if (!markersData || markersData.length === 0) {
      // Default center if no markers
      console.log('No markers to set bounds for, using default center');
      setMapCenter({ lat: 37.7749, lng: -122.4194 });
      setMapZoom(10);
      return;
    }
    
    if (markersData.length === 1) {
      // If only one marker, center on it
      console.log('Only one marker, centering on it:', markersData[0].position);
      setMapCenter(markersData[0].position);
      setMapZoom(14);
      return;
    }
    
    if (!window.google || !window.google.maps) {
      // Default center if Google Maps not loaded
      console.log('Google Maps not loaded, using default center');
      setMapCenter({ lat: 37.7749, lng: -122.4194 });
      setMapZoom(10);
      return;
    }
    
    try {
      console.log('Calculating bounds for', markersData.length, 'markers');
      // Create a new bounds object
      const bounds = new window.google.maps.LatLngBounds();
      let validMarkers = 0;
      
      // Extend bounds with each marker position
      markersData.forEach((marker, index) => {
        if (marker && marker.position && 
            typeof marker.position.lat === 'number' && 
            typeof marker.position.lng === 'number') {
          try {
            const point = new window.google.maps.LatLng(
              marker.position.lat,
              marker.position.lng
            );
            bounds.extend(point);
            validMarkers++;
            console.log(`Added marker ${index} to bounds: ${marker.position.lat}, ${marker.position.lng}`);
          } catch (pointErr) {
            console.error(`Error creating LatLng for marker ${index}:`, pointErr);
          }
        } else {
          console.warn(`Invalid marker position for marker ${index}:`, marker?.position);
        }
      });
      
      // Check if we have valid markers
      if (validMarkers === 0) {
        console.warn('No valid marker positions found');
        setMapCenter({ lat: 37.7749, lng: -122.4194 });
        setMapZoom(10);
        return;
      }
      
      console.log(`Added ${validMarkers} valid markers to bounds`);
      
      // Calculate the center and zoom
      if (mapInstance) {
        // If we have a map instance, let it handle fitting bounds
        console.log('Using map instance to fit bounds');
        mapInstance.fitBounds(bounds);
        return;
      }
      
      // Manual bounds calculation as fallback
      try {
        // Calculate the center from bounds
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        
        if (ne && sw) {
          const center = {
            lat: (ne.lat() + sw.lat()) / 2,
            lng: (ne.lng() + sw.lng()) / 2
          };
          
          console.log('Calculated center from bounds:', center);
          setMapCenter(center);
          
          // Set zoom based on bounds size (approximate)
          const latDiff = Math.abs(ne.lat() - sw.lat());
          const lngDiff = Math.abs(ne.lng() - sw.lng());
          const maxDiff = Math.max(latDiff, lngDiff);
          
          // Approximate zoom level based on bounds
          let zoom = 14;
          if (maxDiff > 0.2) zoom = 11;
          if (maxDiff > 0.5) zoom = 10;
          if (maxDiff > 1) zoom = 9;
          if (maxDiff > 2) zoom = 8;
          if (maxDiff > 4) zoom = 7;
          
          console.log('Calculated zoom level:', zoom);
          setMapZoom(zoom);
        } else {
          throw new Error('Invalid bounds northeast or southwest');
        }
      } catch (boundsError) {
        console.error('Error calculating bounds center:', boundsError);
        
        // Alternative approach: Calculate average of marker positions
        let totalLat = 0;
        let totalLng = 0;
        markersData.forEach(marker => {
          if (marker && marker.position) {
            totalLat += marker.position.lat;
            totalLng += marker.position.lng;
          }
        });
        
        const avgCenter = {
          lat: totalLat / validMarkers,
          lng: totalLng / validMarkers
        };
        
        console.log('Using average center fallback:', avgCenter);
        setMapCenter(avgCenter);
        setMapZoom(10); // Safe default zoom
      }
    } catch (err) {
      console.error('Error setting map bounds:', err);
      // Fallback to default center
      setMapCenter({ lat: 37.7749, lng: -122.4194 });
      setMapZoom(10);
    }
  };
  
  // Calculate routes for day view
  const calculateRoutes = (eventsByAssignee) => {
    if (!window.google || !window.google.maps) {
      console.log('Google Maps not available for route calculation');
      return;
    }

    console.log('Starting route calculations...');
    setLoadingDirections(true);
    setDirectionsResults([]);
    setRouteStats({});
    
    // If no events or not in day view, don't calculate routes
    if (Object.keys(eventsByAssignee).length === 0 || viewMode !== 'day') {
      console.log('No events to calculate routes for or not in day view');
      setLoadingDirections(false);
      return;
    }
    
    // Find the starting point marker
    const startingPointMarker = markers.find(marker => marker.isStartingPoint);
    if (!startingPointMarker) {
      console.warn('No starting point found for directions');
      setLoadingDirections(false);
      return;
    }
    
    console.log('Using starting point for all routes:', startingPointMarker.position);
    
    // Get assignees with events for routes
    const assigneeIds = Object.keys(eventsByAssignee);
    if (assigneeIds.length === 0) {
      console.log('No assignees with events');
      setLoadingDirections(false);
      return;
    }
    
    console.log(`Calculating routes for ${assigneeIds.length} assignees`);
    
    // Process each assignee's route in parallel
    Promise.all(
      assigneeIds.map(async assigneeId => {
        // Get assignee's events and sort by scheduled time
        const assigneeEvents = eventsByAssignee[assigneeId] || [];
        if (assigneeEvents.length === 0) {
          console.log(`No events for assignee ${assigneeId}`);
          return null;
        }
        
        console.log(`Processing ${assigneeEvents.length} events for assignee ${assigneeId}`);
        
        // Sort events by scheduled time
        const sortedEvents = [...assigneeEvents].sort((a, b) => {
          if (!a.scheduledTime) return 1;
          if (!b.scheduledTime) return -1;
          return new Date(a.scheduledTime) - new Date(b.scheduledTime);
        });
        
        // Find corresponding markers for these events
        const eventMarkers = sortedEvents.map(event => 
          markers.find(m => m.event && m.event._id === event._id)
        ).filter(m => m); // Remove any undefined markers
        
        // If no markers found, skip
        if (eventMarkers.length === 0) {
          console.log(`No markers found for assignee ${assigneeId}'s events`);
          return null;
        }
        
        console.log(`Found ${eventMarkers.length} markers for assignee ${assigneeId}'s events`);
        
        try {
          // Create route from starting point to all event locations in order
          const routes = await calculateDirectionsForRoute(
            startingPointMarker.position,
            eventMarkers.map(m => m.position),
            assigneeId
          );
          
          console.log(`Route calculation complete for assignee ${assigneeId}:`, 
            routes ? `${routes.length} route segments` : 'no routes');
          
          return routes;
        } catch (routeError) {
          console.error(`Error calculating route for assignee ${assigneeId}:`, routeError);
          return null;
        }
      })
    )
    .then(results => {
      // Filter out null results
      const validResults = results.filter(r => r);
      console.log(`Route calculations complete: ${validResults.length} valid routes`);
      setDirectionsResults(validResults);
      setLoadingDirections(false);
    })
    .catch(error => {
      console.error('Error calculating routes:', error);
      setLoadingDirections(false);
    });
  };
  
  // Calculate directions for a single route
  const calculateDirectionsForRoute = async (startPosition, waypointPositions, assigneeId) => {
    if (!startPosition || !waypointPositions || waypointPositions.length === 0 || !window.google) {
      return null;
    }
    
    try {
      // Determine route color based on assignee
      let routeColor = '#1890ff'; // Default blue
      
      // Find if it's an employee or crew
      const employee = employees.find(e => e._id === assigneeId);
      const crew = crews.find(c => c._id === assigneeId);
      
      if (employee) {
        routeColor = employee.color || MARKER_COLORS.EMPLOYEE.default;
        // If no color, use a color from the employee colors array based on the assignee ID
        if (!employee.color && assigneeId) {
          const index = assigneeId.charCodeAt(0) % MARKER_COLORS.EMPLOYEE.colors.length;
          routeColor = MARKER_COLORS.EMPLOYEE.colors[index];
        }
      } else if (crew) {
        routeColor = crew.color || MARKER_COLORS.CREW.default;
        // If no color, use a color from the crew colors array based on the assignee ID
        if (!crew.color && assigneeId) {
          const index = assigneeId.charCodeAt(0) % MARKER_COLORS.CREW.colors.length;
          routeColor = MARKER_COLORS.CREW.colors[index];
        }
      }
      
      console.log(`Using route color for assignee ${assigneeId}:`, routeColor);
      
      // For Google Directions API, we need to handle waypoints in chunks
      // because there's a limit of 25 waypoints per request
      const MAX_WAYPOINTS_PER_REQUEST = 23; // Leave room for origin and destination
      
      // Create directions results array
      const allDirectionsResults = [];
      
      // Important: We treat the starting point (company address) as the origin
      // and the first job as the first destination

      // SIMPLE CASE: If we have just one location (plus the starting point)
      if (waypointPositions.length === 1) {
        console.log('Calculating direct route from start to single location');
        
        const result = await getDirections(
          startPosition,           // Starting point (company/custom address) as origin
          waypointPositions[0],    // The only job location as destination
          []                       // No waypoints needed
        );
        
        if (result) {
          allDirectionsResults.push({
            result,
            assigneeId,
            routeColor,
            totalDistance: calculateTotalDistance(result),
            totalDuration: calculateTotalDuration(result)
          });
        }
      }
      // NORMAL CASE: If we have a few locations that can fit in a single request
      else if (waypointPositions.length <= MAX_WAYPOINTS_PER_REQUEST) {
        console.log('Calculating route from start through all locations');
        
        // First location is the origin, last location is the destination
        // All locations in between are waypoints
        const result = await getDirections(
          startPosition,                   // Starting point as origin
          waypointPositions[waypointPositions.length - 1], // Last job as final destination
          waypointPositions.slice(0, -1).map(pos => ({
            location: pos,
            stopover: true                 // Force stopover to ensure route follows roads
          }))
        );
        
        if (result) {
          allDirectionsResults.push({
            result,
            assigneeId,
            routeColor,
            totalDistance: calculateTotalDistance(result),
            totalDuration: calculateTotalDuration(result)
          });
        }
      }
      // COMPLEX CASE: If we have many locations requiring multiple requests
      else {
        console.log('Calculating multi-segment route with many waypoints');
        
        // For multiple segments, we'll create a route from:
        // 1. Starting point → jobs[0:22]
        // 2. jobs[22] → jobs[23:45]
        // 3. And so on...
        
        let segmentStartPosition = startPosition; // Start with company address
        
        for (let i = 0; i < waypointPositions.length; i += MAX_WAYPOINTS_PER_REQUEST) {
          // Calculate how many positions to include in this segment
          const remainingPositions = waypointPositions.length - i;
          const positionsInSegment = Math.min(MAX_WAYPOINTS_PER_REQUEST, remainingPositions);
          
          // Get the positions for this segment
          const segmentPositions = waypointPositions.slice(i, i + positionsInSegment);
          
          console.log(`Calculating route segment ${Math.floor(i/MAX_WAYPOINTS_PER_REQUEST) + 1} from ${i} to ${i + positionsInSegment - 1}`);
          
          // If this is the only/last segment or a single point, create a direct route
          if (segmentPositions.length === 1) {
            const result = await getDirections(
              segmentStartPosition,      // Previous segment's end point (or starting point for first segment)
              segmentPositions[0],       // The only position in this segment
              []                         // No waypoints
            );
            
            if (result) {
              allDirectionsResults.push({
                result,
                assigneeId,
                routeColor,
                totalDistance: calculateTotalDistance(result),
                totalDuration: calculateTotalDuration(result)
              });
            }
          }
          // If we have multiple positions in this segment
          else {
            const result = await getDirections(
              segmentStartPosition,       // Previous segment's end point
              segmentPositions[segmentPositions.length - 1], // Last position in this segment
              segmentPositions.slice(0, -1).map(pos => ({
                location: pos,
                stopover: true            // Force stopover for road following
              }))
            );
            
            if (result) {
              allDirectionsResults.push({
                result,
                assigneeId,
                routeColor,
                totalDistance: calculateTotalDistance(result),
                totalDuration: calculateTotalDuration(result)
              });
            }
          }
          
          // Update the start position for the next segment to be the last position of this segment
          segmentStartPosition = segmentPositions[segmentPositions.length - 1];
        }
      }
      
      // Calculate totals for all segments combined
      if (allDirectionsResults.length > 0) {
        // Create assignee name for display
        const assigneeName = employee ? employee.name : crew ? crew.name : 'Unknown';
        const assigneeType = employee ? 'employee' : crew ? 'crew' : 'unknown';
        
        // Add to route stats
        setRouteStats(prevStats => ({
          ...prevStats,
          [assigneeId]: {
            name: assigneeName,
            type: assigneeType,
            eventCount: waypointPositions.length,
            totalDistance: allDirectionsResults.reduce((total, r) => total + r.totalDistance, 0),
            totalDuration: allDirectionsResults.reduce((total, r) => total + r.totalDuration, 0),
            color: routeColor
          }
        }));
      }
      
      return allDirectionsResults;
    } catch (error) {
      console.error('Error calculating directions for route:', error);
      return null;
    }
  };
  
  // Helper function to get directions from Google Maps API
  const getDirections = (origin, destination, waypoints = []) => {
    if (!window.google || !window.google.maps) {
      return Promise.reject('Google Maps not loaded');
    }
    
    return new Promise((resolve, reject) => {
      const directionsService = new window.google.maps.DirectionsService();
      
      directionsService.route({
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: window.google.maps.TravelMode.DRIVING,
        // Additional options to ensure routes follow roads
        provideRouteAlternatives: false,
        avoidHighways: false,
        avoidTolls: false,
        unitSystem: window.google.maps.UnitSystem.METRIC,
        drivingOptions: {
          departureTime: new Date(), // Use current time to get real-time traffic if available
          trafficModel: 'bestguess'
        }
      }, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          resolve(result);
        } else {
          console.error('Directions request failed with status:', status);
          reject(new Error(`Directions request failed: ${status}`));
        }
      });
    });
  };
  
  // Calculate total distance in meters from directions result
  const calculateTotalDistance = (directionsResult) => {
    if (!directionsResult || !directionsResult.routes || directionsResult.routes.length === 0) {
      return 0;
    }
    
    return directionsResult.routes[0].legs.reduce(
      (total, leg) => total + leg.distance.value, 
      0
    );
  };
  
  // Calculate total duration in seconds from directions result
  const calculateTotalDuration = (directionsResult) => {
    if (!directionsResult || !directionsResult.routes || directionsResult.routes.length === 0) {
      return 0;
    }
    
    return directionsResult.routes[0].legs.reduce(
      (total, leg) => total + leg.duration.value, 
      0
    );
  };
  
  // Format distance for display in miles
  const formatDistance = (meters) => {
    // Convert meters to miles (1 meter = 0.000621371 miles)
    const miles = meters * 0.000621371;
    
    if (miles < 0.1) {
      // For very short distances, show in feet
      const feet = meters * 3.28084;
      return `${feet.toFixed(0)} ft`;
    } else if (miles < 10) {
      // For distances less than 10 miles, show with one decimal place
      return `${miles.toFixed(1)} mi`;
    } else {
      // For longer distances, show as whole number
      return `${miles.toFixed(0)} mi`;
    }
  };
  
  // Format duration for display
  const formatDuration = (seconds) => {
    // Validate input
    if (!seconds || isNaN(seconds) || seconds < 0) {
      return '0 min';
    }

    // Cap at a reasonable maximum (24 hours) for display purposes
    const cappedSeconds = Math.min(seconds, 24 * 60 * 60);
    
    const hours = Math.floor(cappedSeconds / 3600);
    const minutes = Math.floor((cappedSeconds % 3600) / 60);
    
    if (hours === 0) {
      return `${minutes} min`;
    } else if (minutes === 0) {
      return `${hours} hr`;
    } else {
      return `${hours} hr ${minutes} min`;
    }
  };
  
  // Get marker color based on assignee
  const getMarkerColor = (event) => {
    if (!event) return MARKER_COLORS.UNASSIGNED;
    
    if (event.assigneeType === 'employee') {
      // Find employee and get their color if available
      const employee = employees.find(e => e._id === event.assigneeId);
      if (employee && employee.color) {
        return employee.color;
      }
      
      // If no specific color, use a color from the employee colors array based on the assignee ID
      if (event.assigneeId) {
        const index = event.assigneeId.charCodeAt(0) % MARKER_COLORS.EMPLOYEE.colors.length;
        return MARKER_COLORS.EMPLOYEE.colors[index];
      }
      
      // Fallback to default employee color
      return MARKER_COLORS.EMPLOYEE.default;
    } else if (event.assigneeType === 'crew') {
      // Find crew and get their color if available
      const crew = crews.find(c => c._id === event.assigneeId);
      if (crew && crew.color) {
        return crew.color;
      }
      
      // If no specific color, use a color from the crew colors array based on the assignee ID
      if (event.assigneeId) {
        const index = event.assigneeId.charCodeAt(0) % MARKER_COLORS.CREW.colors.length;
        return MARKER_COLORS.CREW.colors[index];
      }
      
      // Fallback to default crew color
      return MARKER_COLORS.CREW.default;
    }
    
    return MARKER_COLORS.UNASSIGNED;
  };
  
  // Optimize routes using GraphHopper API
  const optimizeRoutes = async () => {
    // Only works in day view
    if (viewMode !== 'day') {
      message.warning('Route optimization is only available in day view');
      return;
    }

    // Check if we have jobs to optimize
    if (!markers || markers.length <= 1) {
      message.warning('At least two job locations are needed for route optimization');
      return;
    }
    
    // Check if user has exhausted their limit
    if (optimizationUsage.remaining <= 0) {
      message.error(`You've reached the monthly limit of ${optimizationUsage.limit} optimizations for your ${optimizationUsage.tier} plan. Please upgrade your subscription to continue using route optimization.`);
      return;
    }

    // Set loading state
    setLoadingOptimization(true);
    message.loading('Optimizing route sequence...', 0);
    
    try {
      // Get starting point marker
      const startingPointMarker = markers.find(marker => marker.isStartingPoint);
      if (!startingPointMarker) {
        throw new Error('Starting point not found');
      }

      // Get job markers (exclude starting point)
      const jobMarkers = markers.filter(marker => !marker.isStartingPoint);

      // Create a map of job IDs to their original marker data for reference
      const jobsById = {};

      // Prepare the job data for the GraphHopper API
      const jobsData = jobMarkers.map(marker => {
        const jobId = marker.event?._id || `job_${Math.random().toString(36).substr(2, 9)}`;
        
        // Store the marker with its job ID for later reference
        jobsById[jobId] = marker;
        
        return {
          _id: jobId,
          title: marker.title || 'Untitled Job',
          position: marker.position,
          duration: marker.event?.duration ? marker.event.duration * 60 : 3600, // Convert minutes to seconds
          address: marker.address,
          originalData: {
            title: marker.title,
            address: marker.address,
            event: marker.event
          }
        };
      });

      console.log('Sending job data to optimize API:', {
        jobCount: jobsData.length,
        startingPoint: startingPointMarker.position
      });

      // Make API call to backend GraphHopper endpoint
      const response = await axiosInstance.post('routing/optimize', {
        jobs: jobsData,
        startingPoint: startingPointMarker.position,
        vehicleCapacity: 999, // Default high capacity for lawn services
        skillNames: [] // No specific skills required
      });

      console.log('GraphHopper optimization response:', response.data);

      // Process the optimized job sequence
      if (response.data && response.data.jobSequence) {
        const optimizedJobSequence = response.data.jobSequence.map((item, index) => {
          // Find the original marker using the jobId
          const originalMarker = jobsById[item.jobId];
          
          if (!originalMarker) {
            console.warn(`Could not find original marker for job ID ${item.jobId}`);
            return {
              ...item,
              sequenceNumber: index + 1
            };
          }
          
          // Return a rich job item with all original data preserved
          return {
            ...item,
            jobInfo: {
              ...item.jobInfo,
              title: originalMarker.title,
              address: originalMarker.address,
              event: originalMarker.event
            },
            originalMarker,
            sequenceNumber: index + 1
          };
        });
        
        // Update state with enhanced optimized route data
        setOptimizedRoute({
          ...response.data,
          routeDetails: {
            ...response.data.routeDetails,
            // Convert any extreme values to more reasonable estimates if needed
            totalDuration: Math.min(response.data.routeDetails.totalDuration, 24 * 60 * 60) // Cap at 24 hours
          }
        });
        
        setOptimizedJobSequence(optimizedJobSequence);
        
        // Create a manually ordered jobs array from the optimized sequence
        const orderedJobs = optimizedJobSequence.map(item => ({
          ...item.jobInfo,
          marker: item.originalMarker,
          sequenceNumber: item.sequenceNumber
        }));
        
        setManuallyOrderedJobs(orderedJobs);
        
        // Save the optimized route to routeStats for the Routes tab
        const totalDistance = response.data.routeDetails?.totalDistance || 0;
        const totalDuration = response.data.routeDetails?.totalDuration || 0;
        
        // Add a special "Optimized" route to the routeStats
        setRouteStats(prevStats => ({
          ...prevStats,
          optimized: {
            name: "Optimized Route",
            type: "optimized",
            eventCount: optimizedJobSequence.length,
            totalDistance: totalDistance,
            totalDuration: totalDuration,
            color: "#f5222d", // Red color for optimized route
            isOptimized: true,
            timestamp: new Date().toISOString(),
            jobs: optimizedJobSequence.map(job => ({
              id: job.jobId,
              title: job.jobInfo?.title || 'Untitled Job',
              address: job.jobInfo?.address || 'No address',
              sequenceNumber: job.sequenceNumber
            }))
          }
        }));
        
        // Show usage information if available
        if (response.data.usageData) {
          const { used, limit, remaining } = response.data.usageData;
          message.info(
            `Optimization usage: ${used}/${limit} this month (${remaining} remaining)`, 
            5
          );
        }
      } else {
        message.warn('No optimized route found. Try different locations.');
      }
      
      // Switch to the optimized tab
      setActiveTab('optimized');
      
      // Enable optimized route display
      setShowOptimizedRoute(true);
      
      message.success('Route optimized successfully');
      
      // If the optimization was successful, update the local usage count
      if (response.data?.usageData) {
        setOptimizationUsage(response.data.usageData);
      } else {
        // If no usage data returned, increment the used count manually
        setOptimizationUsage(prev => ({
          ...prev,
          used: prev.used + 1,
          remaining: Math.max(0, prev.remaining - 1)
        }));
      }
    } catch (error) {
      console.error('Error optimizing route:', error);
      message.error('Failed to optimize route: ' + (error.response?.data?.msg || error.message));
    } finally {
      setLoadingOptimization(false);
      message.destroy(); // Clear any loading messages
    }
  };

  // Handle manual reordering of jobs
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(manuallyOrderedJobs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setManuallyOrderedJobs(items);
    
    // Update the map display with the new order
    if (showOptimizedRoute) {
      // Reset the optimized sequence so we're showing the manual order
      setOptimizedJobSequence(items.map((item, index) => ({
        jobId: item.event?._id || `manual_${index}`,
        jobInfo: item,
        sequenceNumber: index + 1
      })));
    }
  };

  // Toggle between optimized and manual routes
  const toggleOptimizedRoute = () => {
    setShowOptimizedRoute(!showOptimizedRoute);
  };
  
  // Handlers for controls
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  
  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };
  
  const handlePreviousDay = () => {
    setSelectedDate(prevDate => moment(prevDate).subtract(1, viewMode === 'day' ? 'day' : 'week'));
  };
  
  const handleNextDay = () => {
    setSelectedDate(prevDate => moment(prevDate).add(1, viewMode === 'day' ? 'day' : 'week'));
  };
  
  const handleAssignmentFilterChange = (value) => {
    setAssignmentFilter(value);
    setSelectedAssigneeId(null); // Reset selected assignee when changing filter type
  };
  
  const handleAssigneeChange = (value) => {
    setSelectedAssigneeId(value);
  };

  const handleStartingPointChange = () => {
    // If we've changed the starting point, recalculate the routes
    if (viewMode === 'day') {
      fetchCalendarEvents();
    }
  };

  // When the map component loads, cache the map instance
  useEffect(() => {
    if (isMapLoaded && markers.length > 0 && mapInstance) {
      console.log('Map is loaded and markers exist, fitting bounds now');
      fitMapToMarkers(markers);
      
      // Enable scroll zoom without requiring Ctrl key
      if (mapInstance && window.google) {
        mapInstance.setOptions({
          gestureHandling: 'greedy', // Allows zooming with scroll wheel without Ctrl key
          scrollwheel: true,
          zoomControl: true,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM
          }
        });
      }
    }
  }, [isMapLoaded, markers, mapInstance]);

  // Add this useEffect to force auto-focus when markers are loaded
  useEffect(() => {
    if (markers.length > 0 && mapInstance && isMapLoaded) {
      console.log('Markers updated, fitting map to markers');
      // Add a small delay to ensure the map is ready
      setTimeout(() => {
        fitMapToMarkers(markers);
      }, 500);
    }
  }, [markers, mapInstance, isMapLoaded]);

  return (
    <div className="min-h-screen bg-gray-900 text-gray-200">
      <Header />
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold mb-4 text-white">Route Map</h1>
        
        {/* Quick Instructions Panel */}
        <div className="mb-6 bg-blue-900/60 p-4 rounded-lg shadow border border-blue-700 help-panel">
          <div className="flex items-start">
            <InfoCircleOutlined className="text-blue-400 mr-3 text-xl mt-1" />
            <div>
              <h3 className="text-lg font-medium text-white mb-2">Getting Started</h3>
              <ul className="list-disc pl-5 text-blue-100 space-y-1 text-sm">
                <li>All your scheduled jobs for this day will appear on the map automatically</li>
                <li>Make sure your <strong>Starting Point</strong> includes a complete address with city, state, and zip</li>
                <li>Click on markers to see job details</li>
                <li>Use the <strong>Optimize Route</strong> button to find the most efficient driving sequence</li>
                <li>Toggle between viewing jobs, routes, and optimized sequences using the tabs</li>
              </ul>
            </div>
          </div>
        </div>
        
        {/* Controls section */}
        <div className="mb-6 bg-gray-800 p-4 rounded-lg shadow border border-gray-700">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            {/* Date selection */}
            <div className="bg-gray-700/50 p-3 rounded border border-gray-600">
              <label className="block text-gray-300 mb-2 font-medium flex items-center">
                <CalendarOutlined className="mr-2" />
                Select Date
              </label>
              <div className="flex space-x-2 mb-2">
                <DatePicker 
                  value={selectedDate} 
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  className="flex-grow"
                />
                <Button 
                  type={viewMode === 'day' ? 'primary' : 'default'} 
                  onClick={() => handleViewModeChange('day')}
                >
                  Day
                </Button>
                <Button 
                  type={viewMode === 'week' ? 'primary' : 'default'} 
                  onClick={() => handleViewModeChange('week')}
                >
                  Week
                </Button>
              </div>
              <div className="grid grid-cols-3 gap-2 mb-2">
                <Button onClick={() => handleDateChange(moment())} icon={<AimOutlined />} className="w-full">
                  Today
                </Button>
                <Button onClick={handlePreviousDay} className="w-full" icon={<LeftOutlined />}>
                  {viewMode === 'day' ? 'Prev' : 'Prev Wk'}
                </Button>
                <Button onClick={handleNextDay} className="w-full" icon={<RightOutlined />}>
                  {viewMode === 'day' ? 'Next' : 'Next Wk'}
                </Button>
              </div>
              
              <Button 
                type="primary" 
                icon={<ReloadOutlined />} 
                onClick={fetchInitialData}
                className="w-full"
              >
                Refresh Map
              </Button>
            </div>
            
            {/* Assignment filtering */}
            <div className="bg-gray-700/50 p-3 rounded border border-gray-600">
              <label className="block text-gray-300 mb-2 font-medium flex items-center">
                <TeamOutlined className="mr-2" />
                Filter Jobs
              </label>
              <Select
                value={assignmentFilter}
                onChange={handleAssignmentFilterChange}
                className="w-full mb-2"
              >
                <Option value="all">All Jobs</Option>
                <Option value="employee">Employees</Option>
                <Option value="crew">Crews</Option>
                <Option value="unassigned">Unassigned</Option>
              </Select>
              
              {assignmentFilter === 'employee' && employees.length > 0 && (
                <Select
                  placeholder="Select Employee"
                  value={selectedAssigneeId}
                  onChange={handleAssigneeChange}
                  className="w-full"
                >
                  <Option value={null}>All Employees</Option>
                  {employees.map(employee => (
                    <Option key={employee._id} value={employee._id}>
                      {employee.name}
                    </Option>
                  ))}
                </Select>
              )}
              
              {assignmentFilter === 'crew' && crews.length > 0 && (
                <Select
                  placeholder="Select Crew"
                  value={selectedAssigneeId}
                  onChange={handleAssigneeChange}
                  className="w-full"
                >
                  <Option value={null}>All Crews</Option>
                  {crews.map(crew => (
                    <Option key={crew._id} value={crew._id}>
                      {crew.name}
                    </Option>
                  ))}
                </Select>
              )}
              
              {/* Route Optimization Button (only in day view) */}
              {viewMode === 'day' && (
                <div className="mt-3">
                  <Button 
                    type="primary" 
                    icon={<RocketOutlined />}
                    onClick={optimizeRoutes}
                    loading={loadingOptimization}
                    className="w-full"
                    disabled={markers.length <= 1}
                  >
                    Optimize Driving Route
                  </Button>
                  {showOptimizedRoute && (
                    <Button
                      onClick={toggleOptimizedRoute}
                      icon={showOptimizedRoute ? <ReloadOutlined /> : <RocketOutlined />}
                      className="mt-2 w-full"
                    >
                      {showOptimizedRoute ? 'Reset to Original Order' : 'Show Optimized Route'}
                    </Button>
                  )}
                </div>
              )}
            </div>
            
            {/* Starting point (only show in day view) */}
            {viewMode === 'day' && (
              <div className="bg-gray-700/50 p-3 rounded border border-gray-600">
                <label className="block text-gray-300 mb-2 font-medium flex items-center">
                  <EnvironmentOutlined className="mr-2 text-red-500" /> 
                  Starting Point
                </label>
                
                <div className="flex items-center mb-3">
                  <Switch 
                    checked={useCustomStartingPoint} 
                    onChange={(checked) => {
                      setUseCustomStartingPoint(checked);
                      if (!checked) {
                        // If switching back to company address, recalculate routes
                        setTimeout(handleStartingPointChange, 100);
                      }
                    }} 
                    className="mr-2"
                  />
                  <span className="text-blue-400 font-medium">{useCustomStartingPoint ? 'Custom Address' : 'Business Address (from Profile)'}</span>
                </div>
                
                {useCustomStartingPoint ? (
                  <div className="space-y-2">
                    <div className="mb-1 text-gray-300 text-xs italic">
                      Enter complete address including street, city, state, and zip code for best results
                    </div>
                    <Input 
                      placeholder="Enter full address (123 Main St, Anytown, CA 12345)" 
                      value={customStartingPoint} 
                      onChange={(e) => setCustomStartingPoint(e.target.value)}
                      className="w-full"
                      prefix={<EnvironmentOutlined />}
                    />
                    <Button 
                      type="primary" 
                      icon={<SearchOutlined />}
                      onClick={handleStartingPointChange}
                      title="Update starting point and recalculate routes"
                      className="w-full"
                    >
                      Update Route
                    </Button>
                  </div>
                ) : (
                  <div className="bg-gray-800 p-2 rounded border border-gray-600">
                    <div className="text-white text-sm mb-1">
                      {companyAddress || 'No business address set. Please update in Profile.'}
                    </div>
                    {companyAddress && !companyAddress.includes(',') && (
                      <div className="text-yellow-400 text-xs mb-1">
                        <WarningOutlined className="mr-1" /> Your business address may be incomplete. For best results, include city, state, and zip code.
                      </div>
                    )}
                    <Button 
                      type="primary" 
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => navigate('/profile')}
                      className="w-full mt-1"
                    >
                      Update in Profile
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          
          {/* View mode note */}
          {viewMode === 'week' && (
            <Alert
              message="Note: Route lines are only displayed in day view"
              type="info"
              showIcon
              className="mt-4"
            />
          )}
          
          {/* Optimization Status */}
          {showOptimizedRoute && (
            <Alert
              message="Showing optimized route sequence"
              description={optimizedRoute?.routeDetails ? 
                `Total distance: ${formatDistance(optimizedRoute.routeDetails.totalDistance)}, 
                 Total driving time: ${formatDuration(optimizedRoute.routeDetails.totalDuration)}` : null}
              type="success"
              showIcon
              className="mt-4"
            />
          )}
        </div>
        
        {/* Rest of the component remains unchanged */}
        {loading ? (
          <div className="flex justify-center items-center py-20">
            <Spin size="large" tip="Loading routes..." />
          </div>
        ) : error ? (
          <Alert 
            message="Error" 
            description={error} 
            type="error" 
            showIcon 
            className="mb-4" 
          />
        ) : (
          <div className="flex flex-col space-y-4">
            {/* Map and job sidebar */}
            <div>
              {!googleMapsReady ? (
                <div className="flex justify-center items-center py-20">
                  <Spin size="large" tip="Loading Google Maps..." />
                </div>
              ) : loadError ? (
                <Alert 
                  message="Error Loading Google Maps" 
                  description={`Could not load Google Maps: ${loadError.message}`} 
                  type="error" 
                  showIcon 
                  className="mb-4" 
                />
              ) : (
                <div className="flex flex-col md:flex-row gap-4">
                  {/* Map component */}
                  <div className="w-full relative">
                    <GoogleMap
                      mapContainerStyle={{ height: '70vh', width: '100%' }}
                      center={mapCenter}
                      zoom={mapZoom}
                      options={{ 
                        styles: [
                          {
                            featureType: "poi",
                            elementType: "labels",
                            stylers: [{ visibility: "off" }]
                          },
                          {
                            featureType: "road",
                            elementType: "geometry.stroke",
                            stylers: [{ color: "#7f8c8d" }, { weight: 1 }]
                          },
                          {
                            featureType: "road",
                            elementType: "labels.text.fill",
                            stylers: [{ color: "#333333" }]
                          },
                          {
                            featureType: "transit",
                            elementType: "labels.icon",
                            stylers: [{ visibility: "off" }]
                          }
                        ],
                        fullscreenControl: true,
                        mapTypeControl: true,
                        streetViewControl: true,
                        zoomControl: true,
                        gestureHandling: 'greedy', // Enables scroll to zoom without Ctrl key
                        scrollwheel: true,
                        zoomControlOptions: {
                          position: window.google?.maps?.ControlPosition?.RIGHT_BOTTOM || 3
                        },
                        minZoom: 3,
                        maxZoom: 20,
                        clickableIcons: false, // Disable clicking on POIs to avoid accidental clicks
                        mapTypeId: "roadmap"
                      }}
                      onLoad={(map) => {
                        console.log('Map loaded');
                        setMapInstance(map);
                        setIsMapLoaded(true);
                        
                        // When map is loaded, immediately fetch the calendar data if needed
                        if (markers.length === 0) {
                          console.log('Map loaded but no markers yet, fetching calendar events...');
                          fetchCalendarEvents();
                        }
                        
                        // When map is loaded, if we already have markers, fit the map to them
                        if (markers.length > 0) {
                          console.log('Map loaded with markers, fitting to markers...');
                          setTimeout(() => {
                            fitMapToMarkers(markers);
                          }, 500);
                        }
                      }}
                    >
                      {/* Enhanced Legend for map markers */}
                      <div className="absolute top-2 left-2 bg-white/90 dark:bg-gray-800/90 p-3 rounded-lg shadow-md z-10 max-w-xs map-legend">
                        <div className="text-sm font-medium mb-2 text-white border-b border-gray-700 pb-1">Map Legend</div>
                        <div className="grid grid-cols-1 gap-y-2 text-xs">
                          <div className="flex items-center">
                            <div className="h-4 w-4 rounded-full bg-red-600 mr-2 flex items-center justify-center">
                              <HomeOutlined style={{ color: 'white', fontSize: '10px' }} />
                            </div>
                            <span className="text-white">Starting Point</span>
                          </div>
                          <div className="flex items-center">
                            <div className="h-4 w-4 rounded-full bg-blue-500 mr-2"></div>
                            <span className="text-white">Employee Jobs</span>
                          </div>
                          <div className="flex items-center">
                            <div className="h-4 w-4 rounded-full bg-orange-500 mr-2"></div>
                            <span className="text-white">Crew Jobs</span>
                          </div>
                          <div className="flex items-center">
                            <div className="h-4 w-4 rounded-full bg-gray-500 mr-2"></div>
                            <span className="text-white">Unassigned Jobs</span>
                          </div>
                          {showOptimizedRoute && (
                          <div className="flex items-center border-t border-gray-700 pt-2 mt-1">
                            <div className="flex items-center justify-center h-5 w-5 rounded-full bg-blue-500 mr-2 text-white font-bold">
                              <span className="text-[11px]">1</span>
                            </div>
                            <span className="text-white">Optimized Route Sequence</span>
                          </div>
                          )}
                        </div>
                      </div>

                      {/* Markers - Only render if window.google is available */}
                      {window.google && markers.filter(marker => {
                        // Filter markers based on assignment filter
                        if (!marker.event) return true; // Always show starting point
                        
                        if (assignmentFilter === 'all') {
                          return true;
                        } else if (assignmentFilter === 'unassigned') {
                          return !marker.event.assigneeId;
                        } else if (assignmentFilter === 'employee') {
                          return marker.event.assigneeType === 'employee' && 
                            (!selectedAssigneeId || marker.event.assigneeId === selectedAssigneeId);
                        } else if (assignmentFilter === 'crew') {
                          return marker.event.assigneeType === 'crew' && 
                            (!selectedAssigneeId || marker.event.assigneeId === selectedAssigneeId);
                        }
                        
                        return true;
                      }).map((marker, index) => {
                        // Only render if window.google is available
                        if (!window.google) return null;
                        
                        // Custom marker style based on type
                        const isStartingPoint = marker.isStartingPoint;
                        let markerColor = '#FF0000'; // Default red for starting point
                        
                        if (!isStartingPoint && marker.event) {
                          // Get the appropriate color based on assignment
                          markerColor = getMarkerColor(marker.event);
                          console.log(`Marker ${index} color for ${marker.event.assigneeType}:`, markerColor);
                        }
                        
                        // Make starting point larger and more prominent
                        const scaleFactor = isStartingPoint ? 1.5 : 1;
                        
                        // Check if this marker is part of an optimized sequence
                        let sequenceNumber = null;
                        if (showOptimizedRoute && !isStartingPoint) {
                          const sequenceItem = optimizedJobSequence.find(item => 
                            item.jobId === marker.event?._id ||
                            (item.jobInfo && marker.event && item.jobInfo.event?._id === marker.event._id)
                          );
                          
                          if (sequenceItem) {
                            sequenceNumber = optimizedJobSequence.indexOf(sequenceItem) + 1;
                          }
                        }
                        
                        // Custom marker icon
                        const markerIcon = isStartingPoint ? {
                          // Special icon for starting point
                          path: window.google.maps.SymbolPath.CIRCLE,
                          fillColor: '#FF0000',
                          fillOpacity: 1.0,
                          strokeWeight: 3,
                          strokeColor: '#FFFFFF',
                          scale: 12,
                          zIndex: 1000 // Ensure starting point is on top
                        } : {
                          // Regular icon for job markers
                          path: window.google.maps.SymbolPath.CIRCLE,
                          fillColor: markerColor,
                          fillOpacity: 0.9,
                          strokeWeight: 2,
                          strokeColor: '#FFFFFF',
                          scale: 8 * scaleFactor
                        };
                        
                        // If this is an optimized route marker, we'll create a custom HTML marker instead
                        if (sequenceNumber !== null) {
                          // Create a custom HTML marker with sequence number
                          const label = {
                            text: sequenceNumber.toString(),
                            color: '#FFFFFF',
                            fontSize: '12px',
                            fontWeight: 'bold'
                          };
                          
                          return (
                            <Marker
                              key={`marker-${index}`}
                              position={marker.position}
                              title={`#${sequenceNumber}: ${marker.title}`}
                              icon={markerIcon}
                              label={label}
                              onClick={() => setSelectedMarker(marker)}
                              zIndex={isStartingPoint ? 1000 : (100 + sequenceNumber)}
                              animation={window.google.maps.Animation.DROP}
                            />
                          );
                        }
                        
                        // Standard marker for non-optimized routes
                        return (
                          <Marker
                            key={`marker-${index}`}
                            position={marker.position}
                            title={marker.title}
                            icon={markerIcon}
                            onClick={() => setSelectedMarker(marker)}
                            zIndex={isStartingPoint ? 1000 : 100}
                            animation={isStartingPoint ? window.google.maps.Animation.BOUNCE : null}
                          />
                        );
                      })}

                      {/* InfoWindow for selected marker - Only render if window.google is available */}
                      {window.google && selectedMarker && (
                        <InfoWindow
                          position={selectedMarker.position}
                          onCloseClick={() => setSelectedMarker(null)}
                        >
                          <div className="text-gray-900 p-1 max-w-xs">
                            <h3 className="font-bold text-lg mb-1">
                              {selectedMarker.isStartingPoint 
                                ? (useCustomStartingPoint ? 'Custom Starting Point' : 'Business Address')
                                : selectedMarker.title}
                            </h3>
                            <p className="text-sm mb-2 text-gray-700">
                              {selectedMarker.address}
                            </p>
                            
                            {selectedMarker.addressComponents && (
                              <div className="text-xs mb-2 text-gray-700">
                                {selectedMarker.addressComponents.city && selectedMarker.addressComponents.state && (
                                  <div>
                                    {selectedMarker.addressComponents.city}, {selectedMarker.addressComponents.state} {selectedMarker.addressComponents.zip || ''}
                                  </div>
                                )}
                              </div>
                            )}
                            
                            {selectedMarker.event && (
                              <>
                                <p className="text-sm mb-1 text-gray-700">
                                  <span className="font-medium">Customer:</span> {selectedMarker.event.customerName}
                                </p>
                                {selectedMarker.event.scheduledTime && (
                                  <p className="text-sm mb-1 text-gray-700">
                                    <span className="font-medium">Time:</span> {moment(selectedMarker.event.scheduledTime).format('h:mm A')}
                                  </p>
                                )}
                                <p className="text-sm mb-2 text-gray-700">
                                  <span className="font-medium">Duration:</span> {selectedMarker.event.duration} min
                                </p>
                                <div className="mt-1">
                                  {selectedMarker.event.assigneeType === 'employee' && (
                                    <Tag color="blue">
                                      <UserOutlined className="mr-1" />
                                      {employees.find(e => e._id === selectedMarker.event.assigneeId)?.name || 'Unknown Employee'}
                                    </Tag>
                                  )}
                                  {selectedMarker.event.assigneeType === 'crew' && (
                                    <Tag color="orange">
                                      <TeamOutlined className="mr-1" />
                                      {crews.find(c => c._id === selectedMarker.event.assigneeId)?.name || 'Unknown Crew'}
                                    </Tag>
                                  )}
                                  {selectedMarker.event.assigneeType === 'unassigned' && (
                                    <Tag color="gray">Unassigned</Tag>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </InfoWindow>
                      )}

                      {/* Directions Renderers - Only render if window.google is available */}
                      {window.google && viewMode === 'day' && !loadingDirections && 
                        directionsResults.map((routeGroup, groupIndex) => 
                          routeGroup.map((route, routeIndex) => {
                            // Only show routes for filtered assignees
                            const showRoute = 
                              (assignmentFilter === 'all') || 
                              (assignmentFilter === 'employee' && employees.find(e => e._id === route.assigneeId)) ||
                              (assignmentFilter === 'crew' && crews.find(c => c._id === route.assigneeId));
                            
                            // If filtering by specific assignee, only show that assignee's routes
                            if (selectedAssigneeId && route.assigneeId !== selectedAssigneeId) {
                              return null;
                            }
                            
                            const isHighlighted = selectedRouteIndex === groupIndex;
                            
                            return showRoute ? (
                              <DirectionsRenderer
                                key={`directions-${groupIndex}-${routeIndex}`}
                                directions={route.result}
                                options={{
                                  suppressMarkers: true, // We'll display our own markers
                                  preserveViewport: true, // Don't auto-pan to this route
                                  polylineOptions: {
                                    strokeColor: route.routeColor,
                                    strokeOpacity: isHighlighted ? 1.0 : 0.9,
                                    strokeWeight: isHighlighted ? 8 : 6,
                                    zIndex: isHighlighted ? 10 : 1, // Higher zIndex for highlighted routes
                                    strokeStyle: 'solid',
                                    icons: [{ // Add directional arrows for all routes
                                      icon: {
                                        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        scale: isHighlighted ? 5 : 4,
                                        strokeColor: '#FFFFFF',
                                        strokeWeight: 2,
                                        fillColor: '#FFFFFF',
                                        fillOpacity: 1
                                      },
                                      repeat: isHighlighted ? '100px' : '150px'
                                    }]
                                  },
                                  routeIndex: 0 // Use the primary route
                                }}
                              />
                            ) : null;
                          })
                        )
                      }
                    </GoogleMap>
                    
                    {/* Loading overlay for directions */}
                    {loadingDirections && (
                      <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
                        <div className="bg-gray-800 rounded-lg p-4 shadow-lg">
                          <Spin size="large" />
                          <p className="mt-2 text-white">Calculating routes...</p>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {/* Job Sidebar */}
                  <div className="w-full md:w-1/3 p-4 bg-gray-800 rounded-lg h-70vh overflow-auto">
                    <Tabs 
                      defaultActiveKey="jobs" 
                      className="text-white route-map-tabs" 
                      activeKey={activeTab} 
                      onChange={setActiveTab}
                    >
                      <TabPane 
                        tab={<span className="text-blue-400 font-medium">Jobs</span>} 
                        key="jobs"
                      >
                        {loading ? (
                          <Skeleton active paragraph={{ rows: 5 }} />
                        ) : (
                          <div>
                            <h3 className="text-lg font-bold mb-4">
                              {viewMode === 'day' 
                                ? `Jobs on ${selectedDate.format('MMM D, YYYY')}` 
                                : `Jobs for week of ${selectedDate.clone().startOf('week').format('MMM D')} - ${selectedDate.clone().endOf('week').format('MMM D, YYYY')}`
                              }
                            </h3>
                            
                            {calendarEvents.length === 0 ? (
                              <Empty 
                                description={<span className="text-gray-400">No jobs scheduled</span>} 
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                              />
                            ) : (
                              <div className="space-y-3">
                                {calendarEvents
                                  .filter(event => {
                                    // Filter events based on assignment filter
                                    if (assignmentFilter === 'all') {
                                      return true;
                                    } else if (assignmentFilter === 'unassigned') {
                                      return !event.assignedEmployee && !event.assignedCrew;
                                    } else if (assignmentFilter === 'employee') {
                                      return !!event.assignedEmployee && 
                                        (!selectedAssigneeId || event.assignedEmployee === selectedAssigneeId);
                                    } else if (assignmentFilter === 'crew') {
                                      return !!event.assignedCrew && 
                                        (!selectedAssigneeId || event.assignedCrew === selectedAssigneeId);
                                    }
                                    return true;
                                  })
                                  .sort((a, b) => {
                                    // Sort events by date and time
                                    if (!a.start) return 1;
                                    if (!b.start) return -1;
                                    return new Date(a.start) - new Date(b.start);
                                  })
                                  .map((event, index) => {
                                    const jobType = event.job?.service || 'Untitled Job';
                                    const customer = event.job?.customer?.name || 'Unknown Customer';
                                    const address = event.job?.customer?.address || event.job?.location?.address || 'No address';
                                    const startTime = event.start ? moment(event.start).format('h:mm A') : 'No time';
                                    const duration = event.job?.estimatedDuration || 0;
                                    
                                    // Determine assigned to (employee or crew)
                                    let assigneeBadge = null;
                                    if (event.assignedEmployee) {
                                      const employee = employees.find(e => e._id === event.assignedEmployee);
                                      assigneeBadge = (
                                        <Tag color="blue">
                                          <UserOutlined className="mr-1" />
                                          {employee?.name || 'Unknown Employee'}
                                        </Tag>
                                      );
                                    } else if (event.assignedCrew) {
                                      const crew = crews.find(c => c._id === event.assignedCrew);
                                      assigneeBadge = (
                                        <Tag color="orange">
                                          <TeamOutlined className="mr-1" />
                                          {crew?.name || 'Unknown Crew'}
                                        </Tag>
                                      );
                                    } else {
                                      assigneeBadge = <Tag color="gray">Unassigned</Tag>;
                                    }
                                    
                                    // Find the corresponding marker to highlight when clicked
                                    const correspondingMarker = markers.find(m => 
                                      m.event && m.event._id === event._id
                                    );
                                    
                                    return (
                                      <Card 
                                        key={`job-${index}`}
                                        className="bg-gray-700 hover:bg-gray-600 cursor-pointer transition duration-200"
                                        size="small"
                                        onClick={() => {
                                          if (correspondingMarker) {
                                            setSelectedMarker(correspondingMarker);
                                            setMapCenter(correspondingMarker.position);
                                            setMapZoom(15);
                                          }
                                        }}
                                      >
                                        <div className="flex flex-col">
                                          <div className="flex justify-between mb-1">
                                            <h4 className="font-bold text-white">{jobType}</h4>
                                            <span className="text-sm text-gray-300">{viewMode === 'week' ? moment(event.start).format('ddd, MMM D') : ''}</span>
                                          </div>
                                          <div className="text-sm mb-1 text-white">{customer}</div>
                                          <div className="text-xs text-gray-300 mb-1 flex items-start">
                                            <EnvironmentOutlined className="mr-1 mt-1" />
                                            <div>
                                              {address}
                                              {correspondingMarker?.addressComponents && (
                                                <div className="text-gray-400">
                                                  {correspondingMarker.addressComponents.city && correspondingMarker.addressComponents.state && (
                                                    <>{correspondingMarker.addressComponents.city}, {correspondingMarker.addressComponents.state} {correspondingMarker.addressComponents.zip || ''}</>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div className="text-xs text-gray-300 mb-2 flex items-center">
                                            <ClockCircleOutlined className="mr-1" />
                                            {startTime} ({duration} min)
                                          </div>
                                          <div>{assigneeBadge}</div>
                                        </div>
                                      </Card>
                                    );
                                  })
                                }
                              </div>
                            )}
                          </div>
                        )}
                      </TabPane>
                      
                      <TabPane 
                        tab={<span className="text-blue-400 font-medium">Routes</span>} 
                        key="routes"
                      >
                        {viewMode !== 'day' ? (
                          <Alert
                            message="Route information is only available in day view"
                            type="info"
                            showIcon
                            className="mb-4"
                          />
                        ) : loadingDirections ? (
                          <Skeleton active paragraph={{ rows: 5 }} />
                        ) : Object.keys(routeStats).length === 0 ? (
                          <Empty 
                            description={<span className="text-gray-400">No routes calculated</span>} 
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          />
                        ) : (
                          <div className="space-y-4">
                            <h3 className="text-lg font-bold mb-4">Route Summary</h3>
                            
                            {Object.keys(routeStats)
                              .filter(assigneeId => {
                                // Filter routes based on assignment filter
                                const routeStat = routeStats[assigneeId];
                                
                                // Always show optimized route regardless of filter
                                if (assigneeId === 'optimized') {
                                  return true;
                                }
                                
                                if (assignmentFilter === 'all') {
                                  return true;
                                } else if (assignmentFilter === 'employee' && routeStat.type === 'employee') {
                                  return !selectedAssigneeId || assigneeId === selectedAssigneeId;
                                } else if (assignmentFilter === 'crew' && routeStat.type === 'crew') {
                                  return !selectedAssigneeId || assigneeId === selectedAssigneeId;
                                }
                                return false;
                              })
                              .map((assigneeId, index) => {
                                const route = routeStats[assigneeId];
                                const isOptimized = assigneeId === 'optimized';
                                
                                return (
                                  <Card 
                                    key={`route-${index}`}
                                    className={`border-l-4 hover:bg-gray-600 cursor-pointer transition duration-200 ${
                                      isOptimized ? 'bg-gray-700/80 mb-4' : 'bg-gray-700'
                                    } ${selectedRouteIndex === index ? 'border-l-8' : 'border-l-4'}`}
                                    style={{ borderLeftColor: route.color }}
                                    onClick={() => {
                                      // Toggle selected route
                                      setSelectedRouteIndex(selectedRouteIndex === index ? null : index);
                                      
                                      // If clicking on optimized route, also show it
                                      if (isOptimized && !showOptimizedRoute) {
                                        setShowOptimizedRoute(true);
                                        // Switch to optimized tab to show the sequence
                                        setActiveTab('optimized');
                                      }
                                    }}
                                  >
                                    <div className="flex justify-between items-center">
                                      <div>
                                        <h4 className="font-bold flex items-center">
                                          {isOptimized ? (
                                            <ThunderboltOutlined className="mr-2 text-yellow-500" />
                                          ) : route.type === 'employee' ? (
                                            <UserOutlined className="mr-2" />
                                          ) : (
                                            <TeamOutlined className="mr-2" />
                                          )}
                                          {route.name}
                                          {isOptimized && (
                                            <Tag color="yellow" className="ml-2">AI Optimized</Tag>
                                          )}
                                        </h4>
                                        <div className="mt-1 text-sm">
                                          <Badge count={route.eventCount} /> jobs
                                          {isOptimized && route.timestamp && (
                                            <div className="text-xs text-gray-400 mt-1">
                                              Optimized {new Date(route.timestamp).toLocaleTimeString()}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="text-right">
                                        <div className="flex items-center text-sm mb-1">
                                          <CarOutlined className="mr-1" />
                                          {formatDistance(route.totalDistance)}
                                        </div>
                                        <div className="flex items-center text-sm">
                                          <ClockCircleOutlined className="mr-1" />
                                          {formatDuration(route.totalDuration)}
                                        </div>
                                      </div>
                                    </div>
                                    
                                    {isOptimized && (
                                      <div className="mt-2">
                                        <Button 
                                          type="primary" 
                                          size="small" 
                                          block
                                          icon={<EyeOutlined />}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setShowOptimizedRoute(true);
                                            setActiveTab('optimized');
                                          }}
                                        >
                                          View Sequence
                                        </Button>
                                      </div>
                                    )}
                                  </Card>
                                );
                              })
                            }
                            
                            <Divider className="border-gray-600" />
                            
                            <div className="text-sm text-gray-400">
                              <div className="flex items-center justify-between mb-1">
                                <span>Total Jobs:</span>
                                <Badge count={Object.values(routeStats).reduce((sum, route) => sum + route.eventCount, 0)} />
                              </div>
                              <div className="flex items-center justify-between mb-1">
                                <span>Total Distance:</span>
                                <span>{formatDistance(Object.values(routeStats).reduce((sum, route) => sum + route.totalDistance, 0))}</span>
                              </div>
                              <div className="flex items-center justify-between">
                                <span>Total Driving Time:</span>
                                <span>{formatDuration(Object.values(routeStats).reduce((sum, route) => sum + route.totalDuration, 0))}</span>
                              </div>
                            </div>
                            
                            <Divider className="border-gray-600" />
                            
                            <div className="text-xs">
                              <Alert
                                message="Note about routes"
                                description="These are estimated driving times and may vary based on traffic conditions and other factors."
                                type="info"
                                showIcon
                                className="text-xs"
                              />
                            </div>
                          </div>
                        )}
                      </TabPane>

                      {/* Optimized Route Tab */}
                      <TabPane 
                        tab={
                          <span className="text-blue-400 font-medium">
                            <ThunderboltOutlined /> Optimized
                            {optimizedJobSequence.length > 0 && (
                              <Badge count={optimizedJobSequence.length} style={{ marginLeft: 8 }} />
                            )}
                          </span>
                        } 
                        key="optimized"
                      >
                        {viewMode !== 'day' ? (
                          <Alert
                            message="Optimization is only available in day view"
                            type="info"
                            showIcon
                            className="mb-4"
                          />
                        ) : loadingOptimization ? (
                          <div className="text-center py-10">
                            <Spin size="large" tip="Optimizing route sequence..." />
                          </div>
                        ) : optimizedJobSequence.length === 0 ? (
                          <div className="space-y-6 py-4">
                            <Empty 
                              description={
                                <span className="text-gray-400">
                                  No optimized route available
                                </span>
                              } 
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                            <div className="text-center">
                              <Button 
                                type="primary" 
                                icon={<RocketOutlined />} 
                                onClick={optimizeRoutes}
                                disabled={markers.length <= 1}
                              >
                                Optimize Route
                              </Button>
                            </div>
                            <Alert
                              message="What is route optimization?"
                              description={
                                <div>
                                  <p>Route optimization will find the most efficient sequence to visit all jobs, minimizing travel time and distance.</p>
                                  <div className="text-xs mt-2">
                                    <span className={optimizationUsage.remaining > 0 ? 'text-green-400' : 'text-red-400'}>
                                      {optimizationUsage.remaining > 0 
                                        ? `You have ${optimizationUsage.remaining} optimizations remaining this month (${optimizationUsage.used}/${optimizationUsage.limit} used).` 
                                        : `You've reached your monthly limit of ${optimizationUsage.limit} optimizations.`
                                      }
                                    </span>
                                    {optimizationUsage.remaining <= 3 && optimizationUsage.tier !== 'enterprise' && (
                                      <p className="mt-1 text-yellow-400">
                                        Consider upgrading your plan for more optimizations.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              }
                              type="info"
                              showIcon
                            />
                          </div>
                        ) : (
                          <div className="space-y-4">
                            <div className="flex justify-between items-center mb-4">
                              <h3 className="text-lg font-bold">Optimized Job Sequence</h3>
                              <Button 
                                size="small" 
                                onClick={optimizeRoutes} 
                                icon={<ReloadOutlined />}
                                title="Recalculate optimized route"
                              >
                                Refresh
                              </Button>
                            </div>
                            
                            {optimizedRoute?.routeDetails && (
                              <div className="bg-gray-700 p-3 rounded-lg mb-4">
                                <div className="text-sm text-gray-300">
                                  <div className="flex justify-between mb-2">
                                    <span>Total Distance:</span>
                                    <span className="font-bold">{formatDistance(optimizedRoute.routeDetails.totalDistance)}</span>
                                  </div>
                                  <div className="flex justify-between">
                                    <span>Total Travel Time:</span>
                                    <span className="font-bold">{formatDuration(optimizedRoute.routeDetails.totalDuration)}</span>
                                  </div>
                                </div>
                              </div>
                            )}
                            
                            <div className="mb-3">
                              <Alert
                                message="Drag and drop to reorder jobs"
                                description="You can manually adjust the sequence by dragging jobs up or down in the list."
                                type="info"
                                showIcon
                              />
                            </div>
                            
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="job-sequence">
                                {(provided) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="space-y-2"
                                  >
                                    {manuallyOrderedJobs.map((job, index) => {
                                      // Get basic job info
                                      const eventObj = job.event || {};
                                      const jobObj = eventObj.job || job.jobInfo?.job || {};
                                      const customerObj = jobObj.customer || {};
                                      
                                      const jobTitle = jobObj.service || job.title || 'Untitled Job';
                                      const customer = customerObj.name || 'Unknown Customer';
                                      const address = job.address || customerObj.address || 'No address';
                                      const startTime = eventObj.start ? moment(eventObj.start).format('h:mm A') : '';
                                      const duration = jobObj.estimatedDuration || eventObj.duration || 60;
                                      
                                      return (
                                        <Draggable 
                                          key={`sequence-${job.event?._id || index}`} 
                                          draggableId={`sequence-${job.event?._id || index}`} 
                                          index={index}
                                        >
                                          {(provided) => (
                                            <Card
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              className="bg-gray-700 hover:bg-gray-600 transition duration-200"
                                              size="small"
                                              title={
                                                <div className="flex justify-between items-center">
                                                  <div className="flex items-center">
                                                    <Badge 
                                                      count={index + 1} 
                                                      style={{ 
                                                        backgroundColor: '#1890ff',
                                                        marginRight: '8px'
                                                      }} 
                                                    />
                                                    <span className="font-bold">{jobTitle}</span>
                                                  </div>
                                                  <div {...provided.dragHandleProps}>
                                                    <DragOutlined style={{ cursor: 'grab' }} />
                                                  </div>
                                                </div>
                                              }
                                            >
                                              <div className="flex flex-col">
                                                <div className="text-sm mb-1">{customer}</div>
                                                <div className="text-xs text-gray-400 mb-1 flex items-center">
                                                  <EnvironmentOutlined className="mr-1" />
                                                  {address}
                                                </div>
                                                {startTime && (
                                                  <div className="text-xs text-gray-400 mb-1 flex items-center">
                                                    <ClockCircleOutlined className="mr-1" />
                                                    {startTime} ({duration} min)
                                                  </div>
                                                )}
                                                {/* If we have arrival/end time from GraphHopper */}
                                                {optimizedJobSequence[index]?.arrivalTime && (
                                                  <div className="text-xs text-green-500 mt-1">
                                                    Estimated arrival: {
                                                      moment(optimizedJobSequence[index].arrivalTime).format('h:mm A')
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                            </Card>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default RouteMap;
