import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance'; // Use axiosInstance
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import Header from '../components/Header';

// Custom drag styles to fix cursor position issue
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 16,
  margin: `0 0 12px 0`,
  
  // change background colour if dragging
  background: isDragging ? 'rgba(63, 63, 70, 0.8)' : 'rgba(63, 63, 70, 0.5)',
  borderRadius: '0.5rem',
  border: isDragging ? '1px solid rgba(59, 130, 246, 0.5)' : '1px solid rgba(75, 85, 99, 0.7)',
  boxShadow: isDragging ? '0 4px 6px -1px rgba(59, 130, 246, 0.2)' : 'none',
  
  // styles we need to apply on draggables
  ...draggableStyle,
});

const ManageEmployees = ({ hideHeader = false }) => {
  const [employees, setEmployees] = useState([]);
  const [crews, setCrews] = useState([]);
  const [newCrewName, setNewCrewName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchEmployees();
    fetchCrews();
  }, []);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/employees', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setError('Failed to fetch employees. Please try again.');
    }
    setLoading(false);
  };

  const fetchCrews = async () => {
    try {
      const response = await axiosInstance.get('/crews', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setCrews(response.data);
    } catch (error) {
      console.error('Error fetching crews:', error);
      setError('Failed to fetch crews. Please try again.');
    }
  };

  const handleAddCrew = async () => {
    if (!newCrewName.trim()) {
      setError('Crew name cannot be empty.');
      return;
    }
    try {
      const response = await axiosInstance.post('/crews', { name: newCrewName }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setCrews([...crews, response.data]);
      setNewCrewName('');
    } catch (error) {
      console.error('Error adding crew:', error);
      setError('Failed to add crew. Please try again.');
    }
  };

  const handleDeleteCrew = async (crewId) => {
    if (window.confirm('Are you sure you want to delete this crew?')) {
      try {
        await axiosInstance.delete(`/crews/${crewId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setCrews(crews.filter(crew => crew._id !== crewId));
      } catch (error) {
        console.error('Error deleting crew:', error);
        setError('Failed to delete crew. Please try again.');
      }
    }
  };

  const onDragStart = (start) => {
    // Add a custom class to the body when dragging starts
    document.body.classList.add('dragging');
    
    // This helps with the cursor position
    if (window.navigator.userAgent.includes('Firefox')) {
      // Firefox needs special handling
      document.body.classList.add('firefox-dragging');
    }
  };

  const onDragEnd = async (result) => {
    // Remove the dragging class
    document.body.classList.remove('dragging');
    document.body.classList.remove('firefox-dragging');
    
    const { source, destination } = result;
    if (!destination) return;
  
    const sourceCrewId = source.droppableId;
    const destinationCrewId = destination.droppableId;
    const employeeId = result.draggableId;
  
    if (sourceCrewId === destinationCrewId) return;
  
    console.log('Attempting to move employee:', { employeeId, sourceCrewId, destinationCrewId });
  
    try {
      const response = await axiosInstance.put('/crews/move-employee', {
        employeeId,
        sourceCrewId: sourceCrewId === 'unassigned' ? null : sourceCrewId,
        destinationCrewId: destinationCrewId === 'unassigned' ? null : destinationCrewId
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Move response:', response.data);
      await fetchEmployees();
      await fetchCrews();
    } catch (error) {
      console.error('Error moving employee:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      setError('Failed to move employee. Please check the console for more details.');
    }
  };

  // Content to render
  const content = (
    <>
      {error && (
        <div className="bg-red-900/50 border border-red-500 text-red-200 px-4 py-3 rounded mb-6">
          {error}
        </div>
      )}
      
      <div className="mb-8">
        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={newCrewName}
            onChange={(e) => setNewCrewName(e.target.value)}
            className="flex-1 max-w-xs border border-gray-700 rounded-lg p-3 bg-surface/50 backdrop-blur-sm text-blue-100 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200"
            placeholder="New Crew Name"
          />
          <button
            onClick={handleAddCrew}
            className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-3 rounded-lg hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50 flex items-center space-x-2"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Add Crew</span>
          </button>
        </div>
      </div>

      <style>
        {`
        /* Custom styles for dragging */
        .dragging {
          cursor: grabbing !important;
        }
        
        /* Fix for Firefox */
        .firefox-dragging * {
          cursor: grabbing !important;
        }
        `}
      </style>

      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {crews.map((crew) => (
            <Droppable key={crew._id} droppableId={crew._id}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="bg-gradient-to-br from-surface to-gray-800 rounded-xl p-6 border border-gray-700 shadow-xl backdrop-blur-sm"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                      {crew.name}
                    </h2>
                    <button
                      onClick={() => handleDeleteCrew(crew._id)}
                      className="text-red-400 hover:text-red-300 transition duration-200 p-2 hover:bg-red-500/10 rounded-lg"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                  <ul className="space-y-3 min-h-[100px]">
                    {crew.members.map((employee, index) => (
                      <Draggable 
                        key={employee._id} 
                        draggableId={employee._id} 
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="cursor-grab"
                          >
                            <div className="text-blue-100 font-medium group-hover:text-blue-300 transition duration-200">
                              {employee.name}
                            </div>
                            <div className="text-gray-400 text-sm">
                              {employee.email}
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
          ))}
          <Droppable droppableId="unassigned">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="bg-gradient-to-br from-surface to-gray-800 rounded-xl p-6 border border-gray-700 shadow-xl backdrop-blur-sm"
              >
                <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400 mb-4">
                  Unassigned Employees
                </h2>
                <ul className="space-y-3 min-h-[100px]">
                  {employees
                    .filter(employee => !crews.some(crew => crew.members.find(member => member._id === employee._id)))
                    .map((employee, index) => (
                      <Draggable 
                        key={employee._id} 
                        draggableId={employee._id} 
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="cursor-grab"
                          >
                            <div className="text-blue-100 font-medium group-hover:text-blue-300 transition duration-200">
                              {employee.name}
                            </div>
                            <div className="text-gray-400 text-sm">
                              {employee.email}
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ul>
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  );

  // If hideHeader is true, just return the content without the container divs
  if (hideHeader) {
    return content;
  }

  // Otherwise, return the content with the container divs
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
      <Header />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="relative mb-8">
            <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
            <h1 className="relative text-4xl font-extrabold sm:text-5xl mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              Manage Crews
            </h1>
          </div>
          {content}
        </div>
      </main>
    </div>
  );
};

export default ManageEmployees;
