// frontend/src/pages/Dashboard.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  PlusIcon,
  UsersIcon,
  MapIcon,
  DocumentIcon,
  ArrowPathIcon,
  EyeIcon,
  EyeSlashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CreditCardIcon,
  ArrowLeftIcon,
  BriefcaseIcon,
  UserGroupIcon,
  UserPlusIcon,
  CalculatorIcon,
} from '@heroicons/react/24/outline';
import Header from '../components/Header';
import { Line, Bar, Doughnut, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { fetchJobs, fetchCustomers, fetchEmployees, fetchAnalytics, fetchAnalyticsCounts } from '../api/dashboardApi';
import axiosInstance from '../utils/axiosInstance';

// Removed import for TrialTimer
// import TrialTimer from '../components/TrialTimer';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

// Add a StripeStatusIndicator component
const StripeStatusIndicator = ({ connected }) => (
  <div className="flex items-center space-x-2 ml-4">
    <div className={`w-3 h-3 rounded-full ${connected ? 'bg-green-500' : 'bg-red-500'}`}></div>
    <span className={`text-sm ${connected ? 'text-green-400' : 'text-red-400'}`}>
      {connected ? 'Stripe connected' : 'Stripe not connected'}
    </span>
  </div>
);

const Dashboard = () => {
  const { user, refreshUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [jobCount, setJobCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [globalTimeRange, setGlobalTimeRange] = useState('7');
  const [customDateRange, setCustomDateRange] = useState({ start: '', end: '' });
  const [showInsights, setShowInsights] = useState(true);
  const [currentChartIndex, setCurrentChartIndex] = useState(0);
  const [counts, setCounts] = useState(null);
  const [stripeConnected, setStripeConnected] = useState(false);
  const [success, setSuccess] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const stripeUpdateAttempted = React.useRef(false);
  const lastStripeCheck = React.useRef(0);

  const quickActions = [
    {
      to: '/jobs',
      icon: BriefcaseIcon,
      text: 'Jobs Hub'
    },
    {
      to: '/customers',
      icon: UserGroupIcon,
      text: 'Customer Hub'
    },
    {
      to: '/quotes?tab=create',
      icon: DocumentIcon,
      text: 'Create Quote'
    },
    {
      to: '/employees?tab=create',
      icon: UserPlusIcon,
      text: 'Add Employee'
    },
    {
      to: '/route-map',
      icon: MapIcon,
      text: 'Route Map'
    },
    {
      to: '/profile?tab=usage',
      icon: CalculatorIcon,
      text: 'Usage Stats'
    }
  ];

  const checkStripeStatus = async () => {
    // Prevent checking too frequently (minimum 5 seconds between checks)
    const now = Date.now();
    if (now - lastStripeCheck.current < 5000) {
      return;
    }
    lastStripeCheck.current = now;

    try {
      console.log('Dashboard: Checking Stripe status');
      const response = await axiosInstance.get('/connect/status');
      console.log('Dashboard: Stripe status response:', response.data);
      
      const statusData = {
        isConnected: !!response.data.accountId,
        accountId: response.data.accountId,
        isOnboarded: response.data.isOnboarded
      };
      
      setStripeConnected(statusData.isOnboarded);
      cacheStripeStatus(statusData);
      
      // Only update user if we haven't attempted it before and it's necessary
      if (!stripeUpdateAttempted.current && 
          statusData.accountId && 
          user && 
          !user.stripeConnectAccountId) {
        stripeUpdateAttempted.current = true;
        console.log('Dashboard: Updating user with Stripe account ID');
        await refreshUser();
      }
    } catch (error) {
      console.error('Dashboard: Error checking Stripe status:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setStripeConnected(false);
      setError('Failed to check Stripe connection status. Please try again later.');
    }
  };

  // Effect for initial load
  useEffect(() => {
    const initializeDashboard = async () => {
      if (!user) {
        return;
      }

      try {
        setLoading(true);
        setError(null);

        // Check if we have cached Stripe status
        const cachedStatus = getStripeStatusFromCache();
        if (cachedStatus) {
          setStripeConnected(cachedStatus.isOnboarded);
        } else {
          await checkStripeStatus();
        }

        await fetchDashboardData();
        setInitialLoadComplete(true);
      } catch (error) {
        console.error('Dashboard: Error during initialization:', error);
        setError('Failed to load dashboard data. Please refresh the page.');
      } finally {
        setLoading(false);
      }
    };

    initializeDashboard();
  }, [user]); // Only depend on user

  // Separate effect for refresh interval
  useEffect(() => {
    if (!initialLoadComplete || !user) return;

    const interval = setInterval(() => {
      fetchDashboardData();
      checkStripeStatus();
    }, 600000); // Refresh every 10 minutes

    return () => clearInterval(interval);
  }, [initialLoadComplete, user]);

  // Reset refs when user changes
  useEffect(() => {
    stripeUpdateAttempted.current = false;
    lastStripeCheck.current = 0;
  }, [user?.id]); // Reset when user ID changes

  // Function to get cached Stripe status
  const getStripeStatusFromCache = () => {
    try {
      const cachedData = localStorage.getItem('stripeStatus');
      if (!cachedData) return null;
      
      const { status, timestamp } = JSON.parse(cachedData);
      const cacheAge = Date.now() - timestamp;
      
      // Cache expires after 30 minutes (1800000 ms)
      if (cacheAge < 1800000) {
        return status;
      } else {
        console.log('Dashboard: Stripe status cache expired');
        return null;
      }
    } catch (error) {
      console.error('Dashboard: Error reading Stripe status from cache:', error);
      return null;
    }
  };

  // Function to cache Stripe status
  const cacheStripeStatus = (status) => {
    try {
      const cacheData = {
        status,
        timestamp: Date.now()
      };
      localStorage.setItem('stripeStatus', JSON.stringify(cacheData));
    } catch (error) {
      console.error('Dashboard: Error caching Stripe status:', error);
    }
  };

  const handleConnectStripe = async () => {
    try {
      console.log('Dashboard: Initializing Stripe Connect...');
      setError(null);
      setSuccess(null);
      
      // Clear the cache before connecting
      localStorage.removeItem('stripeStatus');
      
      const response = await axiosInstance.post('/connect/init');
      
      if (response.data.status === 'already_onboarded') {
        console.log('Dashboard: Stripe already connected:', response.data.message);
        setSuccess(response.data.message);
        checkStripeStatus(); // Refresh the status
        return;
      }
      
      if (response.data.url) {
        console.log('Dashboard: Redirecting to Stripe Connect onboarding...');
        window.location.href = response.data.url;
      } else {
        setError('Failed to get Stripe Connect URL. Please try again.');
      }
    } catch (error) {
      console.error('Dashboard: Error connecting to Stripe:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      if (error.response?.data?.error?.includes('platform-profile')) {
        setError('Stripe platform setup is incomplete. Please contact support to complete the setup.');
      } else {
        setError('Failed to initialize Stripe Connect: ' + (error.response?.data?.message || error.message));
      }
    }
  };

  const handleTimeRangeChange = (e) => {
    const selectedRange = e.target.value;
    setGlobalTimeRange(selectedRange);
    if (selectedRange !== 'custom') {
      setCustomDateRange({ start: '', end: '' });
    }
  };

  const handleCustomDateChange = (e) => {
    setCustomDateRange({ ...customDateRange, [e.target.name]: e.target.value });
  };

  const applyCustomDateRange = () => {
    if (customDateRange.start && customDateRange.end) {
      fetchDashboardData('custom', customDateRange);
    }
  };

  const baseChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
        }
      },
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'rgba(255, 255, 255, 0.9)',
          font: {
            size: 12
          }
        }
      },
      title: {
        color: 'rgba(255, 255, 255, 0.9)',
        font: {
          size: 16,
          weight: 'bold'
        }
      }
    }
  };

  const charts = [
    // Screen 1: Financial Insights
    {
      title: "Revenue Overview",
      charts: [
        {
          title: "Revenue Over Time",
          component: (
            <Line
              data={{
                labels: analyticsData?.revenue?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Total Revenue ($)',
                  data: analyticsData?.revenue?.map((item) => item.totalRevenue) || [],
                  fill: true,
                  backgroundColor: 'rgba(59, 130, 246, 0.2)',
                  borderColor: 'rgba(59, 130, 246, 1)',
                  tension: 0.4,
                }],
              }}
              options={{
                ...baseChartOptions,
                plugins: {
                  ...baseChartOptions.plugins,
                  title: {
                    ...baseChartOptions.plugins.title,
                    text: 'Revenue Trends'
                  }
                }
              }}
            />
          ),
        },
        {
          title: "Monthly Revenue Comparison",
          component: (
            <Bar
              data={{
                labels: analyticsData?.monthlyRevenue?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Revenue',
                  data: analyticsData?.monthlyRevenue?.map((item) => item.total) || [],
                  backgroundColor: 'rgba(54, 162, 235, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Average Job Value",
          component: (
            <Line
              data={{
                labels: analyticsData?.avgJobValue?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Average Value ($)',
                  data: analyticsData?.avgJobValue?.map((item) => item.average) || [],
                  borderColor: 'rgba(255, 99, 132, 1)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
    // Screen 2: Customer Insights
    {
      title: "Customer Insights",
      charts: [
        {
          title: "Customer Growth",
          component: (
            <Bar
              data={{
                labels: analyticsData?.customerGrowth?.map((item) => item._id) || [],
                datasets: [{
                  label: 'New Customers',
                  data: analyticsData?.customerGrowth?.map((item) => item.newCustomers) || [],
                  backgroundColor: 'rgba(153, 102, 255, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Customer Retention",
          component: (
            <Doughnut
              data={{
                labels: ['Retained', 'Lost', 'New'],
                datasets: [{
                  data: analyticsData?.customerRetention || [0, 0, 0],
                  backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Customer Service Types",
          component: (
            <Pie
              data={{
                labels: analyticsData?.serviceTypes?.map((item) => item._id) || [],
                datasets: [{
                  data: analyticsData?.serviceTypes?.map((item) => item.count) || [],
                  backgroundColor: [
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
    // Screen 3: Operational Insights
    {
      title: "Operational Analytics",
      charts: [
        {
          title: "Job Completion Rate",
          component: (
            <Doughnut
              data={{
                labels: ['Completed', 'In Progress', 'Scheduled'],
                datasets: [{
                  data: analyticsData?.jobCompletion || [0, 0, 0],
                  backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Average Service Duration",
          component: (
            <Bar
              data={{
                labels: analyticsData?.serviceDuration?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Hours',
                  data: analyticsData?.serviceDuration?.map((item) => item.avgDuration) || [],
                  backgroundColor: 'rgba(153, 102, 255, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
        {
          title: "Route Efficiency",
          component: (
            <Line
              data={{
                labels: analyticsData?.routeEfficiency?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Jobs Per Route',
                  data: analyticsData?.routeEfficiency?.map((item) => item.jobsPerRoute) || [],
                  borderColor: 'rgba(255, 159, 64, 1)',
                  tension: 0.4,
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
    // Screen 4: Employee Performance
    {
      title: "Employee Performance",
      charts: [
        {
          title: "Jobs Completed by Employee",
          component: (
            <Bar
              data={{
                labels: analyticsData?.employeePerformance?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Completed Jobs',
                  data: analyticsData?.employeePerformance?.map((item) => item.completedJobs) || [],
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                }],
              }}
              options={{
                ...baseChartOptions,
                indexAxis: 'y',
              }}
            />
          ),
        },
        {
          title: "Employee Efficiency Score",
          component: (
            <Line
              data={{
                labels: analyticsData?.employeeEfficiency?.map((item) => item._id) || [],
                datasets: [{
                  label: 'Efficiency Score',
                  data: analyticsData?.employeeEfficiency?.map((item) => item.score) || [],
                  borderColor: 'rgba(255, 99, 132, 1)',
                  fill: true,
                  backgroundColor: 'rgba(255, 99, 132, 0.2)',
                }],
              }}
              options={{
                ...baseChartOptions,
                scales: {
                  y: {
                    beginAtZero: true,
                    max: 100,
                  },
                },
              }}
            />
          ),
        },
        {
          title: "Service Quality Ratings",
          component: (
            <Pie
              data={{
                labels: ['5★', '4★', '3★', '2★', '1★'],
                datasets: [{
                  data: analyticsData?.serviceRatings || [0, 0, 0, 0, 0],
                  backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(255, 99, 132, 0.6)',
                  ],
                }],
              }}
              options={{
                ...baseChartOptions,
              }}
            />
          ),
        },
      ],
    },
  ];

  const nextChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex + 1) % charts.length);
  };

  const prevChart = () => {
    setCurrentChartIndex((prevIndex) => (prevIndex - 1 + charts.length) % charts.length);
  };

  const renderChartCarousel = () => {
    const currentScreen = charts[currentChartIndex];
    return (
      <div className="relative">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">{currentScreen.title}</h2>
          <div className="flex space-x-3">
            <button
              onClick={prevChart}
              className="bg-gradient-to-r from-blue-500 to-blue-600 p-2 rounded-lg hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50"
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
            <button
              onClick={nextChart}
              className="bg-gradient-to-r from-blue-500 to-blue-600 p-2 rounded-lg hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50"
            >
              <ChevronRightIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {currentScreen.charts.map((chart, index) => (
            <div 
              key={index} 
              className="bg-gradient-to-br from-surface to-gray-800 p-6 rounded-xl shadow-lg border border-gray-700 hover:shadow-xl transition duration-300 transform hover:scale-105"
              style={{ height: '400px' }}
            >
              <h3 className="text-lg font-medium mb-4 text-blue-400">{chart.title}</h3>
              <div className="h-[320px]">
                {chart.component}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  // Chart data preparation
  const getChartData = () => {
    if (!analyticsData) return null;

    return {
      revenue: {
        labels: analyticsData.dates,
        datasets: [{
          label: 'Revenue',
          data: analyticsData.revenue,
          borderColor: '#60A5FA',
          tension: 0.4
        }]
      },
      jobs: {
        labels: analyticsData.dates,
        datasets: [{
          label: 'Completed Jobs',
          data: analyticsData.completedJobs,
          borderColor: '#34D399',
          tension: 0.4
        }]
      },
      customers: {
        labels: analyticsData.dates,
        datasets: [{
          label: 'New Customers',
          data: analyticsData.newCustomers,
          borderColor: '#F472B6',
          tension: 0.4
        }]
      }
    };
  };

  const fetchDashboardData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [jobs, customers, employees] = await Promise.all([
        fetchJobs(),
        fetchCustomers(),
        fetchEmployees()
      ]);

      setCounts({
        jobs: jobs.length || 0,
        customers: customers.length || 0,
        employees: employees.length || 0
      });

      // Fetch analytics data
      const data = await fetchAnalytics(globalTimeRange, 
        globalTimeRange === 'custom' ? customDateRange : null
      );
      setAnalyticsData(data);

      // Fetch counts
      const countsData = await fetchAnalyticsCounts();
      setCounts(countsData);

    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
      <Header />

      <main className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {location.pathname !== '/dashboard' && (
          <button
            onClick={handleBack}
            className="mb-6 flex items-center text-gray-400 hover:text-white transition-colors"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            <span>Back</span>
          </button>
        )}

        <div className="flex justify-between items-center mb-6">
          <div className="relative">
            <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
            <h1 className="relative text-4xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              Welcome, {user?.name || 'User'}
            </h1>
          </div>
          <div className="flex items-center">
            <select
              value={globalTimeRange}
              onChange={handleTimeRangeChange}
              className="mr-2 p-2 border rounded bg-gray-800 text-white border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none"
            >
              <option value="7">Past 7 days</option>
              <option value="30">Past 30 days</option>
              <option value="90">Past 90 days</option>
              <option value="all">All time</option>
              <option value="custom">Custom range</option>
            </select>
            {globalTimeRange === 'custom' && (
              <>
                <input
                  type="date"
                  name="start"
                  value={customDateRange.start}
                  onChange={handleCustomDateChange}
                  className="mr-2 p-2 border rounded bg-gray-800 text-white border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none"
                />
                <input
                  type="date"
                  name="end"
                  value={customDateRange.end}
                  onChange={handleCustomDateChange}
                  className="mr-2 p-2 border rounded bg-gray-800 text-white border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none"
                />
                <button
                  onClick={applyCustomDateRange}
                  className="px-4 py-2 rounded-md bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50"
                >
                  Apply
                </button>
              </>
            )}
            <button
              onClick={() => fetchDashboardData(globalTimeRange, globalTimeRange === 'custom' ? customDateRange : null)}
              className="ml-4 px-4 py-2 rounded-md bg-gradient-to-r from-green-500 to-green-600 text-white font-medium hover:from-green-600 hover:to-green-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-green-500/50 flex items-center"
            >
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              Refresh Dashboard
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
            <p>{error}</p>
          </div>
        )}

        {success && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6" role="alert">
            <p>{success}</p>
          </div>
        )}

        {stripeConnected && (
          <div className="mb-8 p-4 bg-green-800/20 rounded-lg shadow-lg border border-green-700/30">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className="flex-shrink-0 bg-white p-2 rounded-full">
                  <CreditCardIcon className="h-6 w-6 text-green-600" />
                </div>
                <div>
                  <div className="flex items-center">
                    <h3 className="text-lg font-semibold text-white mr-2">Stripe Payment Processing</h3>
                    <div className="flex items-center space-x-1 px-2 py-0.5 bg-green-500/20 rounded-full">
                      <div className="w-2 h-2 rounded-full bg-green-500"></div>
                      <span className="text-xs font-medium text-green-300">Connected</span>
                    </div>
                  </div>
                  <p className="text-gray-300 text-sm">Your Stripe account is connected and ready to accept payments from customers</p>
                </div>
              </div>
              <Link
                to="/profile?tab=payments"
                className="px-3 py-1.5 bg-white/10 text-white text-sm rounded-md hover:bg-white/20 transition-colors border border-white/20"
              >
                Manage Stripe
              </Link>
            </div>
          </div>
        )}

        {!stripeConnected && (
          <div className="mb-8 p-5 bg-gradient-to-r from-blue-700 to-blue-900 rounded-lg shadow-xl border border-blue-600 transform hover:scale-[1.01] transition-all duration-300">
            <div className="flex items-center justify-between">
              <div className="flex items-start space-x-4">
                <div className="flex-shrink-0 bg-white p-2 rounded-full">
                  <CreditCardIcon className="h-8 w-8 text-blue-600" />
                </div>
                <div>
                  <div className="flex items-center space-x-2 mb-1">
                    <h3 className="text-xl font-bold text-white">Accept Payments with Stripe</h3>
                    <div className="flex items-center space-x-1 px-2 py-0.5 bg-red-500/20 rounded-full border border-red-400/30">
                      <div className="w-3 h-3 rounded-full bg-red-500 animate-pulse"></div>
                      <span className="text-xs font-medium text-red-300">Not Connected</span>
                    </div>
                  </div>
                  <p className="text-blue-100 mb-4">Connect your Stripe account to start accepting payments from customers and unlock full payment features</p>
                  <button
                    onClick={handleConnectStripe}
                    className="px-4 py-2 bg-white text-blue-600 rounded-md font-medium hover:bg-blue-50 transition-colors inline-flex items-center shadow-lg hover:shadow-white/20"
                  >
                    <CreditCardIcon className="h-5 w-5 mr-2" />
                    Connect Now
                  </button>
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="text-right">
                  <div className="text-white font-bold mb-1">Enable Payment Features</div>
                  <div className="text-blue-200 text-sm">Takes less than 5 minutes to set up</div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
          <OverviewCard 
            title="Jobs" 
            count={counts?.jobs || 0} 
            link="/manage-jobs"
            loading={loading} 
          />
          <OverviewCard 
            title="Customers" 
            count={counts?.customers || 0} 
            link="/manage-customers"
            loading={loading} 
          />
          <StatCard
            icon={<UserGroupIcon className="h-8 w-8 text-purple-400" />}
            title="Employees"
            count={counts?.employees || 0}
            link="/employees"
            color="from-purple-500 to-purple-600"
          />
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">Quick Actions</h2>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-5">
            {quickActions.map((action) => (
              <QuickActionButton
                key={action.text}
                to={action.to}
                icon={action.icon}
                text={action.text}
              />
            ))}
          </div>
        </div>

        <div className="mb-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-semibold">Dashboard Insights</h2>
            <button 
              onClick={() => setShowInsights(!showInsights)} 
              className="text-white hover:text-blue-400 transition-colors duration-200"
            >
              {showInsights ? 
                <EyeSlashIcon className="h-6 w-6" /> : 
                <EyeIcon className="h-6 w-6" />
              }
            </button>
          </div>
          {showInsights && (
            <>
              {loading ? (
                <div className="text-center py-12">
                  <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full mx-auto mb-4"></div>
                  <p>Loading dashboard data...</p>
                </div>
              ) : (
                renderChartCarousel()
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
};

const OverviewCard = ({ title, count, link, loading }) => (
  <div className="bg-gradient-to-br from-surface to-gray-800 p-6 rounded-lg text-center border border-gray-700 shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
    <h3 className="text-lg font-medium text-blue-400">{title}</h3>
    <div className="mt-2 text-3xl font-semibold text-white">
      {loading ? (
        <div className="animate-pulse bg-gray-700 h-8 w-16 rounded mx-auto"></div>
      ) : (
        count
      )}
    </div>
    <div className="mt-4">
      <Link to={link} className="text-blue-400 hover:text-blue-300 transition-colors duration-200">
        View all {title.toLowerCase()}
      </Link>
    </div>
  </div>
);

const StatCard = ({ icon, title, count, link, color = "from-blue-500 to-blue-600" }) => (
  <div className="bg-gradient-to-br from-surface to-gray-800 p-6 rounded-lg border border-gray-700 shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3">
        <div className={`p-3 rounded-lg bg-gradient-to-br ${color}`}>
          {icon}
        </div>
        <div>
          <h3 className="text-lg font-medium text-blue-400">{title}</h3>
          <div className="text-3xl font-semibold text-white">{count}</div>
        </div>
      </div>
      <Link to={link} className="text-blue-400 hover:text-blue-300 transition-colors duration-200">
        View
      </Link>
    </div>
  </div>
);

const QuickActionButton = ({ to, icon: Icon, text, onClick }) => {
  const handleClick = (e) => {
    console.log('QuickActionButton clicked:', { text, to, hasOnClick: !!onClick });
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  if (to) {
    return (
      <Link
        to={to}
        className="bg-gradient-to-br from-surface to-gray-800 p-6 rounded-lg text-center border border-gray-700 shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 group w-full block"
      >
        <div className="flex flex-col items-center space-y-2">
          <Icon className="h-8 w-8 text-blue-400 group-hover:text-blue-300 transition-colors duration-200" />
          <span className="text-white group-hover:text-blue-300 transition-colors duration-200">{text}</span>
        </div>
      </Link>
    );
  }

  return (
    <button
      onClick={handleClick}
      className="bg-gradient-to-br from-surface to-gray-800 p-6 rounded-lg text-center border border-gray-700 shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105 group w-full"
    >
      <div className="flex flex-col items-center space-y-2">
        <Icon className="h-8 w-8 text-blue-400 group-hover:text-blue-300 transition-colors duration-200" />
        <span className="text-white group-hover:text-blue-300 transition-colors duration-200">{text}</span>
      </div>
    </button>
  );
};

export default Dashboard;