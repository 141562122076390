import React from 'react';
import Navbar from '../components/Navbar';
import { 
  ChartBarIcon, 
  UserGroupIcon, 
  ClockIcon, 
  CurrencyDollarIcon 
} from '@heroicons/react/24/outline';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="bg-gradient-to-br from-surface to-gray-800 p-6 rounded-lg border border-gray-700 shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-105">
    <div className="text-blue-400 text-3xl mb-4">
      <Icon className="h-8 w-8" />
    </div>
    <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
    <p className="text-blue-100">{description}</p>
  </div>
);

const About = () => {
  const features = [
    {
      icon: ChartBarIcon,
      title: "Data-Driven Growth",
      description: "Make informed decisions with comprehensive analytics and reporting tools designed specifically for lawn care businesses."
    },
    {
      icon: UserGroupIcon,
      title: "Customer-Focused",
      description: "Build stronger relationships with integrated customer management and communication tools."
    },
    {
      icon: ClockIcon,
      title: "Time-Saving",
      description: "Streamline your operations with efficient scheduling, routing, and job management features."
    },
    {
      icon: CurrencyDollarIcon,
      title: "Revenue Growth",
      description: "Increase your revenue with professional invoicing, online payments, and automated billing systems."
    }
  ];

  return (
    <div className="bg-gradient-to-b from-gray-900 to-background text-text min-h-screen">
      <Navbar />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Hero Section */}
        <div className="text-center mb-16 relative">
          <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
          <h1 className="relative text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            About AUTOLAWN
          </h1>
          <p className="text-xl text-blue-100 max-w-2xl mx-auto relative">
            Empowering lawn care businesses with modern software solutions
          </p>
        </div>

        {/* Mission Section */}
        <div className="max-w-3xl mx-auto mb-16">
          <div className="bg-gradient-to-br from-surface to-gray-800 rounded-lg p-8 border border-gray-700 shadow-lg">
            <h2 className="text-2xl font-bold text-white mb-4">Our Mission</h2>
            <p className="text-blue-100 text-lg leading-relaxed">
              At AUTOLAWN, we're dedicated to transforming the lawn care industry by providing 
              powerful, yet user-friendly software solutions. Our goal is to help lawn care 
              businesses streamline their operations, grow their customer base, and deliver 
              exceptional service through innovative technology.
            </p>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>

        {/* Contact Section */}
        <div className="max-w-3xl mx-auto">
          <div className="bg-gradient-to-br from-surface to-gray-800 rounded-lg p-8 border border-gray-700 shadow-lg text-center">
            <h2 className="text-2xl font-bold text-white mb-6">Get Started Today</h2>
            <p className="text-blue-100 mb-8">
              Ready to take your lawn care business to the next level? Contact our team to learn 
              more about how AUTOLAWN can help you grow.
            </p>
            <div className="space-y-2">
              <p className="text-blue-100">
                <span className="font-semibold">Email:</span>{' '}
                <a href="mailto:support@autolawn.app" className="text-blue-400 hover:text-blue-300">
                  support@autolawn.app
                </a>
              </p>
              <p className="text-blue-100">
                <span className="font-semibold">Phone:</span>{' '}
                <a href="tel:+17249143764" className="text-blue-400 hover:text-blue-300">
                  (724) 914-3764
                </a>
              </p>
              <p className="text-blue-100">
                <span className="font-semibold">Hours:</span> Monday - Friday, 9:00 AM - 5:00 PM EST
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default About;
