import React, { useState } from 'react';
import Modal from './Modal';
import { PDFDownloadLink } from '@react-pdf/renderer';
import QuotePDF from './QuotePDF';
import axiosInstance from '../utils/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import { EnvelopeIcon, PhoneIcon, ExclamationCircleIcon, CheckCircleIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';

const QuoteModal = ({ isOpen, onClose, quote, onConvertToJob, onDelete }) => {
  const { user } = useAuth();
  const [sending, setSending] = useState(false);
  const [sendingSMS, setSendingSMS] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState('one-time');
  const [copied, setCopied] = useState(false);

  // Check if customer has SMS consent
  const hasPhoneNumber = quote?.customerId?.phone || quote?.customerInfo?.phone;
  const hasSmsConsent = quote?.customerId?.smsConsent && !quote?.customerId?.smsOptOut;

  const handleSendEmail = async () => {
    try {
      setSending(true);
      setError('');
      setSuccess('');
      
      const response = await axiosInstance.post(`/quotes/${quote._id}/send`);
      setSuccess('Quote sent successfully via email!');
    } catch (error) {
      console.error('Error sending quote:', error);
      setError(error.response?.data?.message || 'Failed to send quote. Please try again.');
    } finally {
      setSending(false);
    }
  };

  const handleSendSMS = async () => {
    try {
      setSendingSMS(true);
      setError('');
      setSuccess('');
      
      const response = await axiosInstance.post(`/quotes/${quote._id}/send-sms`);
      setSuccess('Quote sent successfully via SMS!');
    } catch (error) {
      console.error('Error sending quote via SMS:', error);
      
      // Check for specific error messages related to SMS consent
      const errorMessage = error.response?.data?.error || error.response?.data?.message;
      
      if (errorMessage && errorMessage.includes('consent') || errorMessage && errorMessage.includes('opted out')) {
        setError(
          'This customer has not consented to receive SMS messages. Please ask them to update their communication preferences.'
        );
      } else if (errorMessage && errorMessage.includes('phone number')) {
        setError(
          'No valid phone number available for this customer. Please update their contact information.'
        );
      } else {
        setError(errorMessage || 'Failed to send quote via SMS. Please try again.');
      }
    } finally {
      setSendingSMS(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      setError('');
      
      // Make sure we have a valid quote ID
      if (!quote || !quote._id) {
        setError('Invalid quote information. Please refresh and try again.');
        setDeleting(false);
        return;
      }
      
      const response = await axiosInstance.delete(`/quotes/${quote._id}`);
      
      // If successful, call onDelete and close the modal
      if (response && response.data) {
        // Call the onDelete callback with the quote ID
        if (typeof onDelete === 'function') {
          onDelete(quote._id);
        }
        onClose();
      }
    } catch (error) {
      console.error('Error deleting quote:', error);
      
      // Handle 404 errors specifically
      if (error.response && error.response.status === 404) {
        setError('Quote not found. It may have been already deleted.');
        
        // Still call onDelete to update the UI
        if (typeof onDelete === 'function') {
          onDelete(quote._id);
        }
        
        // Close the modal after a short delay
        setTimeout(() => onClose(), 2000);
      } else {
        setError(error.response?.data?.message || 'Failed to delete quote. Please try again.');
      }
    } finally {
      setDeleting(false);
    }
  };

  const handleConvertToJob = async () => {
    try {
      setError('');
      setSuccess('');
      
      // Instead of immediately converting, mark the quote as ready for conversion
      const response = await axiosInstance.post(`/quotes/${quote._id}/mark-for-conversion`, {
        frequency: selectedFrequency
      });
      
      // Show success message
      setSuccess('Quote marked for conversion. You can now edit job details before finalizing.');
      
      // Update the quote status in the parent component
      if (typeof onConvertToJob === 'function') {
        onConvertToJob(quote._id, 'pending_conversion');
      }
      
      // Close the frequency modal but keep the quote modal open to show the success message
      setShowFrequencyModal(false);
    } catch (error) {
      console.error('Error marking quote for conversion:', error);
      setError(error.response?.data?.message || 'Failed to mark quote for conversion. Please try again.');
    }
  };

  // Add a function to update SMS consent
  const handleUpdateSmsConsent = async () => {
    if (!quote.customerId?._id) {
      setError('Cannot update SMS consent for this customer');
      return;
    }

    try {
      setError('');
      setSuccess('');
      
      await axiosInstance.put(`/customers/${quote.customerId._id}`, {
        smsConsent: true,
        smsOptOut: false
      });
      
      setSuccess('Customer SMS consent updated successfully. You can now send SMS messages.');
      
      // Refresh the quote data to get updated customer info
      const response = await axiosInstance.get(`/quotes/${quote._id}`);
      if (response.data) {
        // Update the quote in the parent component
        if (typeof onConvertToJob === 'function') {
          onConvertToJob(quote._id, response.data);
        }
      }
    } catch (error) {
      console.error('Error updating SMS consent:', error);
      setError('Failed to update SMS consent. Please try again.');
    }
  };

  const FrequencyModal = () => (
    <Modal
      isOpen={showFrequencyModal}
      onClose={() => setShowFrequencyModal(false)}
      title="Select Job Frequency"
    >
      <div className="p-6">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Job Frequency
          </label>
          <select
            value={selectedFrequency}
            onChange={(e) => setSelectedFrequency(e.target.value)}
            className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="one-time">One Time</option>
            <option value="weekly">Weekly</option>
            <option value="biweekly">Bi-Weekly</option>
            <option value="monthly">Monthly</option>
          </select>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowFrequencyModal(false)}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleConvertToJob}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
          >
            Convert to Job
          </button>
        </div>
      </div>
    </Modal>
  );

  if (!quote) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Quote #${quote.quoteNumber}`}
    >
      <div className="space-y-6 text-gray-300">
        <div className="flex justify-between items-start">
          <div>
            <h4 className="text-sm font-medium text-gray-400">Customer Information</h4>
            <p className="mt-1">{quote.customerId?.name || 'No Customer'}</p>
            {quote.customerId?.address && (
              <p className="mt-1">
                {quote.customerId.address.street}<br />
                {quote.customerId.address.city}, {quote.customerId.address.state} {quote.customerId.address.zipCode}
              </p>
            )}
            {/* Add SMS consent status */}
            {hasPhoneNumber && (
              <div className="mt-2 flex items-center">
                <PhoneIcon className="h-4 w-4 mr-1" />
                <span className="text-sm">{quote.customerId?.phone || quote.customerInfo?.phone}</span>
                {hasSmsConsent ? (
                  <span className="ml-2 flex items-center text-green-400 text-xs">
                    <CheckCircleIcon className="h-4 w-4 mr-1" />
                    SMS Enabled
                  </span>
                ) : (
                  <span className="ml-2 flex items-center text-yellow-400 text-xs">
                    <ExclamationCircleIcon className="h-4 w-4 mr-1" />
                    SMS Disabled
                    {quote.customerId?._id && (
                      <button 
                        onClick={handleUpdateSmsConsent}
                        className="ml-2 text-blue-400 hover:text-blue-300 underline"
                      >
                        Enable
                      </button>
                    )}
                  </span>
                )}
              </div>
            )}
            
            {/* Add Public Quote Link */}
            <div className="mt-3">
              <h4 className="text-sm font-medium text-gray-400">Public Quote Link</h4>
              <div className="flex items-center mt-2">
                <input
                  type="text"
                  readOnly
                  className="flex-1 px-3 py-2 bg-gray-700 border border-gray-600 rounded-l-md text-white text-sm"
                  value={`${window.location.origin}/quotes/view/${quote.quoteIdentifier}`}
                />
                <button
                  type="button"
                  onClick={() => {
                    navigator.clipboard.writeText(`${window.location.origin}/quotes/view/${quote.quoteIdentifier}`);
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000);
                  }}
                  className="px-3 py-2 bg-blue-600 text-white rounded-r-md hover:bg-blue-500 transition-colors"
                >
                  {copied ? 'Copied!' : 'Copy'}
                </button>
              </div>
              <p className="text-xs text-gray-400 mt-1">
                Share this link with your customer to view and respond to the quote
              </p>
            </div>
          </div>
          <div className="text-right">
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-400">Status:</span>
              <span className={`px-2 py-1 rounded text-xs ${
                quote.status === 'draft' ? 'bg-gray-600 text-gray-200' :
                quote.status === 'sent' ? 'bg-blue-900/50 text-blue-200' :
                quote.status === 'approved' ? 'bg-green-900/50 text-green-200' :
                quote.status === 'declined' ? 'bg-red-900/50 text-red-200' :
                quote.status === 'expired' ? 'bg-yellow-900/50 text-yellow-200' :
                'bg-gray-900/50 text-gray-200'
              }`}>
                {quote.status?.toUpperCase()}
              </span>
            </div>
            {quote.createdAt && (
              <p className="text-sm text-gray-400 mt-2">
                Created: {new Date(quote.createdAt).toLocaleDateString()}
              </p>
            )}
            {quote.emailSentDate && (
              <p className="text-sm text-gray-400 mt-1">
                Sent: {new Date(quote.emailSentDate).toLocaleDateString()}
              </p>
            )}
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-400">Line Items</h4>
          <div className="mt-2 space-y-2">
            {quote.lineItems.map((item, index) => (
              <div key={index} className="bg-gray-700 p-3 rounded">
                <div className="flex justify-between">
                  <span className="font-medium">{item.service}</span>
                  <span>${Number(item.price).toFixed(2)}</span>
                </div>
                {item.description && (
                  <p className="text-sm text-gray-400 mt-1">{item.description}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-400">Summary</h4>
          <div className="mt-2 space-y-1">
            <div className="flex justify-between">
              <span>Subtotal</span>
              <span>${quote.totalAmount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between">
              <span>Tax (5%)</span>
              <span>${(quote.totalAmount * 0.05).toFixed(2)}</span>
            </div>
            <div className="flex justify-between font-bold text-white">
              <span>Total</span>
              <span>${(quote.totalAmount * 1.05).toFixed(2)}</span>
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-400">Terms</h4>
          <p className="mt-1">{quote.terms}</p>
        </div>

        {error && (
          <div className="bg-red-900/50 border border-red-500 text-red-200 p-4 rounded-md">
            {error}
          </div>
        )}
        {success && (
          <div className="bg-green-900/50 border border-green-500 text-green-200 p-4 rounded-md">
            {success}
          </div>
        )}

        <div className="mt-8 pt-4 border-t border-gray-700">
          <div className="flex flex-wrap gap-3 justify-between">
            <button
              onClick={handleDelete}
              disabled={deleting || quote.status === 'converted'}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {deleting ? 'Deleting...' : 'Delete Quote'}
            </button>
            
            <div className="flex flex-wrap gap-3">
              <PDFDownloadLink
                document={
                  <QuotePDF
                    quote={{
                      ...quote,
                      totalPrice: quote.totalAmount
                    }}
                    customerInfo={quote.customerId || quote.customerInfo}
                    businessInfo={{
                      name: user?.name || 'Your Company Name',
                      email: user?.email || 'email@example.com',
                      phone: user?.phoneNumber || '123-456-7890',
                      website: 'www.autolawn.app',
                      address: user?.address || '123 Main St, City, State 12345'
                    }}
                  />
                }
                fileName={`${quote.quoteNumber}.pdf`}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                {({ blob, url, loading, error }) =>
                  loading ? 'Generating PDF...' : 'Download PDF'
                }
              </PDFDownloadLink>
              <button
                onClick={handleSendEmail}
                disabled={sending}
                className={`px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center`}
              >
                {sending ? (
                  <>
                    <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-opacity-20 rounded-full border-t-white"></div>
                    Sending...
                  </>
                ) : (
                  <>
                    <EnvelopeIcon className="h-4 w-4 mr-2" />
                    Send Email
                  </>
                )}
              </button>
              <button
                onClick={handleSendSMS}
                disabled={sendingSMS || !hasPhoneNumber || !hasSmsConsent}
                title={!hasPhoneNumber ? 'No phone number available' : !hasSmsConsent ? 'Customer has not consented to SMS' : ''}
                className={`px-4 py-2 ${hasPhoneNumber && hasSmsConsent ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-600 cursor-not-allowed'} text-white rounded transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center`}
              >
                {sendingSMS ? (
                  <>
                    <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-opacity-20 rounded-full border-t-white"></div>
                    Sending...
                  </>
                ) : (
                  <>
                    <PhoneIcon className="h-4 w-4 mr-2" />
                    Send SMS
                  </>
                )}
              </button>
              {quote.status !== 'converted' && (
                <button
                  onClick={() => setShowFrequencyModal(true)}
                  className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors"
                >
                  Convert to Job
                </button>
              )}
            </div>
          </div>
        </div>

        {quote.emailHistory && quote.emailHistory.length > 0 && (
          <div className="mt-6 pt-4 border-t border-gray-700">
            <h3 className="text-lg font-medium text-white mb-3">Email History</h3>
            <div className="space-y-2">
              {quote.emailHistory.map((email, index) => (
                <div key={index} className="flex justify-between items-center text-sm">
                  <div>
                    <span className="text-gray-400">Sent to:</span> {email.recipient}
                  </div>
                  <div>
                    <span className={`px-2 py-1 rounded text-xs ${
                      email.status === 'sent' ? 'bg-green-900/50 text-green-200' :
                      email.status === 'failed' ? 'bg-red-900/50 text-red-200' :
                      'bg-blue-900/50 text-blue-200'
                    }`}>
                      {email.status}
                    </span>
                  </div>
                  <div className="text-gray-400">
                    {new Date(email.sentAt).toLocaleDateString()}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Frequency Selection Modal */}
      <FrequencyModal />
    </Modal>
  );
};

export default QuoteModal; 