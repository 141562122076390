import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { 
  UserPlusIcon, 
  ArrowUpOnSquareIcon, 
  InformationCircleIcon,
  XMarkIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  DocumentTextIcon,
  CheckIcon,
  CreditCardIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';
import Select from 'react-select';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { PatternFormat } from 'react-number-format';

// Full list of U.S. states
const stateOptions = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

// Define libraries array outside component to avoid reloading warning
const libraries = ['places'];

const InfoTooltip = ({ text }) => (
  <div className="relative inline-block group ml-1 text-gray-400 cursor-pointer">
    <InformationCircleIcon className="h-5 w-5" />
    <div className="absolute left-1/2 bottom-full mb-2 -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-sm p-4 rounded-lg shadow-lg w-80 z-10">
      <div className="relative">
        <div className="absolute h-3 w-3 bg-gray-800 transform rotate-45 left-1/2 -translate-x-1/2 -bottom-1.5"></div>
        {text}
      </div>
    </div>
  </div>
);

const Customers = () => {
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      coordinates: []
    },
    notes: '',
    status: 'Active',
    yardSize: '',
    landscapingPotential: false,
    smsConsent: false,
    smsConsentSource: 'web_form',
    smsConsentDetails: {
      verificationMethod: null,
      notes: ''
    }
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [showCsvFormat, setShowCsvFormat] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [stripeSyncStatus, setStripeSyncStatus] = useState(null);
  const [stripeConnected, setStripeConnected] = useState(false);
  const [importFormat, setImportFormat] = useState('csv');

  // Add useEffect to check Stripe Connect status
  useEffect(() => {
    checkStripeStatus();
  }, []);

  const checkStripeStatus = async () => {
    try {
      const response = await axiosInstance.get('/connect/status');
      setStripeConnected(response.data.isOnboarded);
    } catch (error) {
      console.error('Error checking Stripe status:', error);
      setStripeConnected(false);
    }
  };

  // Load Google Maps Places API with static libraries array
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
      const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.long_name || '';
      const route = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
      const city = addressComponents.find(c => c.types.includes('locality') || c.types.includes('sublocality'))?.long_name || '';
      const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.short_name || '';
      const zipCode = addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '';
      
      // Get coordinates
      const coordinates = place.geometry?.location ? [
        place.geometry.location.lng(),
        place.geometry.location.lat()
      ] : [];

      setCustomer(prev => ({
        ...prev,
        address: {
          ...prev.address,
          street: `${streetNumber} ${route}`.trim(),
          city,
          state,
          zipCode,
          coordinates
        }
      }));
    }
  };

  const onLoadAutocomplete = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target || {};
    if (type === 'checkbox') {
      setCustomer(prev => ({ ...prev, [name]: checked }));
    } else if (name && name.includes('.')) {
      const [parent, child] = name.split('.');
      if (parent === 'smsConsentDetails') {
        setCustomer(prev => ({
          ...prev,
          smsConsentDetails: {
            ...prev.smsConsentDetails,
            [child]: value
          }
        }));
      } else {
        setCustomer(prev => ({
          ...prev,
          [parent]: {
            ...prev[parent],
            [child]: value
          }
        }));
      }
    } else {
      setCustomer(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleConsentChange = (e) => {
    const checked = e.target.checked;
    setCustomer(prev => ({
      ...prev,
      smsConsent: checked,
      smsConsentDate: checked ? new Date().toISOString() : null,
      // Reset consent details if unchecking
      smsConsentSource: checked ? prev.smsConsentSource : 'web_form',
      smsConsentDetails: checked ? prev.smsConsentDetails : {
        verificationMethod: null,
        notes: ''
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    setStripeSyncStatus(null);

    try {
      const formattedCustomer = {
        ...customer,
        phone: customer.phone.replace(/\D/g, '')
      };

      const response = await axiosInstance.post('/customers', formattedCustomer);
      
      // If Stripe is connected, show sync status
      if (stripeConnected) {
        setStripeSyncStatus(response.data.stripeSyncStatus);
        if (response.data.stripeSyncStatus === 'failed') {
          setError(`Customer created but Stripe sync failed: ${response.data.stripeSyncError}`);
        } else {
          setSuccess('Customer added successfully and synced with Stripe!');
        }
      } else {
        setSuccess('Customer added successfully!');
      }

      setCustomer({
        name: '',
        email: '',
        phone: '',
        address: { street: '', city: '', state: '', zipCode: '', coordinates: [] },
        notes: '',
        status: 'Active',
        yardSize: '',
        landscapingPotential: false,
        smsConsent: false,
        smsConsentSource: 'web_form',
        smsConsentDetails: {
          verificationMethod: null,
          notes: ''
        }
      });
    } catch (error) {
      console.error('Error creating customer:', error);
      setError(error.response?.data?.message || 'Failed to add customer. Please try again.');
    }
    setLoading(false);
  };

  const handleCsvUpload = async (e) => {
    e.preventDefault();
    if (!csvFile) {
      setError('Please select a CSV file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', csvFile);

    setLoading(true);
    try {
      const response = await axiosInstance.post('/customers/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Check for failed imports
      if (response.data.failed && response.data.failed > 0) {
        const successCount = response.data.total;
        const failedCount = response.data.failed;
        setSuccess(
          `Imported ${successCount} customers successfully, with ${failedCount} failed. ` +
          `Check the server logs for details about the failed imports.`
        );
      } else if (response.data.syncedWithStripe !== undefined) {
        setSuccess(
          `Customers imported successfully! Total: ${response.data.total}, ` +
          `Synced with Stripe: ${response.data.syncedWithStripe}, ` +
          `Failed to sync: ${response.data.syncFailed}`
        );
      } else {
        setSuccess(`${response.data.total} customers imported successfully!`);
      }
      
      // Show warning if no customers were imported
      if (response.data.total === 0) {
        setError('No customers were imported. This could be due to missing required fields or duplicate emails.');
      } else {
        setError(''); // Clear any previous errors
      }
      
      setCsvFile(null);
    } catch (error) {
      console.error('Error importing customers:', error);
      // Display more detailed error information
      if (error.response && error.response.data) {
        setError(`Import failed: ${error.response.data.message || error.response.data.details || 'Check the format of your CSV file. All required fields must be present.'}`);
      } else {
        setError('Failed to import customers. Ensure the CSV format is correct and all required fields are present.');
      }
    }
    setLoading(false);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'rgb(55, 65, 81)',
      borderColor: 'rgb(75, 85, 99)',
      color: 'white',
      '&:hover': {
        borderColor: 'rgb(96, 165, 250)'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgb(31, 41, 55)',
      borderColor: 'rgb(75, 85, 99)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgb(37, 99, 235)' : state.isFocused ? 'rgb(55, 65, 81)' : 'transparent',
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgb(55, 65, 81)',
      }
    }),
  };

  return (
    <div className="min-h-screen">
      <main className="py-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-white">Create Customer</h1>
            <p className="mt-1 text-gray-400">Add a new customer to your database</p>
          </div>
        </div>

        {/* Alerts */}
        {error && (
          <div className="mb-6 bg-red-500/10 border border-red-500 text-red-500 px-4 py-3 rounded-lg flex items-center">
            <XMarkIcon className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}
        {success && (
          <div className="mb-6 bg-green-500/10 border border-green-500 text-green-500 px-4 py-3 rounded-lg flex items-center">
            <CheckIcon className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        {/* Stripe Sync Status */}
        {stripeSyncStatus && (
          <div className={`mb-6 px-4 py-3 rounded-lg flex items-center ${
            stripeSyncStatus === 'synced' 
              ? 'bg-blue-500/10 border border-blue-500 text-blue-500'
              : stripeSyncStatus === 'pending'
                ? 'bg-yellow-500/10 border border-yellow-500 text-yellow-500'
                : 'bg-red-500/10 border border-red-500 text-red-500'
          }`}>
            <div className="flex items-center">
              <div className={`h-2 w-2 rounded-full mr-2 ${
                stripeSyncStatus === 'synced'
                  ? 'bg-blue-500'
                  : stripeSyncStatus === 'pending'
                    ? 'bg-yellow-500'
                    : 'bg-red-500'
              }`} />
              <span>
                {stripeSyncStatus === 'synced'
                  ? 'Synced with Stripe'
                  : stripeSyncStatus === 'pending'
                    ? 'Syncing with Stripe...'
                    : 'Stripe sync failed'}
              </span>
            </div>
          </div>
        )}

        {/* Create Customer Form */}
        <div className="bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
          <div className="p-6 border-b border-gray-700">
            <h2 className="text-xl font-semibold text-white flex items-center">
              <UserPlusIcon className="h-6 w-6 mr-2 text-blue-500" />
              Customer Information
              {stripeConnected && (
                <span className="ml-2 text-sm text-gray-400 flex items-center">
                  <CreditCardIcon className="h-4 w-4 mr-1" />
                  Will sync with Stripe
                </span>
              )}
            </h2>
          </div>

          <form onSubmit={handleSubmit} className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Basic Information */}
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="name"
                      value={customer.name}
                      onChange={handleChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <UserPlusIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      name="email"
                      value={customer.email}
                      onChange={handleChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <EnvelopeIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <PatternFormat
                      format="(###) ###-####"
                      mask="_"
                      value={customer.phone}
                      onValueChange={(values) => {
                        handleChange({
                          target: {
                            name: 'phone',
                            value: values.value
                          }
                        });
                      }}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <PhoneIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>
              </div>

              {/* Address Information */}
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Address <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    {isLoaded ? (
                      <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={handlePlaceChanged}
                      >
                        <input
                          type="text"
                          name="address.street"
                          value={customer.address.street}
                          onChange={handleChange}
                          className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          required
                        />
                      </Autocomplete>
                    ) : (
                      <input
                        type="text"
                        name="address.street"
                        value={customer.address.street}
                        onChange={handleChange}
                        className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        required
                      />
                    )}
                    <MapPinIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      City <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="address.city"
                      value={customer.address.city}
                      onChange={handleChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      State <span className="text-red-500">*</span>
                    </label>
                    <Select
                      options={stateOptions}
                      value={stateOptions.find(option => option.value === customer.address.state)}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'address.state',
                            value: selectedOption.value
                          }
                        });
                      }}
                      styles={customSelectStyles}
                      className="text-black"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Zip Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="address.zipCode"
                    value={customer.address.zipCode}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Additional Information */}
            <div className="mt-6 space-y-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Notes
                </label>
                <div className="relative">
                  <textarea
                    name="notes"
                    value={customer.notes}
                    onChange={handleChange}
                    rows="3"
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  ></textarea>
                  <DocumentTextIcon className="h-5 w-5 text-gray-400 absolute left-3 top-3" />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Yard Size
                  </label>
                  <input
                    type="text"
                    name="yardSize"
                    value={customer.yardSize}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="e.g., Large, 1 acre, etc."
                  />
                </div>

                <div className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="landscapingPotential"
                    checked={customer.landscapingPotential}
                    onChange={handleChange}
                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-600 rounded"
                  />
                  <label className="text-gray-300">
                    Landscaping Potential
                  </label>
                </div>
              </div>

              <div className="mt-4">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="smsConsent"
                      name="smsConsent"
                      type="checkbox"
                      checked={customer.smsConsent}
                      onChange={handleConsentChange}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="smsConsent" className="text-gray-300">
                      Customer agrees to receive SMS notifications about invoices, payments, and service updates
                    </label>
                    <p className="text-gray-500 text-xs mt-1">
                      Customer can opt out at any time by contacting us. Message and data rates may apply.
                    </p>
                  </div>
                </div>

                {customer.smsConsent && (
                  <div className="mt-4 space-y-4 bg-gray-900/50 p-4 rounded-lg">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-2">
                        Consent Source
                      </label>
                      <select
                        name="smsConsentSource"
                        value={customer.smsConsentSource}
                        onChange={handleChange}
                        className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      >
                        <option value="web_form">Web Form</option>
                        <option value="service_agreement">Service Agreement</option>
                        <option value="verbal">Verbal Consent</option>
                      </select>
                    </div>

                    {customer.smsConsentSource === 'verbal' && (
                      <>
                        <div>
                          <label className="block text-sm font-medium text-gray-300 mb-2">
                            Verification Method
                          </label>
                          <select
                            name="smsConsentDetails.verificationMethod"
                            value={customer.smsConsentDetails?.verificationMethod || ''}
                            onChange={handleChange}
                            className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            required
                          >
                            <option value="">Select method</option>
                            <option value="phone_call">Phone Call</option>
                            <option value="in_person">In Person</option>
                          </select>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-300 mb-2">
                            Consent Notes <span className="text-xs text-gray-500">(Required for verbal consent)</span>
                          </label>
                          <textarea
                            name="smsConsentDetails.notes"
                            value={customer.smsConsentDetails?.notes || ''}
                            onChange={handleChange}
                            placeholder="Document the conversation: who gave consent, what was explained, any specific preferences mentioned..."
                            rows="3"
                            className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            required
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="mt-8">
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50 flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Adding Customer...
                  </>
                ) : (
                  <>
                    <UserPlusIcon className="h-5 w-5 mr-2" />
                    Add Customer
                  </>
                )}
              </button>
            </div>
          </form>
        </div>

        {/* CSV Import Section */}
        <div className="mt-8 bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
          <div className="p-6 border-b border-gray-700 cursor-pointer" onClick={() => setShowCsvFormat(!showCsvFormat)}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <ArrowUpOnSquareIcon className="h-6 w-6 text-blue-500 mr-2" />
                <h2 className="text-xl font-semibold text-white">Bulk Import</h2>
                {stripeConnected && (
                  <span className="ml-2 text-sm text-gray-400 flex items-center">
                    <CreditCardIcon className="h-4 w-4 mr-1" />
                    Will sync with Stripe
                  </span>
                )}
              </div>
              <div className="flex items-center text-gray-400">
                <span className="mr-2 text-sm">{showCsvFormat ? 'Hide Details' : 'Show Details'}</span>
                <svg 
                  className={`h-5 w-5 transform transition-transform duration-200 ${showCsvFormat ? 'rotate-180' : ''}`} 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>

          {showCsvFormat && (
            <div className="p-6">
              {/* Simple Import Instructions */}
              <div className="mb-4 bg-blue-500/10 p-4 rounded-lg border border-blue-500/30 text-blue-300">
                <p className="mb-2"><strong>Bulk Import Instructions:</strong></p>
                <ol className="list-decimal list-inside space-y-2 text-sm">
                  <li>Download the template file below</li>
                  <li>Open the file in Excel, Google Sheets, or any spreadsheet program</li>
                  <li>Add your customer information in the same format as the example</li>
                  <li>Save the file (as .csv, .xlsx, or .xls)</li>
                  <li>Upload the file using the form below</li>
                </ol>
                <p className="mt-3 text-xs flex items-center">
                  <InformationCircleIcon className="h-4 w-4 mr-1" />
                  <span>Need more help? <a href="/training" className="text-blue-400 underline hover:text-blue-300">View our training videos</a></span>
                </p>
              </div>

              {/* Example Data Preview */}
              <div className="mb-4">
                <h4 className="font-medium text-blue-400 mb-2">Example Data Preview</h4>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-gray-800 border border-gray-700 rounded-lg text-xs">
                    <thead>
                      <tr className="bg-gray-700">
                        {['name', 'email', 'phone', 'street', 'city', 'state', 'zipCode', 'notes', 'yardSize', 'landscapingPotential', 'smsConsent', 'smsConsentSource', 'smsOptOut'].map((header, i) => (
                          <th key={i} className="px-2 py-1 text-left text-xs font-medium text-blue-300 uppercase tracking-wider border-b border-gray-600">
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-gray-700">
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">John Doe</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">john@example.com</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">(123) 456-7890</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">123 Main St</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">Anytown</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">PA</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">15237</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">Customer note</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">Large</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">true</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">true</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">web_form</td>
                        <td className="px-2 py-1 text-gray-300">false</td>
                      </tr>
                      <tr>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">Jane Smith</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">jane@example.com</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">(987) 654-3210</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">456 Oak Ave</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">Springfield</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">PA</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">15101</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">New customer</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">Small</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">false</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">false</td>
                        <td className="px-2 py-1 text-gray-300 border-r border-gray-600">web_form</td>
                        <td className="px-2 py-1 text-gray-300">false</td>
                      </tr>
                    </tbody>
                  </table>
                  
                  <p className="text-xs text-gray-400 mt-2">
                    This is how your data should be structured in the spreadsheet.
                  </p>
                </div>
              </div>

              {/* Download Template Button */}
              <div className="mb-6 flex justify-center">
                <button
                  className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
                  onClick={() => {
                    // Generate CSV content with PA state code and correct smsConsentSource (web_form)
                    const csvContent = "name,email,phone,street,city,state,zipCode,notes,yardSize,landscapingPotential,smsConsent,smsConsentSource,smsOptOut\nJohn Doe,john@example.com,555-123-4567,123 Main St,Pittsburgh,PA,15237,Regular customer,Medium,High,Yes,web_form,No\nArby's,arby@example.com,555-987-6543,456 Oak Ave,Philadelphia,PA,19123,Commercial customer,Large,Medium,Yes,web_form,No";
                    
                    // Create blob and download
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(blob);
                    
                    link.setAttribute('href', url);
                    link.setAttribute('download', 'customer_import_template.csv');
                    link.style.visibility = 'hidden';
                    
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
                  Download Template
                </button>
              </div>

              <form onSubmit={handleCsvUpload} className="space-y-4">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Select Import File
                  </label>
                  <input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={(e) => setCsvFile(e.target.files[0])}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  />
                  <p className="text-xs text-gray-400 mt-1">
                    Supported formats: CSV (.csv), Excel (.xlsx, .xls)
                  </p>
                </div>

                <button
                  type="submit"
                  disabled={loading || !csvFile}
                  className="w-full bg-gradient-to-r from-green-500 to-green-600 text-white px-6 py-3 rounded-lg font-medium hover:from-green-600 hover:to-green-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-green-500/50 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                    <>
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                      {stripeConnected ? 'Importing & Syncing...' : 'Importing...'}
                    </>
                  ) : (
                    <>
                      <ArrowUpOnSquareIcon className="h-5 w-5 mr-2" />
                      Import Customers
                    </>
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Customers;
